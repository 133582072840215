<div class="page-container">
    <ngx-spinner bdColor="#000000aa" size="medium" color="#64b2cd" type="line-scale-pulse-out-rapid" [fullScreen]="true">
        <h6>Processando...</h6>
    </ngx-spinner>

    <div class="working-area p-4">
        <h1 class="page-title m-0">
            <i class="fas fa-chart-pie mr-2"></i>
            <span>Dashboard</span>
        </h1>

        <p class="page-subtitle m-0 pl-4 pt-3 pr-2 pb-2">
            <i class="fas fa-level-up-alt mr-2"></i>
            Confira aqui as últimas informações sobre a execução de Fluxos das Caixas às quais você tem acesso.
        </p>

        <hr />

        <div class="section mt-4">
            <div class="filter-config">
                <div class="header">
                    <div class="config-title">
                        <div class="title-container">
                            <h3 class="title m-0">
                                <span class="label">Painel de Fluxos Instanciados</span>
                            </h3>
                        </div>
                    </div>
                </div>

                <hr />

                <h5 class="subtitle mb-3">Adicionar critérios para filtragem</h5>

                <div class="config-inputs">
                    <!--<div class="custom-filter-search mb-4 mr-2">
                        <input type="text" class="form-control" minlength="3" maxlength="200"
                                placeholder="Filtrar pelo nome do registro"
                                (keyup.enter)="filterByPipe()"
                                [(ngModel)]="customFilterSearch"
                        >
                        <span class="search-button-container" (click)="filterByPipe()">
                            <i class="fas fa-search" matTooltip="Filtrar pelo nome do registro"></i>
                        </span>
                    </div>-->

                    <div class="predefined-filter-search ml-4">
                        <div class="predefined-filter-section mt-3" [class.closed]="!isShowingFlowInfoSection">
                            <div class="title text-highlight relevant-info-font" (click)="toggleFlowInfoSection()">
                                <i class="toggle-section fas fa-chevron-circle-right mr-1" [class.on]="isShowingFlowInfoSection"></i>
                                <span>Identificação</span>
                                <i class="toggle-section fas fa-chevron-circle-left ml-1" [class.on]="isShowingFlowInfoSection"></i>
                            </div>

                            <div class="section-content" [class.invisible]="!isShowingFlowInfoSection">
                                <div class="keep-left">
                                    <div class="form-group m-0">
                                        <label for="selectedFlowInbox">Caixa de Fluxos <b style="color: red">*</b></label>
                                        <select class="form-control" id="selectedFlowInbox"
                                                [(ngModel)]="selectedFlowInbox"
                                                (change)="getFlowDefinitions()"
                                        >
                                            <option *ngFor="let item of getOrderedFlowInboxesList()" value="{{item.guid}}">
                                                {{item.organizacao.sigla}} - {{item.nomeCurto}}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="form-group m-0 ml-3">
                                        <label for="selectedFlowDefinition">Fluxo</label>
                                        <select class="form-control" id="selectedFlowDefinition"
                                                [disabled]="selectedFlowInbox == null"
                                                [(ngModel)]="selectedFlowDefinition"
                                                (change)="getVersions()"
                                        >
                                            <option value="null"></option>
                                            <option *ngFor="let item of getOrderedFlowDefinitionsList()" value="{{item.id}}">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="form-group m-0 ml-3">
                                        <label for="selectedVersion">Versão</label>
                                        <select class="form-control" id="selectedVersion"
                                                [disabled]="selectedFlowDefinition == null"
                                                [(ngModel)]="selectedVersion"
                                        >
                                            <option value="null"></option>
                                            <option *ngFor="let item of filteredVersions" value="{{item}}">
                                                {{item}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <button type="button" class="btn btn-outline-primary py-0 px-2 ml-3"
                                        matTooltip="Adicionar critério"
                                        [disabled]="selectedFlowInbox == null"
                                        (click)="addFlowInfoFilterCriteria()"
                                >
                                    <i class="fas fa-plus-circle mr-1"></i>
                                    <span>Adicionar critério</span>
                                </button>

                                <button type="button" class="btn btn-outline-danger py-0 px-2 ml-1"
                                        matTooltip="Limpar valores"
                                        [disabled]="selectedFlowInbox == null"
                                        (click)="clearFlowInfoFilterCriteria()"
                                >
                                    <i class="fas fa-times-circle mr-1"></i>
                                    <span>Limpar</span>
                                </button>
                            </div>
                        </div>

                        <div class="chip-container mt-3" *ngIf="getSearchTokensFlowInfo().length == 0">
                            <div class="chip default-filter">
                                <span>Sem Filtros</span>
                            </div>
                        </div>

                        <div class="chip-container" [class.mt-3]="i == 0" [class.mt-2]="i > 0" *ngFor="let timestamp of getFlowInfoTimestamps(); let i = index">
                            <div class="or-statement text-highlight" [class.invisible]="i == 0" *ngIf="getFlowInfoTimestamps().length > 1">OU</div>

                            <div class="chip" *ngFor="let searchToken of getSearchTokensFlowInfo(timestamp)">
                                <span>{{searchToken.text}}</span>
                                <i class="icon fas fa-times-circle ml-1"
                                    matTooltip="Remover filtro"
                                    (click)="removeFilter(searchToken)"
                                ></i>
                            </div>
                        </div>

                        <div class="predefined-filter-section flex-column align-items-start mt-5" [class.closed]="!isShowingFinishDateSection">
                            <div class="title text-highlight relevant-info-font" (click)="toggleFinishDateSection()">
                                <i class="toggle-section fas fa-chevron-circle-right mr-1" [class.on]="isShowingFinishDateSection"></i>
                                <span>Data de Encerramento</span>
                                <i class="toggle-section fas fa-chevron-circle-left ml-1" [class.on]="isShowingFinishDateSection"></i>
                            </div>

                            <div class="subtitle relevant-info-font my-2" [class.invisible]="!isShowingFinishDateSection">• Intervalo predeterminado:</div>

                            <div class="section-content" [class.invisible]="!isShowingFinishDateSection">
                                <div class="predefined-datetime button-text ml-4">
                                    <button type="button" class="btn btn-outline-primary py-0 px-2"
                                            matTooltip="Adicionar critério"
                                            (click)="addCustomFinishDateFilterCriteria(CustomDateTimeCriteriaType.Today)"
                                    >
                                        <i class="fas fa-clock mr-1"></i>
                                        <span>Hoje</span>
                                    </button>

                                    <button type="button" class="btn btn-outline-primary py-0 px-2 ml-2"
                                            matTooltip="Adicionar critério"
                                            (click)="addCustomFinishDateFilterCriteria(CustomDateTimeCriteriaType.Last24)"
                                    >
                                        <i class="fas fa-clock mr-1"></i>
                                        <span>Últimas 24h</span>
                                    </button>

                                    <button type="button" class="btn btn-outline-primary py-0 px-2 ml-2"
                                            matTooltip="Adicionar critério"
                                            (click)="addCustomFinishDateFilterCriteria(CustomDateTimeCriteriaType.Last48)"
                                    >
                                        <i class="fas fa-clock mr-1"></i>
                                        <span>Últimas 48h</span>
                                    </button>

                                    <button type="button" class="btn btn-outline-primary py-0 px-2 ml-2"
                                            matTooltip="Adicionar critério"
                                            (click)="addCustomFinishDateFilterCriteria(CustomDateTimeCriteriaType.Last72)"
                                    >
                                        <i class="fas fa-clock mr-1"></i>
                                        <span>Últimas 72h</span>
                                    </button>

                                    <button type="button" class="btn btn-outline-primary py-0 px-2 ml-2"
                                            matTooltip="Adicionar critério"
                                            (click)="addCustomFinishDateFilterCriteria(CustomDateTimeCriteriaType.LastWeek)"
                                    >
                                        <i class="fas fa-clock mr-1"></i>
                                        <span>Última semana</span>
                                    </button>

                                    <button type="button" class="btn btn-outline-primary py-0 px-2 ml-2"
                                            matTooltip="Adicionar critério"
                                            (click)="addCustomFinishDateFilterCriteria(CustomDateTimeCriteriaType.LastFortnight)"
                                    >
                                        <i class="fas fa-clock mr-1"></i>
                                        <span>Última quinzena</span>
                                    </button>

                                    <button type="button" class="btn btn-outline-primary py-0 px-2 ml-2"
                                            matTooltip="Adicionar critério"
                                            (click)="addCustomFinishDateFilterCriteria(CustomDateTimeCriteriaType.LastMonth)"
                                    >
                                        <i class="fas fa-clock mr-1"></i>
                                        <span>Último mês</span>
                                    </button>

                                    <button type="button" class="btn btn-outline-primary py-0 px-2 ml-2"
                                            matTooltip="Adicionar critério"
                                            (click)="addCustomFinishDateFilterCriteria(CustomDateTimeCriteriaType.LastYear)"
                                    >
                                        <i class="fas fa-clock mr-1"></i>
                                        <span>Último ano</span>
                                    </button>
                                </div>
                            </div>

                            <div class="subtitle relevant-info-font mt-3" [class.invisible]="!isShowingFinishDateSection">• Intervalo customizado:</div>

                            <div class="section-content" [class.invisible]="!isShowingFinishDateSection">
                                <div class="custom-datetime ml-4">
                                    <div class="keep-left">
                                        <div class="form-group m-0">
                                            <label for="selectedFromDateTime">De</label>
                                            <div class="d-flex">
                                                <input type="text" class="datetime form-control" id="selectedFromDateTime" name="selectedFromDateTime"
                                                       [(ngModel)]="selectedFromDateTime"
                                                       [ngxMatDatetimePicker]="selectedFromDateTimePicker"
                                                       [disabled]="true"
                                                >
                                                <mat-datepicker-toggle matSuffix [for]="selectedFromDateTimePicker"></mat-datepicker-toggle>
                                                <ngx-mat-datetime-picker #selectedFromDateTimePicker
                                                                         [showSpinners]="true"
                                                                         [showSeconds]="true"
                                                                         [defaultTime]="[12,00,00]"
                                                                         [disabled]="false"
                                                >
                                                    <ng-template>
                                                        <mat-icon>done</mat-icon>
                                                        <span>OK</span>
                                                    </ng-template>
                                                </ngx-mat-datetime-picker>
                                            </div>
                                        </div>

                                        <div class="form-group m-0 ml-3">
                                            <label for="selectedUntilDateTime">Até</label>
                                            <div class="d-flex">
                                                <input type="text" class="datetime form-control" id="selectedUntilDateTime" name="selectedUntilDateTime"
                                                       [(ngModel)]="selectedUntilDateTime"
                                                       [ngxMatDatetimePicker]="selectedUntilDateTimePicker"
                                                       [disabled]="true"
                                                >
                                                <mat-datepicker-toggle matSuffix [for]="selectedUntilDateTimePicker"></mat-datepicker-toggle>
                                                <ngx-mat-datetime-picker #selectedUntilDateTimePicker
                                                                         [showSpinners]="true"
                                                                         [showSeconds]="true"
                                                                         [defaultTime]="[12,00,00]"
                                                                         [disabled]="false"
                                                >
                                                    <ng-template>
                                                        <mat-icon>done</mat-icon>
                                                        <span>OK</span>
                                                    </ng-template>
                                                </ngx-mat-datetime-picker>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="button" class="btn btn-outline-primary py-0 px-2 ml-3"
                                            matTooltip="Adicionar critério"
                                            [disabled]="selectedFromDateTime == null && selectedUntilDateTime == null"
                                            (click)="addPredefinedFinishDateFilterCriteria()"
                                    >
                                        <i class="fas fa-plus-circle mr-1"></i>
                                        <span>Adicionar critério</span>
                                    </button>

                                    <button type="button" class="btn btn-outline-danger py-0 px-2 ml-1"
                                            matTooltip="Limpar valores"
                                            [disabled]="selectedFromDateTime == null && selectedUntilDateTime == null"
                                            (click)="clearFinishDateFilterCriteria()"
                                    >
                                        <i class="fas fa-times-circle mr-1"></i>
                                        <span>Limpar</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="chip-container mt-3">
                            <div class="chip default-filter" *ngIf="getSearchTokensFinishDate().length == 0; else searchTokensTemplate">
                                <span>Sem Filtros</span>
                            </div>
                            <ng-template #searchTokensTemplate>
                                <div class="chip" *ngFor="let searchToken of getSearchTokensFinishDate()">
                                    <span>{{searchToken.text}}</span>
                                    <i class="icon fas fa-times-circle ml-1"
                                       matTooltip="Remover filtro"
                                       (click)="removeFilter(searchToken)"
                                    ></i>
                                </div>
                            </ng-template>
                        </div>

                        <div class="predefined-filter-section button-text filter-results">
                            <div class="section-content">
                                <button type="button" class="btn btn-outline-primary py-0 px-3"
                                        matTooltip="Trazer estatísticas de acordo com os filtros estabelecidos"
                                        [disabled]="shouldDisabledSearch()"
                                        (click)="getFilteredResults()"
                                >
                                    <i class="fas fa-chart-bar mr-2"></i>
                                    <span>Mostrar estatísticas</span>
                                </button>

                                <button type="button" class="btn btn-outline-primary py-0 px-3 ml-3"
                                        matTooltip="Exportar informações dos Fluxos e valores dos formulários preenchidos em cada um deles para arquivos em formato CSV"
                                        [disabled]="shouldDisabledSearch()"
                                        (click)="exportCsv()"
                                >
                                    <i class="fas fa-file-csv mr-2"></i>
                                    <span>Exportar dados em formato CSV</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="results-area" id="flow-instance-results-area" *ngIf="filteredResults != null" #flowInstanceResultsAreaRef>
                <hr />

                <div class="results-area-section counters mt-3">
                    <dashboard-item class="col-4 p-0"
                                    [inputCounter]="filteredResults.totalFinishedFlowInstances"
                                    [inputCounterLabel]="DashboardAdminDescription.get('totalFinishedFlowInstances')"
                    ></dashboard-item>
                    <dashboard-item class="col-4 p-0 pl-2"
                                    [inputCounter]="filteredResults.averageFinishedFlowInstancesDay"
                                    [inputCounterLabel]="DashboardAdminDescription.get('averageFinishedFlowInstancesDay')"
                    ></dashboard-item>
                    <dashboard-item class="col-4 p-0 pl-2"
                                    [inputCounter]="filteredResults.averageFlowInstanceFinishingTimeSecondsProcessed"
                                    [inputCounterLabel]="DashboardAdminDescription.get('averageFlowInstanceFinishingTimeSeconds')"
                    ></dashboard-item>
                </div>

                <div class="results-area-section mt-5">
                    <div class="chart-container pb-2">
                        <label class="relevant-info-font mt-3">Porcentagem de Fluxos executados por dia da semana no período</label>
                        <ngx-charts-bar-vertical xAxisLabel="Dia"
                                                 yAxisLabel="%"
                                                 legendTitle=""
                                                 [view]="[500, 300]"
                                                 [results]="percentagePerWeekdayResults"
                                                 [xAxis]="true"
                                                 [showXAxisLabel]="false"
                                                 [yAxis]="true"
                                                 [showYAxisLabel]="true"
                                                 [legend]="false"
                                                 [gradient]="false"
                                                 [showDataLabel]="true"
                        ></ngx-charts-bar-vertical>
                    </div>

                    <div class="chart-container pb-2 ml-3">
                        <label class="relevant-info-font mt-3">Porcentagem de Fluxos executados por hora do dia no período</label>
                        <ngx-charts-bar-vertical xAxisLabel="Hora"
                                                 yAxisLabel="%"
                                                 legendTitle=""
                                                 [view]="[830, 300]"
                                                 [results]="percentagePerDayHourResults"
                                                 [xAxis]="true"
                                                 [showXAxisLabel]="false"
                                                 [yAxis]="true"
                                                 [showYAxisLabel]="true"
                                                 [legend]="false"
                                                 [gradient]="false"
                                                 [showDataLabel]="true"
                        ></ngx-charts-bar-vertical>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
