<ng-container *ngIf="ngModel != null">
    <!-- Editor -->
    <div class="wrapper position-relative">
        <ngx-spinner name="tinyMceSpinner" bdColor="rgba(255, 255, 255, 1)" size="medium" color="#64b2cd" type="line-scale-pulse-out-rapid" [fullScreen]="false"></ngx-spinner>

        <editor *ngIf="inputShouldLoadTinyMce"
                [ngModel]="ngModel"
                [init]="inputTinyMceOptions"
                (ngModelChange)="editorNgModelChange($event)"
                (onInitNgModel)="tinyMceFinishedLoading()"
        ></editor>

        <textarea id="tinyMce" class="d-none"></textarea>
    </div>

    <!-- Botões -->
    <div class="form-footer text-right mt-4 mb-1 mx-1">
        <hr />

        <!-- Cancelar -->
        <button type="button" class="btn btn-outline-danger mr-2"
                (click)="toggleEditArea()"
        >Cancelar</button>

        <!-- Salvar Modelo de Documento -->
        <button type="button" class="btn btn-outline-primary"
                [class.read-only]="inputIsReadOnlyMode"
                [disabled]="inputIsReadOnlyMode"
                (click)="onSubmit()"
        >Salvar Modelo de Documento</button>
    </div>
</ng-container>
