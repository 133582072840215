import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output
} from "@angular/core";
import {
    FlowObjectDefinition,
    FlowObjectType,
    FormSchema
} from "../../../../models/flow-object.model";
import {
    ConfigSchema,
    GatewayPathsCondition,
    GatewayRuleOperator,
    GatewayRuleOperatorDescription
} from "../../../../models/config-schema.model";
import { FlowDefinition } from "../../../../models/flow.model";
import { ToastrService } from "ngx-toastr";
import { Enums } from "src/app/shared/enums";
import { Utils } from "../../../../shared/utils";
import { IBaseOption } from "../../../../models/base.model";
import { FlowObjectDefinitionService } from "../../../../services/flow-object-definition.service";

@Component({
    selector: "flow-object-details-gateway-paths",
    templateUrl: "./flow-object-details-gateway-paths.component.html",
    styleUrls: ["./flow-object-details-gateway-paths.component.scss"]
})

export class FlowObjectDetailsGatewayPathsComponent implements OnInit {
    GatewayRuleOperator: typeof GatewayRuleOperator = GatewayRuleOperator;

    model: FlowObjectDefinition;
    configSchema = new ConfigSchema();
    firstGatewayPathName: string;
    secondGatewayPathName: string;
    isLogicalOperatorAnd: boolean = false;
    conditions: GatewayPathsCondition[] = [new GatewayPathsCondition()];
    formFieldOptions: IBaseOption[] = [];
    gatewayRuleOperatorOptions: IBaseOption[] = [];

    @Input() inputModel: FlowObjectDefinition;
    @Output() inputModelChange = new EventEmitter<FlowObjectDefinition>();

    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Output() outputSubmitEvent = new EventEmitter<FlowObjectDefinition>();
    @Output() outputCloseEvent = new EventEmitter<any>();

    constructor(
        private toastr: ToastrService,
        private flowObjectDefinitionService: FlowObjectDefinitionService
    ) {
        for (let item in GatewayRuleOperator) {
            if (
                !isNaN(parseInt(item))
                && parseInt(item) != GatewayRuleOperator.IsNull
                && parseInt(item) != GatewayRuleOperator.IsNotNull
            ) {
                this.gatewayRuleOperatorOptions.push({
                    value: +item,
                    description: GatewayRuleOperatorDescription.get(+item)
                });
            }
        }
    }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        setTimeout(async () => {
            this.model = this.inputModel;
            if (this.model?.configSchema != null) {
                this.configSchema = JSON.parse(this.model.configSchema) as ConfigSchema;

                this.firstGatewayPathName = this.configSchema.taskGatewayPaths.firstGatewayPathName;
                this.secondGatewayPathName = this.configSchema.taskGatewayPaths.secondGatewayPathName;
                this.isLogicalOperatorAnd = this.configSchema.taskGatewayPaths.logicalOperator == 'and';
                this.conditions = this.configSchema.taskGatewayPaths.conditions.length == 0
                    ? this.conditions
                    : this.configSchema.taskGatewayPaths.conditions;
            }

            let formFlowObject = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.typeId == FlowObjectType.StartForm);
            if (formFlowObject != null && !Utils.isNullOrEmpty(this.inputFlowDefinition.ownerInfo)) {
                if (Utils.isNullOrEmpty(formFlowObject.formSchema)) {
                    const response = await this.flowObjectDefinitionService.getFormData(formFlowObject.id);

                    if (!response.isSuccess) {
                        this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                        return;
                    }

                    formFlowObject.formSchema = response.data;
                }

                let formSchema = JSON.parse(formFlowObject.formSchema) as FormSchema;
                let formFlowObjectSchemaFields = Utils.getFormFieldsWithUsableValue(formSchema.components);
                this.formFieldOptions = formFlowObjectSchemaFields.map(x => ({ value: x.key, description: x.label }));
            }

            for (let item of this.conditions) {
                this.ruleOperatorChange(item);
            }
        }, 1);
    }

    // ======================
    // public methods
    // ======================

    ruleOperatorChange(condition: GatewayPathsCondition) {
        if (condition.ruleOperator < GatewayRuleOperator.IsTrue || condition.ruleOperator > GatewayRuleOperator.IsNotEmpty) {
            condition.referenceValueDisabled = false;
        } else {
            condition.referenceValue = null;
            condition.referenceValueDisabled = true;
        }
    }

    getFormFieldDescription(condition: GatewayPathsCondition): string {
        return this.formFieldOptions.find(x => x.value == condition.formField)?.description;
    }

    getRuleOperatorDescription(condition: GatewayPathsCondition): string {
        return this.gatewayRuleOperatorOptions.find(x => x.value == condition.ruleOperator)?.description;
    }

    addCondition() {
        this.conditions.push(new GatewayPathsCondition());
    }

    removeCondition(condition: GatewayPathsCondition) {
        this.conditions = this.conditions.filter(x => x.timestamp != condition.timestamp);
    }

    onSubmit() {
        if (this.inputIsReadOnlyMode || this.isInvalidConfiguration()) return false;

        this.configSchema.taskGatewayPaths.firstGatewayPathName = this.firstGatewayPathName;
        this.configSchema.taskGatewayPaths.secondGatewayPathName = this.secondGatewayPathName;
        this.configSchema.taskGatewayPaths.logicalOperator = this.isLogicalOperatorAnd ? 'and' : 'or';
        this.configSchema.taskGatewayPaths.conditions = this.conditions;

        this.model.configSchema = JSON.stringify(this.configSchema);
        this.outputSubmitEvent.emit(this.model);
    }

    closeForm() {
        this.outputCloseEvent.emit();
    }

    // ======================
    // private methods
    // ======================

    private isInvalidConfiguration() {
        for (let condition of this.conditions) {
            if (
                Utils.isNullOrEmpty(condition.formField)
                || (
                    Utils.isNullOrEmpty(condition.referenceValue)
                    && (condition.ruleOperator < GatewayRuleOperator.IsTrue || condition.ruleOperator > GatewayRuleOperator.IsNotEmpty)
                )
            ) {
                this.toastr.warning(Enums.Messages.NullOrEmpty, Enums.Messages.Warning, Utils.getToastrErrorOptions());
                return true;
            }

            if (
                condition.ruleOperator > GatewayRuleOperator.NotEqual
                && condition.ruleOperator < GatewayRuleOperator.IsTrue
                && isNaN(Number(condition.referenceValue))
            ) {
                this.toastr.warning(Enums.Messages.IsNotNumber, Enums.Messages.Warning, Utils.getToastrErrorOptions());
                return true;
            }
        }

        return false;
    }
}
