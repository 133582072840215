<div class="wrapper">
    <input type="text" class="datetime form-control mr-1" name="dateTimePicker"
           [(ngModel)]="value"
           [disabled]="true"
           [ngxMatDatetimePicker]="dateTimePicker"
           (dateInput)="onChange()"
    >
    <mat-datepicker-toggle matSuffix [for]="dateTimePicker"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #dateTimePicker
                             [showSpinners]="true"
                             [showSeconds]="true"
                             [defaultTime]="[12,00,00]"
                             [disabled]="disabled"
    >
        <ng-template>
            <mat-icon>done</mat-icon>
            <span>OK</span>
        </ng-template>
    </ngx-mat-datetime-picker>
</div>
