import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'help-menu',
    templateUrl: './help-menu.component.html',
    styleUrls: ['./help-menu.component.scss']
})
export class HelpMenuComponent {
    // #region [ViewChild]
    @ViewChild('dropdownMenuRef') dropdownMenuRef: ElementRef;
    @ViewChild('headerButtonRef') headerButtonRef: ElementRef;
    // #endregion

    // #region [HostListener]
    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.dropdownMenuRef.nativeElement.classList.contains('show')) {
            if (!event.composedPath().includes(this.dropdownMenuRef.nativeElement)) {
                this.toggleMenu();
            }
        } else {
            if (event.composedPath().includes(this.headerButtonRef.nativeElement)) {
                this.toggleMenu();
            }
        }
    }
    // #endregion

    // #region [properties]
    isHintModeOn: boolean = false;
    // #endregion

    constructor(private router: Router) {
        router.events.forEach(event => {
            if (event instanceof NavigationEnd) {
                this.isHintModeOn = false;
            }
        });
    }

    // ======================
    // lifecycle methods
    // ======================

    // ======================
    // public methods
    // ======================

    toDocs() {
        open('https://docs.admin.e-flow.es.gov.br');
    }

    toggleMenu() {
        this.dropdownMenuRef.nativeElement.classList.toggle('show');
    }

    // ======================
    // private methods
    // ======================
}
