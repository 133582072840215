export const FLOW_DEFINITION_DETAILS_TINYMCE_OPTIONS = {
    selector: '#tinyMceDefinitionDetails',
    language: 'pt_BR',
    height: '220px',
    content_css: '/assets/css/flow-definition-details-tinymce.css',
    body_class: 'tinymce-body',
    theme: 'silver',
    //forced_root_block: false,
    statusbar: false,
    menubar: false,
    contextmenu: false,
    branding: false,
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt',

    // evita que o tinyMce altere as URLs das imagens fornecidas
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,
    default_link_target: '_blank',
    image_uploadtab: false,
    image_description: false,

    plugins: [
        //'preview',
        'fullscreen',
        'code link image'
    ],

    toolbar1: 'styleselect fontsizeselect | searchreplace | charmap subscript superscript | link | image | code fullscreen',
    toolbar2: 'alignleft aligncenter | bold italic underline | forecolor backcolor | bullist numlist | removeformat | undo redo | visualchars visualblocks | vEDocs',
    //toolbar1: 'fontsizeselect | searchreplace | code fullscreen',
    //toolbar1: 'styleselect fontsizeselect | outdent indent | searchreplace | charmap subscript superscript | removeformat',
    //toolbar2: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table | undo redo | visualchars visualblocks | code fullscreen',
    //toolbar3: 'pdfPreview | vPessoas vLocais vDatas vForm',

    table_toolbar: '',

    mobile: {
        theme: 'mobile',
        plugins: ['autosave', 'lists', 'autolink'],
        toolbar: ['undo', 'bold', 'italic', 'styleselect'],
        height: '50px'
    }
};
