import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FlowDefinition } from '../models/flow.model';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import { DashboardServiceGetParams } from './interfaces/dashboard.interface';
import { DashboardAdmin, FlowInstanceCsv } from '../models/dashboard.model';

@Injectable({
    providedIn: 'root'
})
export class DashboardService extends BaseService {
    controllerRoute: string = 'Dashboard';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async getFlowDefinitionsByFlowInboxId(id: string): Promise<IBaseModel<FlowDefinition[]>> {
        return this.httpClient.get<IBaseModel<FlowDefinition[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/GetFlowDefinitionsByFlowInboxId/${id}`).toPromise();
    }

    public async getFilteredResults(data: DashboardServiceGetParams): Promise<IBaseModel<DashboardAdmin>> {
        return this.httpClient.post<IBaseModel<DashboardAdmin>>(`${this.apiBaseUrl}/${this.controllerRoute}/GetFilteredResults`, data).toPromise();
    }

    public async exportCsv(data: DashboardServiceGetParams): Promise<IBaseModel<FlowInstanceCsv[]>> {
        return this.httpClient.post<IBaseModel<FlowInstanceCsv[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/ExportCsv`, data).toPromise();
    }
}
