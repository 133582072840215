import { Unidade } from "./organograma.model";

export class AgentePublico {
    sub?: string;
    subDescontinuado?: number;
    nome?: string;
    apelido?: string;
    email?: string;
}

export class AgentePublicoPapel {
    guid?: string;
    nome?: string;
    tipo?: string;
    lotacaoGuid?: string;
    lotacao?: Unidade;
    agentePublicoSub?: string;
    agentePublicoNome?: string;
    prioritario?: boolean;
    perfis?: Perfil[];
}

export class ConjuntoGrupo {
    guid?: string;
    nome?: string;
    conjuntoPai?: string;
    tipoNome?: string;
    tipoId?: number;
}

export class PermissaoUsuario {
    papeis?: AgentePublicoPapel[];
}

export class Perfil {
    guid?: string;
    nome?: string;
    descricao?: string;
    orgaos?: Orgao[];
    recursos?: Recurso[];
}

export class Orgao {
    guid?: string;
    nome?: string;
}

export class Recurso {
    guid?: string;
    nome?: string;
    descricao?: string;
    acoes?: Acao[];
}

export class Acao {
    guid?: string;
    nome?: string;
    descricao?: string;
}

export enum TipoFiltro {
    TodosGrupoTrabalho = 'TodosGrupoTrabalho',
    TodosLotacao = 'TodosLotacao',
    TodosNaoLotacaoSemPermissao = 'TodosNaoLotacaoSemPermissao',
    Todos = 'Todos',
    Lotacao = 'Lotacao',
    LotacaoComFilhos = 'LotacaoComFilhos',
    OcupacaoPorLotacao = 'OcupacaoPorLotacao',
    OcupacaoPorLotacaoComFilhos = 'OcupacaoPorLotacaoComFilhos',
    GrupoTrabalho = 'GrupoTrabalho',
    GrupoTrabalhoComLider = 'GrupoTrabalhoComLider',
    ComissaoSiarhes = 'ComissaoSiarhes',
    GrupoPermissao = 'GrupoPermissao'
}
