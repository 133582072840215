import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { Enums } from '../../../shared/enums';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    yearComplement: string;
    env: string;
    environment: string;

    get isLandingPage(): boolean {
        return window?.location?.pathname == Enums.PagesPaths.LandingPage;
    }
    get shouldHideEnvironment(): boolean {
        return this.env?.toLowerCase() == 'prd' || this.isLandingPage;
    }

    constructor(
        private cookieService: CookieService,
        private titleService: Title
    ) {
        this.yearComplement = (new Date()).getFullYear() > 2021 ? `-${(new Date()).getFullYear()} ` : "";
    }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        this.env = atob(this.cookieService.get('prodest-eflow-env')).toLowerCase();
        switch (this.env) {
            case 'loc':
                this.environment = Enums.Messages.EnvironmentLocalhost;
                this.titleService.setTitle(`${this.titleService.getTitle()} [L]`);
                break;

            case 'des':
                this.environment = Enums.Messages.EnvironmentDevelopment;
                this.titleService.setTitle(`${this.titleService.getTitle()} [D]`);
                break;

            case 'hom':
                this.environment = Enums.Messages.EnvironmentStaging;
                this.titleService.setTitle(`${this.titleService.getTitle()} [H]`);
                break;

            case 'tre':
                this.environment = Enums.Messages.EnvironmentLearning;
                this.titleService.setTitle(`${this.titleService.getTitle()} [T]`);
                break;
        }
    }

    // ======================
    // public methods
    // ======================

    // ======================
    // private methods
    // ======================
}
