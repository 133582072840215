<ng-container *ngIf="inputModel != null">
    <div cdkDrag #elem
         class="shadow-sm available-flow-object flow-object-type {{FlowObjectTypeClassName.get(+inputModel.typeId)}}"
         matTooltipPosition="above"
         [class.disabled]="isUniqueTaskDisabled && !inputIsReadOnlyMode"
         [matTooltip]="inputModel.name"
         [matTooltipDisabled]="!isOverflown(elem)"
         [cdkDragDisabled]="inputIsReadOnlyMode || isUniqueTaskDisabled"
         [attr.data-type]="inputModel.typeId"
         (click)="clickEvent()"
         (cdkDragStarted)="dragStarted($event, true)"
         (cdkDragEnded)="dragEnded($event)"
         (cdkDragMoved)="dragMoved($event)"
    >
        <div class="flow-object-body-container" #flowObjectBodyContainerRef>
            <span class="flow-object-body">{{inputModel.name}}</span>
        </div>

        <i *ngIf="!inputIsReadOnlyMode && !isUniqueTaskDisabled" class="select fas fa-angle-double-right"></i>

        <div class="task-icon-container">
            <i *ngIf="[FlowObjectType.StartForm].includes(inputModel.typeId)" class="icon fas fa-file-alt"></i>
            <i *ngIf="[FlowObjectType.StartInboundApi].includes(inputModel.typeId)" class="icon fas fa-external-link-square-alt"></i>
            <i *ngIf="[FlowObjectType.TaskForward].includes(inputModel.typeId)" class="icon fas fa-paper-plane"></i>
            <i *ngIf="[FlowObjectType.TaskAcknowledge].includes(inputModel.typeId)" class="icon fas fa-check"></i>
            <i *ngIf="[FlowObjectType.TaskMidwayOutboundApi, FlowObjectType.FinishOutboundApi].includes(inputModel.typeId)" class="icon fas fa-external-link-alt"></i>
            <i *ngIf="[FlowObjectType.TaskRegisterProcess].includes(inputModel.typeId)" class="icon fas fa-suitcase"></i>
            <i *ngIf="[FlowObjectType.TaskDispatchProcess].includes(inputModel.typeId)" class="icon fas fa-forward"></i>
            <i *ngIf="[FlowObjectType.GatewayApprove].includes(inputModel.typeId)" class="icon fas fa-thumbs-up"></i>
            <i *ngIf="[FlowObjectType.GatewayRules].includes(inputModel.typeId)" class="icon fas fa-gavel"></i>
            <i *ngIf="[FlowObjectType.GatewayPaths].includes(inputModel.typeId)" class="icon fas fa-random"></i>
            <i *ngIf="[FlowObjectType.Finish].includes(inputModel.typeId)" class="icon fas fa-times-circle"></i>
        </div>

        <div class="task-category-container">
            <span *ngIf="isStartingTask">Início</span>
            <span *ngIf="isFinishingTask">Encerramento</span>
            <span *ngIf="isGatewayTask">Gateway</span>
            <span *ngIf="isGeneralTask">Geral</span>
        </div>
    </div>

    <ngx-mat-popover [popoverContent]="actionsPopoverContentTemplate" [disabled]="!inputHasGatewayPath" #actionsMatPopoverRef></ngx-mat-popover>
    <ng-template #actionsPopoverContentTemplate>
        <div class="wrapper available-flow-objects">
            <!-- Sequência Principal -->
            <div class="action px-2" (click)="selectFlowObject()">
                <span class="mr-2">Adicionar Tarefa à Sequência <b>Principal</b></span>
                <i class="fas fa-arrow-alt-circle-up"></i>
            </div>

            <!-- Sequência A -->
            <div class="action px-2" (click)="selectFlowObject(true, false)">
                <span class="mr-2">
                    Adicionar Tarefa à {{inputFirstGatewayPathName.startsWith('Sequência') ? '' : 'Sequência '}}<b>{{inputFirstGatewayPathName}}</b>
                </span>
                <i class="fas fa-arrow-alt-circle-right"></i>
            </div>

            <!-- Sequência B -->
            <div class="action px-2" (click)="selectFlowObject(false, true)">
                <span class="mr-2">
                    Adicionar Tarefa à {{inputSecondGatewayPathName.startsWith('Sequência') ? '' : 'Sequência '}}<b>{{inputSecondGatewayPathName}}</b>
                </span>
                <i class="fas fa-arrow-alt-circle-down"></i>
            </div>
        </div>
    </ng-template>
</ng-container>
