import {
    Component,
    ViewChild,
    ElementRef,
    OnInit,
    ChangeDetectorRef,
    OnDestroy,
    ViewChildren,
    QueryList,
    ChangeDetectionStrategy,
    HostListener
} from '@angular/core';
import 'leader-line';
import {
    CdkDragDrop,
    CdkDragStart,
    CdkDragMove,
    moveItemInArray,
    CdkDragEnd
} from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { SCSS_VARS } from '../../shared/shared-scss-ts-variables';
import { Utils } from '../../shared/utils';
import {
    FlowObjectType,
    FlowObjectDefinition,
    FormSchema,
    FlowObjectTypeDescription,
    FlowObjectTypeEDocsName
} from '../../models/flow-object.model';
import { Grouping, GroupingType } from '../../models/grouping.model';
import { GroupingColumn } from '../../models/grouping-column.model';
import { FlowDefinition } from '../../models/flow.model';
import { BaseComponent } from '../../components/base/base.component';
import { FlowDefinitionService } from '../../services/flow-definition.service';
import { AuthService } from '../../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../shared/enums';
import { FLOW_OBJECT_DETAILS_MODEL_EDITOR_TINYMCE_OPTIONS } from '../../components/details/flow-object-details/flow-object-details-model-editor/flow-object-details-model-editor-tinymce-options';
import { HTML_TEMPLATE_DEFAULT } from '../../components/details/flow-object-details/flow-object-details-model-editor/tinymce-template/tinymce-template-default';
import { HTML_TEMPLATE_APPROVE } from '../../components/details/flow-object-details/flow-object-details-model-editor/tinymce-template/tinymce-template-approve';
import { HTML_TEMPLATE_ACKNOWLEDGE } from '../../components/details/flow-object-details/flow-object-details-model-editor/tinymce-template/tinymce-template-acknowledge';
import { MatDialog } from '@angular/material/dialog';
import { PdfPreviewDialogComponent } from '../../components/pdf-preview-dialog/pdf-preview-dialog.component';
import { IBaseOption } from '../../models/base.model';
import { FlowObjectDetailsFormComponent } from '../../components/details/flow-object-details/flow-object-details-form/flow-object-details-form.component';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService } from 'primeng/api';
import { NgxMatPopoverComponent } from '../../components/ngx-mat-popover/ngx-mat-popover.component';
import { FlowObjectDefinitionService } from '../../services/flow-object-definition.service';
import { TaskRules } from '../../shared/task-rules';
import { FlowObjectDetailsComponent } from '../../components/details/flow-object-details/flow-object-details.component';
import { FlowDefinitionDetailsComponent } from '../../components/details/flow-definition-details/flow-definition-details.component';
import { FlowObjectDetailsModelEditorComponent } from '../../components/details/flow-object-details/flow-object-details-model-editor/flow-object-details-model-editor.component';
import { FlowObjectDetailsOutboundApiComponent } from '../../components/details/flow-object-details/flow-object-details-outbound-api/flow-object-details-outbound-api.component';
import { FORMBUILDER_SCHEMA_DEFAULT } from '../../components/formio/formbuilder-schema-default';
import { FlowRules } from '../../shared/flow-rules';
import { FlowObjectDetailsMidwayOutboundApiComponent } from "../../components/details/flow-object-details/flow-object-details-midway-outbound-api/flow-object-details-midway-outbound-api.component";
import { FlowObjectDetailsGatewayRulesComponent } from '../../components/details/flow-object-details/flow-object-details-gateway-rules/flow-object-details-gateway-rules.component';
import { FlowObjectDetailsGatewayPathsComponent } from '../../components/details/flow-object-details/flow-object-details-gateway-paths/flow-object-details-gateway-paths.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ConfigSchema, ConfigSchemaFlowDefinition } from '../../models/config-schema.model';
import { VisibleArea } from '../../shared/helper-classes';
import { FlowObjectDetailsStartInboundComponent } from '../../components/details/flow-object-details/flow-object-details-start-inbound-api/flow-object-details-start-inbound-api.component';
import { FlowDefinitionDetailsHotConfigsComponent } from '../../components/details/flow-definition-details/flow-definition-details-hot-configs/flow-definition-details-hot-configs.component';
import { AuditLog } from '../../models/auditlog.model';

declare var LeaderLine: any;

@Component({
    selector: 'flow-definition',
    templateUrl: './flow-definition.component.html',
    styleUrls: ['./flow-definition.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowDefinitionComponent extends BaseComponent implements OnInit, OnDestroy {
    // #region [ViewChild]
    @ViewChild('backdropRef') backdropRef: ElementRef;
    @ViewChild('detailsAreaRef') detailsAreaRef: ElementRef;
    @ViewChild('availableFlowObjectsParentRef') availableFlowObjectsParentRef: ElementRef;
    @ViewChild('availableFlowObjectsRef') availableFlowObjectsRef: ElementRef;
    @ViewChild('availableFlowObjectsWrapperRef') availableFlowObjectsWrapperRef: ElementRef;
    @ViewChild('mainPathRef') mainPathRef: ElementRef;
    @ViewChild('firstGatewayPathRef') firstGatewayPathRef: ElementRef;
    @ViewChild('secondGatewayPathRef') secondGatewayPathRef: ElementRef;
    @ViewChild('flowAreaRef') flowAreaRef: ElementRef;
    @ViewChild('flowAreaBackdropRef') flowAreaBackdropRef: ElementRef;
    @ViewChild('pageContainerRef') pageContainerRef: ElementRef;
    @ViewChild('flowDefinitionDetailsRef') flowDefinitionDetailsRef: FlowDefinitionDetailsComponent;
    @ViewChild('flowDefinitionDetailsHotConfigsRef') flowDefinitionDetailsHotConfigsRef: FlowDefinitionDetailsHotConfigsComponent;
    @ViewChild('flowObjectDetailsRef') flowObjectDetailsRef: FlowObjectDetailsComponent;
    @ViewChild('flowObjectFormRef') flowObjectFormRef: FlowObjectDetailsFormComponent;
    @ViewChild('flowObjectStartInboundApiRef') flowObjectStartInboundApiRef: FlowObjectDetailsStartInboundComponent;
    @ViewChild('modelEditorRef') modelEditorRef: FlowObjectDetailsModelEditorComponent;
    @ViewChild('flowObjectOutboundApiRef') flowObjectOutboundApiRef: FlowObjectDetailsOutboundApiComponent;
    @ViewChild('actionsMatPopoverRef') actionsMatPopoverRef: NgxMatPopoverComponent;
    @ViewChild('viewHistoryMatPopoverRef') viewHistoryMatPopoverRef: NgxMatPopoverComponent;
    @ViewChild('flowObjectMidwayOutboundApiRef') flowObjectMidwayOutboundApiRef: FlowObjectDetailsMidwayOutboundApiComponent;
    @ViewChild('flowObjectGatewayRulesRef') flowObjectGatewayRulesRef: FlowObjectDetailsGatewayRulesComponent;
    @ViewChild('flowObjectGatewayPathsRef') flowObjectGatewayPathsRef: FlowObjectDetailsGatewayPathsComponent;
    @ViewChildren('flowObjectBodyContainerRef') flowObjectBodyContainerRef: QueryList<ElementRef>;
    // #endregion

    // #region [HostListener]
    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // tentar evitar comportamento padrão de browser de salvar página com "CTRL + S"
        if (!this.isReadOnlyMode && (event.metaKey || event.ctrlKey) && event.key === 's') {
            event.preventDefault();
            event.stopPropagation();
        }

        // atalho para salvar com "CTRL + B", "CTRL + \" ou "CTRL + NUM 0"
        if (
            !this.isReadOnlyMode
            && (event.metaKey || event.ctrlKey)
            && (
                event.key === 'b'
                || event.key === 'B'
                || event.key === '\\'
                || event.code === 'Numpad0'
            )
        ) {
            switch (this.activeDetailsTab) {
                case Enums.DetailsAreaTabs.FlowDefinitionDetails: this.flowDefinitionDetailsRef?.onSubmit(); break;
                case Enums.DetailsAreaTabs.FlowDefinitionDetailsHotConfigs: this.flowDefinitionDetailsHotConfigsRef?.onSubmit(); break;
                case Enums.DetailsAreaTabs.FlowObjectDetails: this.flowObjectDetailsRef?.onSubmit(); break;
                case Enums.DetailsAreaTabs.FormBuilder: this.flowObjectFormRef?.onSubmit(); break;
                case Enums.DetailsAreaTabs.FormBuilderInboundApi: this.flowObjectStartInboundApiRef?.onSubmit(); break;
                case Enums.DetailsAreaTabs.HtmlEditor: this.modelEditorRef?.onSubmit(); break;
                case Enums.DetailsAreaTabs.OutboundApi: this.flowObjectOutboundApiRef?.onSubmit(); break;
                case Enums.DetailsAreaTabs.MidwayOutboundApi: this.flowObjectMidwayOutboundApiRef?.onSubmit(); break;
                case Enums.DetailsAreaTabs.GatewayRules: this.flowObjectGatewayRulesRef?.onSubmit(); break;
                case Enums.DetailsAreaTabs.GatewayPaths: this.flowObjectGatewayPathsRef?.onSubmit(); break;
            }

            this.saveFlowDefinition();
            event.preventDefault();
            event.stopPropagation();
        }
    }
    // #endregion

    // #region [Type properties]
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    FlowObjectTypeDescription: typeof FlowObjectTypeDescription = FlowObjectTypeDescription;
    FlowTarget: typeof Enums.FlowTarget = Enums.FlowTarget;
    FlowTargetLabel: typeof Enums.FlowTargetLabel = Enums.FlowTargetLabel;
    DetailsAreaTabs: typeof Enums.DetailsAreaTabs = Enums.DetailsAreaTabs;
    DetailsAreaTabsLabel: typeof Enums.DetailsAreaTabsLabel = Enums.DetailsAreaTabsLabel;
    Enums = Enums;
    // #endregion

    // #region [properties]
    model: FlowDefinition;
    flowObjectToEdit: FlowObjectDefinition;
    flowDefinitionToEdit: FlowDefinition;
    selectedGroupings: Grouping[] = [];
    selectedGroupingColumns: GroupingColumn[] = [];
    selectedFlowObjects: FlowObjectDefinition[] = [];
    availableFlowObjects: FlowObjectDefinition[] = [];
    flowDefinitionVersionOptions: number[] = [];
    flowTargetOptions: IBaseOption[] = [];
    isBootstrapFinished: boolean = false;
    isReadOnlyMode: boolean = false;
    isLatestVersion: boolean = false;
    canLoad: boolean = false;
    dragBeenOutsideAvailableArea: boolean = false;
    draggingFlowObjectIndex: any = null;
    selectedVersion: number = null;
    historyEntries: AuditLog[] = [];
    tinyMceOptions: any = FLOW_OBJECT_DETAILS_MODEL_EDITOR_TINYMCE_OPTIONS;
    tinyMceContent: string = HTML_TEMPLATE_DEFAULT;
    // #endregion

    // #region [getters]
    get hasGatewayPath(): boolean {
        return this.selectedFlowObjects.some(x => x.typeId == FlowObjectType.GatewayPaths);
    }

    get hasFlowStarter(): boolean {
        return this.selectedFlowObjects.some(x => x.isFlowStarter);
    }

    get hasHotConfigs(): boolean {
        if (!this.model.configSchema) return false;

        let configSchema = JSON.parse(this.model.configSchema) as ConfigSchemaFlowDefinition;
        return configSchema.hasAutomaticFlowDefinitionDeactivation || configSchema.hasAutomaticFlowInstanceCancellation;
    }
    // #endregion

    // #region [Sequência Principal]
    mainPathFlowObjectRefs: ElementRef[] = [];
    get mainPathGroupings(): Grouping[] {
        return this.selectedGroupings.filter(x => !x.isFirstGatewayPath && !x.isSecondGatewayPath);
    }
    get mainPathGroupingColumns(): GroupingColumn[] {
        return this.selectedGroupingColumns.filter(x => !x.isFirstGatewayPath && !x.isSecondGatewayPath);
    }
    get mainPathFlowObjects(): FlowObjectDefinition[] {
        return this.selectedFlowObjects.filter(x => !x.isFirstGatewayPath && !x.isSecondGatewayPath);
    }
    // #endregion

    // #region [Sequência A]
    firstGatewayPathFlowObjectRefs: ElementRef[] = [];
    get firstGatewayPathGroupings(): Grouping[] {
        return this.selectedGroupings.filter(x => x.isFirstGatewayPath);
    }
    get firstGatewayPathGroupingColumns(): GroupingColumn[] {
        return this.selectedGroupingColumns.filter(x => x.isFirstGatewayPath);
    }
    get firstGatewayPathFlowObjects(): FlowObjectDefinition[] {
        return this.selectedFlowObjects.filter(x => x.isFirstGatewayPath);
    }
    get firstGatewayPathName(): string {
        if (!this.hasGatewayPath) return null;
        let configSchema = JSON.parse(this.selectedFlowObjects.find(x => x.typeId == FlowObjectType.GatewayPaths).configSchema) as ConfigSchema;
        return configSchema.taskGatewayPaths.firstGatewayPathName;
    }
    // #endregion

    // #region [Sequência B]
    secondGatewayPathFlowObjectRefs: ElementRef[] = [];
    get secondGatewayPathGroupings(): Grouping[] {
        return this.selectedGroupings.filter(x => x.isSecondGatewayPath);
    }
    get secondGatewayPathGroupingColumns(): GroupingColumn[] {
        return this.selectedGroupingColumns.filter(x => x.isSecondGatewayPath);
    }
    get secondGatewayPathFlowObjects(): FlowObjectDefinition[] {
        return this.selectedFlowObjects.filter(x => x.isSecondGatewayPath);
    }
    get secondGatewayPathName(): string {
        if (!this.hasGatewayPath) return null;
        let configSchema = JSON.parse(this.selectedFlowObjects.find(x => x.typeId == FlowObjectType.GatewayPaths).configSchema) as ConfigSchema;
        return configSchema.taskGatewayPaths.secondGatewayPathName;
    }
    // #endregion

    // #region [Details Area]
    activeTabIndex: number | undefined = undefined;
    isAreaVisible: VisibleArea = new VisibleArea();
    activeDetailsTab: Enums.DetailsAreaTabs;
    get isDetailsAreaExtraSize(): boolean {
        return this.activeDetailsTab != null
            && ![
                Enums.DetailsAreaTabs.FlowDefinitionDetails,
                Enums.DetailsAreaTabs.FlowDefinitionDetailsHotConfigs,
                Enums.DetailsAreaTabs.FlowObjectDetails,
                Enums.DetailsAreaTabs.GatewayPaths
            ].includes(this.activeDetailsTab);
    }
    get isAnyDetailsAreaOpen(): boolean {
        for (let prop in this.isAreaVisible) {
            if (this.isAreaVisible[prop] == true) {
                return true;
            }
        }
        return false;
    }
    // #endregion

    constructor(
        route: ActivatedRoute,
        router: Router,
        spinner: NgxSpinnerService,
        toastr: ToastrService,
        private dialog: MatDialog, /* utilizado pelo previewPdf via "context"; não remover */
        private changeDetectorRef: ChangeDetectorRef,
        private confirmationService: ConfirmationService,
        private cookieService: CookieService,
        private flowObjectDefinitionService: FlowObjectDefinitionService, /* utilizado pelo previewPdf via "context"; não remover */
        private flowDefinitionService: FlowDefinitionService,
        private authService: AuthService
    ) {
        super(route, router, spinner, toastr);

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        setInterval(() => this.changeDetectorRef.markForCheck(), 1000);

        this.canLoad = this.route.snapshot.queryParams.r == null;
        this.isLatestVersion = Utils.isNullOrEmpty(this.paramVersion);

        this.setFormBuilderAreaVisibility();

        window.addEventListener('beforeunload', () => this.ngOnDestroy());
    }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        if (!this.canLoad) return;

        try {
            if (!this.isCreate) {
                const response = await this.flowDefinitionService.getById(this.paramId, isNaN(this.paramVersion) ? null : this.paramVersion, true);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    this.router.navigate([Enums.PagesPaths.FlowDefinitionList]);
                    return;
                }

                this.model = response.data;

                if (!Utils.isNullOrEmpty(this.paramVersion)) {
                    this.selectedVersion = this.paramVersion;

                    if (this.paramVersion == this.model.version) {
                        this.changeVersion();
                    }
                } else {
                    this.selectedVersion = this.model.version;
                }

                if (!this.model.isActive || this.model.isPublished || (!!this.paramVersion && this.paramVersion < this.model.version)) {
                    this.isReadOnlyMode = true;
                }
            } else {
                if (this.authService.user.unidadesPerfilAdministrador.length == 0) {
                    this.toastr.error(Enums.Messages.NoFlowInboxError, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    console.error(Enums.Messages.NoFlowInboxError);
                }

                this.model = new FlowDefinition({
                    ownerId: this.authService.user.id,
                    organizationId: this.authService.user.unidadesPerfilAdministrador[0].organizacao.guid,
                    unitId: this.authService.user.unidadesPerfilAdministrador[0].guid
                });

                this.selectedVersion = this.model.version;
            }

            this.initializeCollections();
        } catch (error) {
            this.toastr.error(Enums.Messages.GeneralError, Enums.Messages.Error, Utils.getToastrErrorOptions());
            console.error(error);
            this.router.navigate([Enums.PagesPaths.FlowDefinitionList]);
            this.spinner.hide();
        } finally {
            this.activeTabIndex = 0;
        }
    }

    ngOnDestroy() {
        this.clearLinks();
    }

    // ======================
    // public methods
    // ======================

    // #region [screen events]
    onResize(event) {
        if (event.type == 'wheel') {
            //// resize a partir de onWheel
            //let control = 0;
            //let interval = setInterval(() => {
            //    let size = control % 2 == 0 ? this.pageContainerRef.nativeElement.clientWidth - 1 : this.pageContainerRef.nativeElement.clientWidth + 1;
            //    this.pageContainerRef.nativeElement.style.width = `${size}px`;
            //    control++;
            //    if (control == 4) clearInterval(interval);
            //}, 40);
        } else {
            this.linkFlowObjects();
        }
    }

    dragStarted(event: CdkDragStart, isAvailableFlowObject: boolean = false) {
        if (this.isReadOnlyMode) return;

        this.draggingFlowObjectIndex = this.availableFlowObjects.findIndex(item => item.typeId.toString() == event.source.element.nativeElement.dataset.type);

        if (isAvailableFlowObject) {
            this.flowAreaBackdropRef.nativeElement.classList.remove('hidden');
        } else {
            this.clearLinks();
        }
    }

    dragEnded(event: CdkDragEnd) {
        if (this.isReadOnlyMode) return;

        this.flowAreaBackdropRef.nativeElement.classList.add('hidden');
        this.dragBeenOutsideAvailableArea = false;
    }

    dragMoved(event: CdkDragMove) {
        if (this.isReadOnlyMode) return;

        let dropArea = this.flowAreaRef.nativeElement.getBoundingClientRect();
        let sourceArea = this.availableFlowObjectsParentRef.nativeElement.getBoundingClientRect();

        if (this.isMouseInsideArea(event.pointerPosition, dropArea, 2)) {
            this.availableFlowObjectsWrapperRef.nativeElement.insertBefore(
                document.querySelector('.cdk-drag-placeholder'),
                this.draggingFlowObjectIndex == this.availableFlowObjects.length - 1
                    ? null
                    : this.availableFlowObjectsWrapperRef.nativeElement.children[this.draggingFlowObjectIndex]
            );

            this.flowAreaRef.nativeElement.classList.add('droppable');
        } else {
            this.flowAreaRef.nativeElement.classList.remove('droppable');

            if (this.isMouseOutsideArea(event.pointerPosition, sourceArea)) {
                this.dragBeenOutsideAvailableArea = true;
            } else if (this.isMouseInsideArea(event.pointerPosition, sourceArea) && this.dragBeenOutsideAvailableArea) {
                document.dispatchEvent(new MouseEvent('mouseup'));
            }
        }
    }

    dragDropped(event: CdkDragDrop<FlowObjectDefinition[]>) {
        if (this.isReadOnlyMode) return;

        if (event.previousContainer !== event.container) {
            this.selectFlowObject(event.previousContainer.data[event.previousIndex]);
            this.flowAreaRef.nativeElement.classList.remove('droppable');
        } else {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            this.reorderFlowObjects();
            this.linkFlowObjects();
        }

        this.draggingFlowObjectIndex = null;
    }
    // #endregion

    // #region [área do canvas (Flow Area)]
    isOverflown(elem: HTMLElement): boolean {
        let flowObjectBodyContainer = this.flowObjectBodyContainerRef.toArray().find(x => Array.from(elem.children).includes(x.nativeElement))?.nativeElement;
        if (flowObjectBodyContainer == null) return false;

        // compensação por um fator de ajuste empírico
        return flowObjectBodyContainer.scrollWidth > elem.clientWidth * .87;
    }

    gatherMainPathFlowObjectRefs(event) {
        this.mainPathFlowObjectRefs = this.mainPathFlowObjectRefs.concat(event);
    }

    gatherFirstGatewayPathFlowObjectRefs(event) {
        this.firstGatewayPathFlowObjectRefs = this.firstGatewayPathFlowObjectRefs.concat(event);
    }

    gatherSecondGatewayPathFlowObjectRefs(event) {
        this.secondGatewayPathFlowObjectRefs = this.secondGatewayPathFlowObjectRefs.concat(event);
    }
    // #endregion

    // #region [telas de Detalhes]
    openModalArea(flowObject?: FlowObjectDefinition) {
        [this.detailsAreaRef.nativeElement, this.backdropRef.nativeElement].forEach(elem => {
            elem.style.visibility = 'visible';
            elem.style.opacity = '1';
        });

        if (flowObject != null) {
            // editando FlowObject
            this.flowObjectToEdit = Utils.clone(flowObject);
            this.isAreaVisible.flowObjectDetails = true;
            this.activeDetailsTab = Enums.DetailsAreaTabs.FlowObjectDetails;

            if (flowObject.documentHtmlContent != null && flowObject.documentHtmlContent != '') {
                this.tinyMceContent = flowObject.documentHtmlContent;
            } else {
                this.tinyMceContent = HTML_TEMPLATE_DEFAULT;
                flowObject.documentHtmlContent = HTML_TEMPLATE_DEFAULT;
            }
        } else {
            // editando FlowDefinition
            this.flowDefinitionToEdit = Utils.clone(this.model);
            this.flowDefinitionToEdit.flowObjectDefinitions = [...this.selectedFlowObjects];
            this.isAreaVisible.flowDefinitionDetails = true;
            this.isAreaVisible.flowDefinitionDetailsHotConfigs = true;
            this.activeDetailsTab = Enums.DetailsAreaTabs.FlowDefinitionDetails;
        }

        this.setFormBuilderAreaVisibility();
    }

    closeModalAreas(isFromFlowDefinitionDetails?: boolean) {
        let elements = [this.detailsAreaRef.nativeElement, this.backdropRef.nativeElement];
        elements.forEach(elem => elem.style.opacity = '0');
        setTimeout(() => elements.forEach(elem => elem.style.visibility = 'hidden'), 200);

        if (!isFromFlowDefinitionDetails) {
            this.setFormBuilderAreaVisibility();

            // contorno de bug do Formio
            this.flowObjectFormRef.notifyFocusOut();
            this.flowObjectStartInboundApiRef.notifyFocusOut();
        }

        this.isAreaVisible = new VisibleArea();
        this.activeTabIndex = undefined;
        this.activeDetailsTab = undefined;
    }

    toggleEditArea(flowObject?: FlowObjectDefinition) {
        if (flowObject == null) {
            this.closeModalAreas();
            return;
        }

        switch (flowObject.typeId) {
            case FlowObjectType.StartForm:
                this.isAreaVisible.formBuilder = true;
                this.isAreaVisible.htmlEditor = true;
                this.setFormBuilderAreaVisibility();
                break;

            case FlowObjectType.StartInboundApi:
                this.isAreaVisible.formBuilderInboundApi = true;
                this.setFormBuilderAreaVisibility();
                break;

            case FlowObjectType.FinishOutboundApi:
                this.isAreaVisible.api = true;
                break;

            case FlowObjectType.TaskMidwayOutboundApi:
                this.isAreaVisible.midApi = true;
                break;

            case FlowObjectType.GatewayRules:
                this.isAreaVisible.gatewayRules = true;
                break;

            case FlowObjectType.GatewayPaths:
                this.isAreaVisible.gatewayPaths = true;
                break;
        }

        this.openModalArea(flowObject);
    }

    async tabChanged(event: MatTabChangeEvent) {
        this.activeDetailsTab = Enums.DetailsAreaTabsLabelReversed.get(event.tab.textLabel);

        if (this.activeDetailsTab == Enums.DetailsAreaTabs.HtmlEditor) {
            this.spinner.show('tinyMceSpinner');

            if (Utils.isNullOrEmpty(this.flowObjectToEdit.formSchema)) {
                this.flowObjectToEdit.formSchema = await this.getFormData(this.flowObjectToEdit.id);
                this.flowObjectFormRef.ngOnChanges();
            }
        }

        if (this.activeDetailsTab == Enums.DetailsAreaTabs.FormBuilder) {
            if (this.isCreate) {
                this.flowObjectToEdit.formSchema = JSON.stringify(FORMBUILDER_SCHEMA_DEFAULT);
            } else if (Utils.isNullOrEmpty(this.flowObjectToEdit.formSchema)) {
                this.flowObjectToEdit.formSchema = await this.getFormData(this.flowObjectToEdit.id);
                this.flowObjectFormRef.ngOnChanges();
            }

            // contorno de bug do Formio
            this.flowObjectFormRef.debounceCustomComponentsLoading();
        } else {
            // contorno de bug do Formio
            this.flowObjectFormRef.notifyFocusOut();
        }

        if (this.activeDetailsTab == Enums.DetailsAreaTabs.FormBuilderInboundApi) {
            if (this.isCreate) {
                this.flowObjectToEdit.formSchema = JSON.stringify(FORMBUILDER_SCHEMA_DEFAULT);
            } else if (Utils.isNullOrEmpty(this.flowObjectToEdit.formSchema)) {
                this.flowObjectToEdit.formSchema = await this.getFormData(this.flowObjectToEdit.id);
                this.flowObjectStartInboundApiRef.ngOnChanges();
            }

            // contorno de bug do Formio
            this.flowObjectStartInboundApiRef.debounceCustomComponentsLoading();
        } else {
            // contorno de bug do Formio
            this.flowObjectStartInboundApiRef.notifyFocusOut();
        }

        if (this.activeDetailsTab == Enums.DetailsAreaTabs.FormBuilder) {
            this.flowObjectFormRef.startProcessingConditionalTags();
        }
    }

    tinyMceFinishedLoading() {
        this.spinner.hide('tinyMceSpinner');
    }

    shouldLoadModelEditorTinyMce(): boolean {
        if (this.activeDetailsTab == Enums.DetailsAreaTabs.HtmlEditor) {
            this.tinyMceOptions = FLOW_OBJECT_DETAILS_MODEL_EDITOR_TINYMCE_OPTIONS;

            this.tinyMceOptions.addDynamicMenu = editor => {
                editor.ui.registry.addMenuButton('vForm', {
                    text: 'Formulário',
                    fetch: callback => callback(this.getTinyMceMenuFormEntries(this.flowObjectToEdit, editor))
                });
            };

            this.tinyMceOptions.previewPdf = () => this.previewPdf(this);

            return true;
        }

        return false;
    }

    shouldLoadFlowDefinitionDetailsTinyMce(): boolean {
        return this.activeDetailsTab == Enums.DetailsAreaTabs.FlowDefinitionDetails;
    }
    // #endregion

    // #region [modify]
    updateFlowObject(flowObject: FlowObjectDefinition, silentSuccess?: boolean) {
        if (this.isReadOnlyMode) return;

        flowObject.documentHtmlContent = this.tinyMceContent;

        // #region [atualiza o FlowObjectDefinition na collection de controle]
        if (TaskRules.getConfigSchemaDependentOnMainTabTaskTypes().includes(flowObject.typeId)) {
            this.selectedFlowObjects[flowObject.outerIndex].configSchema = flowObject.configSchema;
        }

        switch (this.activeDetailsTab) {
            case Enums.DetailsAreaTabs.FlowObjectDetails:
                this.selectedFlowObjects[flowObject.outerIndex].name = flowObject.name;
                this.selectedFlowObjects[flowObject.outerIndex].publicName = flowObject.publicName;
                this.selectedFlowObjects[flowObject.outerIndex].publicMessageHtml = flowObject.publicMessageHtml;
                this.selectedFlowObjects[flowObject.outerIndex].configSchema = flowObject.configSchema;
                break;

            case Enums.DetailsAreaTabs.FormBuilder:
            case Enums.DetailsAreaTabs.FormBuilderInboundApi:
                this.selectedFlowObjects[flowObject.outerIndex].formSchema = flowObject.formSchema;
                break;

            case Enums.DetailsAreaTabs.HtmlEditor:
                this.selectedFlowObjects[flowObject.outerIndex].documentHtmlContent = flowObject.documentHtmlContent;
                break;

            case Enums.DetailsAreaTabs.GatewayPaths:
                let configSchema = JSON.parse(flowObject.configSchema) as ConfigSchema;
                this.selectedFlowObjects[flowObject.outerIndex + 1].name = configSchema.taskGatewayPaths.firstGatewayPathName;
                this.selectedFlowObjects[flowObject.outerIndex + 2].name = configSchema.taskGatewayPaths.secondGatewayPathName;
                break;
        }

        this.selectedFlowObjects[flowObject.outerIndex].isFlowStarter = flowObject.isFlowStarter;
        // #endregion

        if (silentSuccess) {
            return;
        }

        if (this.hasOnlyDetailsArea()) {
            this.closeModalAreas();
        }

        this.toastr.success(Enums.Messages.SketchSaveSuccess, Enums.Messages.Success);
    }

    async updateFlowDefinition(flowDefinition: FlowDefinition, isFromHotConfigs?: boolean) {
        Object.assign(this.model, flowDefinition);

        if (isFromHotConfigs) {
            await this.saveFlowDefinition(true);
        }

        this.closeModalAreas(true);

        if (!isFromHotConfigs) {
            this.toastr.success(Enums.Messages.SketchSaveSuccess, Enums.Messages.Success);
        }
    }

    async saveFlowDefinition(isFromHotConfigs?: boolean) {
        if (
            (
                this.isReadOnlyMode
                && !isFromHotConfigs
            ) || (
                this.isCreate
                && this.selectedFlowObjects.length == 0
            )
        ) return false;

        await this.proceedWithSaveFlowDefinition();
    }
    // #endregion

    // #region [FlowObjectDefinition actions]
    selectFlowObject(
        flowObject: FlowObjectDefinition,
        isFirstGatewayPath: boolean = false,
        isSecondGatewayPath: boolean = false
    ) {
        if (this.isReadOnlyMode) return;

        const hadGatewayPathBefore = this.hasGatewayPath;

        // #region [adição do Grouping e GroupingColumns principais]
        let newGrouping = new Grouping({
            outerIndex: this.getNextOuterIndexGroupings(isFirstGatewayPath, isSecondGatewayPath),
            typeId: GroupingType.Default,
            ownerId: this.authService.user.id,
            isFirstGatewayPath: isFirstGatewayPath,
            isSecondGatewayPath: isSecondGatewayPath
        });

        let newGroupingColumn_0 = new GroupingColumn({
            outerIndex: this.getNextOuterIndexGroupingColumns(isFirstGatewayPath, isSecondGatewayPath),
            groupingId: newGrouping.id,
            grouping: newGrouping,
            isFirstGatewayPath: isFirstGatewayPath,
            isSecondGatewayPath: isSecondGatewayPath
        });
        // #endregion

        let configSchema = null;
        let formSchema = null;
        let documentHtmlContent = null;

        // #region [tratamento padrão das propriedades que abriguem código-fonte (JSON/HTML)]
        if (
            [
                FlowObjectType.StartForm,
                FlowObjectType.StartInboundApi,
                FlowObjectType.TaskForward,
                FlowObjectType.TaskAcknowledge,
                FlowObjectType.TaskRegisterProcess,
                FlowObjectType.TaskDispatchProcess,
                FlowObjectType.GatewayApprove,
                FlowObjectType.GatewayPaths,
                FlowObjectType.FinishOutboundApi
            ].includes(flowObject.typeId)
        ) {
            configSchema = JSON.stringify(new ConfigSchema());

            if (flowObject.typeId == FlowObjectType.StartForm) {
                formSchema = JSON.stringify(FORMBUILDER_SCHEMA_DEFAULT);
                documentHtmlContent = HTML_TEMPLATE_DEFAULT;
            } else if (flowObject.typeId == FlowObjectType.StartInboundApi) {
                formSchema = JSON.stringify(FORMBUILDER_SCHEMA_DEFAULT);
            } else if (flowObject.typeId == FlowObjectType.TaskAcknowledge) {
                documentHtmlContent = HTML_TEMPLATE_ACKNOWLEDGE;
            } else if (flowObject.typeId == FlowObjectType.GatewayApprove) {
                documentHtmlContent = HTML_TEMPLATE_APPROVE;
            }
        }
        // #endregion

        // #region [adição do FlowObjectDefinition principal]
        let newFlowObject_0 = new FlowObjectDefinition({
            name: flowObject.typeId == FlowObjectType.StartForm ? FlowObjectTypeEDocsName.get(flowObject.typeId) : flowObject.name,
            publicName: flowObject.publicName,
            configSchema: configSchema,
            formSchema: formSchema,
            documentHtmlContent: documentHtmlContent,
            version: this.selectedVersion,
            outerIndex: this.getNextOuterIndexFlowObjects(isFirstGatewayPath, isSecondGatewayPath),
            typeId: flowObject.typeId,
            isFlowStarter: TaskRules.getStarterTaskTypes().includes(flowObject.typeId) ? true : false,
            isFirstGatewayPath: isFirstGatewayPath,
            isSecondGatewayPath: isSecondGatewayPath,
            flowDefinitionId: this.model.id,
            groupingColumnId: newGroupingColumn_0.id,
            groupingColumn: newGroupingColumn_0
        });
        // #endregion

        newGrouping.groupingColumns.push(newGroupingColumn_0);
        newGroupingColumn_0.flowObjectDefinitions.push(newFlowObject_0);

        let newGroupingColumnList = [newGroupingColumn_0];
        let newFlowObjectList = [newFlowObject_0];

        // #region [configuração específica para Tarefas do tipo "Divisão de Fluxo"]
        if (flowObject.typeId == FlowObjectType.GatewayPaths) {
            newGrouping.typeId = GroupingType.Gateway;

            let newGroupingColumn_1 = new GroupingColumn({
                innerIndex: 1,
                outerIndex: this.getNextOuterIndexGroupingColumns(isFirstGatewayPath, isSecondGatewayPath) + 1,
                groupingId: newGrouping.id,
                grouping: newGrouping
            });

            newGrouping.groupingColumns.push(newGroupingColumn_1);
            newGroupingColumnList.push(newGroupingColumn_1);

            let newFlowObject_1 = new FlowObjectDefinition({
                name: 'Sequência A',
                typeId: FlowObjectType.Dummy,
                innerIndex: 0,
                outerIndex: this.getNextOuterIndexFlowObjects(isFirstGatewayPath, isSecondGatewayPath) + 1,
                version: this.selectedVersion,
                flowDefinitionId: this.model.id,
                groupingColumnId: newGroupingColumn_1.id,
                groupingColumn: newGroupingColumn_1
            });

            newGroupingColumn_1.flowObjectDefinitions.push(newFlowObject_1);
            newFlowObjectList.push(newFlowObject_1);

            let newFlowObject_2 = new FlowObjectDefinition({
                name: 'Sequência B',
                typeId: FlowObjectType.Dummy,
                innerIndex: 1,
                outerIndex: this.getNextOuterIndexFlowObjects(isFirstGatewayPath, isSecondGatewayPath) + 2,
                version: this.selectedVersion,
                flowDefinitionId: this.model.id,
                groupingColumnId: newGroupingColumn_1.id,
                groupingColumn: newGroupingColumn_1
            });

            newGroupingColumn_1.flowObjectDefinitions.push(newFlowObject_2);
            newFlowObjectList.push(newFlowObject_2);
        }
        // #endregion

        // #region [configuração específica para Tarefas de Gateway que não sejam do tipo "Divisão de Fluxo"]
        if ([FlowObjectType.GatewayApprove, FlowObjectType.GatewayRules].includes(flowObject.typeId)) {
            newGrouping.typeId = GroupingType.Gateway;

            let newGroupingColumn_1 = new GroupingColumn({
                innerIndex: 1,
                outerIndex: this.getNextOuterIndexGroupingColumns(isFirstGatewayPath, isSecondGatewayPath) + 1,
                groupingId: newGrouping.id,
                grouping: newGrouping,
                isFirstGatewayPath: isFirstGatewayPath,
                isSecondGatewayPath: isSecondGatewayPath
            });

            newGrouping.groupingColumns.push(newGroupingColumn_1);
            newGroupingColumnList.push(newGroupingColumn_1);

            let newFlowObject_1 = new FlowObjectDefinition({
                configSchema: JSON.stringify(new ConfigSchema()),
                outerIndex: this.getNextOuterIndexFlowObjects(isFirstGatewayPath, isSecondGatewayPath) + 1,
                version: this.selectedVersion,
                typeId: FlowObjectType.TaskForward,
                flowDefinitionId: this.model.id,
                groupingColumnId: newGroupingColumn_1.id,
                groupingColumn: newGroupingColumn_1,
                isFirstGatewayPath: isFirstGatewayPath,
                isSecondGatewayPath: isSecondGatewayPath,
            });

            newGroupingColumn_1.flowObjectDefinitions.push(newFlowObject_1);
            newFlowObjectList.push(newFlowObject_1);

            let newFlowObject_2 = new FlowObjectDefinition({
                innerIndex: 1,
                outerIndex: this.getNextOuterIndexFlowObjects(isFirstGatewayPath, isSecondGatewayPath) + 2,
                version: this.selectedVersion,
                typeId: FlowObjectType.Finish,
                flowDefinitionId: this.model.id,
                groupingColumnId: newGroupingColumn_1.id,
                groupingColumn: newGroupingColumn_1,
                isFirstGatewayPath: isFirstGatewayPath,
                isSecondGatewayPath: isSecondGatewayPath,
            });

            newGroupingColumn_1.flowObjectDefinitions.push(newFlowObject_2);
            newFlowObjectList.push(newFlowObject_2);
        }
        // #endregion

        this.selectedGroupings.push(newGrouping);
        this.selectedGroupingColumns = this.selectedGroupingColumns.concat(newGroupingColumnList);
        this.selectedFlowObjects = this.selectedFlowObjects.concat(newFlowObjectList);
        this.reorderFlowObjects();

        // caso a Tarefa esteja sendo adicionada à Sequência Principal,
        // garante-se que seja posicionada antes da Task "GatewayPaths"
        if (hadGatewayPathBefore && !isFirstGatewayPath && !isSecondGatewayPath) {
            this.moveGroupingBackwards(null, newFlowObject_0);
            return;
        }

        this.resolveNextFlowObjects();
        this.linkFlowObjects();
    }

    moveGroupingBackwards(event: MouseEvent = null, flowObject: FlowObjectDefinition) {
        if (this.isReadOnlyMode) {
            event?.stopPropagation();
            return;
        }

        let fromIndex = flowObject.groupingColumn.grouping.outerIndex;
        if (
            fromIndex == 0
            || fromIndex == this.mainPathGroupings.length
            || fromIndex == this.mainPathGroupings.length + this.firstGatewayPathGroupings.length
        ) {
            event?.stopPropagation();
            return;
        }

        this.selectedGroupings[fromIndex].outerIndex -= 1;
        this.selectedGroupings[fromIndex - 1].outerIndex += 1;
        moveItemInArray(this.selectedGroupings, fromIndex, fromIndex - 1);

        this.reorderFlowObjects();
        this.linkFlowObjects();
        event?.stopPropagation();
    }

    moveGroupingForwards(event: MouseEvent, flowObject: FlowObjectDefinition) {
        if (this.isReadOnlyMode) {
            event.stopPropagation();
            return;
        }

        let fromIndex = flowObject.groupingColumn.grouping.outerIndex;
        if (
            fromIndex == this.mainPathGroupings.length - 1
            || fromIndex == this.mainPathGroupings.length + this.firstGatewayPathGroupings.length - 1
            || fromIndex == this.selectedGroupings.length - 1
            || this.selectedGroupings.find(x =>
                x.groupingColumns.some(y =>
                    y.flowObjectDefinitions.some(z =>
                        z.typeId == FlowObjectType.GatewayPaths
                    )
                )
            ).outerIndex == fromIndex + 1
        ) {
            event.stopPropagation();
            return;
        }

        this.selectedGroupings[fromIndex].outerIndex += 1;
        this.selectedGroupings[fromIndex + 1].outerIndex -= 1;
        moveItemInArray(this.selectedGroupings, fromIndex, fromIndex + 1);

        this.reorderFlowObjects();
        this.linkFlowObjects();
        event.stopPropagation();
    }

    moveGroupingUpwards(event: MouseEvent, flowObject: FlowObjectDefinition) {
        if (this.isReadOnlyMode) {
            event.stopPropagation();
            return;
        }

        let toIndex: number;
        if (flowObject.isFirstGatewayPath) {
            flowObject.groupingColumn.grouping.isFirstGatewayPath = false;
            flowObject.groupingColumn.grouping.isSecondGatewayPath = false;

            flowObject.groupingColumn.grouping.groupingColumns.forEach(x => {
                x.isFirstGatewayPath = false;
                x.isSecondGatewayPath = false;

                x.flowObjectDefinitions.forEach(y => {
                    y.isFirstGatewayPath = false;
                    y.isSecondGatewayPath = false;
                });
            });

            toIndex = this.mainPathGroupings.length - 2 >= 0 ? this.mainPathGroupings.length - 2 : 0;

            this.firstGatewayPathFlowObjectRefs = this.firstGatewayPathFlowObjectRefs.filter(x => x.nativeElement.dataset.id != flowObject.id);
        } else if (flowObject.isSecondGatewayPath) {
            flowObject.groupingColumn.grouping.isFirstGatewayPath = true;
            flowObject.groupingColumn.grouping.isSecondGatewayPath = false;

            flowObject.groupingColumn.grouping.groupingColumns.forEach(x => {
                x.isFirstGatewayPath = true;
                x.isSecondGatewayPath = false;

                x.flowObjectDefinitions.forEach(y => {
                    y.isFirstGatewayPath = true;
                    y.isSecondGatewayPath = false;
                });
            });

            toIndex = this.mainPathGroupings.length + this.firstGatewayPathGroupings.length - 1;

            this.secondGatewayPathFlowObjectRefs = this.secondGatewayPathFlowObjectRefs.filter(x => x.nativeElement.dataset.id != flowObject.id);
        }

        while (flowObject.groupingColumn.grouping.outerIndex > toIndex) {
            let fromIndex = flowObject.groupingColumn.grouping.outerIndex;
            this.selectedGroupings[fromIndex].outerIndex -= 1;
            this.selectedGroupings[fromIndex - 1].outerIndex += 1;
            moveItemInArray(this.selectedGroupings, fromIndex, fromIndex - 1);
        }

        this.reorderFlowObjects();
        this.linkFlowObjects();
        event.stopPropagation();
    }

    moveGroupingDownwards(event: MouseEvent, flowObject: FlowObjectDefinition) {
        if (this.isReadOnlyMode) {
            event.stopPropagation();
            return;
        }

        let toIndex: number;
        if (!flowObject.isFirstGatewayPath && !flowObject.isSecondGatewayPath) {
            flowObject.groupingColumn.grouping.isFirstGatewayPath = true;
            flowObject.groupingColumn.grouping.isSecondGatewayPath = false;

            flowObject.groupingColumn.grouping.groupingColumns.forEach(x => {
                x.isFirstGatewayPath = true;
                x.isSecondGatewayPath = false;

                x.flowObjectDefinitions.forEach(y => {
                    y.isFirstGatewayPath = true;
                    y.isSecondGatewayPath = false;
                });
            });

            toIndex = this.mainPathGroupings.length + this.firstGatewayPathGroupings.length - 1;

            this.mainPathFlowObjectRefs = this.mainPathFlowObjectRefs.filter(x => x.nativeElement.dataset.id != flowObject.id);
        } else if (flowObject.isFirstGatewayPath) {
            flowObject.groupingColumn.grouping.isFirstGatewayPath = false;
            flowObject.groupingColumn.grouping.isSecondGatewayPath = true;

            flowObject.groupingColumn.grouping.groupingColumns.forEach(x => {
                x.isFirstGatewayPath = false;
                x.isSecondGatewayPath = true;

                x.flowObjectDefinitions.forEach(y => {
                    y.isFirstGatewayPath = false;
                    y.isSecondGatewayPath = true;
                });
            });

            toIndex = this.mainPathGroupings.length + this.firstGatewayPathGroupings.length + this.secondGatewayPathGroupings.length - 1;

            this.firstGatewayPathFlowObjectRefs = this.firstGatewayPathFlowObjectRefs.filter(x => x.nativeElement.dataset.id != flowObject.id);
        }

        while (flowObject.groupingColumn.grouping.outerIndex < toIndex) {
            let fromIndex = flowObject.groupingColumn.grouping.outerIndex;
            this.selectedGroupings[fromIndex].outerIndex += 1;
            this.selectedGroupings[fromIndex + 1].outerIndex -= 1;
            moveItemInArray(this.selectedGroupings, fromIndex, fromIndex + 1);
        }

        this.reorderFlowObjects();
        this.linkFlowObjects();
        event.stopPropagation();
    }

    removeGrouping(event: MouseEvent, flowObject: FlowObjectDefinition) {
        if (this.isReadOnlyMode) {
            event.stopPropagation();
            return;
        }

        let flowObjectsToRemove: FlowObjectDefinition[] = [];
        let groupingIndexToRemove = flowObject.groupingColumn.grouping.outerIndex;
        let groupingColumnIndexesToRemove: number[] = [];
        let flowObjectIndexesToRemove: number[] = [];

        let groupingColumnsToRemove = this.selectedGroupingColumns.filter(x => x.groupingId == flowObject.groupingColumn.groupingId);
        groupingColumnsToRemove.forEach(item => {
            flowObjectsToRemove = flowObjectsToRemove.concat(this.selectedFlowObjects.filter(x => x.groupingColumnId == item.id));
            groupingColumnIndexesToRemove.push(item.outerIndex);
        });

        flowObjectsToRemove.forEach(item => flowObjectIndexesToRemove.push(item.outerIndex));

        flowObjectIndexesToRemove.sort((a, b) => b - a);
        flowObjectIndexesToRemove.forEach(item => this.selectedFlowObjects.splice(item, 1));

        groupingColumnIndexesToRemove.sort((a, b) => b - a);
        groupingColumnIndexesToRemove.forEach(item => this.selectedGroupingColumns.splice(item, 1));

        this.selectedGroupings.splice(groupingIndexToRemove, 1);

        this.reorderFlowObjects();
        this.linkFlowObjects();
        event.stopPropagation();
    }

    /**
     * altera o tipo de uma Task situada na posição "GroupingColumn.InnerIndex == 1 && FlowObjectDefinition.InnerIndex == 0"
     * (e.g. caminho "Sim" de Tarefa de Aprovação)
     */
    changeTaskType(event: MouseEvent, flowObject: FlowObjectDefinition) {
        if (this.isReadOnlyMode) {
            event.stopPropagation();
            return;
        }

        flowObject.configSchema = JSON.stringify(new ConfigSchema());

        // circula entre os tipos para minimizar a complexidade da interface para oferecer ao usuário diferentes opções
        switch (flowObject.typeId) {
            case FlowObjectType.TaskForward:
                flowObject.typeId = FlowObjectType.TaskRegisterProcess;
                break;

            case FlowObjectType.TaskRegisterProcess:
                flowObject.typeId = FlowObjectType.Finish;
                break;

            case FlowObjectType.Finish:
                flowObject.typeId = FlowObjectType.FinishOutboundApi;
                break;

            default:
                flowObject.typeId = FlowObjectType.TaskForward;
                break;
        }

        flowObject.name = FlowObjectTypeDescription.get(+flowObject.typeId);

        event.stopPropagation();
    }
    // #endregion

    // #region [FlowDefinition actions]
    reload() {
        this.reloadPage(this.router);
    }

    changeVersion() {
        this.router.navigate([Enums.PagesPaths.FlowDefinition, this.model.id, (this.selectedVersion == this.model.version ? '' : this.selectedVersion)]);
    }

    async publish() {
        if (!(await FlowRules.isFlowDefinitionValid(this.model, this.isReadOnlyMode, this.toastr, this.flowObjectDefinitionService))) {
            return;
        }

        this.executeAction(
            this.publish.name,
            Enums.Messages.PublishConfirmMessage,
            () => this.reloadPage(this.router)
        );
    }

    newVersion() {
        this.executeAction(
            this.newVersion.name,
            Enums.Messages.NewVersionConfirmMessage,
            () => this.reloadPage(this.router)
        );
    }

    clone() {
        this.executeAction(
            this.clone.name,
            Enums.Messages.CloneConfirmMessage,
            (clonedFlowDefinition: FlowDefinition) => this.reloadPage(this.router, clonedFlowDefinition.id)
        );
    }

    activate() {
        this.executeAction(
            this.activate.name,
            Enums.Messages.ActivateConfirmMessage,
            () => this.reloadPage(this.router)
        );
    }

    deactivate() {
        this.executeAction(
            this.deactivate.name,
            Enums.Messages.DeactivateConfirmMessage,
            () => this.reloadPage(this.router)
        );
    }

    delete() {
        this.executeAction(
            this.delete.name,
            Enums.Messages.DeleteConfirmMessage,
            () => this.router.navigate([Enums.PagesPaths.FlowDefinitionList])
        );
    }

    instantiate() {
        let environment = atob(this.cookieService.get('prodest-eflow-env'));
        if (environment.toLowerCase() == 'loc') {
            open(`${location.protocol}//${location.hostname}:44222${Enums.PagesPaths.FlowDefinition}/${this.model.id}`);
        } else {
            open(`${location.origin.split('admin.').join('')}${Enums.PagesPaths.FlowDefinition}/${this.model.id}`);
        }

        this.actionsMatPopoverRef.close();
    }

    async loadHistory() {
        this.spinner.show('flow');

        const response = await this.flowDefinitionService.getHistory({ key: window.location.host + window.location.pathname });

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.historyEntries = response.data;
        if (this.historyEntries.length > 0) {
            let currentEntryUser = this.historyEntries[0].auditUserName;

            for (let i = 0; i < this.historyEntries.length; i++) {
                let auditDate = this.historyEntries[i].auditDate;
                let finalSuffix = '';
                let now = new Date() as any;
                let then = new Date(auditDate) as any;
                let diff = (now - then)/1000;

                if (diff < 60) {
                    finalSuffix += Math.floor(diff) + ' segundo' + (Math.floor(diff) > 1 ? 's' : '');
                } else if (diff/60 < 60) {
                    finalSuffix += Math.floor(diff/60) + ' minuto' + (Math.floor(diff/60) > 1 ? 's' : '');
                } else if (diff/60/60 < 24) {
                    finalSuffix += Math.floor(diff/60/60) + ' hora' + (Math.floor(diff/60/60) > 1 ? 's': '');
                } else if (diff/60/60/24 < 30) {
                    finalSuffix += Math.floor(diff/60/60/24) + ' dia' + (Math.floor(diff/60/60/24) > 1 ? 's' : '');
                } else if (diff/60/60/24/30 < 12) {
                    finalSuffix += Math.floor(diff/60/60/24/30) + (Math.floor(diff/60/60/24/30) > 1 ? ' meses' : ' mês');
                } else {
                    finalSuffix += Math.floor(diff/60/60/24/30/12) + ' ano' + (Math.floor(diff/60/60/24/30/12) > 1 ? 's' : '');
                }

                this.historyEntries[i].auditDate = this.getAuditDateString(this.historyEntries[i].auditDate) + `*${finalSuffix} atrás`;

                if (i > 0 && this.historyEntries[i].auditUserName == currentEntryUser) {
                    this.historyEntries[i].auditUserName = [this.historyEntries[i].auditUserName, ''].join('*');
                } else {
                    currentEntryUser = this.historyEntries[i].auditUserName;
                }
            }
        }

        this.viewHistoryMatPopoverRef.open();

        this.spinner.hide('flow');
    }
    // #endregion

    // ======================
    // private methods
    // ======================

    // #region [screen events]
    private isMouseInsideArea(pointerPosition, area, offset?): boolean {
        offset = offset || 0;

        return pointerPosition.x > area.left - offset
            && pointerPosition.x < area.right + offset
            && pointerPosition.y > area.top - offset
            && pointerPosition.y < area.bottom + offset;
    }

    private isMouseOutsideArea(pointerPosition, area, offset?): boolean {
        offset = offset || 0;

        return pointerPosition.x < area.left - offset
            || pointerPosition.x > area.right + offset
            || pointerPosition.y < area.top - offset
            || pointerPosition.y > area.bottom + offset;
    }
    // #endregion

    // #region [área do canvas (Flow Area)]
    private initializeCollections() {
        if (!this.isCreate) {
            this.selectedFlowObjects = this.model.flowObjectDefinitions;
            this.selectedFlowObjects.sort((a, b) => a.outerIndex - b.outerIndex);

            this.selectedFlowObjects.forEach(item => {
                if (!this.selectedGroupingColumns.some(x => x.id == item.groupingColumnId)) {
                    this.selectedGroupingColumns.push(item.groupingColumn);
                }
            });
            this.selectedGroupingColumns.sort((a, b) => a.outerIndex - b.outerIndex);

            this.selectedGroupingColumns.forEach(item => {
                if (!this.selectedGroupings.some(x => x.id == item.groupingId)) {
                    this.selectedGroupings.push(item.grouping);
                }

                item.flowObjectDefinitions = item.flowObjectDefinitions.concat(this.selectedFlowObjects.filter(x => x.groupingColumnId == item.id));
            });
            this.selectedGroupings.sort((a, b) => a.outerIndex - b.outerIndex);

            this.selectedGroupings.forEach(item => {
                item.groupingColumns = item.groupingColumns.concat(this.selectedGroupingColumns.filter(x => x.groupingId == item.id));
            });
        }

        // recupera as Tarefas habilitadas para utilização (especificadas no appsettings.json, relay via cookie)
        let enabledTasks = this.getEnabledTasks();
        if (enabledTasks != null) {
            // caso haja especificação de Tarefas, apenas uma das duas listas é considerada
            if (enabledTasks.whitelist != null) {
                enabledTasks.whitelist.forEach((item: string) => {
                    if (
                        FlowObjectType[item.trim()] != null
                        && +item != FlowObjectType.Dummy
                    ) {
                        this.availableFlowObjects.push(new FlowObjectDefinition({ typeId: FlowObjectType[item.trim()] }));
                    }
                });
            } else if (enabledTasks.blacklist != null) {
                for (let item in FlowObjectType) {
                    if (
                        Number.isInteger(+item)
                        && !enabledTasks.blacklist.includes(FlowObjectType[item.trim()])
                        && +item != FlowObjectType.Dummy
                    ) {
                        this.availableFlowObjects.push(new FlowObjectDefinition({ typeId: +item }));
                    }
                }
            }
        } else {
            // caso não haja especificação de Tarefas, exibe todas as disponíveis
            for (let item in FlowObjectType) {
                if (
                    Number.isInteger(+item)
                    && +item != FlowObjectType.Dummy
                ) {
                    this.availableFlowObjects.push(new FlowObjectDefinition({ typeId: +item }));
                }
            }
        }

        this.flowDefinitionVersionOptions = Array.from({ length: this.model.version }, (item, idx) => idx + 1).reverse();
        this.flowTargetOptions = Array.from(this.FlowTargetLabel, item => {
            return {
                value: item[0].toString(),
                description: item[1]
            };
        });

        setTimeout(() => {
            this.spinner.hide();
            this.isBootstrapFinished = true;
        }, 800);
    }

    private clearLinks() {
        document.querySelectorAll('.leader-line').forEach(elem => elem.parentNode.removeChild(elem));
    }

    private linkFlowObjects() {
        this.clearLinks();

        let interval = setInterval(() => {
            if (document.querySelector('.leader-line.in') != null) {
                clearInterval(interval);
                return;
            }

            this.drawLinks(this.mainPathFlowObjects, this.mainPathFlowObjectRefs, this.mainPathRef);

            if (this.hasGatewayPath) {
                this.drawLinks(this.firstGatewayPathFlowObjects, this.firstGatewayPathFlowObjectRefs, this.firstGatewayPathRef, true);
                this.drawLinks(this.secondGatewayPathFlowObjects, this.secondGatewayPathFlowObjectRefs, this.secondGatewayPathRef, true);
            }
        }, 100);
    }

    private drawLinks(
        flowObjects: FlowObjectDefinition[],
        flowObjectRefs: ElementRef<any>[],
        containerRef: ElementRef<any>,
        isGatewayPath: boolean = false
    ) {
        Utils.flowAreaReady(() => {
            let currentOverlapIndex = 0;

            let options: any = {
                color: SCSS_VARS['$leader-line-color'],
                path: 'grid',
                startSocket: 'right',
                endSocket: 'left',
                dash: {
                    animation: true,
                    len: 50,
                    gap: 2
                }
            };

            // filtro para remover da lista de refs os elementos desconectados do DOM
            flowObjectRefs = flowObjectRefs.filter(x => document.body.contains(x.nativeElement));

            flowObjectRefs.forEach(el => {
                let elem = el.nativeElement;
                let lines = [];
                let currentFlowObject = flowObjects.find(item => item.id == elem.dataset.id);

                currentFlowObject?.next?.forEach(next => {
                    let endElement = flowObjectRefs.find(x => x.nativeElement.dataset.id == next.id).nativeElement;

                    if ([
                        FlowObjectType.GatewayApprove,
                        FlowObjectType.GatewayRules,
                        FlowObjectType.GatewayPaths
                    ].includes(currentFlowObject.typeId)) {
                        let label = currentFlowObject.typeId == FlowObjectType.GatewayPaths ? '' : Enums.GatewayPathsLabels.False;
                        let offset = [-42, 0];

                        if (next.innerIndex == 0) {
                            label = currentFlowObject.typeId == FlowObjectType.GatewayPaths ? '' : Enums.GatewayPathsLabels.True;
                            offset = [-42, -33];
                        }

                        let captionLabelOptions = {
                            color: SCSS_VARS['$leader-line-labels-color'],
                            outlineColor: '',
                            fontSize: '1em',
                            fontWeight: 'bold',
                            offset: offset
                        };
                        let opt0 = JSON.parse(JSON.stringify(options));
                        opt0.endLabel = LeaderLine.captionLabel(label, captionLabelOptions);

                        lines.push(new LeaderLine(elem, endElement, opt0));
                    } else if (elem.offsetTop != endElement.offsetTop && Math.abs(endElement.offsetLeft - elem.offsetLeft) > 250) {
                        currentOverlapIndex++;
                        let overlapCaptionLabelOptions = {
                            color: '#fff',
                            outlineColor: '',
                            fontSize: '1.2em',
                            fontWeight: 'bold',
                            offset: [-5, -20]
                        };
                        let overlapOptions = {
                            plug: 'square',
                            plugSize: 2.5,
                            plugColor: '#ccc',
                            startLabel: LeaderLine.captionLabel(currentOverlapIndex.toString(), overlapCaptionLabelOptions),
                            endLabel: LeaderLine.captionLabel(currentOverlapIndex.toString(), overlapCaptionLabelOptions)
                        };

                        let endPoint = elem.offsetTop + (elem.offsetHeight / 2);
                        let opt1 = JSON.parse(JSON.stringify(options));
                        opt1.endPlug = overlapOptions.plug;
                        opt1.endPlugSize = overlapOptions.plugSize;
                        opt1.endPlugColor = overlapOptions.plugColor;
                        opt1.endLabel = overlapOptions.endLabel;
                        lines.push(new LeaderLine(elem, LeaderLine.pointAnchor(containerRef.nativeElement, { x: '100%', y: endPoint }), opt1));

                        let startPoint = endElement.offsetTop + (endElement.offsetHeight / 2);
                        let opt2 = JSON.parse(JSON.stringify(options));
                        opt2.startPlug = overlapOptions.plug;
                        opt2.startPlugSize = overlapOptions.plugSize;
                        opt2.startPlugColor = overlapOptions.plugColor;
                        opt2.startLabel = overlapOptions.startLabel;
                        lines.push(new LeaderLine(LeaderLine.pointAnchor(containerRef.nativeElement, { x: '0%', y: startPoint }), endElement, opt2));
                    } else {
                        lines.push(new LeaderLine(elem, endElement, options));
                    }
                });

                lines.forEach(line => {
                    line.position();
                    (document.querySelectorAll('.leader-line') as NodeListOf<HTMLElement>).forEach(elem => {
                        elem.dispatchEvent(new Event('focusin'));
                        elem.classList.add('in');
                        elem.dispatchEvent(new Event('focusout'));
                    });
                });
            });
        }, isGatewayPath);
    }

    private reorderFlowObjects() {
        // #region [Main Path]
        this.mainPathGroupings.forEach((item, idx) => item.outerIndex = idx);

        let bufferGroupingColumns = [];
        this.mainPathGroupings.forEach(item => {
            bufferGroupingColumns = bufferGroupingColumns.concat(this.mainPathGroupingColumns.filter(x => x.groupingId == item.id));
        });
        bufferGroupingColumns.forEach((item, idx) => item.outerIndex = idx);

        let bufferFlowObjects = [];
        bufferGroupingColumns.forEach(item => {
            bufferFlowObjects = bufferFlowObjects.concat(this.mainPathFlowObjects.filter(x => x.groupingColumnId == item.id));
        });
        bufferFlowObjects.forEach((item, idx) => item.outerIndex = idx);
        // #endregion

        // #region [First Gateway Path]
        this.firstGatewayPathGroupings.forEach((item, idx) => item.outerIndex = this.mainPathGroupings.length + idx);

        bufferGroupingColumns = [];
        this.firstGatewayPathGroupings.forEach(item => {
            bufferGroupingColumns = bufferGroupingColumns.concat(this.firstGatewayPathGroupingColumns.filter(x => x.groupingId == item.id));
        });
        bufferGroupingColumns.forEach((item, idx) => item.outerIndex = this.mainPathGroupingColumns.length + idx);

        bufferFlowObjects = [];
        bufferGroupingColumns.forEach(item => {
            bufferFlowObjects = bufferFlowObjects.concat(this.firstGatewayPathFlowObjects.filter(x => x.groupingColumnId == item.id));
        });
        bufferFlowObjects.forEach((item, idx) => item.outerIndex = this.mainPathFlowObjects.length + idx);
        // #endregion

        // #region [Second Gateway Path]
        this.secondGatewayPathGroupings.forEach((item, idx) => item.outerIndex = this.mainPathGroupings.length + this.firstGatewayPathGroupings.length + idx);

        bufferGroupingColumns = [];
        this.secondGatewayPathGroupings.forEach(item => {
            bufferGroupingColumns = bufferGroupingColumns.concat(this.secondGatewayPathGroupingColumns.filter(x => x.groupingId == item.id));
        });
        bufferGroupingColumns.forEach((item, idx) => item.outerIndex = this.mainPathGroupingColumns.length + this.firstGatewayPathGroupingColumns.length + idx);

        bufferFlowObjects = [];
        bufferGroupingColumns.forEach(item => {
            bufferFlowObjects = bufferFlowObjects.concat(this.secondGatewayPathFlowObjects.filter(x => x.groupingColumnId == item.id));
        });
        bufferFlowObjects.forEach((item, idx) => item.outerIndex = this.mainPathFlowObjects.length + this.firstGatewayPathFlowObjects.length + idx);
        // #endregion

        this.selectedFlowObjects.sort((a, b) => a.outerIndex - b.outerIndex);
        this.selectedGroupingColumns.sort((a, b) => a.outerIndex - b.outerIndex);
        this.selectedGroupings.sort((a, b) => a.outerIndex - b.outerIndex);

        this.resolveNextFlowObjects();
    }

    private resolveNextFlowObjects() {
        this.mainPathGroupingColumns.forEach((groupingColumn, idx) => {
            if (groupingColumn.flowObjectDefinitions.some(x => x.typeId == FlowObjectType.Dummy)) return;

            let nextToBe = [];
            if (idx < this.mainPathGroupingColumns.length - 1) {
                nextToBe = this.mainPathFlowObjects.filter(x => this.mainPathGroupingColumns[idx + 1].flowObjectDefinitions.some(y => y.id == x.id));
            }

            this.mainPathFlowObjects.find(x => x.id == groupingColumn.flowObjectDefinitions.find(z => z.innerIndex == 0).id).next = nextToBe;
        });

        if (!this.hasGatewayPath) return;

        this.firstGatewayPathGroupingColumns.forEach((groupingColumn, idx) => {
            let nextToBe = [];
            if (idx < this.firstGatewayPathGroupingColumns.length - 1) {
                nextToBe = this.firstGatewayPathFlowObjects.filter(x => this.firstGatewayPathGroupingColumns[idx + 1].flowObjectDefinitions.some(y => y.id == x.id));
            }

            this.firstGatewayPathFlowObjects.find(x => x.id == groupingColumn.flowObjectDefinitions.find(z => z.innerIndex == 0).id).next = nextToBe;
        });

        this.secondGatewayPathGroupingColumns.forEach((groupingColumn, idx) => {
            let nextToBe = [];
            if (idx < this.secondGatewayPathGroupingColumns.length - 1) {
                nextToBe = this.secondGatewayPathFlowObjects.filter(x => this.secondGatewayPathGroupingColumns[idx + 1].flowObjectDefinitions.some(y => y.id == x.id));
            }

            this.secondGatewayPathFlowObjects.find(x => x.id == groupingColumn.flowObjectDefinitions.find(z => z.innerIndex == 0).id).next = nextToBe;
        });
    }

    private getNextOuterIndexGroupings(
        isFirstGatewayPath: boolean,
        isSecondGatewayPath: boolean
    ): number {
        return isFirstGatewayPath
            ? this.mainPathGroupings.length + this.firstGatewayPathGroupings.length
            : isSecondGatewayPath
                ? this.mainPathGroupings.length + this.firstGatewayPathGroupings.length + this.secondGatewayPathGroupings.length
                : this.mainPathGroupings.length;
    }

    private getNextOuterIndexGroupingColumns(
        isFirstGatewayPath: boolean,
        isSecondGatewayPath: boolean
    ): number {
        return isFirstGatewayPath
            ? this.mainPathGroupingColumns.length + this.firstGatewayPathGroupingColumns.length
            : isSecondGatewayPath
                ? this.mainPathGroupingColumns.length + this.firstGatewayPathGroupingColumns.length + this.secondGatewayPathGroupingColumns.length
                : this.mainPathGroupingColumns.length;
    }

    private getNextOuterIndexFlowObjects(
        isFirstGatewayPath: boolean,
        isSecondGatewayPath: boolean
    ): number {
        return isFirstGatewayPath
            ? this.mainPathFlowObjects.length + this.firstGatewayPathFlowObjects.length
            : isSecondGatewayPath
                ? this.mainPathFlowObjects.length + this.firstGatewayPathFlowObjects.length + this.secondGatewayPathFlowObjects.length
                : this.mainPathFlowObjects.length;
    }

    private getEnabledTasks(): any {
        // lê os valores dos cookies de Tarefas habilitadas
        let whitelist = atob(this.cookieService.get('prodest-eflow-admin-tasks-whitelist')).split(/[ ;,\.\-]/gi).filter(x => x != '');
        let blacklist = atob(this.cookieService.get('prodest-eflow-admin-tasks-blacklist')).split(/[ ;,\.\-]/gi).filter(x => x != '');

        // caso ambos os cookies não existam ou ambos possuam valor, ignorá-los
        if (
            (whitelist.length == 0 && blacklist.length == 0)
            || (whitelist.length > 0 && blacklist.length > 0)
        ) {
            return null;
        }

        if (whitelist.length == 0) {
            return { blacklist: blacklist };
        }

        return { whitelist: whitelist };
    }
    // #endregion

    // #region [telas de Detalhes]
    private async previewPdf(context) {
        context.spinner.show('flow');

        const response = await context.flowObjectDefinitionService.previewPdf({
            documentHtmlContent: context.tinyMceContent,
            unitId: context.model.unitId
        });

        context.spinner.hide('flow');

        if (!response.isSuccess) {
            context.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        context.dialog.open(PdfPreviewDialogComponent, {
            data: response.data
        });
    }

    private getTinyMceMenuFormEntries(flowObject: FlowObjectDefinition, editor): any[] {
        if (flowObject.formSchema == null || flowObject.formSchema == '') return;

        let entries = [];
        let formSchema = JSON.parse(flowObject.formSchema) as FormSchema;

        formSchema.components.forEach(item => {
            if (['table', 'tableCustom'].includes(item.type)) {
                item.rows.forEach(row => {
                    row.forEach(subitem => {
                        subitem.components.forEach(subsubitem => {
                            entries.push({
                                type: 'menuitem',
                                text: subsubitem.label,
                                onAction: () => editor.insertContent(`{|${subsubitem.key}|}`)
                            });
                        });
                    });
                });
            } else if (!['button'].includes(item.type)) {
                entries.push({
                    type: 'menuitem',
                    text: item.label,
                    onAction: () => editor.insertContent(`{|${item.key}|}`)
                });
            }
        });

        return entries;
    }

    /**
     * Contorno de bug envolvendo MatTab + FormBuilder.
     * Existência da mat-tab "Construtor de Formulário" não pode ser controlada por *ngIf, a exemplo da mat-tab
     * "Detalhes", ou o componente FormBuilder entra em loop durante a instanciação e trava o navegador
     */
    private setFormBuilderAreaVisibility() {
        if (!this.isBootstrapFinished) {
            setTimeout(() => this.setFormBuilderAreaVisibility(), 600);
            return;
        }

        let matArray = Array.from(document.querySelectorAll('[id ^= "mat-tab-label-"]'));
        let shouldRepeat = true;

        let formBuilderTab = matArray.find((x: HTMLElement) => x.innerText == Enums.DetailsAreaTabsLabel.get(Enums.DetailsAreaTabs.FormBuilder));
        if (formBuilderTab != null) {
            shouldRepeat = false;
            if (this.isAreaVisible.formBuilder) {
                formBuilderTab.classList.remove('d-none');
            } else {
                formBuilderTab.classList.add('d-none');
            }
        }

        formBuilderTab = matArray.find((x: HTMLElement) => x.innerText == Enums.DetailsAreaTabsLabel.get(Enums.DetailsAreaTabs.FormBuilderInboundApi));
        if (formBuilderTab != null) {
            shouldRepeat = false;
            if (this.isAreaVisible.formBuilderInboundApi) {
                formBuilderTab.classList.remove('d-none');
            } else {
                formBuilderTab.classList.add('d-none');
            }
        }

        if (shouldRepeat) {
            setTimeout(() => this.setFormBuilderAreaVisibility(), 600);
        }
    }

    private hasOnlyDetailsArea(): boolean {
        return this.isAreaVisible.flowObjectDetails
            && !this.isAreaVisible.formBuilder
            && !this.isAreaVisible.formBuilderInboundApi
            && !this.isAreaVisible.htmlEditor
            && !this.isAreaVisible.api
            && !this.isAreaVisible.midApi
            && !this.isAreaVisible.gatewayRules
            && !this.isAreaVisible.gatewayPaths;
    }

    private async getFormData(id): Promise<string> {
        const response = await this.flowObjectDefinitionService.getFormData(id);

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return null;
        }

        return response.data;
    }
    // #endregion

    // #region [modify]
    private async proceedWithSaveFlowDefinition() {
        if (!this.isReadOnlyMode) {
            this.model.flowObjectDefinitions = this.selectedFlowObjects;
        }

        this.spinner.show('flow');

        let response;
        if (this.isCreate) {
            response = await this.flowDefinitionService.insert(this.model);
        } else {
            response = await this.flowDefinitionService.update(this.model);
        }

        if (!response.isSuccess) {
            this.toastr.error(
                response.message.description + (
                    response.debugMessage != null && response.debugMessage != response.message.description
                        ? `: ${response.debugMessage}`
                        : ''
                ),
                Enums.Messages.Error,
                Utils.getToastrErrorOptions()
            );
            this.spinner.hide('flow');
            return false;
        }

        this.toastr.success(response.message.description, Enums.Messages.Success);

        if (this.isCreate) {
            this.router.navigate([Enums.PagesPaths.FlowDefinition, this.model.id]);
        } else if (this.isAreaVisible.flowDefinitionDetails || this.isAreaVisible.flowDefinitionDetailsHotConfigs) {
            this.closeModalAreas(true);
        }

        this.spinner.hide('flow');
    }

    private async hasChangedAutoCancelOptions(): Promise<boolean> {
        if (!this.isCreate) {
            const response = await this.flowDefinitionService.getById(this.paramId);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return false;
            }

            let configSchemaResponse = response.data.configSchema != null ? JSON.parse(response.data.configSchema) : new ConfigSchemaFlowDefinition();
            let configSchemaModel = this.model.configSchema != null ? JSON.parse(this.model.configSchema) : new ConfigSchemaFlowDefinition();

            return configSchemaResponse.hasAutomaticFlowDefinitionDeactivation != configSchemaModel.hasAutomaticFlowDefinitionDeactivation
                || configSchemaResponse.daysSinceFlowDefinitionActivationToAutoDeactivate != configSchemaModel.daysSinceFlowDefinitionActivationToAutoDeactivate
                || configSchemaResponse.dateTimeToAutoDeactivateFlowDefinition != configSchemaModel.dateTimeToAutoDeactivateFlowDefinition
                || configSchemaResponse.hasAutomaticFlowInstanceCancellation != configSchemaModel.hasAutomaticFlowInstanceCancellation
                || configSchemaResponse.daysSinceFlowInstanceStartToAutoCancel != configSchemaModel.daysSinceFlowInstanceStartToAutoCancel
                || configSchemaResponse.daysSinceFlowInstanceLastUpdateToAutoCancel != configSchemaModel.daysSinceFlowInstanceLastUpdateToAutoCancel
                || configSchemaResponse.dateTimeToAutoCancelFlowInstance != configSchemaModel.dateTimeToAutoCancelFlowInstance;
        }

        return false;
    }
    // #endregion

    // #region [FlowDefinition actions]
    private executeAction(
        action: string,
        confirmationMessage: string,
        callbackAfterSuccess: Function
    ) {
        this.actionsMatPopoverRef.close();

        this.confirmationService.confirm({
            message: confirmationMessage.replace('{0}', this.model.name),
            accept: async () => {
                this.spinner.show('flow');

                const response = await this.flowDefinitionService[action](this.model.id);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    this.spinner.hide('flow');
                    return;
                }

                this.toastr.success(response.message.description, Enums.Messages.Success);
                callbackAfterSuccess(response.data);
                this.spinner.hide('flow');
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    private reloadPage(router: Router, flowDefinitionId?: string) {
        router.navigateByUrl(Enums.PagesPaths.FlowDefinition + '/?r', { skipLocationChange: true }).then(
            () => this.router.navigate([Enums.PagesPaths.FlowDefinition, flowDefinitionId || this.paramId])
        );
    }

    private getAuditDateString(date: string) {
        return new Date(date).toLocaleString();
    }
    // #endregion
}
