<ng-container *ngIf="model != null">
    <form class="px-1" (ngSubmit)="onSubmit()" #flowObjectMidwayOutboundApi="ngForm">
        <div class="accordion scroll-wrapper styled-scrollbar px-2 pb-3">
            <!-- Configurações Básicas -->
            <div class="card">
                <!-- Header -->
                <div class="accordion-heading">
                    <h5 class="mb-0">
                        <i class="fas fa-chevron-circle-right" [class.on]=" isTabOpen(1)"></i>
                        <a class="btn btn-link" (click)="toggleTab(1)">
                            Configurações Básicas
                        </a>
                    </h5>
                </div>

                <!-- Body -->
                <div class="accordion-body card-body" [class.on]=" isTabOpen(1)">
                    <!-- Método HTTP e URL do Endpoint -->
                    <div class="form-group mb-3">
                        <div class="row">
                            <div class="form-group col-2 m-0">
                                <label for="url">Método HTTP</label>
                                <select class="form-control" id="httpMethod" name="httpMethod" [(ngModel)]="selectedHttpMethod">
                                    <option *ngFor="let option of httpMethodOptions" [value]="option.value">{{option.description}}</option>
                                </select>
                            </div>

                            <div class="form-group col m-0">
                                <label for="url">URL do Endpoint</label>
                                <input type="text" class="form-control" id="url" name="url" required
                                       placeholder="e.g. &quot;https://api.siarhes.es.gov.br/tempo-servico&quot;"
                                       #urlElem="ngModel"
                                       [(ngModel)]="url"
                                >
                                <div [hidden]="urlElem.valid || urlElem.pristine" class="alert alert-danger mt-1">Campo obrigatório</div>
                            </div>
                        </div>
                    </div>

                    <!-- Nome do Sistema de Destino -->
                    <ng-container>
                        <div class="form-group">
                            <label for="publicSystemName">Nome do Sistema de Destino</label>
                            <input type="text" class="form-control" id="publicSystemName" name="publicSystemName" required
                                   #publicSystemNameElem="ngModel"
                                   [(ngModel)]="publicSystemName"
                            >
                        </div>
                        <div [hidden]="publicSystemNameElem.valid || publicSystemNameElem.pristine" class="alert alert-danger">Campo obrigatório</div>

                        <blockquote class="info-container field">
                            <div class="description info-highlight">
                                <i class="fas fa-info-circle info"></i>
                                <span>
                                    Nome do sistema que representa a API de destino para exibição ao usuário no Portal Público. Não possui
                                    nenhum tipo de checagem envolvida; seu caráter é apenas informativo.
                                </span>
                            </div>
                        </blockquote>
                    </ng-container>

                    <!-- Mensagem de Erro Padrão -->
                    <ng-container>
                        <div class="form-group">
                            <label for="errorMessage">Mensagem de Erro Padrão</label>
                            <input type="text" class="form-control" id="errorMessage" name="errorMessage"
                                   [(ngModel)]="errorMessage"
                                   [placeholder]="'e.g. &quot;Erro ao enviar mensagem para o sistema de destino.&quot;'"
                            >
                        </div>

                        <blockquote class="info-container field">
                            <div class="description info-highlight">
                                <i class="fas fa-info-circle info"></i>
                                <span>
                                    Mensagem padrão a ser exibida para o usuário no Portal Público em caso de erro na execução da Tarefa.
                                </span>
                            </div>
                        </blockquote>
                    </ng-container>

                    <!-- Informar parâmetros de URL dinâmica -->
                    <div class="form-group">
                        <mat-checkbox id="useUrlParameters" name="useUrlParameters" color="primary" class="ml-0"
                                      [(ngModel)]="useUrlParameters"
                                      (change)="toggleShowParametersUrl()"
                        >Informar parâmetros de URL dinâmica</mat-checkbox>

                        <blockquote class="info-container checkbox">
                            <div class="description info-highlight" ngNonBindable>
                                <i class="fas fa-info-circle info"></i>
                                <span>
                                    A URL de uma requisição pode ser configurada dinamicamente utilizando-se o template
                                    <span class="code-font text-highlight">{{variável}}</span>. Tomando como exemplo o endereço
                                    <span class="code-font text-highlight">https://api.siarhes.es.gov.br/{{userId}}/tempo-servico</span>,
                                    o parâmetro <span class="code-font text-highlight">{{userId}}</span> seria substituído durante a
                                    execução do Fluxo.
                                </span>
                            </div>
                        </blockquote>
                    </div>

                    <!-- Parâmetros de URL dinâmica -->
                    <div class="key-value-pair-view-container" *ngIf="showParametersUrl">
                        <div class="form-group">
                            <div class="subsection-wrapper ml-4">
                                <div class="form-group mb-0">
                                    <label for="parameterValueType">Parâmetro</label>
                                    <select class="form-control" id="parameterValueType" name="parameterKeyType"
                                            [(ngModel)]="parameterKeyType"
                                            (change)="parameterKey = null;"
                                    >
                                        <option [value]="PairValueTypeValues.CPF" selected>CPF</option>
                                        <optgroup label="Formulário">
                                            <option *ngFor="let entry of formFlowObjectEntries"
                                                    [value]="PairValueTypeValues.Form.replace('{0}', formFlowObject.id).replace('{1}', entry.key)"
                                            >{{entry.label}}</option>
                                        </optgroup>
                                    </select>
                                </div>

                                <div class="form-group mb-0">
                                    <label for="parameterKey">Chave do parâmetro da URL</label>
                                    <input type="text" class="form-control" id="parameterKey" name="parameterKey"
                                           [(ngModel)]="parameterKey"
                                           [placeholder]="parameterKeyType == PairValueTypeValues.CPF ? cpfParameterTip : formParameterTip"
                                    >
                                </div>

                                <button type="button" class="btn btn-outline-primary ml-2" (click)="addParameter()">
                                    <i class="fas fa-plus-circle mr-2"></i>
                                    <span>Adicionar</span>
                                </button>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="key-value-pairs-container ml-4">
                                <div class="key-value-pair mr-2 mb-2 p-2" *ngFor="let parameter of keyValueParametersArray">
                                    <pre class="m-0" [innerHtml]="prettyPrint(parameter)"></pre>
                                    <i class="fas fa-trash-alt ml-2" matTooltip="Remover par de chave/valor"
                                       (click)="removeParameter(parameter)">
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Executar Tarefa em background -->
                    <!--<div class="form-group mt-2">
                        <mat-checkbox id="backgroundExecution" name="backgroundExecution" color="primary" class="ml-0"
                                      [(ngModel)]="backgroundExecution"
                        >Executar Tarefa em background</mat-checkbox>

                        <blockquote class="info-container checkbox">
                            <div class="description info-highlight">
                                <i class="fas fa-info-circle info"></i>
                                <span>
                                    O Fluxo continuará sua execução mesmo que haja erro na execução desta Tarefa.
                                </span>
                            </div>
                        </blockquote>
                    </div>-->

                    <!-- Ignorar erros de execução -->
                    <div class="form-group mt-2">
                        <mat-checkbox id="ignoreErrors" name="ignoreErrors" color="primary" class="ml-0"
                                      [(ngModel)]="ignoreErrors"
                        >Ignorar erros de execução</mat-checkbox>

                        <blockquote class="info-container checkbox">
                            <div class="description info-highlight">
                                <i class="fas fa-info-circle info"></i>
                                <span>
                                    Se marcada, esta opção fará com que o Fluxo prossiga normalmente para a próxima Tarefa mesmo que haja erro
                                    na execução da chamada à API de destino — isto é, caso a chamada tenha retorno com código HTTP diferente de
                                    <span class="code-font text-highlight">2XX</span>. Com a opção desmarcada, o Fluxo será encerrado com a Tarefa
                                    do caminho <b>Não</b> da Tarefa de Aplicação de Regras imediatamente posterior.
                                </span>
                            </div>
                        </blockquote>
                    </div>
                </div>
            </div>

            <!-- Autenticação -->
            <div class="card">
                <!-- Header -->
                <div class="accordion-heading">
                    <h5 class="mb-0">
                        <i class="fas fa-chevron-circle-right" [class.on]=" isTabOpen(2)"></i>
                        <a class="btn btn-link" (click)="toggleTab(2)">
                            Autenticação
                        </a>
                    </h5>
                </div>

                <!-- Body -->
                <div class="accordion-body card-body" [class.on]=" isTabOpen(2)">
                    <div class="form-group">
                        <label for="authenticationScheme">Esquema de Autenticação</label>
                        <select class="form-control" id="authenticationScheme" name="authenticationScheme"
                                [(ngModel)]="authentication.authenticationScheme"
                                (change)="authenticationSchemeChangeHandler()"
                        >
                            <option *ngFor="let option of authenticationSchemeOptions" [value]="option.value">{{option.description}}</option>
                        </select>
                    </div>

                    <div class="form-group ml-4" *ngIf="[AuthenticationSchemeType.Basic, AuthenticationSchemeType.ApiKey].includes(+authentication.authenticationScheme)">
                        <label for="basicOrApiKey">{{authentication.authenticationScheme == AuthenticationSchemeType.Basic
                            ? 'Token de Autenticação Básica (formato Base64)'
                            : 'Token de API Key (header HTTP "X-Api-Key")'
                        }}</label>
                        <input type="text" class="form-control" id="basicOrApiKey" name="basicOrApiKey" #basicOrApiKeyElem="ngModel" required
                                placeholder="e.g. {{authentication.authenticationScheme == AuthenticationSchemeType.Basic ? 'dXNlcm5hbWU6cGFzc3dvcmQ=' : 'abcdef12345'}}"
                                [(ngModel)]="authentication.basicOrApiKey"
                        >
                        <div [hidden]="basicOrApiKeyElem.valid || basicOrApiKeyElem.pristine" class="alert alert-danger">Campo obrigatório</div>
                    </div>

                    <ng-container *ngIf="authentication.authenticationScheme == AuthenticationSchemeType.Jwt">
                        <div class="form-group ml-4">
                            <label for="clientId">Client ID</label>
                            <input type="text" class="form-control" id="clientId" name="clientId" #clientIdElem="ngModel" required
                                    placeholder="e.g. 890ab991-b714-2fc2-ba31-8941fae87133"
                                    [(ngModel)]="authentication.clientId"
                            >
                            <div [hidden]="clientIdElem.valid || clientIdElem.pristine" class="alert alert-danger">Campo obrigatório</div>
                        </div>

                        <div class="form-group ml-4">
                            <label for="clientSecret">Client Secret</label>
                            <input type="text" class="form-control" id="clientSecret" name="clientSecret" #clientSecretElem="ngModel" required
                                    placeholder="e.g. V1Tc23fsda356vvsa45bgsf6KFmPTB"
                                    [(ngModel)]="authentication.clientSecret"
                            >
                            <div [hidden]="clientSecretElem.valid || clientSecretElem.pristine" class="alert alert-danger">Campo obrigatório</div>
                        </div>

                        <div class="form-group ml-4">
                            <label for="scopes">Scopes</label>
                            <input type="text" class="form-control" id="scopes" name="scopes" #scopesElem="ngModel" required
                                    placeholder="e.g. &quot;openid profile email nome&quot;"
                                    [(ngModel)]="authentication.scopes"
                            >
                            <div [hidden]="scopesElem.valid || scopesElem.pristine" class="alert alert-danger">Campo obrigatório</div>
                        </div>

                        <div class="form-group ml-4">
                            <label for="tokenEndpoint">Token Endpoint</label>
                            <input type="text" class="form-control" id="tokenEndpoint" name="tokenEndpoint" #tokenEndpointElem="ngModel" required
                                    placeholder="e.g. https://acessocidadao.es.gov.br/is/connect/token"
                                    [(ngModel)]="authentication.tokenEndpoint"
                            >
                            <div [hidden]="tokenEndpointElem.valid || tokenEndpointElem.pristine" class="alert alert-danger">Campo obrigatório</div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- Corpo da Requisição -->
            <div class="card">
                <!-- Header -->
                <div class="accordion-heading">
                    <h5 class="mb-0">
                        <i class="fas fa-chevron-circle-right" [class.on]=" isTabOpen(3)"></i>
                        <a class="btn btn-link" (click)="toggleTab(3)">
                            Corpo da Requisição
                        </a>
                    </h5>
                </div>

                <!-- Body -->
                <div class="accordion-body card-body" [class.on]=" isTabOpen(3)">
                    <div class="form-group">
                        <span class="subtitle">
                            Monte aqui o conteúdo (payload em formato JSON) que será enviado no corpo da requisição para o endpoint de
                            destino. Você pode adicionar os pares de chave/valor de forma estruturada ou editar o JSON diretamente. Caso
                            queira adicionar valores de campos do formulário respondido na Tarefa inicial, utilize o modo de visualização
                            por pares de chave/valor para que as tags de substituição sejam inseridas corretamente.
                        </span>
                    </div>

                    <div class="form-group">
                        <label class="subsection-label m-0">Modo de visualização</label>
                        <div class="subsection-wrapper ml-4">
                            <span class="slide-toggle-label" [class.on]="!showJsonView" (click)="toggleShowJsonView()">
                                Adicionar pares de chave/valor
                            </span>
                            <mat-slide-toggle class="mx-2" name="showJsonView" color="primary"
                                              [(ngModel)]="showJsonView"
                                              (change)="isJsonValid($event)"></mat-slide-toggle>
                            <span class="slide-toggle-label" [class.on]="showJsonView" (click)="toggleShowJsonView()">
                                Editar JSON completo
                            </span>
                        </div>
                    </div>

                    <div class="key-value-pair-view-container" *ngIf="!showJsonView; else showJsonViewTemplate">
                        <div class="form-group">
                            <label class="subsection-label mb-2">Adicionar pares de chave/valor</label>
                            <div class="subsection-wrapper ml-4">
                                <div class="form-group mb-0">
                                    <label for="pairValueType">Tipo do Valor</label>
                                    <select class="form-control" id="pairValueType" name="pairValueType"
                                            [(ngModel)]="pairValueType"
                                            (change)="clearKeyValueFields()">
                                        <option [value]="PairValueTypeValues.EDocs">Protocolo E-Docs</option>
                                        <optgroup label="Valores">
                                            <option [value]="PairValueTypeValues.String">Texto</option>
                                            <option [value]="PairValueTypeValues.Number">Número</option>
                                            <option [value]="PairValueTypeValues.Boolean">Booleano</option>
                                            <option [value]="PairValueTypeValues.Null">Nulo</option>
                                        </optgroup>
                                        <optgroup label="Formulário">
                                            <option *ngFor="let entry of formFlowObjectEntries"
                                                    [value]="PairValueTypeValues.Form.replace('{0}', formFlowObject.id).replace('{1}', entry.key)"
                                            >{{entry.label}}</option>
                                        </optgroup>
                                    </select>
                                    <i class="fas fa-question-circle"
                                       *ngIf="isFormPairValueType() || pairValueType == PairValueTypeValues.EDocs"
                                       matTooltip="{{isFormPairValueType()
                                            ? '&quot;Valor&quot; será preenchido com o valor respondido pelo usuário no campo escolhido do formulário'
                                            : '&quot;Valor&quot; será preenchido com o protocolo E-Docs do Encaminhamento que representa a instância de Fluxo iniciada pelo usuário'
                                        }}"
                                    ></i>
                                </div>
                                <div class="form-group mb-0">
                                    <label for="pairKey">Chave</label>
                                    <input type="text" class="form-control" id="pairKey" name="pairKey"
                                           [(ngModel)]="pairKey"
                                           placeholder="{{pairValueType == PairValueTypeValues.EDocs
                                                ? 'e.g. &quot;edocs&quot;'
                                                : pairValueType == PairValueTypeValues.String
                                                ? 'e.g. &quot;nome&quot;'
                                                : pairValueType == PairValueTypeValues.Number
                                                ? 'e.g. &quot;categoriaId&quot;'
                                                : pairValueType == PairValueTypeValues.Boolean
                                                ? 'e.g. &quot;flagAtivo&quot;'
                                                : pairValueType == PairValueTypeValues.Null
                                                ? 'e.g. &quot;conteudo&quot;'
                                                : 'e.g. &quot;' + getFormFieldKey() + '&quot;'
                                            }}"
                                    >
                                </div>
                                <div class="form-group mb-0">
                                    <label for="pairValue">Valor</label>
                                    <input type="text" class="form-control" id="pairValue" name="pairValue"
                                           [(ngModel)]="pairValue"
                                           [disabled]="shouldDisablePairValueInput()"
                                           [value]="pairValueType == PairValueTypeValues.Null ? 'null' : ''"
                                           placeholder="{{pairValueType == PairValueTypeValues.EDocs
                                                ? 'e.g. &quot;2021-A1B2C3&quot;'
                                                : pairValueType == PairValueTypeValues.String
                                                ? 'e.g. &quot;Fulano de Tal&quot;'
                                                : pairValueType == PairValueTypeValues.Number
                                                ? 'e.g. 123'
                                                : pairValueType == PairValueTypeValues.Boolean
                                                ? 'e.g. true'
                                                : pairValueType == PairValueTypeValues.Null
                                                ? 'e.g. null'
                                                : '[valor futuro do campo]'
                                            }}"
                                    >
                                </div>
                                <button type="button" class="btn btn-outline-primary ml-2" (click)="addKeyValuePair()">
                                    <i class="fas fa-plus-circle mr-2"></i>
                                    <span>Adicionar</span>
                                </button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="subsection-label mb-2">Pares de chave/valor adicionados</label>
                            <div class="key-value-pairs-container ml-4">
                                <div class="key-value-pair mr-2 mb-2 p-2" *ngFor="let pair of keyValuePairsArray">
                                    <pre class="m-0" [innerHtml]="prettyPrint(pair)"></pre>
                                    <i class="fas fa-trash-alt ml-2" matTooltip="Remover par de chave/valor" (click)="removeKeyValuePair(pair)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-template #showJsonViewTemplate>
                    <div class="json-view-container ml-4">
                        <div class="form-group">
                            <label class="subsection-label mb-2">Editar JSON completo</label>
                            <editor name="jsonToEdit" [init]="tinyMceOptions" [(ngModel)]="jsonToEdit"></editor>
                            <textarea id="tinyMce" class="d-none"></textarea>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>

        <!-- Botões -->
        <div class="form-footer text-right mb-1">
            <hr class="mt-0"/>
            <button type="button" class="btn btn-outline-danger mr-2" (click)="closeForm()">Cancelar</button>
            <button type="submit" class="btn btn-outline-primary"
                    [disabled]="!flowObjectMidwayOutboundApi.form.valid || inputIsReadOnlyMode">
                Salvar Configurações
            </button>
        </div>
    </form>
</ng-container>
