<ng-container *ngIf="model != null">
    <form class="flow-object-form" (ngSubmit)="onSubmit()" #flowObjectForm="ngForm">
        <!-- Form Builder -->
        <div class="form-builder-wrapper debounce-hidden" #wrapperRef>
            <form-builder [form]="formSchema" [options]="options" (change)="onChange($event)"></form-builder>
        </div>

        <!-- Botões -->
        <div class="form-footer form-group text-right mt-3 mb-1 mr-1">
            <hr />

            <!-- FormSchema -->
            <button type="button" class="btn btn-outline-secondary float-left ml-1"
                    (click)="showFormSchema()"
            >Editar código-fonte</button>

            <!-- Cancelar -->
            <button type="button" class="btn btn-outline-danger mr-2"
                    (click)="closeForm()"
            >Cancelar</button>

            <!-- Salvar -->
            <button type="submit" class="btn btn-outline-primary"
                    [disabled]="!flowObjectForm.form.valid || inputIsReadOnlyMode"
            >Salvar Definição de Formulário</button>
        </div>
    </form>

    <!-- Modal do FormSchema -->
    <ng-template #formSchemaRef>
        <textarea id="formSchemaEditor" name="formSchemaEditor" class="form-control" #formSchemaEditorRef
                  style="height: calc(100vh - 78px); resize: none; opacity: 0; transition: opacity .3s ease-in"
                  [ngModel]="formSchema"
        ></textarea>
    </ng-template>
</ng-container>
