import { GroupingColumn } from "./grouping-column.model";
import { Guid } from "guid-typescript";

export class Grouping {
    id?: string = Guid.create().toString();
    innerIndex?: number = 0;
    outerIndex?: number = 0;
    typeId?: GroupingType;
    ownerId?: string;
    isFirstGatewayPath?: boolean = false;
    isSecondGatewayPath?: boolean = false;
    groupingColumns?: GroupingColumn[] = [];

    public constructor(init?: Partial<Grouping>) {
        Object.assign(this, init);
    }
}

export enum GroupingType {
    Default = 1,
    Gateway = 2
}

export const GroupingTypeClassName = new Map<number, string>([
    [GroupingType.Default, 'grouping-default'],
    [GroupingType.Gateway, 'grouping-gateway']
]);
