import { Component, OnInit } from '@angular/core';
import { Enums } from '../../shared/enums';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-access-denied',
    templateUrl: './access-denied.component.html',
    styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {
    title: string = null;
    message: string = null;

    constructor(public router: Router, private authService: AuthService) {
        if (authService.hasProfiles([Enums.Profiles.Administrator])) {
            router.navigate(['/']);
        } else {
            this.title = Enums.Messages.AccessDenied;
            this.message = Enums.Messages.NoAccessToResource;
        }
    }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() { }

    // ======================
    // public methods
    // ======================

    // ======================
    // private methods
    // ======================
}
