<div class="nav-item dropdown p-0">
    <!-- Ícone do header -->
    <a class="nav-link" matTooltip="Ajuda" #headerButtonRef>
        <span class="fa-stack">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-question fa-stack-1x"></i>
        </span>
    </a>

    <!-- Menu -->
    <div class="dropdown-menu dropdown-menu-right" #dropdownMenuRef>
        <!-- Modo Ajuda -->
        <div class="entry-row p-2"
             matTooltip="Ativar/desativar &quot;Modo Ajuda&quot; do E-Flow Admin"
        >
            <a class="btn btn-default btn-flat has-input p-0">
                <div class="keep-left">
                    <i class="fas fa-lightbulb"></i>
                    <span>Modo Ajuda</span>
                </div>

                <div class="keep-right">
                    <mat-slide-toggle color="primary" [(ngModel)]="isHintModeOn" (click)="toggleMenu()"></mat-slide-toggle>
                </div>
            </a>
        </div>

        <hr />

        <!-- Documentação -->
        <div class="entry-row p-2"
             matTooltip="Acessar documentação do E-Flow"
             (click)="toDocs()"
        >
            <a class="btn btn-default btn-flat p-0">
                <i class="fas fa-info-circle"></i>
                <span>Documentação</span>
            </a>
        </div>
    </div>
</div>

<feature-hint [inputIsHintModeOn]="isHintModeOn"></feature-hint>
