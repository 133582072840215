import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Enums } from '../../../shared/enums';

@Component({
    selector: 'app-ribs',
    templateUrl: './ribs.component.html',
    styleUrls: ['./ribs.component.scss']
})
export class RibsComponent implements OnInit {
    env: string;
    environment: string;

    get isLandingPage(): boolean {
        return window?.location?.pathname == Enums.PagesPaths.LandingPage;
    }
    get shouldHideEnvironment(): boolean {
        return this.env?.toLowerCase() == 'prd' || this.isLandingPage;
    }

    constructor(private cookieService: CookieService) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        this.env = atob(this.cookieService.get('prodest-eflow-env')).toLowerCase();
        switch (this.env) {
            case 'loc':
                this.environment = Enums.Messages.EnvironmentLocalhost;
                break;

            case 'des':
                this.environment = Enums.Messages.EnvironmentDevelopment;
                break;

            case 'hom':
                this.environment = Enums.Messages.EnvironmentStaging;
                break;

            case 'tre':
                this.environment = Enums.Messages.EnvironmentLearning;
                break;
        }
    }

    // ======================
    // public methods
    // ======================

    // ======================
    // private methods
    // ======================
}
