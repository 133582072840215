<div class="wrapper p-2">
    <div class="info-header">
        <i class="fas fa-info-circle mr-1"></i>
        <span class="relevant-info-font mr-2">Extensões permitidas:</span>
        <span class="text-highlight relevant-info-font heavy">PDF</span>
    </div>

    <div class="file-container p-2 mt-1" *ngFor="let item of value?.fileName; let idx = index">
        <label class="m-0 py-1 px-2">
            <i class="fas fa-folder-open mr-1"></i>
            <span>Escolher arquivo</span>
            <input type="file" accept="application/pdf"
                   [disabled]="disabled"
                   (change)="changeFile($event)"
            >
        </label>
        <span class="ml-2 relevant-info-font file-name">{{fileNames[idx]}}</span>
        <i class="fas fa-times-circle ml-2" *ngIf="!disabled"
           matTooltip="Remover arquivo"
           (click)="removeFile(idx)"
        ></i>
    </div>

    <div class="file-container p-2 mt-1">
        <label class="m-0 py-1 px-2">
            <i class="fas fa-folder-open mr-1"></i>
            <span>Escolher arquivo</span>
            <input type="file" accept="application/pdf" #anchorFileRef
                   [disabled]="disabled"
                   (change)="changeFile($event)"
            >
        </label>
        <span class="ml-2 relevant-info-font font-italic">Nenhum arquivo selecionado</span>
    </div>
</div>
