<div class="wrapper p-2">
    <div class="info-header">
        <i class="fas fa-info-circle mr-1"></i>
        <span class="relevant-info-font mr-1">Extensões permitidas:</span>
        <span class="text-highlight relevant-info-font text-uppercase heavy ml-1" *ngFor="let extension of allowedExtensions">{{extension}}</span>
    </div>
    <div class="file-container p-2 mt-1">
        <label class="m-0 py-1 px-2">
            <i class="fas fa-folder-open mr-1"></i>
            <span>Escolher arquivo</span>
            <input type="file" *ngIf="drawInput"
                   [accept]="mimeType"
                   [disabled]="disabled"
                   (change)="changeFile($event)"
            >
        </label>
        <ng-container *ngIf="fileName != null; else noFileTemplate">
            <span class="ml-2 relevant-info-font file-name">{{fileName}}</span>
            <i class="fas fa-times-circle ml-2" *ngIf="!disabled"
               matTooltip="Remover arquivo"
               (click)="removeFile($event)"
            ></i>
        </ng-container>
        <ng-template #noFileTemplate>
            <span class="ml-2 relevant-info-font font-italic">Nenhum arquivo selecionado</span>
        </ng-template>
    </div>
</div>
