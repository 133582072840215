import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ViewChild
} from '@angular/core';
import {
    FlowObjectType,
    FlowObjectDefinition,
    FormSchema
} from '../../../../models/flow-object.model';
import {
    ConditionalRecipient,
    ConfigSchema,
    ConfigSchemaSectionAccessLevel,
    ConfigSchemaSectionEDocsLocation
} from '../../../../models/config-schema.model';
import { PapeisFilter } from '../flow-object-details.pipe';
import { AgentePublicoPapel } from '../../../../models/acesso-cidadao.model';
import { AcessoCidadaoService } from '../../../../services/acesso-cidadao.service';
import { Enums } from '../../../../shared/enums';
import { ToastrService } from 'ngx-toastr';
import { FlowDefinition } from '../../../../models/flow.model';
import { Utils } from '../../../../shared/utils';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FLOW_OBJECT_DETAILS_DISPATCH_PROCESS_TINYMCE_OPTIONS } from '../flow-object-details-tinymce-options';
import { IBaseOption } from '../../../../models/base.model';
import { DomSanitizer } from '@angular/platform-browser';
import { AccessLevelComponent } from '../edocs-fields/access-level/access-level.component';
import { EDocsLocationComponent } from '../edocs-fields/edocs-location/edocs-location.component';
import { FlowObjectDefinitionService } from '../../../../services/flow-object-definition.service';

@Component({
    selector: 'flow-object-details-dispatch-process',
    templateUrl: './flow-object-details-dispatch-process.component.html',
    styleUrls: ['./flow-object-details-dispatch-process.component.scss']
})
export class FlowObjectDetailsDispatchProcessComponent implements OnInit {
    // #region [ViewChild]
    @ViewChild('edocsLocationRef') edocsLocationRef: EDocsLocationComponent;
    @ViewChild('edocsLocationConditionalRef') edocsLocationConditionalRef: EDocsLocationComponent;
    @ViewChild('accessLevelRef') accessLevelRef: AccessLevelComponent;
    // #endregion

    // #region [Type properties]
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    RecipientMode: typeof Enums.RecipientMode = Enums.RecipientMode;
    Utils: typeof Utils = Utils;
    // #endregion

    // #region [properties]
    formFlowObjectSchemaFields: any[] = [];
    tinyMceOptionsProcess: any = FLOW_OBJECT_DETAILS_DISPATCH_PROCESS_TINYMCE_OPTIONS;
    recipientMode: Enums.RecipientMode = Enums.RecipientMode.Fixed;
    configSchemaSectionEDocsLocation: ConfigSchemaSectionEDocsLocation = new ConfigSchemaSectionEDocsLocation();
    configSchemaSectionEDocsLocationConditional: ConfigSchemaSectionEDocsLocation = new ConfigSchemaSectionEDocsLocation();
    configSchemaSectionAccessLevel: ConfigSchemaSectionAccessLevel = new ConfigSchemaSectionAccessLevel();
    // #endregion

    // #region [Destinatário Condicional]
    formFieldConditionalOptions: IBaseOption[] = [];
    formFieldConditional: string = null;
    valueConditional: string = null;
    isOperationEqualConditional: boolean = true;
    // #endregion
    // #region [Destinatário :: Servidor]
    servidorCpf: string = '';
    servidorNome: string = '';
    servidorPapelId: string = '';
    servidorPapelDisplay: string = '';
    servidorPapelNome: string = null;
    servidorPapeis: AgentePublicoPapel[] = [];
    selectableServidorPapeis: AgentePublicoPapel[] = [];
    // #endregion
    // #region [Resumo]
    dispatchSummary: string = '';
    isDispatchSummaryBootstrapFinished: boolean = false;
    // #endregion

    // #region [Input/Output]
    @Input() inputConfigSchema: ConfigSchema;
    @Output() inputConfigSchemaChange = new EventEmitter<ConfigSchema>();
    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Output() outputRecipientModeChange = new EventEmitter<Enums.RecipientMode>();
    // #endregion

    constructor(
        public sanitizer: DomSanitizer,
        private toastr: ToastrService,
        private acessoCidadaoService: AcessoCidadaoService,
        private flowObjectDefinitionService: FlowObjectDefinitionService,
        private papeisFilter: PapeisFilter
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        let formFlowObject = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.typeId == FlowObjectType.StartForm);
        if (formFlowObject != null) {
            if (Utils.isNullOrEmpty(formFlowObject.formSchema)) {
                const response = await this.flowObjectDefinitionService.getFormData(formFlowObject.id);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                formFlowObject.formSchema = response.data;
            }

            let formSchema = JSON.parse(formFlowObject.formSchema) as FormSchema;
            this.formFlowObjectSchemaFields = Utils.getFormFieldsWithUsableValue(formSchema.components);
        }

        for (let prop in this.configSchemaSectionAccessLevel) {
            this.configSchemaSectionAccessLevel[prop] = this.inputConfigSchema.taskDispatchProcess.accessLevel[prop] || this.configSchemaSectionAccessLevel[prop];
        }
        for (let prop in this.configSchemaSectionEDocsLocation) {
            this.configSchemaSectionEDocsLocation[prop] = this.inputConfigSchema.taskDispatchProcess.dispatchRecipient[prop] || this.configSchemaSectionEDocsLocation[prop];
        }

        this.initDispatchInfo();

        this.tinyMceOptionsProcess.addDynamicMenu = editor => {
            editor.ui.registry.addMenuButton('vForm', {
                text: 'Formulário',
                fetch: callback => callback(this.getTinyMceMenuFormEntries(formFlowObject, editor))
            });
        };

        // debounce para que a opção de menu "Formulário" possa carregar corretamente
        setTimeout(() => this.isDispatchSummaryBootstrapFinished = true, 100);
    }

    // ======================
    // public methods
    // ======================

    // #region [Destinatário]
    clearDestinatario() {
        this.configSchemaSectionEDocsLocation = new ConfigSchemaSectionEDocsLocation();

        Object.assign(this.inputConfigSchema.taskDispatchProcess.dispatchRecipient, this.configSchemaSectionEDocsLocation);
    }
    // #endregion

    //#region [Destinatário Condicional]
    removeCondition(condition: ConditionalRecipient) {
        if (this.inputIsReadOnlyMode) return;

        this.inputConfigSchema.taskDispatchProcess.conditionalRecipients = this.inputConfigSchema.taskDispatchProcess.conditionalRecipients.filter(x => x.timestamp != condition.timestamp);
    }

    moveCondition(condition: ConditionalRecipient, isDirectionUp: boolean = false) {
        if (this.inputIsReadOnlyMode) return;

        let offset = isDirectionUp ? -1 : 1;

        let fromKey = condition.timestamp;
        let fromCondition = this.inputConfigSchema.taskDispatchProcess.conditionalRecipients.find(x => x.timestamp == fromKey);
        let toCondition = this.inputConfigSchema.taskDispatchProcess.conditionalRecipients[this.inputConfigSchema.taskDispatchProcess.conditionalRecipients.indexOf(fromCondition) + offset];
        let toKey = toCondition.timestamp;

        fromCondition.timestamp = toKey;
        toCondition.timestamp = fromKey;

        this.inputConfigSchema.taskDispatchProcess.conditionalRecipients = this.inputConfigSchema.taskDispatchProcess.conditionalRecipients.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
    }

    removeAllConditions() {
        if (this.inputIsReadOnlyMode) return;

        this.inputConfigSchema.taskDispatchProcess.conditionalRecipients = [];
    }

    prettyPrint(condition: ConditionalRecipient): string {
        return '<span>Se o valor de {0} for {1} {2}, despachar para {3}</span>'
            .replace('{0}', `<b style="color:red">${this.formFieldConditionalOptions.find(x => x.value == condition.formFieldId).description}</b>`)
            .replace('{1}', `<b style="color:purple">${condition.isOperationEqual ? 'igual a' : 'diferente de'}</b>`)
            .replace('{2}', `<b style="color:blue">"${condition.value}"</b>`)
            .replace('{3}', `<b style="color:green">${condition.recipient.name}</b>`);
    }
    // #endregion

    // #region [Destinatário :: Servidor]
    async searchServidorCpf() {
        if (!Utils.isNullOrEmpty(this.servidorCpf)) {
            let response = await this.acessoCidadaoService.getAgentePublicoSub(this.servidorCpf);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            response = await this.acessoCidadaoService.getAgentePublico(response.data.sub);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.servidorNome = response.data.nome;

            let responsePapeis = await this.acessoCidadaoService.getAgentePublicoPapeis(response.data.sub);

            if (!responsePapeis.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.servidorPapeis = responsePapeis.data;
            this.servidorPapeis.sort((a, b) => {
                let first = a.nome || '';
                let second = b.nome || '';
                return first.localeCompare(second);
            });
            this.selectableServidorPapeis = this.servidorPapeis;
        }
    }

    clearServidorCpf() {
        this.servidorCpf = '';
        this.clearServidorPapel();
    }

    servidorPapelDisplayChange() {
        this.selectableServidorPapeis = this.papeisFilter.transform(this.servidorPapeis, this.servidorPapelDisplay);
    }

    servidorPapelIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.servidorPapelId = event.option.value.guid;
        }

        if (this.servidorPapelId == '') {
            this.clearServidorPapel();
        } else {
            let servidor = this.servidorPapeis.find(x => x.guid == this.servidorPapelId);
            let papel = this.servidorPapeis.find(x => x.guid == this.servidorPapelId);
            let suffix = `${papel.lotacao.nomeCurto} - ${papel.lotacao.organizacao.sigla} - ${papel.lotacao.organizacao.organizacaoPai.sigla}`;
            this.servidorPapelNome = `${servidor.nome.toUpperCase()} - ${suffix}`;
        }
    }

    displayServidorPapelId(option): string {
        return !Utils.isNullOrEmpty(option)
            ? `${option.nome} - ${option.lotacao.nomeCurto} - ${option.lotacao.organizacao.sigla} - ${option.lotacao.organizacao.organizacaoPai.sigla}`.toUpperCase()
            : '';
    }

    clearServidorPapel() {
        this.servidorPapelId = '';
        this.servidorPapelDisplay = '';
        this.servidorPapelNome = null;
        this.selectableServidorPapeis = this.servidorPapeis;
    }

    selectServidorPapel() {
        this.configSchemaSectionEDocsLocation.id = this.servidorPapelId;
        this.configSchemaSectionEDocsLocation.name = this.servidorPapelNome;

        Object.assign(this.inputConfigSchema.taskDispatchProcess.dispatchRecipient, this.configSchemaSectionEDocsLocation);
    }
    // #endregion

    resolveDispatchInfo(): boolean {
        if (this.dispatchSummary?.length < 5) {
            this.toastr.error(Enums.Messages.MandatoryDispatchSummary, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return false;
        }

        let div = document.createElement('div');
        div.innerHTML = this.dispatchSummary?.replaceAll('<br />', '\n');
        this.inputConfigSchema.taskDispatchProcess.dispatchSummary = div.textContent || div.innerText || '';

        return true;
    }

    recipientModeChange() {
        if (this.recipientMode == Enums.RecipientMode.PublicAgent) {
            this.inputConfigSchema.taskDispatchProcess.toPublicAgent = true;
        } else {
            this.inputConfigSchema.taskDispatchProcess.toPublicAgent = false;
            this.clearServidorCpf();
        }

        if (this.recipientMode != Enums.RecipientMode.Fixed) {
            this.configSchemaSectionEDocsLocation = new ConfigSchemaSectionEDocsLocation();

            Object.assign(this.inputConfigSchema.taskDispatchProcess.dispatchRecipient, this.configSchemaSectionEDocsLocation);

            this.edocsLocationRef?.clearPatriarca();
        }

        if (this.recipientMode != Enums.RecipientMode.Conditional) {
            this.formFieldConditional = null;
            this.valueConditional = null;

            this.inputConfigSchema.taskDispatchProcess.conditionalRecipients = [];

            this.edocsLocationConditionalRef?.clearPatriarca();
        }

        this.outputRecipientModeChange.emit(this.recipientMode);
    }

    updateSourceCode(forcedConfigSchema: ConfigSchema) {
        this.inputConfigSchema = forcedConfigSchema;

        Object.assign(this.configSchemaSectionEDocsLocation, this.inputConfigSchema.taskDispatchProcess.dispatchRecipient);
        Object.assign(this.configSchemaSectionAccessLevel, this.inputConfigSchema.taskDispatchProcess.accessLevel);

        this.initDispatchInfo();
        this.accessLevelRef?.initLegalReasoningsInfo();
    }

    onUpdateEDocsLocation(event: ConfigSchemaSectionEDocsLocation) {
        Object.assign(this.configSchemaSectionEDocsLocation, event);
        Object.assign(this.inputConfigSchema.taskDispatchProcess.dispatchRecipient, this.configSchemaSectionEDocsLocation);
    }

    onUpdateEDocsLocationConditional(event: ConfigSchemaSectionEDocsLocation) {
        if (Utils.isNullOrEmpty(this.formFieldConditional) || Utils.isNullOrEmpty(this.valueConditional)) {
            this.toastr.warning(Enums.Messages.MandatoryConditionalFields, Enums.Messages.Warning, Utils.getToastrErrorOptions());
            return;
        }

        Object.assign(this.configSchemaSectionEDocsLocationConditional, event);

        this.inputConfigSchema.taskDispatchProcess.conditionalRecipients.push(new ConditionalRecipient({
            formFieldId: this.formFieldConditional,
            value: this.valueConditional,
            isOperationEqual: this.isOperationEqualConditional.toString().toLowerCase() == 'true',
            recipient: Utils.clone(this.configSchemaSectionEDocsLocationConditional)
        }));

        if (this.inputConfigSchema.taskDispatchProcess.conditionalRecipients.length == 1) {
            Utils.scrollToBottom('.scroll-wrapper');
        }
    }

    onUpdateAccessLevel(event: ConfigSchemaSectionAccessLevel) {
        Object.assign(this.configSchemaSectionAccessLevel, event);
        Object.assign(this.inputConfigSchema.taskDispatchProcess.accessLevel, this.configSchemaSectionAccessLevel);
    }

    // ======================
    // private methods
    // ======================

    private initDispatchInfo() {
        this.dispatchSummary = this.inputConfigSchema.taskDispatchProcess.dispatchSummary?.replaceAll('\n', '<br />');

        this.inputConfigSchema.taskDispatchProcess.conditionalRecipients = this.inputConfigSchema.taskDispatchProcess.conditionalRecipients || [];

        this.formFieldConditionalOptions = this.formFlowObjectSchemaFields.map(x => ({ value: x.key, description: x.label }));

        this.recipientMode = this.inputConfigSchema?.taskDispatchProcess.toPublicAgent
            ? Enums.RecipientMode.PublicAgent
            : this.inputConfigSchema?.taskDispatchProcess.conditionalRecipients.length > 0
                ? Enums.RecipientMode.Conditional
                : Enums.RecipientMode.Fixed;

        this.recipientModeChange();
    }

    private getTinyMceMenuFormEntries(flowObject: FlowObjectDefinition, editor): any[] {
        if (Utils.isNullOrEmpty(flowObject.formSchema)) return;

        let entries = [];
        let formSchema = JSON.parse(flowObject.formSchema) as FormSchema;

        formSchema.components.forEach(item => {
            if (!['button'].includes(item.type)) {
                entries.push({
                    type: 'menuitem',
                    text: item.label,
                    onAction: () => editor.insertContent(`{|${item.key}|}`)
                });
            }
        });

        return entries;
    }
}
