import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    CustomDateTimeCriteriaType,
    DashboardAdmin,
    DashboardAdminDescription,
    DashboardAdminLabel,
    DashboardSortState,
    FlowInfoFilterGroup,
    InputDataTaskForm,
    SearchCriteriaType,
    SearchToken
} from '../../models/dashboard.model';
import { FlowDefinition } from '../../models/flow.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../shared/enums';
import { AuthService } from '../../services/auth.service';
import { Utils } from '../../shared/utils';
import { Unidade } from '../../models/organograma.model';
import { DashboardService } from '../../services/dashboard.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import * as moment from 'moment';
import * as JSZip from 'jszip';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    @ViewChild('flowInstanceResultsAreaRef') flowInstanceResultsAreaRef: ElementRef;

    SearchCriteriaType: typeof SearchCriteriaType = SearchCriteriaType;
    CustomDateTimeCriteriaType: typeof CustomDateTimeCriteriaType = CustomDateTimeCriteriaType;
    DashboardAdminLabel: typeof DashboardAdminLabel = DashboardAdminLabel;
    DashboardAdminDescription: typeof DashboardAdminDescription = DashboardAdminDescription;

    filteredResults: DashboardAdmin = null;
    filteredFlowDefinitions: FlowDefinition[] = [];
    filteredVersions: number[] = [];
    searchTokens: SearchToken[] = [];
    sortState: DashboardSortState = new DashboardSortState();
    selectedFlowInbox: string = null;
    selectedFlowDefinition: string = null;
    selectedVersion: number = null;
    selectedFromDateTime: any = null;
    selectedUntilDateTime: any = null;
    customFilterSearch: string = null;
    percentagePerWeekdayResults: any[] = [];
    percentagePerDayHourResults: any[] = [];
    isShowingFlowInfoSection: boolean = true;
    isShowingFinishDateSection: boolean = true;
    flowInfoTypes: SearchCriteriaType[] = [
        SearchCriteriaType.FlowDefinition,
        SearchCriteriaType.FlowInbox,
        SearchCriteriaType.Version
    ];
    finishDateTypes: SearchCriteriaType[] = [
        SearchCriteriaType.FinishDateFrom,
        SearchCriteriaType.FinishDateUntil
    ];

    constructor(
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private dashboardService: DashboardService,
        private authService: AuthService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() { }

    // ======================
    // public methods
    // ======================

    filterByPipe() {
        //if (this.customFilterSearch == null) return;

        //if (this.customFilterSearch.length < 3) {
        //    this.toastr.warning(Enums.Messages.SearchMinLength, Enums.Messages.Warning, Utils.getToastrErrorOptions(true));
        //    return;
        //}
    }

    async addFlowInfoFilterCriteria() {
        if (this.selectedFlowInbox == null) return;

        let now = new Date();

        let flowInbox = this.authService.user?.unidadesPerfilAdministrador.find(x => x.guid == this.selectedFlowInbox);
        this.searchTokens.push(new SearchToken({
            searchCriteriaType: SearchCriteriaType.FlowInbox,
            addedTimestamp: now,
            key: this.selectedFlowInbox,
            text: `Caixa: ${flowInbox.organizacao.sigla} - ${flowInbox.nomeCurto}`
        }));

        if (this.selectedFlowDefinition != null) {
            this.searchTokens.push(new SearchToken({
                searchCriteriaType: SearchCriteriaType.FlowDefinition,
                addedTimestamp: now,
                key: this.selectedFlowDefinition,
                text: `Fluxo: ${this.filteredFlowDefinitions.find(x => x.id == this.selectedFlowDefinition).name}`
            }));
        }

        if (this.selectedVersion != null) {
            this.searchTokens.push(new SearchToken({
                searchCriteriaType: SearchCriteriaType.Version,
                addedTimestamp: now,
                key: this.selectedVersion.toString(),
                text: `Versão: ${this.selectedVersion}`
            }));
        }

        this.searchTokens = [...new Set(this.searchTokens)];
        this.clearFlowInfoFilterCriteria();
    }

    clearFlowInfoFilterCriteria() {
        this.selectedFlowDefinition = null;
        this.selectedFlowInbox = null;
        this.selectedVersion = null;
    }

    async addPredefinedFinishDateFilterCriteria() {
        if (this.selectedFromDateTime == null && this.selectedUntilDateTime == null) return;

        this.searchTokens = this.searchTokens.filter(x =>
            ![
                SearchCriteriaType.FinishDateFrom,
                SearchCriteriaType.FinishDateUntil
            ].includes(x.searchCriteriaType)
        );

        if (this.selectedFromDateTime != null) {
            this.searchTokens.push(new SearchToken({
                searchCriteriaType: SearchCriteriaType.FinishDateFrom,
                key: this.selectedFromDateTime.toISOString(),
                text: `De: ${this.selectedFromDateTime.format('DD/MM/YYYY, HH:mm:ss')}`
            }));
        }

        if (this.selectedUntilDateTime != null) {
            this.searchTokens.push(new SearchToken({
                searchCriteriaType: SearchCriteriaType.FinishDateUntil,
                key: this.selectedUntilDateTime.toISOString(),
                text: `Até: ${this.selectedUntilDateTime.format('DD/MM/YYYY, HH:mm:ss')}`
            }));
        }

        this.searchTokens = [...new Set(this.searchTokens)];
        this.clearFinishDateFilterCriteria();
    }

    async addCustomFinishDateFilterCriteria(type: CustomDateTimeCriteriaType) {
        this.searchTokens = this.searchTokens.filter(x =>
            ![
                SearchCriteriaType.FinishDateFrom,
                SearchCriteriaType.FinishDateUntil
            ].includes(x.searchCriteriaType)
        );

        let now = moment();
        let from = moment(now);
        let until = moment(now);

        switch (type) {
            case CustomDateTimeCriteriaType.Today:
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateFrom,
                    key: from.add(from.utcOffset(), 'm').utc().set({ h: 0, m: 0, s: 0, ms: 0 }).toISOString(),
                    text: `De: ${from.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateUntil,
                    key: until.add(until.utcOffset(), 'm').utc().toISOString(),
                    text: `Até: ${until.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                break;

            case CustomDateTimeCriteriaType.Last24:
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateFrom,
                    key: from.add(from.utcOffset(), 'm').add(-24, 'h').utc().toISOString(),
                    text: `De: ${from.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateUntil,
                    key: until.add(until.utcOffset(), 'm').utc().toISOString(),
                    text: `Até: ${until.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                break;

            case CustomDateTimeCriteriaType.Last48:
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateFrom,
                    key: from.add(from.utcOffset(), 'm').add(-48, 'h').utc().toISOString(),
                    text: `De: ${from.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateUntil,
                    key: until.add(until.utcOffset(), 'm').utc().toISOString(),
                    text: `Até: ${until.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                break;

            case CustomDateTimeCriteriaType.Last72:
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateFrom,
                    key: from.add(from.utcOffset(), 'm').add(-72, 'h').utc().toISOString(),
                    text: `De: ${from.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateUntil,
                    key: until.add(until.utcOffset(), 'm').utc().toISOString(),
                    text: `Até: ${until.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                break;

            case CustomDateTimeCriteriaType.LastWeek:
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateFrom,
                    key: from.add(from.utcOffset(), 'm').add(-1, 'w').utc().toISOString(),
                    text: `De: ${from.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateUntil,
                    key: until.add(until.utcOffset(), 'm').utc().toISOString(),
                    text: `Até: ${until.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                break;

            case CustomDateTimeCriteriaType.LastFortnight:
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateFrom,
                    key: from.add(from.utcOffset(), 'm').add(-15, 'd').utc().toISOString(),
                    text: `De: ${from.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateUntil,
                    key: until.add(until.utcOffset(), 'm').utc().toISOString(),
                    text: `Até: ${until.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                break;

            case CustomDateTimeCriteriaType.LastMonth:
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateFrom,
                    key: from.add(from.utcOffset(), 'm').add(-1, 'M').utc().toISOString(),
                    text: `De: ${from.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateUntil,
                    key: until.add(until.utcOffset(), 'm').utc().toISOString(),
                    text: `Até: ${until.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                break;

            case CustomDateTimeCriteriaType.LastYear:
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateFrom,
                    key: from.add(from.utcOffset(), 'm').add(-1, 'y').utc().toISOString(),
                    text: `De: ${from.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                this.searchTokens.push(new SearchToken({
                    searchCriteriaType: SearchCriteriaType.FinishDateUntil,
                    key: until.add(until.utcOffset(), 'm').utc().toISOString(),
                    text: `Até: ${until.format('DD/MM/YYYY, HH:mm:ss')}`
                }));
                break;

            default:
                break;
        }

        this.searchTokens = [...new Set(this.searchTokens)];
    }

    clearFinishDateFilterCriteria() {
        this.selectedFromDateTime = null;
        this.selectedUntilDateTime = null;
    }

    async removeFilter(searchToken: SearchToken) {
        if (this.finishDateTypes.includes(searchToken.searchCriteriaType)) {
            this.searchTokens = this.searchTokens.filter(x => !this.finishDateTypes.includes(x.searchCriteriaType));
        } else {
            this.searchTokens = this.searchTokens.filter(x => x != searchToken);
        }
    }

    getSearchTokensFlowInfo(timestamp?): SearchToken[] {
        return this.searchTokens.filter(x => this.flowInfoTypes.includes(x.searchCriteriaType)
            && (timestamp != null
                ? x.addedTimestamp == timestamp
                : true
            )
        );
    }

    getSearchTokensFinishDate(): SearchToken[] {
        return this.searchTokens.filter(x => this.finishDateTypes.includes(x.searchCriteriaType));
    }

    getFlowInfoTimestamps(): any[] {
        return [...new Set(this.getSearchTokensFlowInfo().map(x => x.addedTimestamp))];
    }

    getOrderedFlowInboxesList(): Unidade[] {
        return this.authService.user?.unidadesPerfilAdministrador.sort((a, b) => {
            let first = `${a.organizacao.sigla} - ${a.nomeCurto}`;
            let second = `${b.organizacao.sigla} - ${b.nomeCurto}`;
            return first.localeCompare(second);
        });
    }

    getOrderedFlowDefinitionsList(): FlowDefinition[] {
        return this.filteredFlowDefinitions.sort((a, b) => a.name.localeCompare(b.name));
    }

    async getFlowDefinitions() {
        this.selectedFlowDefinition = null;
        this.selectedVersion = null;

        const response = await this.dashboardService.getFlowDefinitionsByFlowInboxId(this.selectedFlowInbox);

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.filteredFlowDefinitions = response.data;
    }

    getVersions() {
        this.selectedVersion = null;

        let flowDefinition = this.filteredFlowDefinitions.find(x => x.id == this.selectedFlowDefinition);
        this.filteredVersions = [...Array(flowDefinition.version + 1).keys()].slice(1).reverse();
    }

    shouldDisabledSearch(): boolean {
        return !this.searchTokens.some(x => x.searchCriteriaType == SearchCriteriaType.FlowInbox);
    }

    toggleFlowInfoSection() {
        this.isShowingFlowInfoSection = !this.isShowingFlowInfoSection;
    }

    toggleFinishDateSection() {
        this.isShowingFinishDateSection = !this.isShowingFinishDateSection;
    }

    async getFilteredResults() {
        if (!this.isFilterValid()) return;

        this.spinner.show();

        this.isShowingFlowInfoSection = false;
        this.isShowingFinishDateSection = false;
        let cnt = 0;
        let interval = setInterval(() => {
            cnt++;
            if (this.flowInstanceResultsAreaRef != null || cnt == 30) {
                if (this.flowInstanceResultsAreaRef != null) {
                    Utils.scrollTo(this.flowInstanceResultsAreaRef.nativeElement.id);
                }

                clearInterval(interval);
            }
        }, 100);

        const response = await this.dashboardService.getFilteredResults({
            flowInfoGroups: this.getFlowInfoGroups(),
            fromDateTime: this.getFromDateTime(),
            untilDateTime: this.getUntilDateTime()
        });

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.loadFilteredResults(response.data);

        setTimeout(() => this.spinner.hide(), 500);
    }

    async exportCsv() {
        if (!this.isFilterValid()) return;

        this.spinner.show();

        try {
            const response = await this.dashboardService.exportCsv({
                flowInfoGroups: this.getFlowInfoGroups(),
                fromDateTime: this.getFromDateTime(),
                untilDateTime: this.getUntilDateTime()
            });

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                this.spinner.hide();
                return;
            }

            const zip = new JSZip();
            let groups = [...new Set(response.data.map(x => `${x.flowDefinitionId}||${x.version}`))];

            groups.forEach(y => {
                let headers = [];
                const splitted = y.split('||');
                let flattenedData = Utils.cloneArray(response.data.filter(x => x.flowDefinitionId == splitted[0] && x.version == +splitted[1]));
                for (let i = 0; i < flattenedData.length; i++) {
                    flattenedData[i].inputDataParsed = JSON.parse(flattenedData[i].inputData) as InputDataTaskForm;
                    flattenedData[i] = Object.assign(flattenedData[i], flattenedData[i].inputDataParsed.data);

                    delete flattenedData[i].flowDefinitionId;
                    delete flattenedData[i].inputData;
                    delete flattenedData[i].inputDataParsed;
                    delete flattenedData[i].submit;
                    delete flattenedData[i].enviar;

                    const values = Object.values(flattenedData[i]);
                    values.forEach((x: any, j: number) => {
                        if (Utils.isObject(x) && x.fileName != null) {
                            // caso o campo seja do tipo "Arquivo(s) PDF"
                            flattenedData[i][Object.keys(flattenedData[i])[j]] = x.fileName;
                        } else if (Array.isArray(x)) {
                            if (x.length == 0) {
                                flattenedData[i][Object.keys(flattenedData[i])[j]] = '';
                            } else {
                                if (Utils.isObject(x[0])) {
                                    // caso o campo seja de um tipo derivado de tabelas
                                    flattenedData[i][Object.keys(flattenedData[i])[j]] = JSON.stringify(x);
                                } else {
                                    // caso o campo seja do tipo "Lista de opção (múltipla)"
                                    flattenedData[i][Object.keys(flattenedData[i])[j]] = x.join(', ');
                                }
                            }
                        } else if (Utils.isString(x)) {
                            // caso o campo seja de um tipo derivado de numérico
                            flattenedData[i][Object.keys(flattenedData[i])[j]] = x.replace('R$ NaN', '').replace('NaN', '');
                        } else if (Utils.isObject(x)) {
                            // failsafe
                            if (Object.keys(x).length == 0) {
                                flattenedData[i][Object.keys(flattenedData[i])[j]] = '';
                            } else {
                                flattenedData[i][Object.keys(flattenedData[i])[j]] = JSON.stringify(x);
                            }
                        }
                    });

                    headers = headers.concat(Object.keys(flattenedData[i]));
                }

                const csvOptions = {
                    fieldSeparator: ';',
                    showLabels: true,
                    useHeader: true,
                    headers: [...new Set(headers)],
                    nullToEmptyString: true,
                    noDownload: true
                };
                const splittedTime = (new Date()).toLocaleString().split(', ');
                const timeString = splittedTime[0].split('/').reverse().join('') + '_' + splittedTime[1].replaceAll(':', '');
                const filename = `${flattenedData[0].name} v${flattenedData[0].version} (${timeString})`.replaceAll('/', '-').replaceAll('\\', '-');
                const csv = new AngularCsv(flattenedData, filename, csvOptions);
                zip.file(`${filename}.csv`, csv.getCsvData());
            });

            zip.generateAsync({
                type: 'blob',
                compression: 'DEFLATE',
                compressionOptions: { level: 9 }
            }).then(content => {
                let a = document.createElement('a');
                a.href = window.URL.createObjectURL(content);
                const splittedTime = (new Date()).toLocaleString().split(', ');
                const timeString = splittedTime[0].split('/').reverse().join('') + '_' + splittedTime[1].replaceAll(':', '');
                a.download = `E-Flow - Fluxos Instanciados (${timeString}).zip`;
                a.click();
            }).catch(() => {
                this.toastr.error(Enums.Messages.CsvExportError, Enums.Messages.Error, Utils.getToastrErrorOptions());
            }).finally(() => {
                this.spinner.hide();
            });
        } catch (error) {
            this.toastr.error(Enums.Messages.CsvExportError, Enums.Messages.Error, Utils.getToastrErrorOptions());
            this.spinner.hide();
            console.error(error);
        }
    }

    // ======================
    // private methods
    // ======================

    private async loadFilteredResults(responseData: DashboardAdmin) {
        this.filteredResults = responseData;

        this.filteredResults.averageFinishedFlowInstancesDay = Utils.round(this.filteredResults.averageFinishedFlowInstancesDay, 1);

        let value = this.filteredResults.averageFlowInstanceFinishingTimeSeconds || 0;
        if (value < 2 * 60) { /* 2min */
            this.filteredResults.averageFlowInstanceFinishingTimeSecondsProcessed = Utils.round(value, 1).toFixed(1) + 's';
        } else if (value < 2 * 60 * 60) { /* 2h */
            this.filteredResults.averageFlowInstanceFinishingTimeSecondsProcessed = Utils.round(value / 60, 1).toFixed(1) + 'min';
        } else if (value < 2 * 24 * 60 * 60) { /* 2d */
            this.filteredResults.averageFlowInstanceFinishingTimeSecondsProcessed = Utils.round(value / 60 / 60, 1).toFixed(1) + 'h';
        } else {
            this.filteredResults.averageFlowInstanceFinishingTimeSecondsProcessed = Utils.round(value / 60 / 60 / 24, 1).toFixed(1) + 'd';
        }

        this.loadPercentageResults();
    }

    private async loadPercentageResults() {
        this.percentagePerWeekdayResults = [];
        let weekdayProps = Object.keys(this.filteredResults).filter(x => x.toLowerCase().includes('weekday'));
        weekdayProps.forEach(x => {
            this.percentagePerWeekdayResults.push({
                name: DashboardAdminLabel.get(x),
                value: this.filteredResults[x]
            });
        });

        this.percentagePerDayHourResults = [];
        let dayHourProps = Object.keys(this.filteredResults).filter(x => x.toLowerCase().includes('hour'));
        dayHourProps.forEach(x => {
            this.percentagePerDayHourResults.push({
                name: DashboardAdminLabel.get(x),
                value: this.filteredResults[x]
            });
        });
    }

    private isFilterValid(): boolean {
        if (!this.searchTokens.some(x => x.searchCriteriaType == SearchCriteriaType.FlowInbox)) {
            this.toastr.warning(Enums.Messages.NoFlowInboxId, Enums.Messages.Warning, Utils.getToastrErrorOptions());
            return false;
        }

        return true;
    }

    private getFlowInfoGroups(): FlowInfoFilterGroup[] {
        let timestamps = this.getFlowInfoTimestamps();
        let flowInfoGroups = [] as FlowInfoFilterGroup[];
        timestamps.forEach(x => {
            let group = this.searchTokens.filter(y => y.addedTimestamp == x);
            flowInfoGroups.push(new FlowInfoFilterGroup({
                flowInboxId: group.find(y => y.searchCriteriaType == SearchCriteriaType.FlowInbox)?.key,
                flowDefinitionId: group.find(y => y.searchCriteriaType == SearchCriteriaType.FlowDefinition)?.key,
                version: group.find(y => y.searchCriteriaType == SearchCriteriaType.Version)?.key
            }));
        });

        return flowInfoGroups;
    }

    private getFromDateTime(): string {
        return this.searchTokens.find(x => x.searchCriteriaType == SearchCriteriaType.FinishDateFrom)?.key
            || moment({ y: 2000, M: 6, d: 1 }).utc().toISOString();
    }

    private getUntilDateTime(): string {
        return this.searchTokens.find(x => x.searchCriteriaType == SearchCriteriaType.FinishDateUntil)?.key
            || moment({ y: 3000, M: 6, d: 1 }).utc().toISOString();
    }
}
