import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import { Organizacao, Unidade } from '../models/organograma.model';

@Injectable({
    providedIn: 'root'
})
export class OrganogramaService extends BaseService {
    controllerRoute: string = 'Organograma';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async getOrganizacoesFilhas(id: string): Promise<IBaseModel<Organizacao[]>> {
        return this.httpClient.get<IBaseModel<Organizacao[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/OrganizacoesFilhas/${id}`).toPromise();
    }

    public async getOrganizacao(id: string): Promise<IBaseModel<Organizacao>> {
        return this.httpClient.get<IBaseModel<Organizacao>>(`${this.apiBaseUrl}/${this.controllerRoute}/Organizacao/${id}`).toPromise();
    }

    public async getUnidadesOrganizacao(id: string): Promise<IBaseModel<Unidade[]>> {
        return this.httpClient.get<IBaseModel<Unidade[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/UnidadesOrganizacao/${id}`).toPromise();
    }
}
