import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { IBaseModel } from '../models/base.model';
import { IUserStatusModel, IUserProfileModel } from '../models/user.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class AuthService extends BaseService {
    controllerRoute: string = 'User';

    public user: IUserProfileModel;

    constructor(
        httpClient: HttpClient,
        private spinner: NgxSpinnerService
    ) {
        super(httpClient);
    }

    public async getUserProfile(showLoading: boolean = false): Promise<IBaseModel<IUserProfileModel>> {
        if (this.user) {
            return Promise.resolve({ isSuccess: true, data: this.user } as IBaseModel<IUserProfileModel>);
        } else {
            try {
                if (showLoading) {
                    this.spinner.show('root');
                }

                const response = await this.httpClient.get<IBaseModel<IUserProfileModel>>(`${this.apiBaseUrl}/${this.controllerRoute}/Profile`).toPromise();
                this.user = response.data;
                return response;
            } catch (error) {
                delete (this.user);
                throw (error);
            } finally {
                this.spinner.hide('root');
            }
        }
    }

    public async getStatus(): Promise<IBaseModel<IUserStatusModel>> {
        return await this.httpClient.get<IBaseModel<IUserStatusModel>>(`${this.apiBaseUrl}/${this.controllerRoute}/Status`).toPromise();
    }

    public logout() {
        window.location.replace('/Account/Logout');
    }

    public login() {
        window.location.replace('/Account/Login?returnUrl=' + window.location);
    }

    public hasProfiles(profiles: string[]) {
        return this.user?.perfis
            && profiles.some(p => this.user.perfis.map(x => x.trim().toLowerCase()).includes(p.trim().toLowerCase()));
    }

    public isAdmin() {
        return this.user?.unidadesPerfilAdministrador?.length > 0;
    }
}
