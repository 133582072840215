<div *ngIf="model != null && canLoad" class="working-area container px-2" (resized)="onResize($event)" (wheel)="onResize($event)" #pageContainerRef>
    <ngx-spinner name="flow" bdColor="#000000aa" size="medium" color="#64b2cd" type="line-scale-pulse-out-rapid" [fullScreen]="true">
        <h6>Processando...</h6>
    </ngx-spinner>

    <div class="container-flex d-flex my-3">
        <div class="backdrop" #backdropRef></div>

        <!-- Telas de Detalhes -->
        <div class="details-area card p-3" [class.extra-size]="isDetailsAreaExtraSize" #detailsAreaRef>
            <ngx-spinner name="detailsAreaSpinner" bdColor="rgba(255, 255, 255, 1)" size="medium" color="#64b2cd" type="line-scale-pulse-out-rapid" [fullScreen]="false"></ngx-spinner>

            <i class="button close fa fa-times" matTooltip="Fechar" (click)="closeModalAreas(isAreaVisible.flowDefinitionDetails)"></i>

            <mat-tab-group disablePagination="true" #matTabGroupRef
                           [(selectedIndex)]="activeTabIndex"
                           (selectedTabChange)="tabChanged($event)"
            >
                <!-- Detalhes do Fluxo -->
                <mat-tab [label]="DetailsAreaTabsLabel.get(DetailsAreaTabs.FlowDefinitionDetails)" *ngIf="isAreaVisible.flowDefinitionDetails">
                    <flow-definition-details #flowDefinitionDetailsRef
                                             [inputModel]="flowDefinitionToEdit"
                                             [inputIsReadOnlyMode]="isReadOnlyMode"
                                             [inputFlowTargetOptions]="flowTargetOptions"
                                             [inputShouldLoadTinyMce]="shouldLoadFlowDefinitionDetailsTinyMce()"
                                             (outputUpdateFlowDefinitionEvent)="updateFlowDefinition($event)"
                                             (outputCloseEvent)="closeModalAreas(isAreaVisible.flowDefinitionDetails)"
                    ></flow-definition-details>
                </mat-tab>

                <!-- Configurações Imediatas -->
                <mat-tab [label]="DetailsAreaTabsLabel.get(DetailsAreaTabs.FlowDefinitionDetailsHotConfigs)" *ngIf="isAreaVisible.flowDefinitionDetailsHotConfigs && isLatestVersion">
                    <flow-definition-details-hot-configs #flowDefinitionDetailsHotConfigsRef
                                                         [inputModel]="flowDefinitionToEdit"
                                                         [inputIsReadOnlyMode]="isReadOnlyMode"
                                                         (outputUpdateFlowDefinitionEvent)="updateFlowDefinition($event, true)"
                                                         (outputCloseEvent)="closeModalAreas(isAreaVisible.flowDefinitionDetailsHotConfigs)"
                    ></flow-definition-details-hot-configs>
                </mat-tab>

                <!-- Detalhes -->
                <mat-tab [label]="DetailsAreaTabsLabel.get(DetailsAreaTabs.FlowObjectDetails)" *ngIf="!isAreaVisible.flowDefinitionDetails && !isAreaVisible.flowDefinitionDetailsHotConfigs">
                    <ng-container *ngIf="isAreaVisible.flowObjectDetails">
                        <flow-object-details #flowObjectDetailsRef
                                             [(inputModel)]="flowObjectToEdit"
                                             [inputFlowDefinition]="model"
                                             [inputIsReadOnlyMode]="isReadOnlyMode"
                                             (outputUpdateFlowObjectEvent)="updateFlowObject($event)"
                                             (outputCloseEvent)="toggleEditArea()"
                        ></flow-object-details>
                    </ng-container>
                </mat-tab>

                <!-- Construtor de Formulário -->
                <mat-tab [label]="DetailsAreaTabsLabel.get(DetailsAreaTabs.FormBuilder)">
                    <h6>Edite aqui a estrutura do formulário que será exibido para o usuário ao se executar esta tarefa.</h6>
                    <hr>
                    <flow-object-details-form #flowObjectFormRef
                                              [(inputModel)]="flowObjectToEdit"
                                              [inputFlowDefinition]="model"
                                              [inputIsReadOnlyMode]="isReadOnlyMode"
                                              (outputSubmitEvent)="updateFlowObject($event)"
                                              (outputCloseEvent)="toggleEditArea()"
                    ></flow-object-details-form>
                </mat-tab>

                <!-- Construtor de Formulário: Iniciar por API -->
                <mat-tab [label]="DetailsAreaTabsLabel.get(DetailsAreaTabs.FormBuilderInboundApi)">
                    <h6>Liste aqui os anexos enviados pelo sistema de origem que serão capturados como Documentos no E-Docs.</h6>
                    <hr>
                    <flow-object-details-start-inbound-api #flowObjectStartInboundApiRef
                                                           [(inputModel)]="flowObjectToEdit"
                                                           [inputFlowDefinition]="model"
                                                           [inputIsReadOnlyMode]="isReadOnlyMode"
                                                           (outputSubmitEvent)="updateFlowObject($event)"
                                                           (outputCloseEvent)="toggleEditArea()"
                    ></flow-object-details-start-inbound-api>
                </mat-tab>

                <!-- Editor de Modelo de Documento -->
                <mat-tab [label]="DetailsAreaTabsLabel.get(DetailsAreaTabs.HtmlEditor)" *ngIf="isAreaVisible.htmlEditor">
                    <ng-template matTabContent>
                        <h6>Edite aqui a estrutura do documento que será enviado ao E-Docs como resultado do preenchimento do formulário desta tarefa.</h6>
                        <hr>
                        <flow-object-details-model-editor ngDefaultControl #modelEditorRef
                                                          [(ngModel)]="tinyMceContent"
                                                          [inputShouldLoadTinyMce]="shouldLoadModelEditorTinyMce()"
                                                          [inputTinyMceOptions]="tinyMceOptions"
                                                          [inputIsReadOnlyMode]="isReadOnlyMode"
                                                          (outputOnInitNgModelEvent)="tinyMceFinishedLoading()"
                                                          (outputToggleEditAreaEvent)="toggleEditArea()"
                                                          (outputUpdateHtmlEditorAreaEvent)="updateFlowObject(flowObjectToEdit)"
                        ></flow-object-details-model-editor>
                    </ng-template>
                </mat-tab>

                <!-- Aplicação de Regra -->
                <mat-tab [label]="DetailsAreaTabsLabel.get(DetailsAreaTabs.GatewayRules)" *ngIf="isAreaVisible.gatewayRules">
                    <flow-object-details-gateway-rules #flowObjectGatewayRulesRef
                                                       [(inputModel)]="flowObjectToEdit"
                                                       [inputFlowDefinition]="model"
                                                       [inputIsReadOnlyMode]="isReadOnlyMode"
                                                       (outputSubmitEvent)="updateFlowObject($event)"
                                                       (outputCloseEvent)="toggleEditArea()">
                    </flow-object-details-gateway-rules>
                </mat-tab>

                <!-- Divisão de Fluxo -->
                <mat-tab [label]="DetailsAreaTabsLabel.get(DetailsAreaTabs.GatewayPaths)" *ngIf="isAreaVisible.gatewayPaths">
                    <flow-object-details-gateway-paths #flowObjectGatewayPathsRef
                                                       [(inputModel)]="flowObjectToEdit"
                                                       [inputFlowDefinition]="model"
                                                       [inputIsReadOnlyMode]="isReadOnlyMode"
                                                       (outputSubmitEvent)="updateFlowObject($event)"
                                                       (outputCloseEvent)="toggleEditArea()">
                    </flow-object-details-gateway-paths>
                </mat-tab>

                <!-- API de Destino (Intermediária) -->
                <mat-tab [label]="DetailsAreaTabsLabel.get(DetailsAreaTabs.MidwayOutboundApi)" *ngIf="isAreaVisible.midApi">
                    <flow-object-details-midway-outbound-api #flowObjectMidwayOutboundApiRef
                                                             [(inputModel)]="flowObjectToEdit"
                                                             [inputFlowDefinition]="model"
                                                             [inputIsReadOnlyMode]="isReadOnlyMode"
                                                             (outputSubmitEvent)="updateFlowObject($event)"
                                                             (outputCloseEvent)="toggleEditArea()"
                    ></flow-object-details-midway-outbound-api>
                </mat-tab>

                <!-- API de Destino (Final) -->
                <mat-tab [label]="DetailsAreaTabsLabel.get(DetailsAreaTabs.OutboundApi)" *ngIf="isAreaVisible.api">
                    <flow-object-details-outbound-api #flowObjectOutboundApiRef
                                                      [(inputModel)]="flowObjectToEdit"
                                                      [inputFlowDefinition]="model"
                                                      [inputIsReadOnlyMode]="isReadOnlyMode"
                                                      (outputSubmitEvent)="updateFlowObject($event)"
                                                      (outputCloseEvent)="toggleEditArea()"
                    ></flow-object-details-outbound-api>
                </mat-tab>
            </mat-tab-group>
        </div>

        <!-- Tarefas Disponíveis -->
        <div class="card library-area p-4 my-1 mx-0" #availableFlowObjectsParentRef
             cdkDropList
             cdkDropListSortingDisabled
             [cdkDropListConnectedTo]="['flow-area-backdrop']"
             [cdkDropListData]="availableFlowObjects"
             [cdkDropListDisabled]="hasGatewayPath"
        >
            <div featureHint="availableFlowObjects">
                <h5>Tarefas Disponíveis</h5>
            </div>

            <hr>

            <div class="available-flow-objects styled-scrollbar" [class.read-only]="isReadOnlyMode" #availableFlowObjectsRef>
                <ngx-spinner bdColor="#f3f9fb" size="medium" color="#64b2cd" type="line-scale-pulse-out-rapid" [fullScreen]="false"></ngx-spinner>

                <div class="wrapper" *ngIf="isBootstrapFinished" #availableFlowObjectsWrapperRef>
                    <available-flow-object *ngFor="let flowObject of availableFlowObjects"
                                           [attr.featureHint]="(
                                               flowObject.typeId == FlowObjectType.StartForm
                                                   ? 'availableFlowObjectStartForm'
                                               : flowObject.typeId == FlowObjectType.StartInboundApi
                                                   ? 'availableFlowObjectStartInboundApi'
                                               : flowObject.typeId == FlowObjectType.TaskForward
                                                   ? 'availableFlowObjectTaskForward'
                                               : flowObject.typeId == FlowObjectType.TaskAcknowledge
                                                   ? 'availableFlowObjectTaskAcknowledge'
                                               : flowObject.typeId == FlowObjectType.TaskMidwayOutboundApi
                                                   ? 'availableFlowObjectTaskMidwayOutboundApi'
                                               : flowObject.typeId == FlowObjectType.TaskRegisterProcess
                                                   ? 'availableFlowObjectTaskRegisterProcess'
                                               : flowObject.typeId == FlowObjectType.TaskDispatchProcess
                                                   ? 'availableFlowObjectTaskDispatchProcess'
                                               : flowObject.typeId == FlowObjectType.GatewayApprove
                                                   ? 'availableFlowObjectGatewayApprove'
                                               : flowObject.typeId == FlowObjectType.GatewayRules
                                                   ? 'availableFlowObjectGatewayRules'
                                               : flowObject.typeId == FlowObjectType.GatewayPaths
                                                   ? 'availableFlowObjectGatewayPaths'
                                               : flowObject.typeId == FlowObjectType.Finish
                                                   ? 'availableFlowObjectFinish'
                                               : flowObject.typeId == FlowObjectType.FinishOutboundApi
                                                   ? 'availableFlowObjectFinishOutboundApi'
                                                   : null
                                           )"
                                           [inputModel]="flowObject"
                                           [inputIsReadOnlyMode]="isReadOnlyMode"
                                           [inputHasGatewayPath]="hasGatewayPath"
                                           [inputHasFlowStarter]="hasFlowStarter"
                                           [inputFirstGatewayPathName]="firstGatewayPathName"
                                           [inputSecondGatewayPathName]="secondGatewayPathName"
                                           (outputSelectFlowObjectEvent)="selectFlowObject($event.flowObject, $event.isFirstGatewayPath, $event.isSecondGatewayPath)"
                                           (outputDragStartedEvent)="dragStarted($event.event, $event.isAvailableFlowObject)"
                                           (outputDragEndedEvent)="dragEnded($event)"
                                           (outputDragMovedEvent)="dragMoved($event)"
                    ></available-flow-object>
                </div>
            </div>
        </div>

        <!-- Flow Area -->
        <ng-container>
            <div class="card flow-area p-4 hidden" id="flow-area-backdrop" #flowAreaBackdropRef
                 cdkDropList
                 (cdkDropListDropped)="dragDropped($event)"
            ></div>

            <div class="card flow-area p-4 my-1 mx-0" #flowAreaRef
                 cdkDropList
                 cdkDropListOrientation="horizontal"
                 [cdkDropListData]="selectedGroupings"
                 (cdkDropListDropped)="dragDropped($event)"
            >
                <!-- Somente Leitura -->
                <div class="read-only-warning"
                     matTooltip="Esta versão do Fluxo encontra-se em modo &quot;Somente Leitura&quot; e nenhuma alteração de conteúdo será salva"
                     [class.on]="isReadOnlyMode && activeDetailsTab != Enums.DetailsAreaTabs.FlowDefinitionDetailsHotConfigs"
                     [class.details-on]="isAnyDetailsAreaOpen"
                     [class.wide]="isDetailsAreaExtraSize"
                >
                    <i class="fas fa-glasses mr-2"></i>
                    <span>Somente Leitura</span>
                </div>

                <!-- Header -->
                <div class="flow-area-header">
                    <div class="keep-left">
                        <!-- Breadcrumb/título -->
                        <span class="title">
                            <a [routerLink]="[Enums.PagesPaths.FlowDefinitionList]">
                                <span>Meus Fluxos</span>
                            </a>
                            <i class="fas fa-chevron-right mx-2"></i>
                            <span class="title-name-container" featureHint="flowAreaHeaderTitle">
                                <span class="h5 title-name my-0" [matTooltip]="model.name">
                                    {{model.name}}
                                </span>
                            </span>
                        </span>

                        <!-- Tags -->
                        <div class="tags" featureHint="flowAreaHeaderTags">
                            <!-- Ativação -->
                            <span class="fas fa-check-circle tag active"
                                  matTooltip="Fluxo {{model.isActive ? 'ativo' : 'inativo'}}"
                                  [class.on]="model.isActive"
                            ></span>

                            <!-- Publicação -->
                            <span class="fas fa-cloud tag published"
                                  matTooltip="Última versão do Fluxo {{model.isPublished ? '' : 'não '}}publicada"
                                  [class.on]="model.isPublished"
                            ></span>

                            <!-- Configurações Imediatas -->
                            <span class="fas fa-history tag hot-configs"
                                  matTooltip="Fluxo {{hasHotConfigs ? '' : 'não '}}contém Configurações Imediatas ativadas"
                                  [class.on]="hasHotConfigs"
                            ></span>
                        </div>
                    </div>

                    <!-- Barra de tarefas -->
                    <div class="keep-right">
                        <!-- Versão -->
                        <div class="form-group select-container my-0 mr-1" featureHint="flowAreaHeaderVersion">
                            <label for="flowDefinitionVersion">Versão</label>
                            <select class="form-control" id="flowDefinitionVersion" name="flowDefinitionVersion"
                                    [(ngModel)]="selectedVersion"
                                    (change)="changeVersion()"
                            >
                                <option *ngFor="let option of flowDefinitionVersionOptions" [value]="option">{{option}}</option>
                            </select>
                        </div>

                        <!-- Ações -->
                        <div featureHint="flowAreaHeaderActions">
                            <button type="button" class="btn mr-1" matTooltip="Visualizar ações"
                                    [disabled]="isCreate"
                                    [ngClass]="isCreate ? 'btn-default read-only' : 'btn-outline-primary'"
                                    (click)="actionsMatPopoverRef.open()"
                            >
                                <i class="fas fa-bolt"></i>
                                <span class="ml-1">Ações</span>
                            </button>
                            <ngx-mat-popover [popoverContent]="actionsPopoverContentTemplate" [disabled]="isCreate" #actionsMatPopoverRef></ngx-mat-popover>
                            <ng-template #actionsPopoverContentTemplate>
                                <div class="actions-wrapper">
                                    <!-- Publicar -->
                                    <div class="action px-2"
                                         *ngIf="!model.isPublished"
                                         (click)="publish()"
                                    >
                                        <i class="fas fa-cloud-upload-alt"></i>
                                        <span class="ml-2">Publicar</span>
                                    </div>

                                    <!-- Versionar -->
                                    <div class="action px-2"
                                         *ngIf="model.isPublished"
                                         (click)="newVersion()"
                                    >
                                        <i class="fas fa-code-fork"></i>
                                        <span class="ml-2">Versionar</span>
                                    </div>

                                    <!-- Clonar -->
                                    <div class="action px-2"
                                         (click)="clone()"
                                    >
                                        <i class="fas fa-clone"></i>
                                        <span class="ml-2">Clonar</span>
                                    </div>

                                    <!-- Ativar -->
                                    <div class="action px-2"
                                         *ngIf="!model.isActive"
                                         (click)="activate()"
                                    >
                                        <i class="fas fa-check-circle"></i>
                                        <span class="ml-2">Ativar</span>
                                    </div>

                                    <!-- Desativar -->
                                    <div class="action px-2"
                                         *ngIf="model.isActive && (model.isPublished || model.version > 1)"
                                         (click)="deactivate()"
                                    >
                                        <i class="fas fa-times-circle"></i>
                                        <span class="ml-2">Desativar</span>
                                    </div>

                                    <!-- Remover -->
                                    <div class="action px-2"
                                         *ngIf="!model.isPublished && model.version == 1"
                                         (click)="delete()"
                                    >
                                        <i class="fas fa-trash"></i>
                                        <span class="ml-2">Remover</span>
                                    </div>

                                    <!-- Histórico de alterações -->
                                    <div class="action px-2"
                                         (click)="loadHistory()"
                                    >
                                        <i class="fas fa-book-open"></i>
                                        <span class="ml-2">Histórico de alterações</span>
                                    </div>

                                    <!-- Instanciar última versão -->
                                    <div class="action px-2"
                                         *ngIf="model.isPublished || model.version > 1"
                                         (click)="instantiate()"
                                    >
                                        <i class="fas fa-external-link-alt"></i>
                                        <span class="ml-2">Instanciar a última versão publicada</span>
                                    </div>
                                </div>
                            </ng-template>

                            <ngx-mat-popover [popoverContent]="viewHistoryPopoverContentTemplate" #viewHistoryMatPopoverRef></ngx-mat-popover>
                            <ng-template #viewHistoryPopoverContentTemplate>
                                <div class="history-wrapper styled-scrollbar">
                                    <div class="title entry py-1 px-2" [class.scrollbar]="historyEntries.length > 18">
                                        <h5>Histórico de Alterações por Usuário</h5>
                                    </div>

                                    <div class="entry px-2" [class.same-above]="entry.auditUserName.endsWith('*')" *ngFor="let entry of historyEntries">
                                        <div class="col left">
                                            <i class="fas fa-user"></i>
                                            <span class="ml-1">{{entry.auditUserName.split('*')[0]}}</span>
                                        </div>
                                        <div class="col right" matTooltip="{{entry.auditDate.split('*')[1]}}">
                                            <i class="fas fa-clock ml-3"></i>
                                            <span class="ml-1">{{entry.auditDate.split('*')[0]}}</span>
                                            <i class="fas fa-question-circle info"></i>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                        <!-- Recarregar -->
                        <div featureHint="flowAreaHeaderReload">
                            <button type="button" class="btn mr-1" matTooltip="Recarregar conteúdo do Fluxo"
                                    [disabled]="isCreate"
                                    [ngClass]="isCreate ? 'btn-default read-only' : 'btn-outline-primary'"
                                    (click)="reload()"
                            >
                                <i class="fas fa-sync-alt"></i>
                                <span class="ml-1">Recarregar</span>
                            </button>
                        </div>

                        <!-- Editar -->
                        <div featureHint="flowAreaHeaderEdit">
                            <button type="button" class="btn btn-outline-primary mr-1" matTooltip="Editar propriedades do Fluxo"
                                    (click)="openModalArea()"
                            >
                                <i class="fas fa-pencil-alt"></i>
                                <span class="ml-1">Editar</span>
                            </button>
                        </div>

                        <!-- Salvar -->
                        <div featureHint="flowAreaHeaderSave">
                            <button type="button" class="btn" matTooltip="Salvar alterações no Fluxo (&quot;Control&quot; + &quot;B&quot;)"
                                    [disabled]="isReadOnlyMode || (isCreate && selectedFlowObjects.length == 0)"
                                    [ngClass]="isReadOnlyMode || (isCreate && selectedFlowObjects.length == 0)
                                                   ? 'btn-default read-only'
                                                   : 'btn-outline-primary'
                                              "
                                    (click)="saveFlowDefinition()"
                            >
                                <i class="fas fa-save"></i>
                                <span class="ml-1">Salvar</span>
                            </button>
                        </div>
                    </div>
                </div>

                <hr>

                <!-- Área do Canvas -->
                <div class="selected-flow-objects-container">
                    <ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="medium" color="#64b2cd" type="line-scale-pulse-out-rapid" [fullScreen]="false"></ngx-spinner>

                    <!-- Sequência Principal -->
                    <div class="main-path" #mainPathRef>
                        <ng-container *ngIf="isBootstrapFinished">
                            <div class="path-name" *ngIf="hasGatewayPath">Sequência Principal</div>

                            <div class="msg empty" *ngIf="mainPathFlowObjects.length == 0; else mainPathFlowObjectsTemplate">
                                <strong>Adicione uma Tarefa</strong>
                            </div>

                            <ng-template #mainPathFlowObjectsTemplate>
                                <selected-grouping *ngFor="let grouping of mainPathGroupings"
                                                   [inputModel]="grouping"
                                                   [inputIsReadOnlyMode]="isReadOnlyMode"
                                                   [inputHasGatewayPath]="hasGatewayPath"
                                                   [inputSelectedGroupings]="selectedGroupings"
                                                   [inputSelectedGroupingColumns]="mainPathGroupingColumns"
                                                   [inputSelectedFlowObjects]="mainPathFlowObjects"
                                                   (outputToggleEditAreaEvent)="toggleEditArea($event)"
                                                   (outputRemoveGroupingEvent)="removeGrouping($event.event, $event.flowObject)"
                                                   (outputChangeTaskTypeEvent)="changeTaskType($event.event, $event.flowObject)"
                                                   (outputMoveGroupingBackwardsEvent)="moveGroupingBackwards($event.event, $event.flowObject)"
                                                   (outputMoveGroupingForwardsEvent)="moveGroupingForwards($event.event, $event.flowObject)"
                                                   (outputMoveGroupingUpwardsEvent)="moveGroupingUpwards($event.event, $event.flowObject)"
                                                   (outputMoveGroupingDownwardsEvent)="moveGroupingDownwards($event.event, $event.flowObject)"
                                                   (outputNotifyFlowObjectLoadedEvent)="gatherMainPathFlowObjectRefs($event)"
                                                   (outputDragStartedEvent)="dragStarted($event)"
                                ></selected-grouping>
                            </ng-template>
                        </ng-container>
                    </div>

                    <!-- Sequência A -->
                    <div class="gateway-path first" *ngIf="hasGatewayPath" #firstGatewayPathRef>
                        <ng-container *ngIf="isBootstrapFinished">
                            <div class="path-name">
                                {{firstGatewayPathName}}
                            </div>

                            <div class="msg empty" *ngIf="firstGatewayPathFlowObjects.length == 0; else firstGatewayPathFlowObjectsTemplate">
                                <strong>Adicione uma Tarefa</strong>
                            </div>

                            <ng-template #firstGatewayPathFlowObjectsTemplate>
                                <selected-grouping *ngFor="let grouping of firstGatewayPathGroupings"
                                                   [inputModel]="grouping"
                                                   [inputIsReadOnlyMode]="isReadOnlyMode"
                                                   [inputHasGatewayPath]="hasGatewayPath"
                                                   [inputSelectedGroupings]="selectedGroupings"
                                                   [inputSelectedGroupingColumns]="firstGatewayPathGroupingColumns"
                                                   [inputSelectedFlowObjects]="firstGatewayPathFlowObjects"
                                                   (outputToggleEditAreaEvent)="toggleEditArea($event)"
                                                   (outputRemoveGroupingEvent)="removeGrouping($event.event, $event.flowObject)"
                                                   (outputChangeTaskTypeEvent)="changeTaskType($event.event, $event.flowObject)"
                                                   (outputMoveGroupingBackwardsEvent)="moveGroupingBackwards($event.event, $event.flowObject)"
                                                   (outputMoveGroupingForwardsEvent)="moveGroupingForwards($event.event, $event.flowObject)"
                                                   (outputMoveGroupingUpwardsEvent)="moveGroupingUpwards($event.event, $event.flowObject)"
                                                   (outputMoveGroupingDownwardsEvent)="moveGroupingDownwards($event.event, $event.flowObject)"
                                                   (outputNotifyFlowObjectLoadedEvent)="gatherFirstGatewayPathFlowObjectRefs($event)"
                                                   (outputDragStartedEvent)="dragStarted($event)"
                                ></selected-grouping>
                            </ng-template>
                        </ng-container>
                    </div>

                    <!-- Sequência B -->
                    <div class="gateway-path second" *ngIf="hasGatewayPath" #secondGatewayPathRef>
                        <ng-container *ngIf="isBootstrapFinished">
                            <div class="path-name">
                                {{secondGatewayPathName}}
                            </div>

                            <div class="msg empty" *ngIf="secondGatewayPathFlowObjects.length == 0; else secondGatewayPathFlowObjectsTemplate">
                                <strong>Adicione uma Tarefa</strong>
                            </div>

                            <ng-template #secondGatewayPathFlowObjectsTemplate>
                                <selected-grouping *ngFor="let grouping of secondGatewayPathGroupings"
                                                   [inputModel]="grouping"
                                                   [inputIsReadOnlyMode]="isReadOnlyMode"
                                                   [inputHasGatewayPath]="hasGatewayPath"
                                                   [inputSelectedGroupings]="selectedGroupings"
                                                   [inputSelectedGroupingColumns]="secondGatewayPathGroupingColumns"
                                                   [inputSelectedFlowObjects]="secondGatewayPathFlowObjects"
                                                   (outputToggleEditAreaEvent)="toggleEditArea($event)"
                                                   (outputRemoveGroupingEvent)="removeGrouping($event.event, $event.flowObject)"
                                                   (outputChangeTaskTypeEvent)="changeTaskType($event.event, $event.flowObject)"
                                                   (outputMoveGroupingBackwardsEvent)="moveGroupingBackwards($event.event, $event.flowObject)"
                                                   (outputMoveGroupingForwardsEvent)="moveGroupingForwards($event.event, $event.flowObject)"
                                                   (outputMoveGroupingUpwardsEvent)="moveGroupingUpwards($event.event, $event.flowObject)"
                                                   (outputMoveGroupingDownwardsEvent)="moveGroupingDownwards($event.event, $event.flowObject)"
                                                   (outputNotifyFlowObjectLoadedEvent)="gatherSecondGatewayPathFlowObjectRefs($event)"
                                                   (outputDragStartedEvent)="dragStarted($event)"
                                ></selected-grouping>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
