import { FlowObjectDefinition } from "./flow-object.model";
import { Grouping } from "./grouping.model";
import { Guid } from "guid-typescript";

export class GroupingColumn {
    id?: string = Guid.create().toString();
    innerIndex?: number = 0;
    outerIndex?: number = 0;
    groupingId?: string;
    grouping?: Grouping;
    isFirstGatewayPath?: boolean = false;
    isSecondGatewayPath?: boolean = false;
    flowObjectDefinitions?: FlowObjectDefinition[] = [];

    public constructor(init?: Partial<GroupingColumn>) {
        Object.assign(this, init);
    }
}
