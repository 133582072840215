import { FlowObjectDefinition } from "./flow-object.model";
import { Guid } from "guid-typescript";
import { Enums } from '../shared/enums';
import { ConfigSchemaFlowDefinition } from "./config-schema.model";

export class FlowDefinition {
    id?: string = Guid.create().toString();
    name?: string = Enums.Messages.MyNewFlow;
    publicName?: string;
    publishedName?: string;
    description?: string;
    publishedDescription?: string;
    configSchema?: string = JSON.stringify(new ConfigSchemaFlowDefinition());
    version?: number = 1;
    isActive?: boolean = true;
    isPublished?: boolean = false;
    isPinned?: boolean = false;
    isUnlistedModeActive?: boolean = false;
    ownerId?: string;
    ownerInfo?: string = null;
    unitId?: string;
    unitInfo?: string = null;
    publishedUnitId?: string = null;
    publishedUnitInfo?: string = null;
    organizationId?: string;
    organizationInfo?: string = null;
    publishedOrganizationId?: string = null;
    publishedOrganizationInfo?: string = null;
    patriarchId?: string;
    patriarchInfo?: string = null;
    publishedPatriarchId?: string = null;
    publishedPatriarchInfo?: string = null;
    targetId?: number = Enums.FlowTarget.Citizen;
    publishedTargetId?: number = null;
    contextId?: string = null;
    catalogServiceId?: string = null;
    contextIndex?: number = null;
    createDate?: Date;
    updateDate?: Date;
    publishDate?: Date;
    versionDate?: Date;
    activateDate?: Date;
    deactivateDate?: Date;
    pinDate?: Date;
    flowObjectDefinitions?: FlowObjectDefinition[] = [];

    public constructor(init?: Partial<FlowDefinition>) {
        Object.assign(this, init);
    }
}

export class FlowDefinitionContext {
    id?: string = Guid.createEmpty().toString();
    catalogServiceId?: string = null;
    name?: string = null;
    flowDefinitions?: FlowDefinition[] = [];

    public constructor(init?: Partial<FlowDefinitionContext>) {
        Object.assign(this, init);
    }
}
