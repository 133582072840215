import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import { ConjuntoGrupo, TipoFiltro, AgentePublico, AgentePublicoPapel } from '../models/acesso-cidadao.model';
import { Unidade } from '../models/organograma.model';

@Injectable({
    providedIn: 'root'
})
export class AcessoCidadaoService extends BaseService {
    controllerRoute: string = 'AcessoCidadao';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async getConjuntoGrupos(id: string, tipoFiltro?: TipoFiltro): Promise<IBaseModel<ConjuntoGrupo[]>> {
        let httpParams = new HttpParams();

        httpParams = httpParams.append('tipoFiltro', tipoFiltro != null ? tipoFiltro : TipoFiltro.Todos);

        return this.httpClient.get<IBaseModel<ConjuntoGrupo[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/Conjunto/${id}/Grupos`, { params: httpParams }).toPromise();
    }

    public async getConjuntoAgentesPublicos(id: string): Promise<IBaseModel<AgentePublico[]>> {
        return this.httpClient.get<IBaseModel<AgentePublico[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/Conjunto/${id}/AgentesPublicos`).toPromise();
    }

    public async getUnidadesPerfilAdministrador(idUsuario: string): Promise<IBaseModel<Unidade[]>> {
        return this.httpClient.get<IBaseModel<Unidade[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/UnidadesPerfilAdministrador/${idUsuario}`).toPromise();
    }

    public async getGrupo(idGrupo: string): Promise<IBaseModel<ConjuntoGrupo>> {
        return this.httpClient.get<IBaseModel<ConjuntoGrupo>>(`${this.apiBaseUrl}/${this.controllerRoute}/Grupo/${idGrupo}`).toPromise();
    }

    public async getAgentePublico(sub: string): Promise<IBaseModel<AgentePublico>> {
        return this.httpClient.get<IBaseModel<AgentePublico>>(`${this.apiBaseUrl}/${this.controllerRoute}/AgentePublico/${sub}`).toPromise();
    }

    public async getAgentePublicoSub(cpf: string): Promise<IBaseModel<AgentePublico>> {
        return this.httpClient.get<IBaseModel<AgentePublico>>(`${this.apiBaseUrl}/${this.controllerRoute}/AgentePublico/${cpf}/Sub`).toPromise();
    }

    public async getAgentePublicoPapeis(sub: string): Promise<IBaseModel<AgentePublicoPapel[]>> {
        return this.httpClient.get<IBaseModel<AgentePublicoPapel[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/AgentePublico/${sub}/Papeis`).toPromise();
    }

    public async getCidadaoExiste(cpf: string): Promise<IBaseModel<void>> {
        return this.httpClient.get<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}/Cidadao/${cpf}`).toPromise();
    }

    public async getCidadaoSub(cpf: string): Promise<IBaseModel<AgentePublico>> {
        return this.httpClient.get<IBaseModel<AgentePublico>>(`${this.apiBaseUrl}/${this.controllerRoute}/Cidadao/${cpf}/Sub`).toPromise();
    }

    public async getRestritoCidadao(sub: string): Promise<IBaseModel<AgentePublico>> {
        return this.httpClient.get<IBaseModel<AgentePublico>>(`${this.apiBaseUrl}/${this.controllerRoute}/Restrito/Cidadao/${sub}`).toPromise();
    }
}
