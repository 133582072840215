export class Plano {
    id?: string;
    codigo?: string;
    nome?: string;
    classes?: Classe[];
}

export class Classe {
    id?: string;
    codigo?: string;
    nome?: string;
    observacao?: string;
    ativo?: boolean;
    unidadeArquivamento?: boolean;
}

export class FundamentoLegal {
    id?: string;
    nome?: string;
    descricao?: string;
    ordenacao?: number;
    nivelAcesso?: string;
}

export class Patriarca {
    id?: string;
    nome?: string;
    sigla?: string;
    descricaoTipoAgente?: string;
    tipoAgente?: number;
}

export enum AccessLevelType {
    Public = 1,
    Organizational = 2,
    Confidential = 3,
    Classified = 4
}

export const AccessLevelTypeDescription = new Map<number, string>([
    [AccessLevelType.Public, 'Público'],
    [AccessLevelType.Organizational, 'Organizacional'],
    [AccessLevelType.Confidential, 'Sigiloso'],
    [AccessLevelType.Classified, 'Classificado']
]);

export enum DocumentLegalValueCombination {
    ServidorDocumentoEletronicoSemAssinatura = 0,
    ServidorDocumentoEletronicoAssinadoEletronicamente = 1,
    ServidorDocumentoEletronicoAssinadoDigitalmente = 2,
    ServidorDocumentoEscaneadoDocumentoOriginal = 3,
    ServidorDocumentoEscaneadoCopiaSimples = 4,
    ServidorDocumentoEscaneadoCopiaAutenticadaCartorio = 5,
    ServidorDocumentoEscaneadoCopiaAutenticadaAdministrativamente = 6,
    CidadaoDocumentoEletronicoSemAssinatura = 7,
    CidadaoDocumentoEletronicoAssinadoEletronicamente = 8,
    CidadaoDocumentoEletronicoAssinadoDigitalmente = 9,
    CidadaoDocumentoEscaneadoCopiaSimples = 10
}

export const DocumentLegalValueCombinationDescription = new Map<number, string>([
    [DocumentLegalValueCombination.ServidorDocumentoEletronicoSemAssinatura, 'Servidor: Documento Eletrônico > Sem Assinatura'],
    [DocumentLegalValueCombination.ServidorDocumentoEletronicoAssinadoEletronicamente, 'Servidor: Documento Eletrônico > Assinado Eletronicamente (E-Docs)'],
    [DocumentLegalValueCombination.ServidorDocumentoEletronicoAssinadoDigitalmente, 'Servidor: Documento Eletrônico > Assinado Digitalmente (ICP-Brasil)'],
    [DocumentLegalValueCombination.ServidorDocumentoEscaneadoDocumentoOriginal, 'Servidor: Documento Escaneado > Documento Original'],
    [DocumentLegalValueCombination.ServidorDocumentoEscaneadoCopiaSimples, 'Servidor: Documento Escaneado > Cópia Simples'],
    [DocumentLegalValueCombination.ServidorDocumentoEscaneadoCopiaAutenticadaCartorio, 'Servidor: Documento Escaneado > Cópia Autenticada em Cartório'],
    [DocumentLegalValueCombination.ServidorDocumentoEscaneadoCopiaAutenticadaAdministrativamente, 'Servidor: Documento Escaneado > Cópia Autenticada Administrativamente'],
    [DocumentLegalValueCombination.CidadaoDocumentoEletronicoSemAssinatura, 'Cidadão: Documento Eletrônico > Sem Assinatura'],
    [DocumentLegalValueCombination.CidadaoDocumentoEletronicoAssinadoEletronicamente, 'Cidadão: Documento Eletrônico > Assinado Eletronicamente (E-Docs)'],
    [DocumentLegalValueCombination.CidadaoDocumentoEletronicoAssinadoDigitalmente, 'Cidadão: Documento Eletrônico > Assinado Digitalmente (ICP-Brasil)'],
    [DocumentLegalValueCombination.CidadaoDocumentoEscaneadoCopiaSimples, 'Cidadão: Documento Escaneado > Cópia Simples']
]);
