import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output
} from '@angular/core';
import {
    ComissoesFilter,
    GruposFilter,
    OrganizacoesFilter,
    PatriarcasFilter,
    UnidadesFilter
} from '../../flow-object-details.pipe';
import { AcessoCidadaoService } from '../../../../../services/acesso-cidadao.service';
import { OrganogramaService } from '../../../../../services/organograma.service';
import { Organizacao, Unidade } from '../../../../../models/organograma.model';
import { ConjuntoGrupo, TipoFiltro } from '../../../../../models/acesso-cidadao.model';
import { Enums } from '../../../../../shared/enums';
import { ToastrService } from 'ngx-toastr';
import { Patriarca } from '../../../../../models/edocs.model';
import { EDocsService } from '../../../../../services/edocs.service';
import { Utils } from '../../../../../shared/utils';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ConfigSchemaSectionEDocsLocation, LocationType } from '../../../../../models/config-schema.model';

@Component({
    selector: 'edocs-location',
    templateUrl: './edocs-location.component.html',
    styleUrls: ['./edocs-location.component.scss']
})
export class EDocsLocationComponent implements OnInit {
    Utils: typeof Utils = Utils;

    // #region [Patriarca]
    patriarcaId: string = '';
    patriarcaDisplay: string = '';
    patriarcaNome: string = null;
    patriarcas: Patriarca[] = [];
    selectablePatriarcas: Patriarca[];
    // #endregion
    // #region [Organização]
    organizacaoId: string = '';
    organizacaoDisplay: string = '';
    organizacaoNome: string = null;
    organizacoes: Organizacao[] = [];
    selectableOrganizacoes: Organizacao[] = [];
    // #endregion
    // #region [Unidade]
    unidadeId: string = '';
    unidadeDisplay: string = '';
    unidadeNome: string = null;
    unidades: Unidade[] = [];
    selectableUnidades: Unidade[] = [];
    // #endregion
    // #region [Grupo]
    grupoId: string = '';
    grupoDisplay: string = '';
    grupoNome: string = null;
    grupos: ConjuntoGrupo[] = [];
    selectableGrupos: ConjuntoGrupo[] = [];
    // #endregion
    // #region [Comissão]
    comissaoId: string = '';
    comissaoDisplay: string = '';
    comissaoNome: string = null;
    comissoes: ConjuntoGrupo[] = [];
    selectableComissoes: ConjuntoGrupo[] = [];
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: ConfigSchemaSectionEDocsLocation;
    @Input() inputFlowDefinitionOrganizationId: string;
    @Input() inputIsReadOnlyMode: boolean;
    @Input() inputShowTooltips: boolean = false;
    @Input() inputIsConditionalForwarding: boolean = false;
    @Output() outputModelChange = new EventEmitter<ConfigSchemaSectionEDocsLocation>();
    // #endregion

    constructor(
        private toastr: ToastrService,
        private acessoCidadaoService: AcessoCidadaoService,
        private organogramaService: OrganogramaService,
        private edocsService: EDocsService,
        private patriarcasFilter: PatriarcasFilter,
        private organizacoesFilter: OrganizacoesFilter,
        private unidadesFilter: UnidadesFilter,
        private gruposFilter: GruposFilter,
        private comissoesFilter: ComissoesFilter
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        const response_patriarcas = await this.edocsService.getPatriarcas();

        if (!response_patriarcas.isSuccess) {
            this.toastr.error(response_patriarcas.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.patriarcas = response_patriarcas.data;
        this.patriarcas.sort((a, b) => {
            let first = a.sigla || a.nome || '';
            let second = b.sigla || b.nome || '';
            return first.localeCompare(second);
        });
        this.selectablePatriarcas = this.patriarcas;
    }

    // ======================
    // public methods
    // ======================

    // #region [Patriarca]
    patriarcaDisplayChange() {
        this.selectablePatriarcas = this.patriarcasFilter.transform(this.patriarcas, this.patriarcaDisplay);
    }

    async patriarcaIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.patriarcaId = event.option.value.id;
        }

        if (this.patriarcaId != '') {
            const response = await this.organogramaService.getOrganizacoesFilhas(this.patriarcaId);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.organizacoes = response.data;
            this.organizacoes.sort((a, b) => {
                let first = a.sigla || a.nomeFantasia || a.razaoSocial || '';
                let second = b.sigla || b.nomeFantasia || b.razaoSocial || '';
                return first.localeCompare(second);
            });
            this.selectableOrganizacoes = this.organizacoes;
        } else {
            this.organizacaoId = '';
            this.organizacoes = [];
            this.selectableOrganizacoes = [];
            this.inputModel.name = null;
            this.inputModel.id = null;
            this.patriarcaNome = null;
        }
    }

    displayPatriarcaId(option): string {
        return option != null && option != ''
            ? `${option.sigla} - ${option.nome.toUpperCase()}`
            : '';
    }

    clearPatriarca() {
        this.patriarcaId = '';
        this.patriarcaDisplay = '';
        this.selectablePatriarcas = this.patriarcas;
        this.organizacoes = [];
        this.clearOrganizacao();
    }
    // #endregion

    // #region [Organização]
    organizacaoDisplayChange() {
        this.selectableOrganizacoes = this.organizacoesFilter.transform(this.organizacoes, this.organizacaoDisplay);
    }

    async organizacaoIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.organizacaoId = event.option.value.guid;
        }

        if (this.organizacaoId != '') {
            let organization = this.organizacoes.find(x => x.guid == this.organizacaoId);
            this.organizacaoNome = `${organization.sigla} - ${organization.nomeFantasia == '.' ? organization.sigla : organization.nomeFantasia}`;

            let response = await this.organogramaService.getUnidadesOrganizacao(this.organizacaoId);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.unidades = response.data;
            this.unidades.sort((a, b) => {
                let first = a.nomeCurto || a.nome || a.sigla || '';
                let second = b.nomeCurto || b.nome || b.sigla || '';
                return first.localeCompare(second);
            });
            this.selectableUnidades = this.unidades;

            response = await this.acessoCidadaoService.getConjuntoGrupos(this.organizacaoId, TipoFiltro.TodosGrupoTrabalho);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.grupos = response.data;
            this.grupos.sort((a, b) => a.nome.localeCompare(b.nome));
            this.grupos.sort((a, b) => {
                let first = a.nome || '';
                let second = b.nome || '';
                return first.localeCompare(second);
            });
            this.selectableGrupos = this.grupos;

            response = await this.acessoCidadaoService.getConjuntoGrupos(this.organizacaoId, TipoFiltro.ComissaoSiarhes);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.comissoes = response.data;
            this.comissoes.sort((a, b) => a.nome.localeCompare(b.nome));
            this.comissoes.sort((a, b) => {
                let first = a.nome || '';
                let second = b.nome || '';
                return first.localeCompare(second);
            });
            this.selectableComissoes = this.comissoes;
        }
    }

    displayOrganizacaoId(option): string {
        return !Utils.isNullOrEmpty(option)
            ? `${option.sigla} - ${option.nomeFantasia == '.' ? option.sigla : option.nomeFantasia}`
            : '';
    }

    clearOrganizacao() {
        this.organizacaoId = '';
        this.organizacaoDisplay = '';
        this.organizacaoNome = null;
        this.selectableOrganizacoes = this.organizacoes;
        this.unidades = [];
        this.clearUnidade();
        this.grupos = [];
        this.clearGrupo();
        this.comissoes = [];
        this.clearComissao();
    }

    selectOrganizacao() {
        if (this.isDisabledOrganizacao() || Utils.isNullOrEmpty(this.organizacaoId)) return;

        this.inputModel.id = this.organizacaoId;
        this.inputModel.name = this.organizacaoNome;
        this.inputModel.type = LocationType.Organization;

        this.outputModelChange.emit(this.inputModel);

        if (!this.inputIsConditionalForwarding) {
            this.clearPatriarca();
        }
    }

    isDisabledOrganizacao(): boolean {
        return this.inputIsReadOnlyMode || this.organizacoes.length == 0;
    }
    // #endregion

    // #region [Unidade]
    unidadeDisplayChange() {
        this.selectableUnidades = this.unidadesFilter.transform(this.unidades, this.unidadeDisplay);
    }

    unidadeIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.unidadeId = event.option.value.guid;
        }

        if (this.unidadeId == '') {
            this.clearUnidade();
        } else {
            let unidade = this.unidades.find(x => x.guid == this.unidadeId);
            this.unidadeNome = unidade.nome.toUpperCase() + (!Utils.isNullOrEmpty(unidade.nomeCurto) ? ` - ${unidade.nomeCurto.toUpperCase()}` : '');
        }
    }

    displayUnidadeId(option): string {
        return !Utils.isNullOrEmpty(option)
            ? `${option.nomeCurto ? option.nomeCurto.toUpperCase() + ' - ' : ''}${option.nome.toUpperCase()}`
            : '';
    }

    clearUnidade() {
        this.unidadeId = '';
        this.unidadeDisplay = '';
        this.unidadeNome = null;
        this.selectableUnidades = this.unidades;
    }

    selectUnidade() {
        if (this.isDisabledUnidade() || Utils.isNullOrEmpty(this.unidadeId)) return;

        this.inputModel.id = this.unidadeId;
        this.inputModel.name = this.unidadeNome;
        this.inputModel.type = LocationType.Organization;

        this.outputModelChange.emit(this.inputModel);

        if (!this.inputIsConditionalForwarding) {
            this.clearPatriarca();
        }
    }

    isDisabledUnidade(): boolean {
        return this.inputIsReadOnlyMode || this.unidades.length == 0;
    }
    // #endregion

    // #region [Grupo]
    grupoDisplayChange() {
        this.selectableGrupos = this.gruposFilter.transform(this.grupos, this.grupoDisplay);
    }

    grupoIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.grupoId = event.option.value.guid;
        }

        if (this.grupoId == '') {
            this.clearGrupo();
        } else {
            let organizacao = this.organizacoes.find(x => x.guid == this.organizacaoId);
            this.grupoNome = this.grupos.find(x => x.guid == this.grupoId).nome.toUpperCase()
                + (!Utils.isNullOrEmpty(organizacao.sigla) ? ` - ${organizacao.sigla.toUpperCase()}` : '')
                + (!Utils.isNullOrEmpty(organizacao.organizacaoPai?.sigla) ? ` - ${organizacao.organizacaoPai?.sigla.toUpperCase()}` : '');
        }
    }

    displayGrupoId(option): string {
        return !Utils.isNullOrEmpty(option)
            ? option.nome.toUpperCase()
            : '';
    }

    clearGrupo() {
        this.grupoId = '';
        this.grupoDisplay = '';
        this.grupoNome = null;
        this.selectableGrupos = this.grupos;
    }

    selectGrupo() {
        if (this.isDisabledGrupo() || Utils.isNullOrEmpty(this.grupoId)) return;

        this.inputModel.id = this.grupoId;
        this.inputModel.name = this.grupoNome;
        this.inputModel.type = LocationType.Group;

        this.outputModelChange.emit(this.inputModel);

        if (!this.inputIsConditionalForwarding) {
            this.clearPatriarca();
        }
    }

    isDisabledGrupo(): boolean {
        return this.inputIsReadOnlyMode || this.grupos.length == 0;
    }
    // #endregion

    // #region [Comissão]
    comissaoDisplayChange() {
        this.selectableComissoes = this.comissoesFilter.transform(this.comissoes, this.comissaoDisplay);
    }

    comissaoIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.comissaoId = event.option.value.guid;
        }

        if (this.comissaoId == '') {
            this.clearComissao();
        } else {
            let organizacao = this.organizacoes.find(x => x.guid == this.organizacaoId);
            this.comissaoNome = this.comissoes.find(x => x.guid == this.comissaoId).nome.toUpperCase()
                + (!Utils.isNullOrEmpty(organizacao.sigla) ? ` - ${organizacao.sigla.toUpperCase()}` : '')
                + (!Utils.isNullOrEmpty(organizacao.organizacaoPai?.sigla) ? ` - ${organizacao.organizacaoPai?.sigla.toUpperCase()}` : '');
        }
    }

    displayComissaoId(option): string {
        return !Utils.isNullOrEmpty(option)
            ? option.nome.toUpperCase()
            : '';
    }

    clearComissao() {
        this.comissaoId = '';
        this.comissaoDisplay = '';
        this.comissaoNome = null;
        this.selectableComissoes = this.comissoes;
    }

    selectComissao() {
        if (this.isDisabledComissao() || Utils.isNullOrEmpty(this.comissaoId)) return;

        this.inputModel.id = this.comissaoId;
        this.inputModel.name = this.comissaoNome;
        this.inputModel.type = LocationType.Comission;

        this.outputModelChange.emit(this.inputModel);

        if (!this.inputIsConditionalForwarding) {
            this.clearPatriarca();
        }
    }

    isDisabledComissao(): boolean {
        return this.inputIsReadOnlyMode || this.comissoes.length == 0;
    }
    // #endregion

    isReady(): boolean {
        return this.inputModel != null && this.inputFlowDefinitionOrganizationId != null;
    }

    // ======================
    // private methods
    // ======================
}
