import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild
} from '@angular/core';
import {
    AccessLevelType,
    AccessLevelTypeDescription,
    FundamentoLegal,
    Patriarca
} from '../../../../../models/edocs.model';
import { FundamentosFilter, PatriarcasFilter } from '../../flow-object-details.pipe';
import { Enums } from '../../../../../shared/enums';
import { EDocsService } from '../../../../../services/edocs.service';
import { IBaseOption } from '../../../../../models/base.model';
import { Utils } from '../../../../../shared/utils';
import { ToastrService } from 'ngx-toastr';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { OrganogramaService } from '../../../../../services/organograma.service';
import { ConfigSchemaSectionAccessLevel } from '../../../../../models/config-schema.model';

@Component({
    selector: 'access-level',
    templateUrl: './access-level.component.html',
    styleUrls: ['./access-level.component.scss']
})
export class AccessLevelComponent implements OnInit {
    // #region [ViewChild]
    @ViewChild('fundamentacaoLegalRef') fundamentacaoLegalRef: ElementRef;
    // #endregion

    // #region [Type properties]
    AccessLevelType: typeof AccessLevelType = AccessLevelType;
    AccessLevelFilterType: typeof Enums.AccessLevelFilterType = Enums.AccessLevelFilterType;
    Utils: typeof Utils = Utils;
    // #endregion

    // #region [properties]
    accessLevelTypeOptions: IBaseOption[] = [];
    // #endregion

    // #region [Patriarca]
    patriarcaId: string = '';
    patriarcaDisplay: string = '';
    patriarcaNome: string = null;
    patriarcas: Patriarca[] = [];
    selectablePatriarcas: Patriarca[];
    // #endregion
    // #region [Fundamentos Legais]
    fundamentoId: string = '';
    fundamentoDisplay: string = '';
    fundamentoNome: string = null;
    fundamentoDescricao: string = null;
    fundamentosNomes: string = null;
    fundamentoFilterType: Enums.AccessLevelFilterType = null;
    fundamentos: FundamentoLegal[] = [];
    selectableFundamentos: FundamentoLegal[] = [];
    selectedFundamentos: IBaseOption[] = [];
    // #endregion

    // #region [getters]
    get isFundamentoDisplayDisabled(): boolean {
        return this.inputIsReadOnlyMode
            || Utils.isNullOrEmpty(this.fundamentoFilterType)
            || (
                this.fundamentoFilterType == Enums.AccessLevelFilterType.Patriarch
                && Utils.isNullOrEmpty(this.patriarcaId)
            );
    }
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: ConfigSchemaSectionAccessLevel;
    @Input() inputFlowDefinitionOrganizationId: string;
    @Input() inputIsReadOnlyMode: boolean = false;
    @Input() inputShowTooltips: boolean = false;
    @Output() outputModelChange = new EventEmitter<ConfigSchemaSectionAccessLevel>();
    // #endregion

    constructor(
        private toastr: ToastrService,
        private organogramaService: OrganogramaService,
        private edocsService: EDocsService,
        private fundamentosFilter: FundamentosFilter,
        private patriarcasFilter: PatriarcasFilter
    ) {
        for (let item in AccessLevelType) {
            // **X**: o endpoint de encaminhamento da v2 exige parâmetros extras em "classificacaoInformacao"
            // que a v1 não exigia; "Classified" fica pra depois
            if (!isNaN(parseInt(item)) && +item != AccessLevelType.Classified) {
                this.accessLevelTypeOptions.push({
                    value: +item,
                    description: AccessLevelTypeDescription.get(+item)
                });
            }
        }
    }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        let interval = setInterval(async () => {
            if (this.inputModel != null) {
                clearInterval(interval);

                this.initLegalReasoningsInfo();

                const response_Patriarcas = await this.edocsService.getPatriarcas();

                if (!response_Patriarcas.isSuccess) {
                    this.toastr.error(response_Patriarcas.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                this.patriarcas = response_Patriarcas.data;
                this.patriarcas.sort((a, b) => {
                    let first = a.sigla || a.nome || '';
                    let second = b.sigla || b.nome || '';
                    return first.localeCompare(second);
                });
                this.selectablePatriarcas = this.patriarcas;
            }
        }, 100);
    }

    // ======================
    // public methods
    // ======================

    // #region [Fundamento Legal]
    fundamentoDisplayChange() {
        this.selectableFundamentos = this.fundamentosFilter.transform(this.fundamentos, this.fundamentoDisplay);
    }

    fundamentoIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.fundamentoId = event.option.value.id;
        }

        let fundamento = this.fundamentos.find(x => x.id == this.fundamentoId);
        this.fundamentoNome = `${fundamento.nivelAcesso} - ${fundamento.nome}`;
        this.fundamentoDescricao = `${fundamento.nome} - ${fundamento.descricao}`;
    }

    displayFundamentoId(option): string {
        return option != null && option != ''
            ? `${option.nivelAcesso.toUpperCase()} - ${option.nome.toUpperCase()}`
            : '';
    }

    addFundamento(event) {
        if (this.isFundamentoDisplayDisabled) return;

        if (
            // caso o Nível de Acesso selecionado seja Sigiloso ou Restrito
            this.shouldFilterFundamentos()
            // caso a opção já não esteja incluída
            && this.selectedFundamentos.find(x => x.value == this.fundamentoId) == null
        ) {
            this.selectedFundamentos.push({
                value: this.fundamentoId,
                description: this.fundamentoNome
            });

            // atualiza conteúdo do tooltip
            this.fundamentosNomes = '';
            this.selectedFundamentos.forEach(x => this.fundamentosNomes += `${x.description}; `);
            this.fundamentosNomes = this.fundamentosNomes.split('; ').slice(0 ,-1).join('; ');
            this.fundamentoDescricao = '';

            this.fundamentoDisplay = '';
            this.selectableFundamentos = this.fundamentos;
        }

        this.deselectOptions(event);

        this.resolveLegalReasoningsInfo();
    }

    removeFundamento(event) {
        let selectedOptionsValues = Array.from((this.fundamentacaoLegalRef.nativeElement as HTMLSelectElement).options).filter(x => x.selected).map(x => x.value);
        selectedOptionsValues.forEach(item => {
            let idx = this.selectedFundamentos.findIndex(x => item.includes(x.value));
            this.selectedFundamentos.splice(idx, 1);
        });

        this.deselectOptions(event);

        this.resolveLegalReasoningsInfo();
    }

    shouldFilterFundamentos(): boolean {
        return [AccessLevelType.Confidential, AccessLevelType.Classified].includes(+this.inputModel.id);
    }

    async fundamentoFilterTypeChange() {
        if (this.fundamentoFilterType == Enums.AccessLevelFilterType.Citizen) {
            const response_Fundamentos = await this.edocsService.getFundamentosLegaisCidadao();

            if (!response_Fundamentos.isSuccess) {
                this.toastr.error(response_Fundamentos.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.fundamentos = response_Fundamentos.data;
            this.fundamentos.sort((a, b) => a.ordenacao - b.ordenacao);
            this.filterFundamentosSigilosos();
            this.selectableFundamentos = this.fundamentos;

            this.clearPatriarca();
            this.fundamentoId = null;
        }
    }
    // #endregion

    // #region [Patriarca]
    patriarcaDisplayChange() {
        this.selectablePatriarcas = this.patriarcasFilter.transform(this.patriarcas, this.patriarcaDisplay);
    }

    async patriarcaIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.patriarcaId = event.option.value.id;
        }

        if (this.patriarcaId != '') {
            const response_Fundamentos = await this.edocsService.getFundamentosLegaisPatriarca(this.patriarcaId);

            if (!response_Fundamentos.isSuccess) {
                this.toastr.error(response_Fundamentos.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.fundamentos = response_Fundamentos.data;
            this.fundamentos.sort((a, b) => a.ordenacao - b.ordenacao);
            this.filterFundamentosSigilosos();
            this.selectableFundamentos = this.fundamentos;
        } else {
            this.patriarcaNome = null;
        }
    }

    displayPatriarcaId(option): string {
        return option != null && option != ''
            ? `${option.sigla} - ${option.nome.toUpperCase()}`
            : '';
    }

    clearPatriarca() {
        this.patriarcaId = '';
        this.patriarcaDisplay = '';
        this.selectablePatriarcas = this.patriarcas;
    }
    // #endregion

    deselectOptions(event) {
        setTimeout(() => event.value = null, 1);
    }

    accessLevelIdChange() {
        this.selectedFundamentos = [];
        this.resolveLegalReasoningsInfo();
        this.fundamentosNomes = '';
        this.fundamentoId = '';
        this.fundamentoDescricao = '';
        this.clearPatriarca();
        this.fundamentoFilterType = null;

        if (this.shouldFilterFundamentos()) {
            this.filterFundamentosSigilosos();
        }

        this.inputModel.name = this.accessLevelTypeOptions.find(x => x.value == this.inputModel.id).description;

        this.outputModelChange.emit(this.inputModel);
    }

    isReady(): boolean {
        return this.inputModel != null && this.inputFlowDefinitionOrganizationId != null;
    }

    initLegalReasoningsInfo() {
        if (this.inputModel.legalReasoningsIds?.length > 0) {
            this.selectedFundamentos = [];

            for (let i = 0; i < this.inputModel.legalReasoningsIds.length; i++) {
                this.selectedFundamentos.push({
                    value: this.inputModel.legalReasoningsIds[i],
                    description: this.inputModel.legalReasoningsNames[i]
                });
            }
        }
    }

    // ======================
    // private methods
    // ======================

    private filterFundamentosSigilosos() {
        if (AccessLevelType.Confidential == +this.inputModel.id) {
            this.fundamentos = this.fundamentos.filter(x => x.nivelAcesso?.toUpperCase() == "SIGILOSO");
            // motivo: ver **X**
        //} else if (AccessLevelType.Classified == +this.inputModel.id) {
        //    this.fundamentos = this.fundamentos.filter(x => x.nivelAcesso?.toUpperCase() == "CLASSIFICADO");
        }
    }

    private resolveLegalReasoningsInfo() {
        this.inputModel.legalReasoningsIds = [];
        this.inputModel.legalReasoningsNames = [];

        if (this.selectedFundamentos.length > 0) {
            this.selectedFundamentos.forEach(x => {
                this.inputModel.legalReasoningsIds.push(x.value);
                this.inputModel.legalReasoningsNames.push(x.description);
            });
        }

        this.outputModelChange.emit(this.inputModel);
    }
}
