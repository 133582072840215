export const HTML_TEMPLATE_ACKNOWLEDGE = `
<table style="border-collapse: collapse; width: 100%; height: 80px;" border="0">
    <tbody>
        <tr style="height: 80px;">
            <td style="width: 15%; height: 80px; padding-bottom: 10px;">{|FluxoPatriarcaBrasao|}</td>
            <td style="width: 85%; height: 80px;">
                <strong>{|FluxoPatriarcaNome|}</strong>
                <br />
                <strong>
                    <span style="font-size: 11pt;">{|FluxoOrgaoNome|} - {|FluxoOrgaoSigla|}</span>
                </strong>
            </td>
        </tr>
    </tbody>
</table>
<table style="border-collapse: collapse; width: 100%;" border="0">
    <tbody>
        <tr>
            <td style="padding-top: 7px; padding-right: 5px; padding-bottom: 7px; text-align: right; width: 100%;" colspan="2">
                <span style="font-size: 11pt;">
                    <strong>&nbsp;</strong>
                </span>
            </td>
        </tr>
        <tr style="border-bottom-width: 1px; background-color: #ced4d9;">
            <td style="width: 21.9911%; padding: 7px 5px; border-right: 1px solid #bbb; text-align: right;">
                <span style="font-size: 11pt;">
                    <strong>E-FLOW</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding: 7px;">
                <span style="font-size: 11pt;">
                    <strong>CI&Ecirc;NCIA DE CONTE&Uacute;DO</strong>
                </span>
            </td>
        </tr>
    </tbody>
</table>
<table style="border-collapse: collapse; width: 100%; height: 126px;" border="0">
    <tbody>
        <tr style="min-height: 18px;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <span style="font-size: 8pt; color: #666;">
                    <strong>FLUXO</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; font-size: 10pt; word-break: normal; overflow-wrap: normal; white-space: normal;">{|_NomeFluxo_|} (Vers&atilde;o {|_VersaoFluxo_|})</p>
            </td>
        </tr>
        <tr style="min-height: 18px;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <span style="font-size: 8pt; color: #666;">
                    <strong>CAIXA DE FLUXOS</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; font-size: 10pt; word-break: normal; overflow-wrap: normal; white-space: normal;">{|_CaixaFluxos_|}</p>
            </td>
        </tr>
        <tr style="min-height: 18px;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <span style="font-size: 8pt; color: #666;">
                    <strong>TAREFA</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; font-size: 10pt; word-break: normal; overflow-wrap: normal; white-space: normal;">{|_NomeTarefa_|}</p>
            </td>
        </tr>
        <tr style="min-height: 18px;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <span style="font-size: 8pt; color: #666;">
                    <strong>ID DA INST&Acirc;NCIA</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; font-size: 10pt; word-break: normal; overflow-wrap: normal; white-space: normal; text-transform: uppercase;">{|_IdInstancia_|}</p>
            </td>
        </tr>
        <tr style="min-height: 18px;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <span style="font-size: 8pt; color: #666;">
                    <strong>REQUERENTE</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; font-size: 10pt; word-break: normal; overflow-wrap: normal; white-space: normal;">{|_RequerenteInstancia_|}</p>
            </td>
        </tr>
        <tr style="min-height: 18px;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <span style="font-size: 8pt; color: #666;">
                    <strong>CONTE&Uacute;DO AVALIADO</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; font-size: 10pt; word-break: normal; overflow-wrap: normal; white-space: normal;">{|_ConteudoAvaliado_|}</p>
            </td>
        </tr>
        <tr style="min-height: 18px; border-bottom: 1px solid #dddddd;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <span style="font-size: 8pt; color: #666;">
                    <strong>MENSAGEM</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; display: inline-block; font-size: 10pt; background-color: #ecf0f1; padding: 3px 5px; border-radius: 5px; word-break: normal; overflow-wrap: normal; white-space: normal;">{|_Mensagem_|}</p>
            </td>
        </tr>
    </tbody>
</table>
<table style="border-collapse: collapse; width: 100%;" border="0">
    <tbody>
        <tr style="height: 25px;">
            <td style="width: 100%; height: 25px;">&nbsp;</td>
        </tr>
        <tr style="height: 25px;">
            <td style="width: 100%; height: 25px;">&nbsp;</td>
        </tr>
    </tbody>
</table>
<p style="text-align: center; text-transform: uppercase;">
    <strong>{|UsuarioNome|}</strong>
    <br />
    <span style="font-size: 9pt;">
        <strong>{|UsuarioPapel|}</strong>
    </span>
    <br />
    <span style="font-size: 9pt;">
        <strong>{|UsuarioUnidadeNome|} - {|UsuarioUnidadeSigla|} - {|UsuarioOrgaoSigla|}</strong>
    </span>
</p>
`;
