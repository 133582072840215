import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'pdf-preview-dialog',
    templateUrl: './pdf-preview-dialog.component.html',
    styleUrls: ['./pdf-preview-dialog.component.scss']
})
export class PdfPreviewDialogComponent {
    safeUrl: SafeResourceUrl;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: string,
        private dialogRef: MatDialogRef<PdfPreviewDialogComponent>,
        private domSanitizer: DomSanitizer
    ) {
        this.safeUrl = domSanitizer.bypassSecurityTrustResourceUrl(data);
    }

    // ======================
    // lifecycle methods
    // ======================

    // ======================
    // public methods
    // ======================

    close() {
        this.dialogRef.close();
    }

    // ======================
    // private methods
    // ======================
}
