import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { Enums } from '../../shared/enums';
import { Utils } from '../../shared/utils';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        public router: Router,
        private authService: AuthService,
        private toastr: ToastrService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    // ======================
    // public methods
    // ======================

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.allowAccess(next.data);
    }

    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.allowAccess(next.data);
    }

    // ======================
    // private methods
    // ======================

    private allowAccess(routeData: any): Promise<boolean> {
        return this.authService.getStatus()
            .then(async response => {
                if (routeData.isLandingPage) {
                    if (!response.data.isAuthenticated) {
                        return true;
                    } else {
                        this.router.navigate([Enums.PagesPaths.Home]);
                    }
                }

                if (!response.isSuccess || !response.data.isAuthenticated) {
                    this.authService.login();
                    return false;
                }

                if (!this.authService.user) {
                    await this.authService.getUserProfile(true);
                }

                if (routeData?.profiles) {
                    if (routeData.profiles.includes(Enums.Profiles.ErrorPage)) {
                        return true;
                    }

                    let remainingProfiles = [...routeData.profiles];

                    if (routeData.profiles.includes(Enums.Profiles.Administrator)) {
                        if (!this.authService.isAdmin()) {
                            this.toastr.error(Enums.Messages.NoAccessToResource, Enums.Messages.AccessDenied, Utils.getToastrErrorOptions());
                            this.router.navigate([Enums.PagesPaths.Error]);
                            return false;
                        }

                        remainingProfiles = routeData.profiles.filter(x => x != Enums.Profiles.Administrator);
                    }

                    if (remainingProfiles.length > 0) {
                        return this.authService.hasProfiles(remainingProfiles);
                    }
                }

                return true;
            })
            .catch(error => {
                this.authService.login();
                return false;
            });
    }
}
