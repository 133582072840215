import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild
} from '@angular/core';
import {
    AccessLevelType,
    AccessLevelTypeDescription,
    DocumentLegalValueCombination,
    DocumentLegalValueCombinationDescription
} from '../../../../models/edocs.model';
import { FlowObjectDefinition } from '../../../../models/flow-object.model';
import { FORMBUILDER_OPTIONS_DEFAULT } from '../../../formio/formbuilder-options-default';
import { Components, Templates, Formio } from '@formio/angular';
import { EDocsService } from '../../../../services/edocs.service';
import { AuthService } from '../../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../../../shared/enums';
import { Utils } from '../../../../shared/utils';
import { MatDialog } from '@angular/material/dialog';
import { TemplateRef } from '@angular/core';
import { FORMBUILDER_SCHEMA_DEFAULT } from '../../../formio/formbuilder-schema-default';
import { IBaseOption } from '../../../../models/base.model';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { FormBuilderTemplates } from '../../../formio/formbuilder-templates';
import { FlowDefinition } from '../../../../models/flow.model';

@Component({
    selector: 'flow-object-details-form',
    templateUrl: './flow-object-details-form.component.html',
    styleUrls: ['./flow-object-details-form.component.scss']
})
export class FlowObjectDetailsFormComponent implements OnInit {
    // #region [ViewChild]
    @ViewChild('wrapperRef') wrapperRef: ElementRef;
    @ViewChild('formSchemaRef') formSchemaRef: TemplateRef<HTMLElement>;
    @ViewChild('importCsvRef') importCsvRef: TemplateRef<HTMLElement>;
    @ViewChild('formSchemaEditorRef') formSchemaEditorRef: ElementRef;
    // #endregion

    // #region [Type properties]
    Utils: typeof Utils = Utils;
    AccessLevelType: typeof AccessLevelType = AccessLevelType;
    AccessLevelTypeDescription: typeof AccessLevelTypeDescription = AccessLevelTypeDescription;
    DocumentLegalValueCombination: typeof DocumentLegalValueCombination = DocumentLegalValueCombination;
    DocumentLegalValueCombinationDescription: typeof DocumentLegalValueCombinationDescription = DocumentLegalValueCombinationDescription;
    // #endregion

    // #region [properties]
    model: FlowObjectDefinition;
    formSchema: any = FORMBUILDER_SCHEMA_DEFAULT;
    options: any = FORMBUILDER_OPTIONS_DEFAULT;
    selectedDropdown: string;
    availableDropdownsOptions: IBaseOption[] = [];
    csvFile: any;
    isFormioBugProcessed: boolean = false;
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowObjectDefinition;
    @Output() inputModelChange = new EventEmitter<FlowObjectDefinition>();

    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Output() outputSubmitEvent = new EventEmitter<FlowObjectDefinition>();
    @Output() outputCloseEvent = new EventEmitter<any>();
    // #endregion

    constructor(
        private csvParser: NgxCsvParser,
        private dialog: MatDialog,
        private toastr: ToastrService,
        private authService: AuthService,
        private edocsService: EDocsService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        // #region [injeção dos métodos para se obter as opções de dropdowns do E-Docs em componentes do tipo "PdfUpload"/"PdfUploadMultiple" no FormBuilder]
        let _this = this;

        let pdfUploadComponents = this.options.editForm.pdfUpload.find(x => x.key == 'display').components as any[];
        pdfUploadComponents = pdfUploadComponents.concat(this.options.editForm.pdfUploadMultiple.find(x => x.key == 'display').components as any[]);

        pdfUploadComponents.filter(x => x.key == 'documentLegalValueCombination').forEach(x => {
            x.data = {
                custom(context) {
                    let documentLegalValueCombinations = [];

                    for (let item in DocumentLegalValueCombination) {
                        if (!isNaN(parseInt(item))) {
                            documentLegalValueCombinations.push({
                                value: +item,
                                label: DocumentLegalValueCombinationDescription.get(+item)
                            });
                        }
                    }

                    return documentLegalValueCombinations;
                }
            }
        });

        pdfUploadComponents.filter(x => x.key == 'accessLevel').forEach(x => {
            x.data = {
                custom(context) {
                    let accessLevels = [];

                    for (let item in AccessLevelType) {
                        if (!isNaN(parseInt(item)) && +item != AccessLevelType.Classified) {
                            accessLevels.push({
                                value: +item,
                                label: AccessLevelTypeDescription.get(+item)
                            });
                        }
                    }

                    return accessLevels;
                }
            }
        });

        pdfUploadComponents.filter(x => x.key == 'activePlans').forEach(x => {
            x.customConditional = context => {
                return context.data.documentLegalValueCombination.value == DocumentLegalValueCombination.ServidorDocumentoEletronicoAssinadoEletronicamente;
            };

            x.data = {
                custom(context) {
                    const getValues = async context => {
                        const patriarchId = _this.inputFlowDefinition.patriarchId;
                        const response = await _this.edocsService.getPlanosAtivos(patriarchId);

                        if (!response.isSuccess) {
                            _this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                            return;
                        }

                        let activePlans = response.data.sort((a, b) => {
                            let first = a.codigo || a.nome || '';
                            let second = b.codigo || b.nome || '';
                            return first.localeCompare(second);
                        });

                        return activePlans.map(x => ({
                            value: x.id,
                            label: `${x.codigo} - ${x.nome.toUpperCase()}`
                        }));
                    };

                    getValues(context).then(response => {
                        context.instance.setItems(response, true);
                    });
                }
            }
        });

        pdfUploadComponents.filter(x => x.key == 'documentClass').forEach(x => {
            x.customConditional = context => {
                return context.data.documentLegalValueCombination.value == DocumentLegalValueCombination.ServidorDocumentoEletronicoAssinadoEletronicamente
                    && JSON.stringify(context.data.activePlans) != JSON.stringify({});
            };

            x.data = {
                custom(context) {
                    const getValues = async context => {
                        const response = await _this.edocsService.getClassesAtivas(context.data.activePlans.value);

                        if (!response.isSuccess) {
                            _this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                            return;
                        }

                        // filtrando apenas as classes e não seus agrupadores
                        let documentClasses = response.data.filter(x =>
                            // XX.XX.XX.XX
                            x.codigo.split('.').length == 4
                            // XX.XXXX
                            || x.codigo.split('.').slice(-1)[0].length == 4
                        ).sort((a, b) => `${a.codigo.trim()} - ${a.nome.trim()}`.localeCompare(`${b.codigo.trim()} - ${b.nome.trim()}`));

                        return documentClasses.map(x => ({
                            value: x.id,
                            label: `${x.codigo.trim()} - ${x.nome.trim()}`
                        }));
                    };

                    getValues(context).then(response => {
                        context.instance.setItems(response, true);
                    });
                }
            }
        });

        pdfUploadComponents.filter(x => x.key == 'legalReasoning').forEach(x => {
            x.customConditional = context => {
                return context.data.accessLevel.value == AccessLevelType.Confidential;
            };

            x.data = {
                custom(context) {
                    const getValues = async context => {
                        let response = null;
                        if (_this.inputFlowDefinition.targetId == Enums.FlowTarget.Citizen) {
                            response = await _this.edocsService.getFundamentosLegaisCidadao();
                        } else {
                            const patriarchId = _this.inputFlowDefinition.organizationId;
                            response = await _this.edocsService.getFundamentosLegaisPatriarca(patriarchId);
                        }

                        if (!response.isSuccess) {
                            _this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                            return;
                        }

                        let legalReasonings = response.data.sort((a, b) => a.ordenacao - b.ordenacao).filter(x => x.nivelAcesso.toUpperCase() == "SIGILOSO");

                        return legalReasonings.map(x => ({
                            value: x.id,
                            label: `${x.nivelAcesso.trim()}: ${x.nome.trim()}`
                        }));
                    };

                    getValues(context).then(response => {
                        context.instance.setItems(response, true);
                    });
                }
            }
        });
        // #endregion

        // #region [configuração do FormSchema]
        const textfieldComponent = Components.components.textfield;
        const textfieldSchema = textfieldComponent.schema;
        Components.components.textfield.schema = (...extend) => {
            //@ts-ignore
            return textfieldSchema({ label: null }, ...extend);
        };

        class Cpf extends textfieldComponent {
            static schema(...extend) {
                return textfieldComponent.schema({
                    type: 'cpf',
                    label: null,
                    key: 'cpf',
                    inputMask: '999.999.999-99',
                    validate: {
                        custom: "function isCpfValid(cpf) { cpf = cpf.replace(/[^\\d]+/g,''); if (cpf == '') return false; if (cpf.length != 11 || cpf == '00000000000' || cpf == '11111111111' || cpf == '22222222222' || cpf == '33333333333' || cpf == '44444444444' || cpf == '55555555555' || cpf == '66666666666' || cpf == '77777777777' || cpf == '88888888888' || cpf == '99999999999' ) return false; add = 0; for (i=0; i < 9; i ++) add += parseInt(cpf.charAt(i)) * (10 - i); rev = 11 - (add % 11); if (rev == 10 || rev == 11) rev = 0; if (rev != parseInt(cpf.charAt(9))) return false; add = 0; for (i = 0; i < 10; i ++) add += parseInt(cpf.charAt(i)) * (11 - i); rev = 11 - (add % 11); if (rev == 10 || rev == 11) rev = 0; if (rev != parseInt(cpf.charAt(10))) return false; return true; }\r\nvalid = !component.validate?.required && (input == null || input == '') ? true : isCpfValid(input) ? true : 'CPF inválido';"
                    }
                });
            }

            static get builderInfo() {
                return {
                    title: 'CPF',
                    icon: 'fas fa-id-card',
                    group: 'advanced',
                    weight: 21,
                    schema: Cpf.schema()
                };
            }
        }

        class Cnpj extends textfieldComponent {
            static schema(...extend) {
                return textfieldComponent.schema({
                    type: 'cnpj',
                    label: null,
                    key: 'cnpj',
                    inputMask: '99.999.999/9999-99',
                    validate: {
                        custom: "function isCnpjValid(cnpj) { cnpj = cnpj.replace(/[^\\d]+/g,''); if (cnpj == '') return false; if (cnpj.length != 14) return false; if (cnpj == \"00000000000000\" || cnpj == \"11111111111111\" || cnpj == \"22222222222222\" || cnpj == \"33333333333333\" || cnpj == \"44444444444444\" || cnpj == \"55555555555555\" || cnpj == \"66666666666666\" || cnpj == \"77777777777777\" || cnpj == \"88888888888888\" || cnpj == \"99999999999999\") return false; tamanho = cnpj.length - 2; numeros = cnpj.substring(0,tamanho); digitos = cnpj.substring(tamanho); soma = 0; pos = tamanho - 7; for (i = tamanho; i >= 1; i--) { soma += numeros.charAt(tamanho - i) * pos--; if (pos < 2) pos = 9; } resultado = soma % 11 < 2 ? 0 : 11 - soma % 11; if (resultado != digitos.charAt(0)) return false; tamanho = tamanho + 1; numeros = cnpj.substring(0,tamanho); soma = 0; pos = tamanho - 7; for (i = tamanho; i >= 1; i--) { soma += numeros.charAt(tamanho - i) * pos--; if (pos < 2) pos = 9; } resultado = soma % 11 < 2 ? 0 : 11 - soma % 11; if (resultado != digitos.charAt(1)) return false; return true; }\nvalid = !component.validate?.required && (input == null || input == '') ? true : isCnpjValid(input) ? true : 'CNPJ inválido';"
                    }
                });
            }

            static get builderInfo() {
                return {
                    title: 'CNPJ',
                    icon: 'fas fa-suitcase',
                    group: 'advanced',
                    weight: 22,
                    schema: Cnpj.schema()
                };
            }
        }

        class EDocsProtocol extends textfieldComponent {
            static schema(...extend) {
                return textfieldComponent.schema({
                    type: 'eDocsProtocol',
                    label: null,
                    key: 'eDocsProtocol',
                    case: 'uppercase',
                    inputMask: '9999-******'
                });
            }

            static get builderInfo() {
                return {
                    title: 'Protocolo E-Docs',
                    icon: 'fa fa-file',
                    group: 'basic',
                    weight: -10,
                    schema: EDocsProtocol.schema()
                };
            }
        }

        Formio.use({
            components: {
                cpf: Cpf,
                cnpj: Cnpj,
                eDocsProtocol: EDocsProtocol
            }
        });

        const textareaSchema = Components.components.textarea.schema;
        Components.components.textarea.schema = (...extend) => {
            //@ts-ignore
            return textareaSchema({ label: null }, ...extend);
        };

        const numberSchema = Components.components.number.schema;
        Components.components.number.schema = (...extend) => {
            //@ts-ignore
            return numberSchema({ label: null }, ...extend);
        };

        const passwordSchema = Components.components.password.schema;
        Components.components.password.schema = (...extend) => {
            //@ts-ignore
            return passwordSchema({ label: null }, ...extend);
        };

        const checkboxSchema = Components.components.checkbox.schema;
        Components.components.checkbox.schema = (...extend) => {
            //@ts-ignore
            return checkboxSchema({ label: null }, ...extend);
        };

        const selectboxesSchema = Components.components.selectboxes.schema;
        Components.components.selectboxes.schema = (...extend) => {
            //@ts-ignore
            return selectboxesSchema({ label: null }, ...extend);
        };

        const selectComponent = Components.components.select;
        const selectSchema = selectComponent.schema;
        Components.components.select.schema = (...extend) => {
            //@ts-ignore
            return selectSchema({ label: null }, ...extend);
        };

        class SelectRepository extends selectComponent {
            static schema(...extend) {
                return selectComponent.schema({
                    type: 'selectRepository',
                    label: null,
                    key: 'selectRepository',
                    hidden: true,
                    hideLabel: true,
                    dataSrc: 'json',
                    data: {
                        json: [
                            { label: 'Título 1', value: 'valor1', propriedadeDeComparacao: 'valorDeComparacao1' },
                            { label: 'Título 2', value: 'valor2', propriedadeDeComparacao: 'valorDeComparacao2' }
                        ]
                    },
                    valueProperty: 'value'
                });
            }

            static get builderInfo() {
                return {
                    title: 'Lista Repositório',
                    icon: 'fa fa-th-list',
                    group: 'advanced',
                    weight: 70,
                    schema: SelectRepository.schema()
                };
            }
        }

        class SelectFiltered extends selectComponent {
            static schema(...extend) {
                return selectComponent.schema({
                    type: 'selectFiltered',
                    label: null,
                    key: 'selectFiltered',
                    dataSrc: 'custom',
                    data: {
                        custom: "values = form.components.find(x => x.key == 'listaRepositorio').data.json.filter(x => x.propriedadeDeComparacao.toLowerCase() == data['campoDeComparacao'].toLowerCase());"
                    }
                });
            }

            static get builderInfo() {
                return {
                    title: 'Lista Filtrada',
                    icon: 'fa fa-th-list',
                    group: 'advanced',
                    weight: 71,
                    schema: SelectFiltered.schema()
                };
            }
        }

        Formio.use({
            components: {
                selectRepository: SelectRepository,
                selectFiltered: SelectFiltered
            }
        });

        const radioSchema = Components.components.radio.schema;
        Components.components.radio.schema = (...extend) => {
            //@ts-ignore
            return radioSchema({ label: null }, ...extend);
        };

        const buttonSchema = Components.components.button.schema;
        Components.components.button.schema = (...extend) => {
            //@ts-ignore
            return buttonSchema({ label: null }, ...extend);
        };

        const emailSchema = Components.components.email.schema;
        Components.components.email.schema = (...extend) => {
            //@ts-ignore
            return emailSchema({ label: null }, ...extend);
        };

        const urlSchema = Components.components.url.schema;
        Components.components.url.schema = (...extend) => {
            //@ts-ignore
            return urlSchema({ label: null }, ...extend);
        };

        const phoneNumberComponent = Components.components.phoneNumber;
        const phoneNumberSchema = phoneNumberComponent.schema;
        Components.components.phoneNumber.schema = (...extend) => {
            //@ts-ignore
            let override = {
                label: null,
                inputMask: null,
                allowMultipleMasks: true,
                inputMasks: [
                    {
                        label: 'Celular',
                        mask: '(99) 99999-9999'
                    },
                    {
                        label: 'Fixo',
                        mask: '(99) 9999-9999'
                    }
                ]
            };
            //@ts-ignore
            return phoneNumberSchema(override, ...extend);
        };

        class LandlineNumber extends phoneNumberComponent {
            static schema(...extend) {
                return phoneNumberComponent.schema({
                    type: 'landlineNumber',
                    label: null,
                    key: 'landlineNumber',
                    inputMask: '(99) 9999-9999',
                    validate: null,
                    allowMultipleMasks: false,
                    inputMasks: null
                });
            }

            static get builderInfo() {
                return {
                    title: 'Telefone Fixo',
                    icon: 'fas fa-phone-alt',
                    group: 'advanced',
                    weight: 31,
                    schema: LandlineNumber.schema()
                };
            }
        }

        class CellNumber extends phoneNumberComponent {
            static schema(...extend) {
                return phoneNumberComponent.schema({
                    type: 'cellNumber',
                    label: null,
                    key: 'cellNumber',
                    inputMask: '(99) 99999-9999',
                    validate: null,
                    allowMultipleMasks: false,
                    inputMasks: null
                });
            }

            static get builderInfo() {
                return {
                    title: 'Telefone Celular',
                    icon: 'fas fa-mobile-alt',
                    group: 'advanced',
                    weight: 32,
                    schema: CellNumber.schema()
                };
            }
        }
        Formio.use({
            components: {
                landlineNumber: LandlineNumber,
                cellNumber: CellNumber
            }
        });

        const daySchema = Components.components.day.schema;
        Components.components.day.schema = (...extend) => {
            let override = {
                //label: FORMBUILDER_OPTIONS.i18n.pt[daySchema(...extend).label],
                label: null,
                hideInputLabels: true,
                useLocaleSettings: true,
                dayFirst: true,
                fields: {
                    day: {
                        placeholder: 'Dia'
                    },
                    month: {
                        placeholder: 'Mês'
                    },
                    year: {
                        placeholder: 'Ano'
                    }
                }
            };
            //@ts-ignore
            return daySchema(override, ...extend);
        };

        const timeSchema = Components.components.time.schema;
        Components.components.time.schema = (...extend) => {
            //@ts-ignore
            return timeSchema({ label: null }, ...extend);
        };

        const currencySchema = Components.components.currency.schema;
        Components.components.currency.schema = (...extend) => {
            //@ts-ignore
            return currencySchema({ label: null }, ...extend);
        };

        const contentSchema = Components.components.content.schema;
        Components.components.content.schema = (...extend) => {
            //@ts-ignore
            return contentSchema({ label: null }, ...extend);
        };

        const tableComponent = Components.components.table;
        class TableCustom extends tableComponent {
            static schema(...extend) {
                return tableComponent.schema({
                    type: 'tableCustom',
                    label: null,
                    key: 'tableCustom'
                });
            }

            static get builderInfo() {
                return {
                    title: 'Tabela',
                    icon: 'fa fa-table',
                    group: 'advanced',
                    weight: 100,
                    schema: TableCustom.schema()
                };
            }
        }

        const datagridComponent = Components.components.datagrid;
        class DatagridCustom extends datagridComponent {
            static schema(...extend) {
                return datagridComponent.schema({
                    type: 'datagridCustom',
                    label: null,
                    key: 'datagridCustom'
                });
            }

            static get builderInfo() {
                return {
                    title: 'Tabela de Dados',
                    icon: 'fa fa-th',
                    group: 'advanced',
                    weight: 101,
                    schema: DatagridCustom.schema()
                };
            }
        }

        Formio.use({
            components: {
                tableCustom: TableCustom,
                datagridCustom: DatagridCustom
            }
        });

        const builderComponentForm = Templates.current.builderComponent.form;
        Templates.current.builderComponent.form = ctx => FormBuilderTemplates.builderComponentFormTemplate(builderComponentForm, ctx);

        const builderEditFormForm = Templates.current.builderEditForm.form;
        Templates.current.builderEditForm.form = ctx => FormBuilderTemplates.builderEditFormTemplate(builderEditFormForm, ctx);

        const datagridForm = Templates.current.datagrid.form;
        Templates.current.datagrid.form = ctx => FormBuilderTemplates.datagridFormTemplate(datagridForm, ctx);

        //const currencyEditForm = Components.components.currency.editForm;
        //Components.components.currency.editForm = () => {
        //    let editForm = currencyEditForm();
        //    let currency = FormioUtils.getComponent(editForm.components, 'currency', false);
        //    currency.defaultValue = 'BRL';
        //    currency.data.values = [
        //        { label: 'Brazilian Real (BRL)', value: 'BRL' },
        //        { label: 'US Dollar (USD)', value: 'USD' },
        //        { label: 'Euro (EUR)', value: 'EUR' },
        //        { label: 'Pound Sterling (GBP)', value: 'GBP' }
        //    ];

        //    return editForm;
        //};
        // #endregion
    }

    ngOnChanges() {
        this.model = this.inputModel;

        if (!Utils.isNullOrEmpty(this.model?.formSchema)) {
            this.formSchema = JSON.parse(this.model.formSchema);
        }

        this.inputModelChange.emit(this.model);
    }

    // ======================
    // public methods
    // ======================

    onChange(event) {
        switch (event.type) {
            case 'updateComponent':
                if (!this.isFormioBugProcessed) {
                    // contorno de bug do Formio
                    event.form?.components.filter(x => x.type == 'day').forEach(x => x.dayFirst = true);
                    this.isFormioBugProcessed = true;
                }

                break;

            case 'saveComponent':
                if (event.form?.components.length > 0) {
                    event.form.components.forEach(x => {
                        if (this.formSchema.components.length > 0) {
                            this.formSchema.components.filter(y => y.key == x.key).forEach(z => {
                                Object.assign(z, x);
                            });
                        } else {
                            this.formSchema = event.form;
                        }
                    });
                }

                this.model.formSchema = JSON.stringify(this.formSchema);
                this.processConditionalTags();
                break;

            case 'addComponent':
            case 'deleteComponent':
                this.processConditionalTags();
                break;

            default: break;
        }
    }

    onSubmit() {
        if (this.inputIsReadOnlyMode) return false;

        this.model.formSchema = JSON.stringify(this.formSchema);
        this.outputSubmitEvent.emit(this.model);
    }

    closeForm() {
        this.outputCloseEvent.emit();
    }

    /**
     * Contorno de bug do Formio.
     * Componentes Formio customizados criados como componentes Angular tendem a não recarregar
     * naturalmente uma vez que a tag <form-builder> é removida do DOM por algum motivo (neste caso,
     * quando a mat-tab "Construtor de Formulário" perde o foco). Enquanto o problema não é resolvido
     * no Formio (https://github.com/formio/angular/issues/759), a solução adotada é forçar um
     * "soft change" no schema do componente
     */
    debounceCustomComponentsLoading() {
        if (this.model?.formSchema == null || this.formSchema == FORMBUILDER_SCHEMA_DEFAULT) {
            this.wrapperRef.nativeElement.classList.remove('debounce-hidden');
        } else {
            this.formSchema = FORMBUILDER_SCHEMA_DEFAULT;
            setTimeout(() => this.formSchema = JSON.parse(this.model.formSchema), 1);
            setTimeout(() => this.wrapperRef.nativeElement.classList.remove('debounce-hidden'), 700);
        }
    }

    /**
     * Extensão do contorno de bug do Formio relatado em "debounceCustomComponentsLoading"
     */
    notifyFocusOut() {
        this.wrapperRef?.nativeElement.classList.add('debounce-hidden');
    }

    showFormSchema() {
        let backupFormSchema = JSON.stringify(this.formSchema);

        this.formSchema = JSON.stringify(this.formSchema, null, 4);

        let dialog = this.dialog.open(this.formSchemaRef, {
            minWidth: '800px'
        });

        dialog.afterOpened().subscribe(() => {
            setTimeout(() => {
                this.formSchemaEditorRef.nativeElement.scrollLeft = 0;
                this.formSchemaEditorRef.nativeElement.scrollTop = 0;
            }, 1);
            setTimeout(() => {
                this.formSchemaEditorRef.nativeElement.style.opacity = '1';
            }, 100);
        });

        dialog.afterClosed().subscribe(() => {
            let response = Utils.tryParseJson(this.formSchemaEditorRef.nativeElement.value);
            if (!response) {
                this.toastr.error(Enums.Messages.FormSchemaParsingError, Enums.Messages.Error, Utils.getToastrErrorOptions());
                this.formSchema = JSON.parse(backupFormSchema);
            } else {
                this.formSchema = response;
                this.model.formSchema = JSON.stringify(this.formSchema);
            }

            setTimeout(() => this.processConditionalTags(), 2000);
        });
    }

    showImportCsv() {
        let dialog = this.dialog.open(this.importCsvRef, {
            width: '600px',
            panelClass: 'import-csv-dialog'
        });

        dialog.afterOpened().subscribe(() => {
            for (let dropdown of this.formSchema.components.filter(x => x.type == 'select')) {
                this.availableDropdownsOptions.push({
                    value: dropdown.key,
                    description: dropdown.label
                });
            }
        });

        dialog.afterClosed().subscribe(() => {
            this.model.formSchema = JSON.stringify(this.formSchema);
        });
    }

    loadOptions() {
        if (this.csvFile.fileContent[0].length == 0) {
            this.toastr.warning(Enums.Messages.CsvEmptyError, Enums.Messages.Warning, Utils.getToastrErrorOptions());
            return;
        }

        this.csvParser.parse(new File([new Uint8Array(this.csvFile.fileContent[0])], 'default.csv'), {
            header: true,
            delimiter: ';'
        }).pipe().subscribe((result: any[]) => {
            if (result.length == 0) {
                this.toastr.warning(Enums.Messages.CsvNoRecordsError, Enums.Messages.Warning, Utils.getToastrErrorOptions());
                return;
            }

            result.forEach(x => this.formSchema.components.find(y => y.key == this.selectedDropdown).data.values.push({
                label: x.rotulo,
                value: x.valor
            }));

            this.toastr.success(Enums.Messages.CsvImportSuccess, Enums.Messages.Success);
        }, (error: NgxCSVParserError) => {
            this.toastr.error(Enums.Messages.CsvImportError, Enums.Messages.Error, Utils.getToastrErrorOptions());
            console.log('CSVParser Error', error);
        });
    }

    startProcessingConditionalTags() {
        let i = 0;
        let interval = setInterval(() => {
            if (i == 5) {
                clearInterval(interval);
                return;
            }

            this.processConditionalTags();
            i++;
        }, 1500);
    }

    // ======================
    // private methods
    // ======================

    private processConditionalTags() {
        document.querySelectorAll('.tag-conditional').forEach(x => x.remove());

        if (this.formSchema?.components == null) return;

        for (let item of this.formSchema.components) {
            if (!Utils.isNullOrEmpty(item.conditional?.show) || !Utils.isNullOrEmpty(item.customConditional)) {
                let root = document.querySelector(`.formio-component-${item.key}`)?.parentElement;
                if (root == null || root.querySelector('.tag-conditional') != null) continue;

                let element = document.createElement('i');
                element.classList.add('fas');
                element.classList.add('fa-code-branch');
                element.classList.add('tag-conditional');

                if (!Utils.isNullOrEmpty(item.customConditional)) {
                    element.classList.add('advanced');
                    element.setAttribute('title', Enums.Messages.ConditionalFieldAdvanced);
                } else {
                    element.setAttribute('title', Enums.Messages.ConditionalFieldSimple);
                }

                root.appendChild(element);
            }
        }
    }
}
