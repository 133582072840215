<div class="nav-item dropdown p-0">
    <a class="nav-link" matTooltip="Menu Principal" #headerButtonRef>
        <i class="fas fa-bars"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-left" #dropdownMenuRef>
        <!--<a class="entry-row pl-2 py-2"
           matTooltip="Página Inicial"
           [routerLink]="[Enums.PagesPaths.Home]"
           (click)="toggleMenu()"
        >
            <div class="btn btn-default btn-flat p-0">
                <i class="fas fa-home"></i>
                <span>Início</span>
            </div>
        </a>-->
        <a class="entry-row pl-2 py-2"
           matTooltip="Crie um novo Fluxo em uma das Caixas disponíveis para o seu usuário"
           [routerLink]="[Enums.PagesPaths.FlowDefinition]"
           (click)="toggleMenu()"
        >
            <div class="btn btn-default btn-flat p-0">
                <i class="fas fa-plus-circle"></i>
                <span>Criar um Fluxo</span>
            </div>
        </a>
        <a class="entry-row pl-2 py-2"
           matTooltip="Acesse os Fluxos já criados nas Caixas disponíveis para o seu usuário"
           [routerLink]="[Enums.PagesPaths.FlowDefinitionList]"
           (click)="toggleMenu()"
        >
            <div class="btn btn-default btn-flat p-0">
                <i class="fas fa-folder-open"></i>
                <span>Meus Fluxos</span>
            </div>
        </a>
        <a class="entry-row pl-2 py-2"
           matTooltip="Confira algumas estatísticas acerca de como seus Fluxos são instanciados no Portal Público"
           [routerLink]="[Enums.PagesPaths.Dashboard]"
           (click)="toggleMenu()"
        >
            <div class="btn btn-default btn-flat p-0">
                <i class="fas fa-chart-pie"></i>
                <span>Dashboard</span>
            </div>
        </a>
    </div>
</div>
