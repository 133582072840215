import { AfterViewInit, Input } from '@angular/core';
import { Component } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import Shepherd from 'shepherd.js';

@Component({
    selector: 'feature-hint',
    templateUrl: './feature-hint.component.html',
    styleUrls: ['./feature-hint.component.scss']
})
export class FeatureHintComponent implements AfterViewInit {
    // #region [properties]
    isActive: boolean = false;
    addedFeatureHints: string[] = [];
    // #endregion

    // #region [Input/Output]
    @Input() inputIsHintModeOn: boolean = false;
    // #endregion

    constructor(private shepherdService: ShepherdService) { }

    // ======================
    // lifecycle methods
    // ======================

    ngAfterViewInit() {
        setInterval(() => this.processHintMode(), 500);
    }

    // ======================
    // public methods
    // ======================

    startHint(hintId: string, attachTo?: string) {
        if (this.isActive) return;

        // define um novo Tour de passo único para cada Dica
        this.shepherdService.tourObject = new Shepherd.Tour({
            exitOnEsc: true,
            useModalOverlay: true,
            steps: [
                {
                    attachTo: {
                        element: `[featureHint="${hintId}"]`,
                        on: attachTo || 'bottom'
                    },
                    cancelIcon: {
                        enabled: true
                    },
                    canClickTarget: false,
                    modalOverlayOpeningPadding: 6,
                    // lê o conteúdo da Dica diretamente de "feature-hint.component.html"
                    text: document.querySelector(`[featureHintContent~="${hintId}"]`).innerHTML
                }
            ]
        });

        this.isActive = true;

        let interval = setInterval(() => {
            // aguarda até que os elementos necessários estejam disponíveis no DOM
            let elem = document.querySelector(`[featureHint="${hintId}"]`);
            if (elem == null) return;
            clearInterval(interval);

            // esconde os triggers de Dicas enquanto uma Dica é exibida
            document.querySelectorAll('[featureHint] .hint-trigger').forEach(x => x.classList.add('hidden'));

            // inicia a exibição da Dica
            this.shepherdService.tourObject.start();
            this.shepherdService.tourObject.on('cancel', () => {
                this.isActive = false;

                // retorna com os triggers de Dicas quando a Dica corrente é fechada
                document.querySelectorAll('[featureHint] .hint-trigger').forEach(x => x.classList.remove('hidden'));
            });
        }, 300);
    }

    // ======================
    // private methods
    // ======================

    private processHintMode() {
        // Modo Ajuda desligado
        if (!this.inputIsHintModeOn) {
            document.querySelectorAll('[featureHint]').forEach(x => {
                x.classList.remove('hint-mode-on');
                x.querySelectorAll('.hint-trigger').forEach(x => x.remove());

                // restabelece o antigo valor de "z-index" do elemento
                x['style'].zIndex = x.getAttribute('oldZIndex');
            });

            this.addedFeatureHints = [];
            return;
        }
        // Modo Ajuda ligado
        else {
            let hintsToDraw = document.querySelectorAll('[featureHint]');

            // caso algum (o primeiro, se mais de um) grupo exclusivo de Dicas seja identificado,
            // garante que apenas as Dicas do grupo sejam exibidas
            let exclusive = document.querySelector('[featureHintExclusiveGroup]');
            if (exclusive != null) {
                const exclusiveId = exclusive.getAttribute('featureHint').split('|')[0];
                hintsToDraw = document.querySelectorAll(`[featureHint^="${exclusiveId}|"], [featureHint="${exclusiveId}"]`);
            }

            hintsToDraw.forEach(x => {
                // ignora ocasiões de Dicas repetidas
                const hintId = x.getAttribute('featureHint');
                if (document.querySelector(`.hint-trigger[hintId="${hintId}"]`) != null) return;

                // processa as configurações de alinhamento do trigger da Dica
                let vAlign = x.getAttribute('featureHintVAlign');
                vAlign = `v-align-${(vAlign != null ? vAlign : 'middle')}`;
                let hAlign = x.getAttribute('featureHintHAlign');
                hAlign = `h-align-${(hAlign != null ? hAlign : 'center')}`;

                // insere o trigger da Dica no DOM
                let elem = document.createElement('div');
                elem.innerHTML = `<i class="fas fa-lightbulb hint-trigger ${vAlign} ${hAlign}" hintId="${hintId}" title="Exibir dica"></i>`;
                x.appendChild(elem.firstChild);
                x.classList.add('hint-mode-on');

                // garante que o container do trigger da Dica esteja visível
                x.setAttribute('oldZIndex', x['style'].zIndex);
                let computedZIndex = window.getComputedStyle(x).getPropertyValue('z-index');
                x['style'].zIndex = !Number.isInteger(computedZIndex) ? '1' : computedZIndex;
                x['style'].zIndex = (parseFloat(x['style'].zIndex) + 10).toString();

                // garante que o clique no trigger da Dica apenas exiba a Dica
                x.querySelector('.hint-trigger').addEventListener('click', evt => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    this.startHint(hintId, x.getAttribute('featureHintShowOn'));
                });

                this.addedFeatureHints.push(hintId);
            });

            // remove da collection de controle eventuais Dicas que não devem ser exibidas no momento
            this.addedFeatureHints = this.addedFeatureHints.filter(x =>
                [...new Set(Array.from(hintsToDraw).map(y => y.getAttribute('featureHint')))].includes(x)
            );

            // remove do DOM eventuais Dicas que não devem ser exibidas no momento
            document.querySelectorAll('.hint-trigger').forEach(x => {
                if (!this.addedFeatureHints.includes(x.getAttribute('hintId'))) {
                    x.remove();
                }
            });
        }
    }
}
