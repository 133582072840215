import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild
} from '@angular/core';
import {
    AccessLevelType,
    AccessLevelTypeDescription,
    DocumentLegalValueCombination,
    DocumentLegalValueCombinationDescription
} from '../../../../models/edocs.model';
import { FlowObjectDefinition } from '../../../../models/flow-object.model';
import { Templates } from '@formio/angular';
import { EDocsService } from '../../../../services/edocs.service';
import { AuthService } from '../../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../../../shared/enums';
import { Utils } from '../../../../shared/utils';
import { MatDialog } from '@angular/material/dialog';
import { TemplateRef } from '@angular/core';
import { FORMBUILDER_SCHEMA_DEFAULT } from '../../../formio/formbuilder-schema-default';
import { FORMBUILDER_OPTIONS_INBOUND_API } from '../../../formio/formbuilder-options-inbound-api';
import { FormBuilderTemplates } from '../../../formio/formbuilder-templates';
import { FlowDefinition } from '../../../../models/flow.model';

@Component({
    selector: 'flow-object-details-start-inbound-api',
    templateUrl: './flow-object-details-start-inbound-api.component.html',
    styleUrls: ['./flow-object-details-start-inbound-api.component.scss']
})
export class FlowObjectDetailsStartInboundComponent implements OnInit {
    // #region [ViewChild]
    @ViewChild('wrapperRef') wrapperRef: ElementRef;
    @ViewChild('formSchemaRef') formSchemaRef: TemplateRef<HTMLElement>;
    @ViewChild('formSchemaEditorRef') formSchemaEditorRef: ElementRef;
    // #endregion

    // #region [Type properties]
    Utils: typeof Utils = Utils;
    AccessLevelType: typeof AccessLevelType = AccessLevelType;
    AccessLevelTypeDescription: typeof AccessLevelTypeDescription = AccessLevelTypeDescription;
    DocumentLegalValueCombination: typeof DocumentLegalValueCombination = DocumentLegalValueCombination;
    DocumentLegalValueCombinationDescription: typeof DocumentLegalValueCombinationDescription = DocumentLegalValueCombinationDescription;
    // #endregion

    // #region [properties]
    model: FlowObjectDefinition;
    formSchema: any = FORMBUILDER_SCHEMA_DEFAULT;
    options: any = FORMBUILDER_OPTIONS_INBOUND_API;
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowObjectDefinition;
    @Output() inputModelChange = new EventEmitter<FlowObjectDefinition>();

    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Output() outputSubmitEvent = new EventEmitter<FlowObjectDefinition>();
    @Output() outputCloseEvent = new EventEmitter<any>();
    // #endregion

    constructor(
        private dialog: MatDialog,
        private toastr: ToastrService,
        private authService: AuthService,
        private edocsService: EDocsService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        // #region [injeção dos métodos para se obter as opções de dropdowns do E-Docs em componentes do tipo "PdfUpload"/"PdfUploadMultiple" no FormBuilder]
        let _this = this;

        let pdfUploadComponents = this.options.editForm.pdfUpload.find(x => x.key == 'display').components as any[];
        pdfUploadComponents = pdfUploadComponents.concat(this.options.editForm.pdfUploadMultiple.find(x => x.key == 'display').components as any[]);

        pdfUploadComponents.filter(x => x.key == 'documentLegalValueCombination').forEach(x => {
            x.data = {
                custom(context) {
                    let documentLegalValueCombinations = [];

                    for (let item in DocumentLegalValueCombination) {
                        if (!isNaN(parseInt(item))) {
                            documentLegalValueCombinations.push({
                                value: +item,
                                label: DocumentLegalValueCombinationDescription.get(+item)
                            });
                        }
                    }

                    return documentLegalValueCombinations;
                }
            }
        });

        pdfUploadComponents.filter(x => x.key == 'accessLevel').forEach(x => {
            x.data = {
                custom(context) {
                    let accessLevels = [];

                    for (let item in AccessLevelType) {
                        if (!isNaN(parseInt(item)) && +item != AccessLevelType.Classified) {
                            accessLevels.push({
                                value: +item,
                                label: AccessLevelTypeDescription.get(+item)
                            });
                        }
                    }

                    return accessLevels;
                }
            }
        });

        pdfUploadComponents.filter(x => x.key == 'activePlans').forEach(x => {
            x.customConditional = context => {
                return context.data.documentLegalValueCombination.value == DocumentLegalValueCombination.ServidorDocumentoEletronicoAssinadoEletronicamente;
            };

            x.data = {
                custom(context) {
                    const getValues = async context => {
                        const patriarchId = _this.inputFlowDefinition.organizationId;
                        const response = await _this.edocsService.getPlanosAtivos(patriarchId);

                        if (!response.isSuccess) {
                            _this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                            return;
                        }

                        let activePlans = response.data.sort((a, b) => {
                            let first = a.codigo || a.nome || '';
                            let second = b.codigo || b.nome || '';
                            return first.localeCompare(second);
                        });

                        return activePlans.map(x => ({
                            value: x.id,
                            label: `${x.codigo} - ${x.nome.toUpperCase()}`
                        }));
                    };

                    getValues(context).then(response => {
                        context.instance.setItems(response, true);
                    });
                }
            }
        });

        pdfUploadComponents.filter(x => x.key == 'documentClass').forEach(x => {
            x.customConditional = context => {
                return context.data.documentLegalValueCombination.value == DocumentLegalValueCombination.ServidorDocumentoEletronicoAssinadoEletronicamente
                    && JSON.stringify(context.data.activePlans) != JSON.stringify({});
            };

            x.data = {
                custom(context) {
                    const getValues = async context => {
                        const response = await _this.edocsService.getClassesAtivas(context.data.activePlans.value);

                        if (!response.isSuccess) {
                            _this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                            return;
                        }

                        // filtrando apenas as classes e não seus agrupadores
                        let documentClasses = response.data.filter(x =>
                            // XX.XX.XX.XX
                            x.codigo.split('.').length == 4
                            // XX.XXXX
                            || x.codigo.split('.').slice(-1)[0].length == 4
                        ).sort((a, b) => `${a.codigo.trim()} - ${a.nome.trim()}`.localeCompare(`${b.codigo.trim()} - ${b.nome.trim()}`));

                        return documentClasses.map(x => ({
                            value: x.id,
                            label: `${x.codigo.trim()} - ${x.nome.trim()}`
                        }));
                    };

                    getValues(context).then(response => {
                        context.instance.setItems(response, true);
                    });
                }
            }
        });

        pdfUploadComponents.filter(x => x.key == 'legalReasoning').forEach(x => {
            x.customConditional = context => {
                return context.data.accessLevel.value == AccessLevelType.Confidential;
            };

            x.data = {
                custom(context) {
                    const getValues = async context => {
                        let response = null;
                        if (_this.inputFlowDefinition.targetId == Enums.FlowTarget.Citizen) {
                            response = await _this.edocsService.getFundamentosLegaisCidadao();
                        } else {
                            const patriarchId = _this.inputFlowDefinition.organizationId;
                            response = await _this.edocsService.getFundamentosLegaisPatriarca(patriarchId);
                        }

                        if (!response.isSuccess) {
                            _this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                            return;
                        }

                        let legalReasonings = response.data.sort((a, b) => a.ordenacao - b.ordenacao).filter(x => x.nivelAcesso.toUpperCase() == "SIGILOSO");

                        return legalReasonings.map(x => ({
                            value: x.id,
                            label: `${x.nivelAcesso.trim()}: ${x.nome.trim()}`
                        }));
                    };

                    getValues(context).then(response => {
                        context.instance.setItems(response, true);
                    });
                }
            }
        });
        // #endregion

        const builderComponentForm = Templates.current.builderComponent.form;
        Templates.current.builderComponent.form = ctx => FormBuilderTemplates.builderComponentFormTemplate(builderComponentForm, ctx);

        const builderEditFormForm = Templates.current.builderEditForm.form;
        Templates.current.builderEditForm.form = ctx => FormBuilderTemplates.builderEditFormTemplate(builderEditFormForm, ctx);

        const datagridForm = Templates.current.datagrid.form;
        Templates.current.datagrid.form = ctx => FormBuilderTemplates.datagridFormTemplate(datagridForm, ctx);
    }

    ngOnChanges() {
        this.model = this.inputModel;

        if (!Utils.isNullOrEmpty(this.model?.formSchema)) {
            this.formSchema = JSON.parse(this.model.formSchema);
        }

        this.inputModelChange.emit(this.model);
    }

    // ======================
    // public methods
    // ======================

    onChange(event) {
        if (event.form?.components.length > 0) {
            event.form.components.forEach(x => {
                if (this.formSchema.components.length > 0) {
                    this.formSchema.components.filter(y => y.key == x.key).forEach(z => {
                        Object.assign(z, x);
                    });
                } else {
                    this.formSchema = event.form;
                }
            });
        }

        this.model.formSchema = JSON.stringify(this.formSchema);
    }

    /**
     * Contorno de bug do Formio.
     * Componentes Formio customizados criados como componentes Angular tendem a não recarregar
     * naturalmente uma vez que a tag <form-builder> é removida do DOM por algum motivo (neste caso,
     * quando a mat-tab "Construtor de Formulário" perde o foco). Enquanto o problema não é resolvido
     * no Formio (https://github.com/formio/angular/issues/759), a solução adotada é forçar um
     * "soft change" no schema do componente
     */
    debounceCustomComponentsLoading() {
        if (this.model?.formSchema == null || this.formSchema == FORMBUILDER_SCHEMA_DEFAULT) {
            this.wrapperRef.nativeElement.classList.remove('debounce-hidden');
        } else {
            this.formSchema = FORMBUILDER_SCHEMA_DEFAULT;
            setTimeout(() => this.formSchema = JSON.parse(this.model.formSchema), 1);
            setTimeout(() => this.wrapperRef.nativeElement.classList.remove('debounce-hidden'), 700);
        }
    }

    /**
     * Extensão do contorno de bug do Formio relatado em "debounceCustomComponentsLoading"
     */
    notifyFocusOut() {
        this.wrapperRef?.nativeElement.classList.add('debounce-hidden');
    }

    showFormSchema() {
        this.formSchema = JSON.stringify(this.formSchema, null, 4);

        let dialog = this.dialog.open(this.formSchemaRef, {
            minWidth: '800px'
        });

        dialog.afterOpened().subscribe(() => {
            setTimeout(() => {
                this.formSchemaEditorRef.nativeElement.scrollLeft = 0;
                this.formSchemaEditorRef.nativeElement.scrollTop = 0;
            }, 1);
            setTimeout(() => {
                this.formSchemaEditorRef.nativeElement.style.opacity = '1';
            }, 100);
        });

        dialog.afterClosed().subscribe(() => {
            this.formSchema = JSON.parse(this.formSchemaEditorRef.nativeElement.value);
            this.formSchema.components = this.formSchema.components.filter(x => ['pdfUpload', 'pdfUploadMultiple', 'button'].includes(x.type));
            this.model.formSchema = JSON.stringify(this.formSchema);
        });
    }

    onSubmit() {
        if (this.inputIsReadOnlyMode) return false;

        this.model.formSchema = JSON.stringify(this.formSchema);
        this.outputSubmitEvent.emit(this.model);
    }

    closeForm() {
        this.outputCloseEvent.emit();
    }

    // ======================
    // private methods
    // ======================
}
