import {
    FlowObjectType,
    FlowObjectDefinition
} from '../../../../models/flow-object.model';
import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ViewChild
} from '@angular/core';
import {
    ConfigSchema,
    ConfigSchemaSectionAccessLevel,
    ConfigSchemaSectionDocumentClass,
    ConfigSchemaTaskAcknowledge,
    ConfigSchemaTaskApprove,
    ConfigSchemaTaskForm
} from '../../../../models/config-schema.model';
import { AccessLevelType } from '../../../../models/edocs.model';
import { FlowDefinition } from '../../../../models/flow.model';
import { Utils } from '../../../../shared/utils';
import { AccessLevelComponent } from '../edocs-fields/access-level/access-level.component';

@Component({
    selector: 'flow-object-details-document',
    templateUrl: './flow-object-details-document.component.html',
    styleUrls: ['./flow-object-details-document.component.scss']
})
export class FlowObjectDetailsDocumentComponent implements OnInit {
    @ViewChild('accessLevelRef') accessLevelRef: AccessLevelComponent;

    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    AccessLevelType: typeof AccessLevelType = AccessLevelType;
    Utils: typeof Utils = Utils;

    configSchemaSectionAccessLevel: ConfigSchemaSectionAccessLevel = new ConfigSchemaSectionAccessLevel();
    configSchemaSectionDocumentClass: ConfigSchemaSectionDocumentClass = new ConfigSchemaSectionDocumentClass();

    @Input() inputConfigSchema: ConfigSchema;
    @Output() inputConfigSchemaChange = new EventEmitter<ConfigSchema>();

    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputFlowObjectDefinition: FlowObjectDefinition;
    @Input() inputIsReadOnlyMode: boolean;

    constructor() { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        this.resolveConfigSchema();

        for (let prop in this.configSchemaSectionAccessLevel) {
            if (this.inputFlowObjectDefinition.typeId == FlowObjectType.TaskAcknowledge) {
                this.configSchemaSectionAccessLevel[prop] = this.inputConfigSchema.taskAcknowledge.accessLevel[prop] || this.configSchemaSectionAccessLevel[prop];
            } else if (this.inputFlowObjectDefinition.typeId == FlowObjectType.GatewayApprove) {
                this.configSchemaSectionAccessLevel[prop] = this.inputConfigSchema.taskApprove.accessLevel[prop] || this.configSchemaSectionAccessLevel[prop];
            } else {
                this.configSchemaSectionAccessLevel[prop] = this.inputConfigSchema.taskForm.accessLevel[prop] || this.configSchemaSectionAccessLevel[prop];
            }
        }

        for (let prop in this.configSchemaSectionDocumentClass) {
            if (this.inputFlowObjectDefinition.typeId == FlowObjectType.TaskAcknowledge) {
                this.configSchemaSectionDocumentClass[prop] = this.inputConfigSchema.taskAcknowledge.documentClass[prop] || this.configSchemaSectionDocumentClass[prop];
            } else if (this.inputFlowObjectDefinition.typeId == FlowObjectType.GatewayApprove) {
                this.configSchemaSectionDocumentClass[prop] = this.inputConfigSchema.taskApprove.documentClass[prop] || this.configSchemaSectionDocumentClass[prop];
            } else {
                this.configSchemaSectionDocumentClass[prop] = this.inputConfigSchema.taskForm.documentClass[prop] || this.configSchemaSectionDocumentClass[prop];
            }
        }
    }

    // ======================
    // public methods
    // ======================

    updateSourceCode(forcedConfigSchema: ConfigSchema) {
        this.inputConfigSchema = forcedConfigSchema;

        if (this.inputFlowObjectDefinition.typeId == FlowObjectType.TaskAcknowledge) {
            Object.assign(this.configSchemaSectionAccessLevel, this.inputConfigSchema.taskAcknowledge.accessLevel);
            Object.assign(this.configSchemaSectionDocumentClass, this.inputConfigSchema.taskAcknowledge.documentClass);
        } else if (this.inputFlowObjectDefinition.typeId == FlowObjectType.GatewayApprove) {
            Object.assign(this.configSchemaSectionAccessLevel, this.inputConfigSchema.taskApprove.accessLevel);
            Object.assign(this.configSchemaSectionDocumentClass, this.inputConfigSchema.taskApprove.documentClass);
        } else {
            Object.assign(this.configSchemaSectionAccessLevel, this.inputConfigSchema.taskForm.accessLevel);
            Object.assign(this.configSchemaSectionDocumentClass, this.inputConfigSchema.taskForm.documentClass);
        }

        this.accessLevelRef?.initLegalReasoningsInfo();
    }

    onUpdateAccessLevel(event: ConfigSchemaSectionAccessLevel) {
        Object.assign(this.configSchemaSectionAccessLevel, event);

        if (this.inputFlowObjectDefinition.typeId == FlowObjectType.TaskAcknowledge) {
            Object.assign(this.inputConfigSchema.taskAcknowledge.accessLevel, this.configSchemaSectionAccessLevel);
        } else if (this.inputFlowObjectDefinition.typeId == FlowObjectType.GatewayApprove) {
            Object.assign(this.inputConfigSchema.taskApprove.accessLevel, this.configSchemaSectionAccessLevel);
        } else {
            Object.assign(this.inputConfigSchema.taskForm.accessLevel, this.configSchemaSectionAccessLevel);
        }
    }

    onUpdateDocumentClass(event: ConfigSchemaSectionDocumentClass) {
        Object.assign(this.configSchemaSectionDocumentClass, event);

        if (this.inputFlowObjectDefinition.typeId == FlowObjectType.TaskAcknowledge) {
            Object.assign(this.inputConfigSchema.taskAcknowledge.documentClass, this.configSchemaSectionDocumentClass);
        } else if (this.inputFlowObjectDefinition.typeId == FlowObjectType.GatewayApprove) {
            Object.assign(this.inputConfigSchema.taskApprove.documentClass, this.configSchemaSectionDocumentClass);
        } else {
            Object.assign(this.inputConfigSchema.taskForm.documentClass, this.configSchemaSectionDocumentClass);
        }
    }

    // ======================
    // private methods
    // ======================

    private resolveConfigSchema() {
        this.inputConfigSchema.taskApprove = this.inputConfigSchema.taskApprove || new ConfigSchemaTaskApprove();
        this.inputConfigSchema.taskAcknowledge = this.inputConfigSchema.taskAcknowledge || new ConfigSchemaTaskAcknowledge();
        this.inputConfigSchema.taskForm = this.inputConfigSchema.taskForm || new ConfigSchemaTaskForm();

        if (this.inputFlowObjectDefinition.typeId != FlowObjectType.StartForm) {
            if (this.inputFlowObjectDefinition.typeId == FlowObjectType.GatewayApprove) {
                if (
                    Utils.areEqualObjects(this.inputConfigSchema.taskApprove, new ConfigSchemaTaskApprove())
                    && !Utils.areEqualObjects(this.inputConfigSchema.taskForm, new ConfigSchemaTaskForm())
                ) {
                    Object.assign(this.inputConfigSchema.taskApprove, this.inputConfigSchema.taskForm);
                }
            } else if (this.inputFlowObjectDefinition.typeId == FlowObjectType.TaskAcknowledge) {
                if (
                    Utils.areEqualObjects(this.inputConfigSchema.taskAcknowledge, new ConfigSchemaTaskAcknowledge())
                    && !Utils.areEqualObjects(this.inputConfigSchema.taskForm, new ConfigSchemaTaskForm())
                ) {
                    Object.assign(this.inputConfigSchema.taskAcknowledge, this.inputConfigSchema.taskForm);
                }
            }

            this.inputConfigSchema.taskForm = new ConfigSchemaTaskForm();
        }
    }
}
