import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output
} from '@angular/core';
import {
    FlowObjectType,
    FlowObjectDefinition,
    FormSchema
} from '../../../../models/flow-object.model';
import {
    Organizacao,
    Unidade
} from '../../../../models/organograma.model';
import {
    ConjuntoGrupo,
    TipoFiltro
} from '../../../../models/acesso-cidadao.model';
import {
    ComissoesFilter,
    GruposFilter,
    OrganizacoesFilter,
    PatriarcasFilter,
    UnidadesFilter
} from '../flow-object-details.pipe';
import {
    ApiResolver,
    AuthenticationModel,
    AuthenticationSchemeType,
    AuthenticationSchemeTypeDescription,
    ConfigSchema,
    HttpMethod,
    HttpMethodDescription,
    LocationType
} from '../../../../models/config-schema.model';
import { FlowDefinition } from '../../../../models/flow.model';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../../../shared/enums';
import { FLOW_OBJECT_DETAILS_OUTBOUND_API_TINYMCE_OPTIONS } from './flow-object-details-outbound-api-tinymce-options';
import { Utils } from '../../../../shared/utils';
import { OrganogramaService } from '../../../../services/organograma.service';
import { AcessoCidadaoService } from '../../../../services/acesso-cidadao.service';
import { IBaseOption } from '../../../../models/base.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FlowObjectDefinitionService } from '../../../../services/flow-object-definition.service';
import { Patriarca } from '../../../../models/edocs.model';
import { EDocsService } from '../../../../services/edocs.service';

@Component({
    selector: 'flow-object-details-outbound-api',
    templateUrl: './flow-object-details-outbound-api.component.html',
    styleUrls: ['./flow-object-details-outbound-api.component.scss']
})
export class FlowObjectDetailsOutboundApiComponent implements OnInit {
    // #region [Type properties]
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    AuthenticationSchemeType: typeof AuthenticationSchemeType = AuthenticationSchemeType;
    // #endregion

    // #region [properties]
    FormTypeStartTag = '{$|form|$}_' as const;
    FormTypeSeparatorTag = '{$|_|$}' as const;
    FormTypeEndTag = '_{$|form|$}' as const;
    PairValueTypeValues = {
        String: '{$|string|$}',
        Number: '{$|number|$}',
        Boolean: '{$|bool|$}',
        Null: '{$|null|$}',
        EDocs: '{$|edocs|$}',
        Form: `${this.FormTypeStartTag}{0}${this.FormTypeSeparatorTag}{1}${this.FormTypeEndTag}`,
        CPF: '{$|cpf|$}',
    } as const;
    apiResponseExample = {
        'sucesso': true,
        'mensagem': 'Certificado de Conclusão de Curso emitido com sucesso.'
    } as const;

    model: FlowObjectDefinition;
    configSchema: ConfigSchema = new ConfigSchema();
    url: string = '';
    pairKey: string = '';
    pairValue: string = '';
    pairValueType: string;
    id: string = '';
    fullJson: string = '';
    jsonToEdit: string = '';
    publicSystemName: string = '';
    keyValuePairsArray: any[] = [];
    keyValuePairsObject: any = {};
    formFlowObjectEntries: any[] = [];
    formFlowObject: FlowObjectDefinition;
    tinyMceOptions: any = FLOW_OBJECT_DETAILS_OUTBOUND_API_TINYMCE_OPTIONS;
    showJsonView: boolean = false;
    previousShowJsonView: boolean = false;
    authentication: AuthenticationModel = new AuthenticationModel();
    ignoreErrors: boolean = false;
    errorMessage?: string = null;
    showParametersUrl = false;
    parameterKey: string;
    parameterKeyType: string;
    keyValueParametersArray: any[] = [];
    cpfParameterTip = 'e.g. "{{cpf}}"';
    formParameterTip = 'e.g. "{{matricula}}"';
    useUrlParameters = false;
    authenticationSchemeOptions: IBaseOption[] = [];
    selectedHttpMethod: HttpMethod = HttpMethod.Get;
    httpMethodOptions: IBaseOption[] = [];
    openTabs: number[] = [1];
    resolver: ApiResolver = new ApiResolver();
    // #endregion

    // #region [Patriarca]
    patriarcaId: string = '';
    patriarcaDisplay: string = '';
    patriarcaNome: string = null;
    patriarcas: Patriarca[] = [];
    selectablePatriarcas: Patriarca[];
    // #endregion
    // #region [Organização]
    organizacaoId: string = '';
    organizacaoDisplay: string = '';
    organizacaoNome: string = null;
    organizacoes: Organizacao[] = [];
    selectableOrganizacoes: Organizacao[] = [];
    // #endregion
    // #region [Unidade]
    unidadeId: string = '';
    unidadeDisplay: string = '';
    unidadeNome: string = null;
    unidades: Unidade[] = [];
    selectableUnidades: Unidade[] = [];
    // #endregion
    // #region [Grupo]
    grupoId: string = '';
    grupoDisplay: string = '';
    grupoNome: string = null;
    grupos: ConjuntoGrupo[] = [];
    selectableGrupos: ConjuntoGrupo[] = [];
    // #endregion
    // #region [Comissão]
    comissaoId: string = '';
    comissaoDisplay: string = '';
    comissaoNome: string = null;
    comissoes: ConjuntoGrupo[] = [];
    selectableComissoes: ConjuntoGrupo[] = [];
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowObjectDefinition;
    @Output() inputModelChange = new EventEmitter<FlowObjectDefinition>();
    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Output() outputSubmitEvent = new EventEmitter<FlowObjectDefinition>();
    @Output() outputCloseEvent = new EventEmitter<any>();
    // #endregion

    constructor(
        private toastr: ToastrService,
        private organogramaService: OrganogramaService,
        private edocsService: EDocsService,
        private acessoCidadaoService: AcessoCidadaoService,
        private flowObjectDefinitionService: FlowObjectDefinitionService,
        private patriarcasFilter: PatriarcasFilter,
        private organizacoesFilter: OrganizacoesFilter,
        private unidadesFilter: UnidadesFilter,
        private gruposFilter: GruposFilter,
        private comissoesFilter: ComissoesFilter
    ) {
        this.pairValueType = this.PairValueTypeValues.String;

        for (let item in AuthenticationSchemeType) {
            if (!isNaN(parseInt(item))) {
                this.authenticationSchemeOptions.push({
                    value: +item,
                    description: AuthenticationSchemeTypeDescription.get(+item)
                });
            }
        }

        for (let item in HttpMethod) {
            if (!isNaN(parseInt(item))) {
                this.httpMethodOptions.push({
                    value: +item,
                    description: HttpMethodDescription.get(+item)
                });
            }
        }
    }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        setTimeout(async () => {
            this.model = this.inputModel;
            if (this.model?.configSchema != null) {
                this.configSchema = JSON.parse(this.model.configSchema) as ConfigSchema;
                this.selectedHttpMethod = this.configSchema.taskOutboundApi.httpMethod ?? HttpMethod.Get;
                this.url = this.configSchema.taskOutboundApi.url;
                this.publicSystemName = this.configSchema.taskOutboundApi.publicSystemName;

                for (const [key, value] of Object.entries(this.configSchema.taskOutboundApi.urlParameters)) {
                    const keyValuePair = JSON.parse(`{"${key}": "${value}"}`);
                    this.keyValueParametersArray.push(keyValuePair);
                }

                if (this.keyValueParametersArray.length > 0) {
                    this.showParametersUrl = this.useUrlParameters = true;
                }

                this.ignoreErrors = this.configSchema.taskOutboundApi.ignoreErrors;
                this.errorMessage = this.configSchema.taskOutboundApi.errorMessage;
                this.authentication = this.configSchema.taskOutboundApi.authentication;
                this.fullJson = JSON.stringify(this.configSchema.taskOutboundApi.data);
                this.setJsonToEdit();

                for (const key in this.configSchema.taskOutboundApi.data) {
                    let value = this.configSchema.taskOutboundApi.data[key];
                    if (!this.isNumber(value) && !this.isBool(value) && !this.isNull(value)) {
                        value = `"${value}"`;
                    }

                    const keyValuePair = JSON.parse(`{ "${key}": ${value} }`);
                    this.keyValuePairsObject = Object.assign(this.keyValuePairsObject, keyValuePair);
                    this.keyValuePairsArray.push(keyValuePair);
                }

                this.resolver = this.configSchema.taskOutboundApi.resolver;
            }
        }, 1);

        this.pairValueType = this.PairValueTypeValues.String;
        this.parameterKeyType = this.PairValueTypeValues.CPF;
        this.authentication.authenticationScheme = AuthenticationSchemeType.None;

        this.formFlowObject = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.typeId == FlowObjectType.StartForm);
        if (this.formFlowObject != null) {
            if (Utils.isNullOrEmpty(this.formFlowObject.formSchema)) {
                const response = await this.flowObjectDefinitionService.getFormData(this.formFlowObject.id);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                this.formFlowObject.formSchema = response.data;
            }

            this.formFlowObjectEntries = this.getFormFlowObjectEntries(this.formFlowObject);
        }

        const response_patriarcas = await this.edocsService.getPatriarcas();

        if (!response_patriarcas.isSuccess) {
            this.toastr.error(response_patriarcas.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.patriarcas = response_patriarcas.data;
        this.patriarcas.sort((a, b) => {
            let first = a.sigla || a.nome || '';
            let second = b.sigla || b.nome || '';
            return first.localeCompare(second);
        });
        this.selectablePatriarcas = this.patriarcas;
    }

    // ======================
    // public methods
    // ======================

    // #region [Patriarca]
    patriarcaDisplayChange() {
        this.selectablePatriarcas = this.patriarcasFilter.transform(this.patriarcas, this.patriarcaDisplay);
    }

    async patriarcaIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.patriarcaId = event.option.value.id;
        }

        if (this.patriarcaId != '') {
            const response = await this.organogramaService.getOrganizacoesFilhas(this.patriarcaId);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.organizacoes = response.data;
            this.organizacoes.sort((a, b) => {
                let first = a.sigla || a.nomeFantasia || a.razaoSocial || '';
                let second = b.sigla || b.nomeFantasia || b.razaoSocial || '';
                return first.localeCompare(second);
            });
            this.selectableOrganizacoes = this.organizacoes;
        } else {
            this.organizacaoId = '';
            this.organizacoes = [];
            this.selectableOrganizacoes = [];
            this.resolver.name = null;
            this.resolver.id = null;
            this.patriarcaNome = null;
        }
    }

    displayPatriarcaId(option): string {
        return option != null && option != ''
            ? `${option.sigla} - ${option.nome.toUpperCase()}`
            : '';
    }

    clearPatriarca() {
        this.patriarcaId = '';
        this.patriarcaDisplay = '';
        this.selectablePatriarcas = this.patriarcas;
        this.organizacoes = [];
        this.clearOrganizacao();
    }
    // #endregion

    // #region [Organização]
    organizacaoDisplayChange() {
        this.selectableOrganizacoes = this.organizacoesFilter.transform(this.organizacoes, this.organizacaoDisplay);
    }

    async organizacaoIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.organizacaoId = event.option.value.guid;
        }

        if (this.organizacaoId != '') {
            let organization = this.organizacoes.find(x => x.guid == this.organizacaoId);
            this.organizacaoNome = `${organization.sigla} - ${organization.nomeFantasia == '.' ? organization.sigla : organization.nomeFantasia}`;

            let response = await this.organogramaService.getUnidadesOrganizacao(this.organizacaoId);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.unidades = response.data;
            this.unidades.sort((a, b) => {
                let first = a.nomeCurto || a.nome || a.sigla || '';
                let second = b.nomeCurto || b.nome || b.sigla || '';
                return first.localeCompare(second);
            });
            this.selectableUnidades = this.unidades;

            response = await this.acessoCidadaoService.getConjuntoGrupos(this.organizacaoId, TipoFiltro.TodosGrupoTrabalho);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.grupos = response.data;
            this.grupos.sort((a, b) => a.nome.localeCompare(b.nome));
            this.grupos.sort((a, b) => {
                let first = a.nome || '';
                let second = b.nome || '';
                return first.localeCompare(second);
            });
            this.selectableGrupos = this.grupos;

            response = await this.acessoCidadaoService.getConjuntoGrupos(this.organizacaoId, TipoFiltro.ComissaoSiarhes);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.comissoes = response.data;
            this.comissoes.sort((a, b) => a.nome.localeCompare(b.nome));
            this.comissoes.sort((a, b) => {
                let first = a.nome || '';
                let second = b.nome || '';
                return first.localeCompare(second);
            });
            this.selectableComissoes = this.comissoes;
        }
    }

    displayOrganizacaoId(option): string {
        return !Utils.isNullOrEmpty(option)
            ? `${option.sigla} - ${option.nomeFantasia == '.' ? option.sigla : option.nomeFantasia}`
            : '';
    }

    clearOrganizacao() {
        this.organizacaoId = '';
        this.organizacaoDisplay = '';
        this.organizacaoNome = null;
        this.selectableOrganizacoes = this.organizacoes;
        this.unidades = [];
        this.clearUnidade();
        this.grupos = [];
        this.clearGrupo();
        this.comissoes = [];
        this.clearComissao();
    }

    isDisabledOrganizacao(): boolean {
        return this.inputIsReadOnlyMode || this.organizacoes.length == 0;
    }
    // #endregion

    // #region [Unidade]
    unidadeDisplayChange() {
        this.selectableUnidades = this.unidadesFilter.transform(this.unidades, this.unidadeDisplay);
    }

    unidadeIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.unidadeId = event.option.value.guid;
        }

        if (this.unidadeId == '') {
            this.clearUnidade();
        } else {
            let unidade = this.unidades.find(x => x.guid == this.unidadeId);
            this.unidadeNome = unidade.nome.toUpperCase() + (!Utils.isNullOrEmpty(unidade.nomeCurto) ? ` - ${unidade.nomeCurto.toUpperCase()}` : '');
        }
    }

    displayUnidadeId(option): string {
        return !Utils.isNullOrEmpty(option)
            ? `${option.nomeCurto ? option.nomeCurto.toUpperCase() + ' - ' : ''}${option.nome.toUpperCase()}`
            : '';
    }

    clearUnidade() {
        this.unidadeId = '';
        this.unidadeDisplay = '';
        this.unidadeNome = null;
        this.selectableUnidades = this.unidades;
    }

    selectUnidade() {
        if (this.isDisabledUnidade() || Utils.isNullOrEmpty(this.unidadeId)) return;

        this.resolver.id = this.unidadeId;
        this.resolver.name = this.unidadeNome;
        this.resolver.type = LocationType.Organization;
    }

    isDisabledUnidade(): boolean {
        return this.inputIsReadOnlyMode || this.unidades.length == 0;
    }
    // #endregion

    // #region [Grupo]
    grupoDisplayChange() {
        this.selectableGrupos = this.gruposFilter.transform(this.grupos, this.grupoDisplay);
    }

    grupoIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.grupoId = event.option.value.guid;
        }

        if (this.grupoId == '') {
            this.clearGrupo();
        } else {
            let organizacao = this.organizacoes.find(x => x.guid == this.organizacaoId);
            this.grupoNome = this.grupos.find(x => x.guid == this.grupoId).nome.toUpperCase()
                + (!Utils.isNullOrEmpty(organizacao.sigla) ? ` - ${organizacao.sigla.toUpperCase()}` : '')
                + (!Utils.isNullOrEmpty(organizacao.organizacaoPai?.sigla) ? ` - ${organizacao.organizacaoPai?.sigla.toUpperCase()}` : '');
        }
    }

    displayGrupoId(option): string {
        return !Utils.isNullOrEmpty(option)
            ? option.nome.toUpperCase()
            : '';
    }

    clearGrupo() {
        this.grupoId = '';
        this.grupoDisplay = '';
        this.grupoNome = null;
        this.selectableGrupos = this.grupos;
    }

    selectGrupo() {
        if (this.isDisabledGrupo() || Utils.isNullOrEmpty(this.grupoId)) return;

        this.resolver.id = this.grupoId;
        this.resolver.name = this.grupoNome;
        this.resolver.type = LocationType.Group;
    }

    isDisabledGrupo(): boolean {
        return this.inputIsReadOnlyMode || this.grupos.length == 0;
    }
    // #endregion

    // #region [Comissão]
    comissaoDisplayChange() {
        this.selectableComissoes = this.comissoesFilter.transform(this.comissoes, this.comissaoDisplay);
    }

    comissaoIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.comissaoId = event.option.value.guid;
        }

        if (this.comissaoId == '') {
            this.clearComissao();
        } else {
            let organizacao = this.organizacoes.find(x => x.guid == this.organizacaoId);
            this.comissaoNome = this.comissoes.find(x => x.guid == this.comissaoId).nome.toUpperCase()
                + (!Utils.isNullOrEmpty(organizacao.sigla) ? ` - ${organizacao.sigla.toUpperCase()}` : '')
                + (!Utils.isNullOrEmpty(organizacao.organizacaoPai?.sigla) ? ` - ${organizacao.organizacaoPai?.sigla.toUpperCase()}` : '');
        }
    }

    displayComissaoId(option): string {
        return !Utils.isNullOrEmpty(option)
            ? option.nome.toUpperCase()
            : '';
    }

    clearComissao() {
        this.comissaoId = '';
        this.comissaoDisplay = '';
        this.comissaoNome = null;
        this.selectableComissoes = this.comissoes;
    }

    selectComissao() {
        if (this.isDisabledComissao() || Utils.isNullOrEmpty(this.comissaoId)) return;

        this.resolver.id = this.comissaoId;
        this.resolver.name = this.comissaoNome;
        this.resolver.type = LocationType.Comission;
    }

    isDisabledComissao(): boolean {
        return this.inputIsReadOnlyMode || this.comissoes.length == 0;
    }
    // #endregion

    addKeyValuePair() {
        if (this.isPairValueTypeEmpty()) return;
        if (this.isPairKeyEmpty()) return;
        if (this.isPairKeyAlreadyAdded()) return;
        if (!this.isPairValueValid()) return;

        let keyValuePair = this.parseKeyValuePair();
        this.keyValuePairsArray.push(keyValuePair);
        this.keyValuePairsObject = Object.assign(this.keyValuePairsObject, keyValuePair);
        this.fullJson = JSON.stringify(this.keyValuePairsObject);
        this.setJsonToEdit();
        this.clearKeyValueFields();
    }

    removeKeyValuePair(pair) {
        this.keyValuePairsArray = this.keyValuePairsArray.filter(x => x != pair);

        this.keyValuePairsObject = {};
        this.keyValuePairsArray.forEach(item => {
            this.keyValuePairsObject = Object.assign(this.keyValuePairsObject, item);
        });

        this.fullJson = JSON.stringify(this.keyValuePairsObject);
        this.setJsonToEdit();
    }

    isJsonValid(event?: any): boolean {
        try {
            this.fullJson = this.getJsonToEditText();
            this.keyValuePairsObject = JSON.parse(this.fullJson);
            this.keyValuePairsArray = [];
            for (const key in this.keyValuePairsObject) {
                let value = this.keyValuePairsObject[key];
                if (!this.isNumber(value) && !this.isBool(value) && !this.isNull(value)) {
                    value = `"${value}"`;
                }

                let keyValuePair = JSON.parse(`{ "${key}": ${value} }`);
                this.keyValuePairsArray.push(keyValuePair);
            }

            if (this.showJsonView) {
                this.setJsonToEdit();
                this.jsonToEdit = Utils.jsonSyntaxHighlight(this.jsonToEdit, true);
            }

            this.previousShowJsonView = this.showJsonView;

            return true;
        } catch (error) {
            this.toastr.error(Enums.Messages.JsonDeserializeError, Enums.Messages.Error, this.getToastrErrorOptions());
            console.error(error);
            this.showJsonView = this.previousShowJsonView;
            if (event?.source != null) {
                event.source.checked = this.previousShowJsonView;
            }

            return false;
        }
    }

    toggleShowJsonView() {
        this.showJsonView = !this.showJsonView;
        this.isJsonValid();
    }

    getFormFieldKey(): string {
        return this.pairValueType
            .split(this.FormTypeStartTag).join('')
            .split(this.FormTypeEndTag).join('')
            .split(this.FormTypeSeparatorTag)
            .slice(-1)[0];
    }

    prettyPrint(pair): string {
        let jsonString = JSON.stringify(pair, null, 2);
        return Utils.jsonSyntaxHighlight(jsonString);
    }

    clearKeyValueFields() {
        this.pairKey = null;
        this.pairValue = null;
    }

    authenticationSchemeChangeHandler() {
        if (this.authentication.authenticationScheme != AuthenticationSchemeType.Jwt) {
            this.authentication.clientId = null;
            this.authentication.clientSecret = null;
            this.authentication.scopes = null;
            this.authentication.tokenEndpoint = null;
        }

        if (
            this.authentication.authenticationScheme == AuthenticationSchemeType.None
            || this.authentication.authenticationScheme == AuthenticationSchemeType.Jwt
        ) {
            this.authentication.basicOrApiKey = null;
        }
    }

    shouldDisablePairValueInput(): boolean {
        return this.pairValueType == this.PairValueTypeValues.EDocs
            || this.pairValueType == this.PairValueTypeValues.Null
            || this.isFormPairValueType();
    }

    clearResolver() {
        this.resolver = new ApiResolver();
    }

    toggleShowParametersUrl() {
        this.showParametersUrl = !this.showParametersUrl;

        if (!this.showParametersUrl) {
            this.keyValueParametersArray.length = 0;
        }
    }

    addParameter() {
        if (this.isInvalidKey(this.parameterKeyType, this.parameterKey)) return;

        const keyValue = this.parseKeyValuePair(this.parameterKey, this.parameterKeyType);
        this.keyValueParametersArray.push(keyValue);
        this.clearKeyValueFields();
    }

    removeParameter(parameter) {
        this.keyValueParametersArray = this.keyValueParametersArray.filter(x => x !== parameter);
    }

    toggleTab(tab: number) {
        if (this.isTabOpen(tab)) {
            this.openTabs = this.openTabs.filter(x => x != tab);
        } else {
            this.openTabs.push(tab);
        }
    }

    isTabOpen(tab: number): boolean {
        return this.openTabs.includes(tab);
    }

    closeForm() {
        this.outputCloseEvent.emit();
    }

    onSubmit() {
        if (this.inputIsReadOnlyMode || !this.isJsonValid()) return false;

        this.configSchema.taskOutboundApi.httpMethod = this.selectedHttpMethod;
        this.configSchema.taskOutboundApi.url = this.url;
        this.configSchema.taskOutboundApi.publicSystemName = this.publicSystemName;
        this.configSchema.taskOutboundApi.ignoreErrors = this.ignoreErrors;
        this.configSchema.taskOutboundApi.errorMessage = this.errorMessage;

        this.configSchema.taskOutboundApi.urlParameters = {};
        if (this.keyValueParametersArray.length > 0) {
            let objectsArray = {};
            this.keyValueParametersArray.forEach(item => {
                objectsArray = Object.assign(objectsArray, item);
            });

            this.configSchema.taskOutboundApi.urlParameters = objectsArray;
        }

        if (this.configSchema.taskOutboundApi.authentication != null) {
            Object.assign(this.configSchema.taskOutboundApi.authentication, this.authentication);
        } else {
            this.configSchema.taskOutboundApi.authentication = this.authentication;
        }

        if (this.configSchema.taskOutboundApi.resolver != null) {
            Object.assign(this.configSchema.taskOutboundApi.resolver, this.resolver);
        } else {
            this.configSchema.taskOutboundApi.resolver = this.resolver;
        }

        if (!Utils.isNullOrEmpty(this.fullJson)) {
            this.configSchema.taskOutboundApi.data = JSON.parse(this.fullJson);
        }

        this.model.configSchema = JSON.stringify(this.configSchema);
        this.outputSubmitEvent.emit(this.model);
    }

    // ======================
    // private methods
    // ======================

    private getFormFlowObjectEntries(flowObject: FlowObjectDefinition): any[] {
        if (Utils.isNullOrEmpty(flowObject.formSchema)) return;

        let entries = [];
        let formSchema = JSON.parse(flowObject.formSchema) as FormSchema;

        formSchema.components.forEach(item => {
            if (!['button', 'pdfUpload'].includes(item.type)) {
                entries.push({
                    label: item.label,
                    key: `${item.key}`
                });
            }
        });

        return entries;
    }

    private isPairKeyAlreadyAdded(): boolean {
        if (this.keyValuePairsObject.hasOwnProperty(this.pairKey)) {
            this.toastr.error(Enums.Messages.KeyAlreadyAdded, Enums.Messages.Error, this.getToastrErrorOptions());
            return true;
        }

        return false;
    }

    private isPairKeyEmpty(): boolean {
        if (this.pairKey == '' || this.pairKey == null) {
            this.toastr.error(Enums.Messages.EmptyKey, Enums.Messages.Error, this.getToastrErrorOptions());
            return true;
        }

        return false;
    }

    private isPairValueTypeEmpty(): boolean {
        if (this.pairValueType == '' || this.pairValueType == null) {
            this.toastr.error(Enums.Messages.EmptyPairValueType, Enums.Messages.Error, this.getToastrErrorOptions());
            return true;
        }

        return false;
    }

    private isPairValueValid(): boolean {
        if (
            this.pairValueType != '' && this.pairValueType != null
            && (
                (
                    this.pairValueType == this.PairValueTypeValues.String
                    && ( this.pairValue != '' && this.pairValue != null )
                ) || (
                    this.pairValueType == this.PairValueTypeValues.Number
                    && this.isNumber(this.pairValue)
                ) || (
                    this.pairValueType == this.PairValueTypeValues.Boolean
                    && this.isBool(this.pairValue)
                ) || (
                    this.pairValueType == this.PairValueTypeValues.Null
                    && this.isNull(this.pairValue)
                ) || (
                    this.pairValueType == this.PairValueTypeValues.EDocs
                    && this.pairValue !== this.PairValueTypeValues.EDocs
                ) || this.isFormPairValueType()
            )
        ) {
            return true;
        }

        this.toastr.error(Enums.Messages.InvalidPairValue, Enums.Messages.Error, this.getToastrErrorOptions());
        return false;
    }

    private isFormPairValueType(): boolean {
        return this.pairValueType.startsWith(this.FormTypeStartTag)
            && this.pairValueType.includes(this.FormTypeSeparatorTag)
            && this.pairValueType.endsWith(this.FormTypeEndTag);
    }

    private isFormValueType(value: any): boolean {
        return value.startsWith(this.FormTypeStartTag)
            && value.includes(this.FormTypeSeparatorTag)
            && value.endsWith(this.FormTypeEndTag);
    }

    private isInvalidTemplate(value: any): boolean {
        if (!value.startsWith('{{') || !value.endsWith('}}')) {
            this.toastr.error(Enums.Messages.InvalidKeyTemplate.toString(), Enums.Messages.Error.toString(), this.getToastrErrorOptions());
            return true;
        }
        return false;
    }

    private isInvalidKey(keyType: any, key: any): boolean {
        if (Utils.isNullOrEmpty(keyType) || Utils.isNullOrEmpty(key)) {
            this.toastr.error(Enums.Messages.NullOrEmpty.toString(), Enums.Messages.Error.toString(), this.getToastrErrorOptions());
            return true;
        }

        if (this.isInvalidTemplate(key)) return true;

        if (keyType == this.PairValueTypeValues.String
            || (
                keyType == this.PairValueTypeValues.Number
                && this.isNumber(key)
            ) || (
                keyType == this.PairValueTypeValues.Boolean
                && this.isBool(key)
            ) || (
                keyType == this.PairValueTypeValues.Null
                && this.isNull(key)
            ) || (
                keyType == this.PairValueTypeValues.EDocs
                && key !== this.PairValueTypeValues.EDocs
            ) || this.isFormValueType(keyType) || (
                keyType == this.PairValueTypeValues.CPF
                && key !== this.PairValueTypeValues.CPF
            )
        ) {
            return false;
        }

        this.toastr.error(Enums.Messages.InvalidPairValue.toString(), Enums.Messages.Error.toString(), this.getToastrErrorOptions());
        return true;
    }

    private isNumber(value): boolean {
        value = '' + value;
        return !isNaN(value) && !isNaN(parseFloat(value));
    }

    private isBool(value): boolean {
        return ['true', 'false'].includes(value?.toString().trim().toLowerCase());
    }

    private isNull(value): boolean {
        return value === null;
    }

    private parseKeyValuePair(key?, value?): any {
        key = key || this.pairKey;
        value = value || this.pairValue;

        try {
            switch (this.pairValueType) {
                case this.PairValueTypeValues.String:
                    return JSON.parse(`{"${key}": "${value}"}`);

                case this.PairValueTypeValues.Number:
                    return JSON.parse(`{"${key}": ${value}}`);

                case this.PairValueTypeValues.Boolean:
                    return JSON.parse(`{"${key}": ${value}}`);

                case this.PairValueTypeValues.Null:
                    return JSON.parse(`{"${key}": null}`);

                default:
                    return JSON.parse(`{"${key}" : "${value || this.pairValueType}"}`);
            }
        } catch (error) {
            this.toastr.error(Enums.Messages.JsonSerializeError, Enums.Messages.Error, this.getToastrErrorOptions());
            console.error(error);
        }
    }

    private setJsonToEdit() {
        this.keyValuePairsObject = JSON.parse(this.fullJson);
        this.jsonToEdit = JSON.stringify(this.keyValuePairsObject, null, 2);
        let pre = document.createElement('pre');
        pre.innerHTML = this.jsonToEdit;
        let div = document.createElement('div');
        div.appendChild(pre);
        this.jsonToEdit = div.innerHTML;
    }

    private getJsonToEditText(): string {
        let div = document.createElement('div');
        div.innerHTML = this.jsonToEdit;
        return div.innerText.replace('\n', '');
    }

    private getToastrErrorOptions(): any {
        return {
            closeButton: true,
            timeOut: 10000,
            extendedTimeOut: 5000,
        };
    };
}
