import { Pipe, PipeTransform } from "@angular/core";
import { FlowDefinition } from "../../models/flow.model";

@Pipe({
    name: 'nameFilter'
})
export class NameFilter implements PipeTransform {
    transform(data: FlowDefinition[], inputText?: string) {
        return inputText != null && inputText.trim() != ''
            ? data.filter(x => x.name.toLowerCase().includes(inputText.toLowerCase()))
            : data;
    }
}

@Pipe({
    name: 'isActiveFilter'
})
export class IsActiveFilter implements PipeTransform {
    transform(data: FlowDefinition[], isOn: boolean, isOff: boolean) {
        if (isOn) {
            return data.filter(x => x.isActive);
        } else if (isOff) {
            return data.filter(x => !x.isActive);
        }

        return data;
    }
}

@Pipe({
    name: 'isPublishedFilter'
})
export class IsPublishedFilter implements PipeTransform {
    transform(data: FlowDefinition[], isOn: boolean, isOff: boolean) {
        if (isOn) {
            return data.filter(x => x.isPublished);
        } else if (isOff) {
            return data.filter(x => !x.isPublished);
        }

        return data;
    }
}
