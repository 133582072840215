<ng-container *ngIf="inputConfigSchema != null">
    <div class="form-group mt-4">
        <h5>Configurações do E-Docs</h5>
        <hr class="mt-0 mb-4" />
    </div>

    <div class="form-group mt-4">
        <h6>
            <span featureHint="flowObjectDetails|DocumentClass">Classificação Documental</span>
        </h6>
    </div>

    <!-- Classificação Documental -->
    <document-class [inputModel]="configSchemaSectionDocumentClass"
                    [inputFlowDefinitionOrganizationId]="inputFlowDefinition.organizationId"
                    [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                    (outputModelChange)="onUpdateDocumentClass($event)"
    ></document-class>

    <div class="form-group mt-4">
        <h6>
            <span featureHint="flowObjectDetails|AccessLevel">Nível de Acesso do Documento</span>
        </h6>
    </div>

    <!-- Nível de Acesso -->
    <access-level #accessLevelRef
                  [inputModel]="configSchemaSectionAccessLevel"
                  [inputFlowDefinitionOrganizationId]="inputFlowDefinition.organizationId"
                  [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                  (outputModelChange)="onUpdateAccessLevel($event)"
    ></access-level>
</ng-container>
