<footer class="main-footer {{env}}" [matTooltip]="shouldHideEnvironment ? '' : 'Ambiente de ' + environment">
    <div class="wrapper">
        <div class="environment ml-2" [class.invisible]="shouldHideEnvironment">
            <span>{{environment}}</span>
        </div>
        <div class="content-wrapper">
            <span>© 2021</span>
            <span>{{yearComplement || ' '}}</span>
            <a class="ml-1" href="https://prodest.es.gov.br/">PRODEST</a>
        </div>
        <div class="environment mr-2" [class.invisible]="shouldHideEnvironment">
            <span>{{environment}}</span>
        </div>
    </div>
</footer>
