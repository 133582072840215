import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Enums } from '../../shared/enums';

@Component({
    selector: 'landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
    Enums = Enums;

    //NUM_PICS: number = 2 as const;

    //get currentWindow(): number {
    //    let second = new Date().getSeconds();
    //    for (let i = 0; i < this.NUM_PICS; i++) {
    //        let secWindow = [...Array(60).keys()].splice(i * (60 / this.NUM_PICS), 60 / this.NUM_PICS);
    //        if (secWindow.includes(second)) {
    //            return i;
    //        }
    //    }

    //    return 0;
    //}

    get showEdoquinho(): boolean {
        return this.route.snapshot.queryParams.e != null;
    }

    constructor(private route: ActivatedRoute) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() { }

    // ======================
    // public methods
    // ======================

    // ======================
    // private methods
    // ======================
}
