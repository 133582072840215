<ng-container *ngIf="isReady()">
    <!-- Nível de Acesso -->
    <div class="form-group" featureHint="flowObjectDetails|AccessLevelId">
        <label for="accessLevelId">Nível de Acesso</label>
        <i class="fas fa-question-circle ml-1" *ngIf="inputShowTooltips"
           matTooltip="Nível de acesso no E-Docs do documento gerado a partir da execução da Tarefa"
        ></i>
        <select class="form-control relevant-info-font" id="accessLevelId" name="accessLevelId" required
                [(ngModel)]="inputModel.id"
                [matTooltip]="inputModel.name"
                [disabled]="inputIsReadOnlyMode"
                (change)="accessLevelIdChange()"
        >
            <option *ngFor="let option of accessLevelTypeOptions" [value]="option.value">{{option.description}}</option>
        </select>
    </div>

    <ng-container *ngIf="shouldFilterFundamentos()">
        <!-- Seletor de filtro de fundamentos -->
        <div featureHint="flowObjectDetails|AccessLevelFilterType">
            <mat-radio-group class="filter-type mb-2" id="fundamentoFilterType" name="fundamentoFilterType" color="primary"
                             [(ngModel)]="fundamentoFilterType"
                             [disabled]="inputIsReadOnlyMode"
                             [required]="true"
                             (change)="fundamentoFilterTypeChange()"
            >
                <mat-radio-button [value]="AccessLevelFilterType.Citizen">Exibir apenas Fundamentos permitidos para o Cidadão</mat-radio-button>
                <mat-radio-button [value]="AccessLevelFilterType.Patriarch">Exibir Fundamentos do seguinte Patriarca:</mat-radio-button>
            </mat-radio-group>
        </div>

        <!-- Patriarcas -->
        <div class="form-group ml-4" *ngIf="fundamentoFilterType == AccessLevelFilterType.Patriarch" featureHint="flowObjectDetails|AccessLevelPatriarch">
            <label for="patriarcaId">Selecione um Patriarca para filtrar os Fundamentos Legais mais abaixo</label>
            <input type="text" class="form-control relevant-info-font one-button" id="patriarcaDisplay" name="patriarcaDisplay"
                   placeholder="(nenhum selecionado)"
                   [(ngModel)]="patriarcaDisplay"
                   [matTooltip]="patriarcaNome"
                   [matAutocomplete]="patriarcaAutocomplete"
                   (input)="patriarcaDisplayChange()"
            >
            <mat-autocomplete #patriarcaAutocomplete="matAutocomplete"
                              [displayWith]="displayPatriarcaId"
                              (optionSelected)="patriarcaIdChange($event)"
            >
                <mat-option *ngFor="let option of selectablePatriarcas" [value]="option">{{option.sigla}} - {{option.nome.toUpperCase()}}</mat-option>
            </mat-autocomplete>
            <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearPatriarca()"></i>
        </div>

        <!-- Fundamentos Disponíveis -->
        <div class="form-group position-relative" featureHint="flowObjectDetails|AccessLevelAvailable"
             [ngClass]="fundamentoFilterType == AccessLevelFilterType.Patriarch ? 'ml-5' : 'ml-4'"
        >
            <label for="fundamentoId">Selecione um dos Fundamentos disponíveis e o adicione à lista abaixo</label>
            <input type="text" class="form-control relevant-info-font one-button" id="fundamentoDisplay" name="fundamentoDisplay"
                   placeholder="(nenhum selecionado)"
                   [(ngModel)]="fundamentoDisplay"
                   [matTooltip]="fundamentoDescricao"
                   [disabled]="isFundamentoDisplayDisabled"
                   [matAutocomplete]="fundamentoAutocomplete"
                   (input)="fundamentoDisplayChange()"
            >
            <mat-autocomplete #fundamentoAutocomplete="matAutocomplete"
                              [displayWith]="displayFundamentoId"
                              (optionSelected)="fundamentoIdChange($event)"
            >
                <mat-option *ngFor="let option of selectableFundamentos" [value]="option">{{option.nivelAcesso.toUpperCase()}} - {{option.nome.toUpperCase()}}</mat-option>
            </mat-autocomplete>
            <i class="fas fa-plus-circle action add ml-2" matTooltip="Adicionar Fundamento Legal" (click)="addFundamento(fundamentacaoLegalRef)"></i>
        </div>

        <!-- Fundamentação Legal selecionada -->
        <div class="form-group position-relative" featureHint="flowObjectDetails|AccessLevelSelected"
             [ngClass]="fundamentoFilterType == AccessLevelFilterType.Patriarch ? 'ml-6' : 'ml-5'"
        >
            <label for="legalReasoningIds">Fundamentação Legal selecionada</label>
            <i class="fas fa-question-circle ml-1" *ngIf="inputShowTooltips"
               matTooltip="Fundamentação legal para a restrição no E-Docs do documento gerado a partir do encerramento da Tarefa"
            ></i>
            <select multiple class="form-control relevant-info-font one-button" id="legalReasoningIds" name="legalReasoningIds" size="2" #fundamentacaoLegalRef
                    [disabled]="inputIsReadOnlyMode"
                    [matTooltip]="fundamentosNomes"
            >
                <option *ngFor="let option of selectedFundamentos" [value]="option.value">{{option.description.toUpperCase()}}</option>
            </select>
            <i class="fas fa-minus-circle action remove multiple ml-2" matTooltip="Remover Fundamento Legal" (click)="removeFundamento(fundamentacaoLegalRef)"></i>
        </div>
    </ng-container>
</ng-container>
