<ng-container *ngIf="model != null">
    <form class="px-1" (ngSubmit)="onSubmit()" #flowObjectGatewayRules="ngForm">
        <div class="scroll-wrapper styled-scrollbar px-2">
            <!-- Tipo de regra -->
            <div class="form-group rule-type-container ml-0 mb-3">
                <span>Selecione o tipo de regra que será avaliada pela Tarefa:</span>

                <mat-radio-group class="mb-2" id="httpSuccessRule" name="httpSuccessRule" color="primary"
                                 [(ngModel)]="httpSuccessRule"
                                 (change)="httpSuccessRuleChange()"
                >
                    <mat-radio-button [value]="true">Código HTTP da classe 2XX</mat-radio-button>

                    <blockquote class="info-container radiobutton">
                        <div class="description info-highlight">
                            <i class="fas fa-info-circle info"></i>
                            <span>
                                A Tarefa avaliará apenas o código HTTP de retorno obtido na requisição realizada à API de destino;
                                caso o mesmo seja diferente de <span class="code-font text-highlight">200</span>, o Fluxo será encerrado.
                            </span>
                        </div>
                    </blockquote>

                    <mat-radio-button class="mt-3" [value]="false">Propriedade da API</mat-radio-button>

                    <blockquote class="info-container radiobutton">
                        <div class="description info-highlight">
                            <i class="fas fa-info-circle info"></i>
                            <span>
                                A Tarefa decidirá se o Fluxo deve continuar ou se encerrar a partir da avaliação de uma determinada
                                propriedade contida no retorno da requisição realizada à API de destino.
                            </span>
                        </div>
                    </blockquote>
                </mat-radio-group>
            </div>

            <!-- Configuração da comparação: comportamento -->
            <ng-container *ngIf="httpSuccessRule == false">
                <mat-checkbox id="shouldIgnoreNoValueError" name="shouldIgnoreNoValueError" color="primary"
                              [(ngModel)]="model.shouldIgnoreNoValueError"
                >
                    Ignorar erros quando o objeto retornado pela API de destino não contiver o campo indicado em "Propriedade"
                    na regra mais abaixo
                </mat-checkbox>

                <blockquote class="info-container checkbox">
                    <div class="description info-highlight">
                        <i class="fas fa-info-circle info"></i>
                        <span>
                            Se marcada, esta opção fará com que o E-Flow avalie a regra mais abaixo como
                            <span class="code-font text-highlight">false</span> caso o campo indicado esteja ausente no retorno da
                            API. Se desmarcada, o E-Flow considerará a ausência do campo como sendo um erro, interrompendo a
                            execução do Fluxo.
                        </span>
                    </div>
                </blockquote>

                <mat-checkbox id="shouldIgnoreNonNumberError" name="shouldIgnoreNonNumberError" color="primary"
                              [(ngModel)]="model.shouldIgnoreNonNumberError"
                >
                    Ignorar erros de tipo de valores numéricos para o campo indicado em "Propriedade" na regra mais abaixo
                </mat-checkbox>

                <blockquote class="info-container checkbox">
                    <div class="description info-highlight">
                        <i class="fas fa-info-circle info"></i>
                        <span>
                            Se marcada, esta opção fará com que o E-Flow avalie a regra mais abaixo como
                            <span class="code-font text-highlight">false</span> caso, no retorno da API, o campo indicado contenha
                            valores não numéricos e o <b>Operador</b> escolhido mais abaixo seja exclusivo de valores numéricos
                            (<span class="code-font text-highlight">é maior que</span>,
                            <span class="code-font text-highlight">é maior que ou igual a</span>,
                            <span class="code-font text-highlight">é menor que</span>,
                            <span class="code-font text-highlight">é menor que ou igual a</span>). Se desmarcada, o E-Flow considerará
                            a ocorrência como sendo um erro, interrompendo a execução do Fluxo.
                        </span>
                    </div>
                </blockquote>
            </ng-container>

            <!-- Configuração da comparação: regra -->
            <div class="form-group" *ngIf="showProperties">
                <div class="row">
                    <!-- Propriedade -->
                    <div class="form-group col">
                        <label for="propertyKey">Propriedade</label>
                        <input type="text" class="form-control" id="propertyKey" name="propertyKey" placeholder="e.g. &quot;userId&quot;"
                               [(ngModel)]="propertyKey"
                        >

                        <blockquote class="info-container field">
                            <div class="description info-highlight">
                                <i class="fas fa-info-circle info"></i>
                                <span>
                                    Nome do campo contido no retorno da requisição à API de destino que conterá o valor a ser avaliado
                                    pela regra.
                                </span>
                            </div>
                        </blockquote>
                    </div>

                    <!-- Operador -->
                    <div class="form-group col">
                        <label for="ruleOperator">Operador</label>
                        <select class="form-control" id="ruleOperator" name="ruleOperator"
                                [(ngModel)]="ruleOperator"
                                (change)="ruleOperatorChange()"
                        >
                            <option *ngFor="let option of gatewayRuleOperatorOptions" [value]="option.value">{{option.description}}</option>
                        </select>

                        <blockquote class="info-container field">
                            <div class="description info-highlight">
                                <i class="fas fa-info-circle info"></i>
                                <span>Operador de comparação a ser utilizado na avaliação da regra.</span>
                            </div>
                        </blockquote>
                    </div>

                    <!-- Valor -->
                    <div class="form-group col">
                        <label for="value">Valor</label>
                        <input type="text" class="form-control" id="propertyValue" name="propertyValue"
                               [(ngModel)]="propertyValue"
                               [disabled]="valueDisabled"
                               placeholder="{{ruleOperator <= GatewayRuleOperator.LessThan
                                   ? 'e.g. &quot;10&quot;'
                                   : ruleOperator == GatewayRuleOperator.IsTrue
                                   ? 'e.g. &quot;true&quot;'
                                   : ruleOperator == GatewayRuleOperator.IsFalse
                                   ? 'e.g. &quot;false&quot;'
                                   : ruleOperator == GatewayRuleOperator.IsNull
                                   ? 'e.g. &quot;null&quot;'
                                   : ruleOperator == GatewayRuleOperator.IsNotNull
                                   ? 'e.g. &quot;Inscrição confirmada. Número: 1234.&quot;'
                                   : ruleOperator == GatewayRuleOperator.IsEmpty
                                   ? 'e.g. &quot;&quot;'
                                   : ruleOperator == GatewayRuleOperator.IsNotEmpty
                                   ? 'e.g. &quot;Registro efetuado.&quot;'
                                   : 'e.g. &quot;termo a ser pesquisado&quot;'
                               }}"
                        >

                        <blockquote class="info-container field">
                            <div class="description info-highlight">
                                <i class="fas fa-info-circle info"></i>
                                <span>
                                    Valor de referência com o qual o valor do campo indicado em <b>Propriedade</b> será comparado.
                                </span>
                            </div>
                        </blockquote>
                    </div>
                </div>
            </div>

            <!-- Mensagem -->
            <div class="form-group ml-0">
                <label for="message">Mensagem</label>
                <textarea type="text" class="form-control" id="message" name="message" rows="2"
                            placeholder="e.g. &quot;Entre em contato com (...)&quot;"
                            [(ngModel)]="message"
                ></textarea>

                <blockquote class="info-container field">
                    <div class="description info-highlight">
                        <i class="fas fa-info-circle info"></i>
                        <span>
                            Mensagem alternativa a ser exibida na Tarefa caso a regra configurada seja avaliada como
                            <span class="code-font text-highlight">false</span> no momento da execução.
                        </span>
                    </div>
                </blockquote>
            </div>
        </div>

        <!-- Botões -->
        <div class="form-footer text-right mb-1">
            <hr class="mt-0" />
            <button type="button" class="btn btn-outline-danger mr-2" (click)="closeForm()">Cancelar</button>
            <button type="submit" class="btn btn-outline-primary"
                    [disabled]="!flowObjectGatewayRules.form.valid || inputIsReadOnlyMode"
            >Salvar Configurações</button>
        </div>
    </form>
</ng-container>
