<ngx-spinner name="root" bdColor="#000000aa" size="medium" color="#64b2cd" type="line-scale-pulse-out-rapid" [fullScreen]="true">
    <h5>Processando...</h5>
</ngx-spinner>

<div class="main-container" [class.landing-page]="isLandingPage">
    <app-header></app-header>

    <app-ribs></app-ribs>

    <div class="content">
        <router-outlet></router-outlet>
    </div>

    <div class="mvp">
        <div class="card p-5 text-center">
            <i class="fas fa-mobile-alt mr-2"></i>
            <i class="fas fa-tablet-alt mx-2"></i>
            <i class="fas fa-laptop mx-2"></i>
            <b>Suporte a dispositivos de resoluções menores em breve!</b>
        </div>
    </div>

    <app-footer></app-footer>
</div>

<p-confirmDialog icon="pi pi-exclamation-triangle" header="Confirmação" #cd>
    <p-footer>
        <button type="button" class="btn btn-outline-danger mr-2" (click)="cd.reject()">
            <i class="fas fa-times mr-2"></i>
            <span>Não</span>
        </button>

        <button type="button" class="btn btn-outline-primary" (click)="cd.accept()">
            <i class="fas fa-check mr-2"></i>
            <span>Sim</span>
        </button>
    </p-footer>
</p-confirmDialog>
