import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import { Servico } from '../models/catalogo-es.model';

@Injectable({
    providedIn: 'root'
})
export class CatalogoEsService extends BaseService {
    controllerRoute: string = 'CatalogoEs';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async getServicosOrganizacao(id: string): Promise<IBaseModel<Servico[]>> {
        return this.httpClient.get<IBaseModel<Servico[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/ServicosOrganizacao/${id}`).toPromise();
    }
}
