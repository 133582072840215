import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/http/auth.guard';

import { ErrorComponent } from './core/error/error.component';
import { AccessDeniedComponent } from './core/access-denied/access-denied.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { FlowDefinitionComponent } from './pages/flow-definition/flow-definition.component';
import { FlowDefinitionListComponent } from './pages/flow-definition-list/flow-definition-list.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { Enums } from './shared/enums';

// data: array vazio para aceitar todos os perfis do AC
const routes: Routes = [
    {
        path: Enums.PagesPaths.LandingPage.slice(1),
        component: LandingPageComponent,
        canActivate: [AuthGuard],
        data: { profiles: [], isLandingPage: true }
    },
    {
        path: Enums.PagesPaths.Home.slice(1),
        component: FlowDefinitionListComponent,
        canActivate: [AuthGuard],
        data: { profiles: [Enums.Profiles.Administrator] }
    },
    {
        path: Enums.PagesPaths.FlowDefinitionList.slice(1),
        component: FlowDefinitionListComponent,
        canActivate: [AuthGuard],
        data: { profiles: [Enums.Profiles.Administrator] }
    },
    {
        path: Enums.PagesPaths.Dashboard.slice(1),
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: { profiles: [Enums.Profiles.Administrator] }
    },
    {
        path: Enums.PagesPaths.FlowDefinition.slice(1),
        component: FlowDefinitionComponent,
        canActivate: [AuthGuard],
        data: { profiles: [Enums.Profiles.Administrator] }
    },
    {
        path: `${Enums.PagesPaths.FlowDefinition.slice(1)}/:id`,
        component: FlowDefinitionComponent,
        canActivate: [AuthGuard],
        data: { profiles: [Enums.Profiles.Administrator] }
    },
    {
        path: `${Enums.PagesPaths.FlowDefinition.slice(1)}/:id/:version`,
        component: FlowDefinitionComponent,
        canActivate: [AuthGuard],
        data: { profiles: [Enums.Profiles.Administrator] }
    },
    {
        path: Enums.PagesPaths.Error.slice(1),
        component: ErrorComponent,
        canActivate: [AuthGuard],
        data: { profiles: [Enums.Profiles.ErrorPage] }
    },
    {
        path: Enums.PagesPaths.AccessDenied.slice(1),
        component: AccessDeniedComponent,
        canActivate: [AuthGuard],
        data: { profiles: [] }
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
