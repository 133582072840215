import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild,
    TemplateRef
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfigSchemaFlowDefinition } from '../../../../models/config-schema.model';
import { FlowDefinition } from '../../../../models/flow.model';
import { AuthService } from '../../../../services/auth.service';
import { Enums } from '../../../../shared/enums';
import { Utils } from '../../../../shared/utils';

@Component({
    selector: 'flow-definition-details-hot-configs',
    templateUrl: './flow-definition-details-hot-configs.component.html',
    styleUrls: ['./flow-definition-details-hot-configs.component.scss']
})
export class FlowDefinitionDetailsHotConfigsComponent implements OnInit {
    // #region [ViewChild]
    @ViewChild('configSchemaRef') configSchemaRef: TemplateRef<HTMLElement>;
    @ViewChild('configSchemaEditorRef') configSchemaEditorRef: ElementRef;
    // #endregion

    // #region [properties]
    model: FlowDefinition;
    configSchema: ConfigSchemaFlowDefinition = new ConfigSchemaFlowDefinition();
    configSchemaFlat: string = null;
    isAutoDeactivateSinceFlowDefinitionActivation: boolean;
    isAutoDeactivateOnDateTime: boolean;
    isAutoCancelSinceFlowInstanceStart: boolean;
    isAutoCancelSinceFlowInstanceLastUpdate: boolean;
    isAutoCancelOnDateTime: boolean;
    isLatestVersion: boolean;
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Output() outputUpdateFlowDefinitionEvent = new EventEmitter<FlowDefinition>();
    @Output() outputCloseEvent = new EventEmitter<any>();
    // #endregion

    constructor(
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private toastr: ToastrService,
        public authService: AuthService
    ) {
        this.isLatestVersion = Utils.isNullOrEmpty(this.route.snapshot.paramMap.get('version'));
    }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        let interval = setInterval(async () => {
            if (this.inputModel == null) return;
            clearInterval(interval);

            this.model = this.inputModel;

            if (this.model.configSchema != null) {
                Object.assign(this.configSchema, JSON.parse(this.model.configSchema));
            }

            this.initConfigSchemaInfo();
        }, 200);
    }

    // ======================
    // public methods
    // ======================

    clearAutoCancelChildren() {
        this.isAutoCancelSinceFlowInstanceStart = false;
        this.isAutoCancelSinceFlowInstanceLastUpdate = false;
        this.isAutoCancelOnDateTime = false;
        this.configSchema.daysSinceFlowInstanceStartToAutoCancel = null;
        this.configSchema.daysSinceFlowInstanceLastUpdateToAutoCancel = null;
        this.configSchema.dateTimeToAutoCancelFlowInstance = null;
    }

    clearAutoCancelSinceFlowInstanceStart(event: MatCheckboxChange) {
        if (!event?.checked) {
            this.configSchema.daysSinceFlowInstanceStartToAutoCancel = null;
        }
    }

    clearAutoCancelSinceFlowInstanceLastUpdate(event: MatCheckboxChange) {
        if (!event?.checked) {
            this.configSchema.daysSinceFlowInstanceLastUpdateToAutoCancel = null;
        }
    }

    clearAutoCancelOnDateTime(event: MatCheckboxChange) {
        if (!event?.checked) {
            this.configSchema.dateTimeToAutoCancelFlowInstance = null;
        }
    }

    clearAutoDeactivateChildren() {
        this.isAutoDeactivateSinceFlowDefinitionActivation = false;
        this.isAutoDeactivateOnDateTime = false;
        this.configSchema.daysSinceFlowDefinitionActivationToAutoDeactivate = null;
        this.configSchema.dateTimeToAutoDeactivateFlowDefinition = null;
    }

    clearAutoDeactivateSinceFlowDefinitionActivation(event: MatCheckboxChange) {
        if (!event?.checked) {
            this.configSchema.daysSinceFlowDefinitionActivationToAutoDeactivate = null;
        }
    }

    clearAutoDeactivateOnDateTime(event: MatCheckboxChange) {
        if (!event?.checked) {
            this.configSchema.dateTimeToAutoDeactivateFlowDefinition = null;
        }
    }

    showConfigSchema() {
        let tempObject = {
            model: Utils.decycleFlowDefinition(this.model),
            configSchema: JSON.parse(JSON.stringify(this.configSchema))
        };
        delete tempObject.model.configSchema;
        delete tempObject.model.flowObjectDefinitions;

        this.configSchemaFlat = btoa(encodeURIComponent(JSON.stringify(tempObject)));

        let dialog = this.dialog.open(this.configSchemaRef, {
            minWidth: '800px'
        });

        dialog.afterOpened().subscribe(() => {
            setTimeout(() => {
                this.configSchemaEditorRef.nativeElement.scrollLeft = 0;
                this.configSchemaEditorRef.nativeElement.scrollTop = 0;
            }, 1);
            setTimeout(() => {
                this.configSchemaEditorRef.nativeElement.style.opacity = '1';
            }, 100);
        });

        dialog.afterClosed().subscribe(async () => {
            try {
                let modalObject = JSON.parse(decodeURIComponent(atob(this.configSchemaEditorRef.nativeElement.value)));
                delete modalObject.model.configSchema;

                // checa se o tipo da origem do conteúdo é o mesmo tipo do conteúdo atual (i.e. se a origem não é um FlowObjectDefinition)
                if (modalObject.model.targetId == null) {
                    this.toastr.error(Enums.Messages.InvalidConfigSchemaSourceType, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                // checa se o código-fonte foi alterado
                if (JSON.stringify(tempObject) != JSON.stringify(modalObject)) {
                    this.configSchema = JSON.parse(JSON.stringify(modalObject.configSchema)) as ConfigSchemaFlowDefinition;
                }
            } catch (error) {
                this.toastr.error(Enums.Messages.InvalidFlowDefinitionConfigSchemaSource, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.initConfigSchemaInfo();
        });
    }

    onSubmit() {
        if (!this.isLatestVersion) return false;

        if (
            (
                this.configSchema.hasAutomaticFlowDefinitionDeactivation
                && !this.isAutoDeactivateSinceFlowDefinitionActivation
                && !this.isAutoDeactivateOnDateTime
            ) || (
                this.isAutoDeactivateSinceFlowDefinitionActivation
                && this.configSchema.daysSinceFlowDefinitionActivationToAutoDeactivate <= 0
            ) || (
                this.isAutoDeactivateOnDateTime
                && this.configSchema.dateTimeToAutoDeactivateFlowDefinition == null
            )
        ) {
            this.toastr.error(Enums.Messages.InvalidAutoDeactivateOptions, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
            return false;
        }

        if (
            (
                this.configSchema.hasAutomaticFlowInstanceCancellation
                && !this.isAutoCancelSinceFlowInstanceStart
                && !this.isAutoCancelSinceFlowInstanceLastUpdate
                && !this.isAutoCancelOnDateTime
            ) || (
                this.isAutoCancelSinceFlowInstanceStart
                && this.configSchema.daysSinceFlowInstanceStartToAutoCancel <= 0
            ) || (
                this.isAutoCancelSinceFlowInstanceLastUpdate
                && this.configSchema.daysSinceFlowInstanceLastUpdateToAutoCancel <= 0
            ) || (
                this.isAutoCancelOnDateTime
                && this.configSchema.dateTimeToAutoCancelFlowInstance == null
            )
        ) {
            this.toastr.error(Enums.Messages.InvalidAutoCancelOptions, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
            return false;
        }

        this.model.configSchema = JSON.stringify(this.configSchema);

        this.outputUpdateFlowDefinitionEvent.emit(this.model);
    }

    closeForm() {
        this.outputCloseEvent.emit();
    }

    // ======================
    // private methods
    // ======================

    private initConfigSchemaInfo() {
        this.isAutoDeactivateSinceFlowDefinitionActivation = this.configSchema.daysSinceFlowDefinitionActivationToAutoDeactivate != null;
        this.isAutoDeactivateOnDateTime = this.configSchema.dateTimeToAutoDeactivateFlowDefinition != null;
        this.isAutoCancelSinceFlowInstanceStart = this.configSchema.daysSinceFlowInstanceStartToAutoCancel != null;
        this.isAutoCancelSinceFlowInstanceLastUpdate = this.configSchema.daysSinceFlowInstanceLastUpdateToAutoCancel != null;
        this.isAutoCancelOnDateTime = this.configSchema.dateTimeToAutoCancelFlowInstance != null;
    }
}
