import {
    ClassesFilter,
    PatriarcasFilter,
    PlanosFilter
} from '../../flow-object-details.pipe';
import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output
} from '@angular/core';
import {
    Plano,
    Classe,
    Patriarca
} from '../../../../../models/edocs.model';
import { Enums } from '../../../../../shared/enums';
import { EDocsService } from '../../../../../services/edocs.service';
import { Utils } from '../../../../../shared/utils';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ToastrService } from 'ngx-toastr';
import { ConfigSchemaSectionDocumentClass } from '../../../../../models/config-schema.model';

@Component({
    selector: 'document-class',
    templateUrl: './document-class.component.html',
    styleUrls: ['./document-class.component.scss']
})
export class DocumentClassComponent implements OnInit {
    Utils: typeof Utils = Utils;

    // #region [Patriarca]
    patriarcaId: string = '';
    patriarcaDisplay: string = '';
    patriarcaNome: string = null;
    patriarcas: Patriarca[] = [];
    selectablePatriarcas: Patriarca[];
    // #endregion
    // #region [Plano]
    planoId: string = '';
    planoDisplay: string = '';
    planoNome: string = null;
    planos: Plano[] = [];
    selectablePlanos: Plano[];
    // #endregion
    // #region [Classe]
    classeId: string = '';
    classeDisplay: string = '';
    classes: Classe[] = [];
    selectableClasses: Classe[] = [];
    // #endregion

    @Input() inputModel: ConfigSchemaSectionDocumentClass;
    @Input() inputFlowDefinitionOrganizationId: string;
    @Input() inputIsReadOnlyMode: boolean;
    @Input() inputShowTooltips: boolean = false;

    @Output() outputModelChange = new EventEmitter<ConfigSchemaSectionDocumentClass>();

    constructor(
        private toastr: ToastrService,
        private edocsService: EDocsService,
        private planosFilter: PlanosFilter,
        private classesFilter: ClassesFilter,
        private patriarcasFilter: PatriarcasFilter
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        const response_Patriarcas = await this.edocsService.getPatriarcas();

        if (!response_Patriarcas.isSuccess) {
            this.toastr.error(response_Patriarcas.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.patriarcas = response_Patriarcas.data;
        this.patriarcas.sort((a, b) => {
            let first = a.sigla || a.nome || '';
            let second = b.sigla || b.nome || '';
            return first.localeCompare(second);
        });
        this.selectablePatriarcas = this.patriarcas;
    }

    // ======================
    // public methods
    // ======================

    // #region [Patriarca]
    patriarcaDisplayChange() {
        this.selectablePatriarcas = this.patriarcasFilter.transform(this.patriarcas, this.patriarcaDisplay);
    }

    async patriarcaIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.patriarcaId = event.option.value.id;
        }

        if (this.patriarcaId != '') {
            const response = await this.edocsService.getPlanosAtivos(this.patriarcaId);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.planos = response.data;
            this.planos.sort((a, b) => {
                let first = a.codigo || a.nome || '';
                let second = b.codigo || b.nome || '';
                return first.localeCompare(second);
            });
            this.selectablePlanos = this.planos;
            this.planoDisplay = '';

            let selectedPatriarch = this.patriarcas.find(x => x.id == this.patriarcaId);
            this.planoNome = `${selectedPatriarch.sigla} - ${selectedPatriarch.nome.toUpperCase()}`;
        } else {
            this.planoId = '';
            this.planos = [];
            this.selectablePlanos = [];
            this.patriarcaNome = null;
            this.inputModel.name = null;
            this.inputModel.id = null;

            this.outputModelChange.emit(this.inputModel);
        }
    }

    displayPatriarcaId(option): string {
        return option != null && option != ''
            ? `${option.sigla} - ${option.nome.toUpperCase()}`
            : '';
    }

    clearPatriarca() {
        this.patriarcaId = '';
        this.patriarcaDisplay = '';
        this.selectablePatriarcas = this.patriarcas;
        this.planos = [];
        this.clearPlano();
    }
    // #endregion

    // #region [Plano]
    planoDisplayChange() {
        this.selectablePlanos = this.planosFilter.transform(this.planos, this.planoDisplay);
    }

    async planoIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.planoId = event.option.value.id;
        }

        if (this.planoId != '') {
            let response = await this.edocsService.getClassesAtivas(this.planoId);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.classes = response.data;
            this.classes.sort((a, b) => a.codigo.localeCompare(b.codigo));
            this.classes.sort((a, b) => {
                let first = a.codigo || a.nome || '';
                let second = b.codigo || b.nome || '';
                return first.localeCompare(second);
            });
            this.selectableClasses = this.classes;
            this.classeDisplay = '';

            let selectedPlan = this.planos.find(x => x.id == this.planoId);
            this.planoNome = `${selectedPlan.codigo} - ${selectedPlan.nome.toUpperCase()}`;
        } else {
            this.classeId = '';
            this.classes = [];
            this.selectableClasses = [];
            this.planoNome = null;
            this.inputModel.name = null;
            this.inputModel.id = null;

            this.outputModelChange.emit(this.inputModel);
        }
    }

    displayPlanoId(option): string {
        return option != null && option != ''
            ? `${option.codigo} - ${option.nome.toUpperCase()}`
            : '';
    }

    clearPlano() {
        this.planoId = '';
        this.planoDisplay = '';
        this.selectablePlanos = this.planos;
        this.classes = [];
        this.clearClasse();
    }
    // #endregion

    // #region [Classe]
    classeDisplayChange() {
        this.selectableClasses = this.classesFilter.transform(this.classes, this.classeDisplay);
    }

    classeIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.classeId = event.option.value.id;
        }

        if (this.classeId == '') {
            this.classeId = null;
            this.inputModel.name = null;
        } else {
            let selectedClass = this.classes.find(x => x.id == this.classeId);
            this.inputModel.name = `${selectedClass.codigo} - ${selectedClass.nome.toUpperCase()}`;
        }

        this.inputModel.id = this.classeId;

        this.outputModelChange.emit(this.inputModel);
    }

    displayClasseId(option): string {
        return option != null && option != ''
            ? `${option.codigo} - ${option.nome.toUpperCase()}`
            : '';
    }

    clearClasse() {
        this.classeId = '';
        this.classeDisplay = '';
        this.selectableClasses = this.classes;
    }

    clearClassificacao() {
        this.inputModel.name = null;
        this.inputModel.id = null;

        this.outputModelChange.emit(this.inputModel);
    }

    isActualClasse(option: Classe): boolean {
        return option.unidadeArquivamento;
    }
    // #endregion

    isReady(): boolean {
        return this.inputModel != null && this.inputFlowDefinitionOrganizationId != null;
    }

    // ======================
    // private methods
    // ======================
}
