export const HTML_TEMPLATE_DEFAULT = `
<table style="border-collapse: collapse; width: 100%;" border="0">
    <tbody>
        <tr>
            <td style='width: 15%; height: 80px;'>{|FluxoPatriarcaBrasao|}</td>
            <td style="width: 85%; height: 80px;">
                <strong>{|FluxoPatriarcaNome|}</strong>
                <br />
                <strong>
                    <span style="font-size: 11pt;">{|FluxoOrgaoNome|} - {|FluxoOrgaoSigla|}</span>
                </strong>
            </td>
        </tr>
    </tbody>
</table>

<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-size: 14pt;"><strong>T&Iacute;TULO</strong></span></p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: left;">Redija seu documento a partir daqui.</p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: left; text-transform: uppercase;">
    <strong>{|UsuarioNome|}</strong>
    <br />
    <strong><span style="font-size: 10pt;">{|UsuarioPapel|}</span></strong>
</p>
`;
