<ng-container *ngIf="isReady()">
    <!-- Patriarcas -->
    <div class="form-group forwarding-recipient ml-3px" featureHint="flowObjectDetails|ForwardPatriarch">
        <label for="patriarcaId">Selecione um Patriarca para filtrar os Órgãos abaixo</label>
        <input type="text" class="form-control relevant-info-font one-button" id="patriarcaDisplay" name="patriarcaDisplay"
               placeholder="(nenhum selecionado)"
               [(ngModel)]="patriarcaDisplay"
               [matTooltip]="patriarcaNome"
               [matAutocomplete]="patriarcaAutocomplete"
               [disabled]="inputIsReadOnlyMode"
               (input)="patriarcaDisplayChange()"
        >
        <mat-autocomplete #patriarcaAutocomplete="matAutocomplete"
                          [displayWith]="displayPatriarcaId"
                          (optionSelected)="patriarcaIdChange($event)"
        >
            <mat-option *ngFor="let option of selectablePatriarcas" [value]="option">{{option.sigla}} - {{option.nome.toUpperCase()}}</mat-option>
        </mat-autocomplete>
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearPatriarca()"></i>
    </div>

    <!-- Órgãos -->
    <div class="form-group forwarding-recipient ml-4" featureHint="flowObjectDetails|ForwardOrganization">
        <label for="organizacaoId">Selecione um Órgão para filtrar as opções disponíveis abaixo</label>
        <input type="text" class="form-control relevant-info-font two-buttons" id="organizacaoDisplay" name="organizacaoDisplay"
               placeholder="(nenhum selecionado)"
               [(ngModel)]="organizacaoDisplay"
               [matTooltip]="organizacaoNome"
               [matAutocomplete]="organizacaoAutocomplete"
               [disabled]="isDisabledOrganizacao()"
               (input)="organizacaoDisplayChange()"
        >
        <mat-autocomplete #organizacaoAutocomplete="matAutocomplete"
                          [displayWith]="displayOrganizacaoId"
                          (optionSelected)="organizacaoIdChange($event)"
        >
            <mat-option *ngFor="let option of selectableOrganizacoes" [value]="option">
                {{option.sigla}} - {{option.nomeFantasia == '.' ? option.sigla : option.nomeFantasia}}
            </mat-option>
        </mat-autocomplete>
        <i class="fas fa-check-circle action add ml-2" matTooltip="Escolher" (click)="selectOrganizacao()"></i>
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearOrganizacao()"></i>
    </div>

    <!-- Setores -->
    <div class="form-group forwarding-recipient ml-5" featureHint="flowObjectDetails|ForwardUnit">
        <label for="unidadeId">Setores</label>
        <input type="text" class="form-control relevant-info-font two-buttons" id="unidadeDisplay" name="unidadeDisplay"
               placeholder="(nenhum selecionado)"
               [(ngModel)]="unidadeDisplay"
               [matTooltip]="unidadeNome"
               [matAutocomplete]="unidadeAutocomplete"
               [disabled]="isDisabledUnidade()"
               (input)="unidadeDisplayChange()"
        >
        <mat-autocomplete #unidadeAutocomplete="matAutocomplete"
                          [displayWith]="displayUnidadeId"
                          (optionSelected)="unidadeIdChange($event)"
        >
            <mat-option *ngFor="let option of selectableUnidades" [value]="option">
                {{option.nomeCurto ? option.nomeCurto.toUpperCase() + ' - ' : ''}}{{option.nome.toUpperCase()}}
            </mat-option>
        </mat-autocomplete>
        <i class="fas fa-check-circle action add ml-2" matTooltip="Escolher" (click)="selectUnidade()"></i>
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearUnidade()"></i>
    </div>

    <!-- Grupos de Trabalho -->
    <div class="form-group forwarding-recipient ml-5" featureHint="flowObjectDetails|ForwardGroup">
        <label for="grupoId">Grupos de Trabalho</label>
        <input type="text" class="form-control relevant-info-font two-buttons" id="grupoDisplay" name="grupoDisplay"
               placeholder="(nenhum selecionado)"
               [(ngModel)]="grupoDisplay"
               [matTooltip]="grupoNome"
               [matAutocomplete]="grupoAutocomplete"
               [disabled]="isDisabledGrupo()"
               (input)="grupoDisplayChange()"
        >
        <mat-autocomplete #grupoAutocomplete="matAutocomplete"
                          [displayWith]="displayGrupoId"
                          (optionSelected)="grupoIdChange($event)"
        >
            <mat-option *ngFor="let option of selectableGrupos" [value]="option">
                {{option.nome.toUpperCase()}}
            </mat-option>
        </mat-autocomplete>
        <i class="fas fa-check-circle action add ml-2" matTooltip="Escolher" (click)="selectGrupo()"></i>
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearGrupo()"></i>
    </div>

    <!-- Comissões e Contratos -->
    <div class="form-group forwarding-recipient ml-5" featureHint="flowObjectDetails|ForwardComission">
        <label for="comissaoId">Comissões e Contratos</label>
        <input type="text" class="form-control relevant-info-font two-buttons" id="comissaoDisplay" name="comissaoDisplay"
               placeholder="(nenhum selecionado)"
               [(ngModel)]="comissaoDisplay"
               [matTooltip]="comissaoNome"
               [matAutocomplete]="comissaoAutocomplete"
               [disabled]="isDisabledComissao()"
               (input)="comissaoDisplayChange()"
        >
        <mat-autocomplete #comissaoAutocomplete="matAutocomplete"
                          [displayWith]="displayComissaoId"
                          (optionSelected)="comissaoIdChange($event)"
        >
            <mat-option *ngFor="let option of selectableComissoes" [value]="option">
                {{option.nome.toUpperCase()}}
            </mat-option>
        </mat-autocomplete>
        <i class="fas fa-check-circle action add ml-2" matTooltip="Escolher" (click)="selectComissao()"></i>
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearComissao()"></i>
    </div>
</ng-container>
