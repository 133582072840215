import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormioCustomComponent } from '@formio/angular';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../../shared/enums';
import { Utils } from '../../../shared/utils';

@Component({
    selector: 'pdf-upload-multiple',
    templateUrl: './pdf-upload-multiple.component.html',
    styleUrls: ['./pdf-upload-multiple.component.scss']
})
export class PdfUploadMultipleComponent implements FormioCustomComponent<any> {
    @ViewChild('anchorFileRef') anchorFileRef: ElementRef;

    private MAX_SIZE_MB: number = 15;

    fileNames: string[] = [];

    @Input() maxFiles: number;
    @Input() disabled: boolean;
    @Input() value: any;
    @Output() valueChange = new EventEmitter<any>();

    constructor(private toastr: ToastrService) { }

    // ======================
    // lifecycle methods
    // ======================

    // ======================
    // public methods
    // ======================

    changeFile(event) {
        const file = event.target.files[0] as File;

        // caso a extensão do arquivo não seja PDF
        if (file.name.split('.').slice(-1)[0].toLowerCase() != 'pdf') {
            // reinicia valores do input e do componente Formio
            this.removeFile(event);
            this.toastr.error(Enums.Messages.PdfFilesOnly, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        // caso o tamanho do arquivo seja maior que o máximo estabelecido
        if (file.size > this.MAX_SIZE_MB * 1024 * 1024) {
            // reinicia valores do input e do componente Formio
            this.removeFile(event);
            this.toastr.error(Enums.Messages.PdfMaxSizeLimit.replace('{0}', this.MAX_SIZE_MB.toString()), Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onloadend = () => {
            // converte o arquivo para um array de bytes, uma vez que o "JSON.stringify > JSON.parse"
            // que ocorre no meio do caminho até o hook do evento de submit do Formio
            // transformaria o objeto do tipo File em um objeto vazio ("{}")
            let byteArray = [];
            let array = new Uint8Array(fileReader.result as ArrayBuffer);
            for (let byte of array) {
                byteArray.push(byte);
            }

            this.fileNames.push(file.name);
            this.value.fileName.push(file.name);
            this.value.fileType.push(file.type);
            this.value.fileContent.push(byteArray);

            this.valueChange.emit(this.value);

            this.anchorFileRef.nativeElement.value = '';
        };
    }

    removeFile(idx: number) {
        this.fileNames.splice(idx, 1);
        this.value.fileName.splice(idx, 1);
        this.value.fileType.splice(idx, 1);
        this.value.fileContent.splice(idx, 1);
        this.valueChange.emit(this.value);
    }

    candAddMoreFiles(): boolean {
        return (
            // caso o componente não tenha terminado de carregar ainda
            this.value == null
            || (
                // caso o componente esteja em modo de edição (/flow-definition)
                this.value?.minioKey == null
                // limite de arquivos definido no Construtor de Formulário
                && this.value?.fileName?.length < this.maxFiles
            )
            // caso não haja nenhum arquivo inserido ainda
            || this.value?.fileName?.length == 0
        );
    }

    // ======================
    // private methods
    // ======================
}
