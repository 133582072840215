import {
    FlowObjectType,
    FlowObjectDefinition,
    FormSchema
} from '../../../../models/flow-object.model';
import {
    ComissoesFilter,
    GruposFilter,
    OrganizacoesFilter,
    PapeisFilter,
    PatriarcasFilter,
    UnidadesFilter
} from '../flow-object-details.pipe';
import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output
} from '@angular/core';
import {
    ConfigSchema,
    ConfigSchemaSectionDocumentClass,
    Stakeholder,
    StakeholderType,
    StakeholderTypeDescription
} from '../../../../models/config-schema.model';
import { AcessoCidadaoService } from '../../../../services/acesso-cidadao.service';
import { OrganogramaService } from '../../../../services/organograma.service';
import { Organizacao, Unidade } from '../../../../models/organograma.model';
import { AgentePublicoPapel, ConjuntoGrupo, TipoFiltro } from '../../../../models/acesso-cidadao.model';
import { Enums } from '../../../../shared/enums';
import { ToastrService } from 'ngx-toastr';
import { Patriarca } from '../../../../models/edocs.model';
import { EDocsService } from '../../../../services/edocs.service';
import { FlowDefinition } from '../../../../models/flow.model';
import { IBaseOption } from '../../../../models/base.model';
import { Utils } from '../../../../shared/utils';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FLOW_OBJECT_DETAILS_REGISTER_PROCESS_TINYMCE_OPTIONS } from '../flow-object-details-tinymce-options';
import { FlowObjectDefinitionService } from '../../../../services/flow-object-definition.service';

@Component({
    selector: 'flow-object-details-register-process',
    templateUrl: './flow-object-details-register-process.component.html',
    styleUrls: ['./flow-object-details-register-process.component.scss']
})
export class FlowObjectDetailsRegisterProcessComponent implements OnInit {
    // #region [Type properties]
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    StakeholderType: typeof StakeholderType = StakeholderType;
    StakeholderTypeDescription: typeof StakeholderTypeDescription = StakeholderTypeDescription;
    Utils: typeof Utils = Utils;
    // #endregion

    // #region [properties]
    formFlowObjectSchemaFields: any[] = [];
    tinyMceOptionsProcess: any = FLOW_OBJECT_DETAILS_REGISTER_PROCESS_TINYMCE_OPTIONS;
    configSchemaSectionDocumentClass: ConfigSchemaSectionDocumentClass = new ConfigSchemaSectionDocumentClass();
    // #endregion

    // #region [Localização :: Patriarca]
    patriarcaId_ProcessLocation: string = '';
    patriarcaDisplay_ProcessLocation: string = '';
    patriarcaNome_ProcessLocation: string = null;
    patriarcas_ProcessLocation: Patriarca[] = [];
    selectablePatriarcas_ProcessLocation: Patriarca[] = [];
    // #endregion
    // #region [Localização :: Organização]
    organizacaoId_ProcessLocation: string = '';
    organizacaoDisplay_ProcessLocation: string = '';
    organizacaoNome_ProcessLocation: string = null;
    organizacoes_ProcessLocation: Organizacao[] = [];
    selectableOrganizacoes_ProcessLocation: Organizacao[] = [];
    // #endregion
    // #region [Localização :: Unidade]
    unidadeId_ProcessLocation: string = '';
    unidadeDisplay_ProcessLocation: string = '';
    unidadeNome_ProcessLocation: string = null;
    unidades_ProcessLocation: Unidade[] = [];
    selectableUnidades_ProcessLocation: Unidade[] = [];
    // #endregion
    // #region [Interessados :: Patriarca]
    patriarcaId_ProcessStakeholders: string = '';
    patriarcaDisplay_ProcessStakeholders: string = '';
    patriarcaNome_ProcessStakeholders: string = null;
    patriarcas_ProcessStakeholders: Patriarca[] = [];
    selectablePatriarcas_ProcessStakeholders: Patriarca[] = [];
    isShowingPatriarcaSection: boolean = false;
    // #endregion
    // #region [Interessados :: Organização]
    organizacaoId_ProcessStakeholders: string = '';
    organizacaoDisplay_ProcessStakeholders: string = '';
    organizacaoNome_ProcessStakeholders: string = null;
    organizacoes_ProcessStakeholders: Organizacao[] = [];
    selectableOrganizacoes_ProcessStakeholders: Organizacao[] = [];
    isShowingOrganizacaoSection: boolean = false;
    // #endregion
    // #region [Interessados :: Unidade]
    unidadeId_ProcessStakeholders: string = '';
    unidadeDisplay_ProcessStakeholders: string = '';
    unidadeNome_ProcessStakeholders: string = null;
    unidades_ProcessStakeholders: Unidade[] = [];
    selectableUnidades_ProcessStakeholders: Unidade[] = [];
    isShowingUnidadeSection: boolean = false;
    // #endregion
    // #region [Interessados :: Grupo]
    grupoId_ProcessStakeholders: string = '';
    grupoDisplay_ProcessStakeholders: string = '';
    grupoNome_ProcessStakeholders: string = null;
    grupos_ProcessStakeholders: ConjuntoGrupo[] = [];
    selectableGrupos_ProcessStakeholders: ConjuntoGrupo[] = [];
    isShowingGrupoSection: boolean = false;
    // #endregion
    // #region [Interessados :: Comissão]
    comissaoId_ProcessStakeholders: string = '';
    comissaoDisplay_ProcessStakeholders: string = '';
    comissaoNome_ProcessStakeholders: string = null;
    comissoes_ProcessStakeholders: ConjuntoGrupo[] = [];
    selectableComissoes_ProcessStakeholders: ConjuntoGrupo[] = [];
    isShowingComissaoSection: boolean = false;
    // #endregion
    // #region [Interessados :: Servidor]
    servidorCpf_ProcessStakeholders: string = '';
    servidorNome_ProcessStakeholders: string = '';
    servidorPapelId_ProcessStakeholders: string = '';
    servidorPapelDisplay_ProcessStakeholders: string = '';
    servidorPapelNome_ProcessStakeholders: string = null;
    servidorPapeis_ProcessStakeholders: AgentePublicoPapel[] = [];
    selectableServidorPapeis_ProcessStakeholders: AgentePublicoPapel[] = [];
    isShowingServidorSection: boolean = false;
    // #endregion
    // #region [Interessados :: Cidadão]
    cidadaoFormField_ProcessStakeholders: string = '';
    cidadaoFormFieldOptions_ProcessStakeholders: IBaseOption[] = [];
    isShowingCidadaoSection: boolean = false;
    isCitizenFlowInstanceOwner: boolean = null;
    // #endregion
    // #region [Interessados :: Pessoa Jurídica]
    empresaCompanyName_ProcessStakeholders: string = null;
    empresaCompanyNameFormField_ProcessStakeholders: string = '';
    empresaCompanyNameFormFieldOptions_ProcessStakeholders: IBaseOption[] = [];
    isEmpresaCompanyNameFreeEntry: boolean = null;
    empresaCnpj_ProcessStakeholders: string = null;
    empresaCnpjFormField_ProcessStakeholders: string = '';
    empresaCnpjFormFieldOptions_ProcessStakeholders: IBaseOption[] = [];
    isEmpresaCnpjFreeEntry: boolean = null;
    empresaEmail_ProcessStakeholders: string = null;
    empresaEmailFormField_ProcessStakeholders: string = '';
    empresaEmailFormFieldOptions_ProcessStakeholders: IBaseOption[] = [];
    isEmpresaEmailFreeEntry: boolean = null;
    isShowingEmpresaSection: boolean = false;
    // #endregion
    // #region [Resumo]
    processSummary: string = '';
    // #endregion

    // #region [Input/Output]
    @Input() inputConfigSchema: ConfigSchema;
    @Output() inputConfigSchemaChange = new EventEmitter<ConfigSchema>();
    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    // #endregion

    constructor(
        private toastr: ToastrService,
        private acessoCidadaoService: AcessoCidadaoService,
        private organogramaService: OrganogramaService,
        private edocsService: EDocsService,
        private flowObjectDefinitionService: FlowObjectDefinitionService,
        private patriarcasFilter: PatriarcasFilter,
        private organizacoesFilter: OrganizacoesFilter,
        private unidadesFilter: UnidadesFilter,
        private gruposFilter: GruposFilter,
        private comissoesFilter: ComissoesFilter,
        private papeisFilter: PapeisFilter
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        let formFlowObject = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.typeId == FlowObjectType.StartForm);
        if (formFlowObject != null) {
            if (Utils.isNullOrEmpty(formFlowObject.formSchema)) {
                const response = await this.flowObjectDefinitionService.getFormData(formFlowObject.id);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                formFlowObject.formSchema = response.data;
            }

            let formSchema = JSON.parse(formFlowObject.formSchema) as FormSchema;
            this.formFlowObjectSchemaFields = Utils.getFormFieldsWithUsableValue(formSchema.components);
        }

        for (let prop in this.configSchemaSectionDocumentClass) {
            this.configSchemaSectionDocumentClass[prop] = this.inputConfigSchema.taskRegisterProcess.processClass[prop]
                || this.configSchemaSectionDocumentClass[prop];
        }

        this.initProcessInfo();

        const response = await this.edocsService.getPatriarcas();

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.patriarcas_ProcessLocation = response.data;
        this.patriarcas_ProcessLocation.sort((a, b) => {
            let first = a.sigla || a.nome || '';
            let second = b.sigla || b.nome || '';
            return first.localeCompare(second);
        });
        this.selectablePatriarcas_ProcessLocation = this.patriarcas_ProcessLocation;

        this.patriarcas_ProcessStakeholders = response.data;
        this.patriarcas_ProcessStakeholders.sort((a, b) => {
            let first = a.sigla || a.nome || '';
            let second = b.sigla || b.nome || '';
            return first.localeCompare(second);
        });
        this.selectablePatriarcas_ProcessStakeholders = this.patriarcas_ProcessStakeholders;

        this.tinyMceOptionsProcess.addDynamicMenu = editor => {
            editor.ui.registry.addMenuButton('vForm', {
                text: 'Formulário',
                fetch: callback => callback(this.getTinyMceMenuFormEntries(formFlowObject, editor))
            });
        };
    }

    // ======================
    // public methods
    // ======================

    //#region [Localização]
    clear_ProcessLocation() {
        this.inputConfigSchema.taskRegisterProcess.processLocationId = null;
        this.inputConfigSchema.taskRegisterProcess.processLocationName = null;
    }
    // #endregion

    //#region [Localização :: Patriarca]
    patriarcaDisplayChange_ProcessLocation() {
        this.selectablePatriarcas_ProcessLocation = this.patriarcasFilter.transform(this.patriarcas_ProcessLocation, this.patriarcaDisplay_ProcessLocation);
    }

    async patriarcaIdChange_ProcessLocation(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.patriarcaId_ProcessLocation = event.option.value.id;
        }

        if (this.patriarcaId_ProcessLocation != '') {
            let patriarca = this.patriarcas_ProcessLocation.find(x => x.id == this.patriarcaId_ProcessLocation);
            this.patriarcaNome_ProcessLocation = `${patriarca.sigla} - ${patriarca.nome}`;

            let response = await this.organogramaService.getOrganizacoesFilhas(this.patriarcaId_ProcessLocation);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.organizacoes_ProcessLocation = response.data;
            this.organizacoes_ProcessLocation.sort((a, b) => {
                let first = a.nomeFantasia || a.sigla || '';
                let second = b.nomeFantasia || b.sigla || '';
                return first.localeCompare(second);
            });
            this.selectableOrganizacoes_ProcessLocation = this.organizacoes_ProcessLocation;
        }
    }

    displayPatriarcaId_ProcessLocation(option): string {
        return !Utils.isNullOrEmpty(option)
            ? `${option.sigla} - ${option.nome}`
            : '';
    }

    clearPatriarca_ProcessLocation() {
        this.patriarcaId_ProcessLocation = '';
        this.patriarcaDisplay_ProcessLocation = '';
        this.patriarcaNome_ProcessLocation = null;
        this.selectablePatriarcas_ProcessLocation = this.patriarcas_ProcessLocation;
        this.organizacoes_ProcessLocation = [];
        this.clearOrganizacao_ProcessLocation();
        this.unidades_ProcessLocation = [];
        this.clearUnidade_ProcessLocation();
    }

    selectPatriarca_ProcessLocation() {
        this.inputConfigSchema.taskRegisterProcess.processLocationId = this.patriarcaId_ProcessLocation;
        this.inputConfigSchema.taskRegisterProcess.processLocationName = this.patriarcaNome_ProcessLocation;

        this.clearPatriarca_ProcessLocation();
    }
    // #endregion

    //#region [Localização :: Organização]
    organizacaoDisplayChange_ProcessLocation() {
        this.selectableOrganizacoes_ProcessLocation = this.organizacoesFilter.transform(this.organizacoes_ProcessLocation, this.organizacaoDisplay_ProcessLocation);
    }

    async organizacaoIdChange_ProcessLocation(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.organizacaoId_ProcessLocation = event.option.value.guid;
        }

        if (this.organizacaoId_ProcessLocation != '') {
            let organization = this.organizacoes_ProcessLocation.find(x => x.guid == this.organizacaoId_ProcessLocation);
            this.organizacaoNome_ProcessLocation = `${organization.sigla} - ${organization.nomeFantasia == '.' ? organization.sigla : organization.nomeFantasia}`;

            let response = await this.organogramaService.getUnidadesOrganizacao(this.organizacaoId_ProcessLocation);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.unidades_ProcessLocation = response.data;
            this.unidades_ProcessLocation.sort((a, b) => {
                let first = a.nomeCurto || a.nome || a.sigla || '';
                let second = b.nomeCurto || b.nome || b.sigla || '';
                return first.localeCompare(second);
            });
            this.selectableUnidades_ProcessLocation = this.unidades_ProcessLocation;
        }
    }

    displayOrganizacaoId_ProcessLocation(option): string {
        return !Utils.isNullOrEmpty(option)
            ? `${option.sigla} - ${option.nomeFantasia == '.' ? option.sigla : option.nomeFantasia}`
            : '';
    }

    clearOrganizacao_ProcessLocation() {
        this.organizacaoId_ProcessLocation = '';
        this.organizacaoDisplay_ProcessLocation = '';
        this.organizacaoNome_ProcessLocation = null;
        this.selectableOrganizacoes_ProcessLocation = this.organizacoes_ProcessLocation;
        this.unidades_ProcessLocation = [];
        this.clearUnidade_ProcessLocation();
    }

    selectOrganizacao_ProcessLocation() {
        this.inputConfigSchema.taskRegisterProcess.processLocationId = this.organizacaoId_ProcessLocation;
        this.inputConfigSchema.taskRegisterProcess.processLocationName = this.organizacaoNome_ProcessLocation;

        this.clearPatriarca_ProcessLocation();
    }
    // #endregion

    // #region [Localização :: Unidade]
    unidadeDisplayChange_ProcessLocation() {
        this.selectableUnidades_ProcessLocation = this.unidadesFilter.transform(this.unidades_ProcessLocation, this.unidadeDisplay_ProcessLocation);
    }

    unidadeIdChange_ProcessLocation(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.unidadeId_ProcessLocation = event.option.value.guid;
        }

        if (this.unidadeId_ProcessLocation == '') {
            this.clearUnidade_ProcessLocation();
        } else {
            let unidade = this.unidades_ProcessLocation.find(x => x.guid == this.unidadeId_ProcessLocation);
            this.unidadeNome_ProcessLocation = (unidade.nomeCurto ? unidade.nomeCurto.toUpperCase() + ' - ' : '') + unidade.nome.toUpperCase();
        }
    }

    displayUnidadeId_ProcessLocation(option): string {
        return !Utils.isNullOrEmpty(option)
            ? `${option.nomeCurto ? option.nomeCurto.toUpperCase() + ' - ' : ''}${option.nome.toUpperCase()}`
            : '';
    }

    clearUnidade_ProcessLocation() {
        this.unidadeId_ProcessLocation = '';
        this.unidadeDisplay_ProcessLocation = '';
        this.unidadeNome_ProcessLocation = null;
        this.selectableUnidades_ProcessLocation = this.unidades_ProcessLocation;
    }

    selectUnidade_ProcessLocation() {
        this.inputConfigSchema.taskRegisterProcess.processLocationId = this.unidadeId_ProcessLocation;
        this.inputConfigSchema.taskRegisterProcess.processLocationName = this.unidadeNome_ProcessLocation;

        this.clearPatriarca_ProcessLocation();
    }
    // #endregion

    //#region [Interessados]
    removeStakeholder(stakeholder: Stakeholder) {
        if (this.inputIsReadOnlyMode) return;

        this.inputConfigSchema.taskRegisterProcess.stakeholders = this.inputConfigSchema.taskRegisterProcess.stakeholders.filter(x => x.timestamp != stakeholder.timestamp);
    }

    removeAllStakeholders() {
        if (this.inputIsReadOnlyMode) return;

        this.inputConfigSchema.taskRegisterProcess.stakeholders = [];
    }
    // #endregion

    //#region [Interessados :: Patriarca]
    patriarcaDisplayChange_ProcessStakeholders() {
        this.selectablePatriarcas_ProcessStakeholders = this.patriarcasFilter.transform(this.patriarcas_ProcessStakeholders, this.patriarcaDisplay_ProcessStakeholders);
    }

    async patriarcaIdChange_ProcessStakeholders(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.patriarcaId_ProcessStakeholders = event.option.value.id;
        }

        if (this.patriarcaId_ProcessStakeholders != '') {
            let patriarca = this.patriarcas_ProcessStakeholders.find(x => x.id == this.patriarcaId_ProcessStakeholders);
            this.patriarcaNome_ProcessStakeholders = `${patriarca.sigla} - ${patriarca.nome}`;

            let response = await this.organogramaService.getOrganizacoesFilhas(this.patriarcaId_ProcessStakeholders);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.organizacoes_ProcessStakeholders = response.data;
            this.organizacoes_ProcessStakeholders.sort((a, b) => {
                let first = a.nomeFantasia || a.sigla || '';
                let second = b.nomeFantasia || b.sigla || '';
                return first.localeCompare(second);
            });
            this.selectableOrganizacoes_ProcessStakeholders = this.organizacoes_ProcessStakeholders;
        }
    }

    displayPatriarcaId_ProcessStakeholders(option): string {
        return !Utils.isNullOrEmpty(option)
            ? `${option.sigla} - ${option.nome}`
            : '';
    }

    clearPatriarca_ProcessStakeholders() {
        this.patriarcaId_ProcessStakeholders = '';
        this.patriarcaDisplay_ProcessStakeholders = '';
        this.patriarcaNome_ProcessStakeholders = null;
        this.selectablePatriarcas_ProcessStakeholders = this.patriarcas_ProcessStakeholders;
        this.organizacoes_ProcessStakeholders = [];
        this.clearOrganizacao_ProcessStakeholders();
        this.unidades_ProcessStakeholders = [];
        this.clearUnidade_ProcessStakeholders();
    }

    selectPatriarca_ProcessStakeholders() {
        this.inputConfigSchema.taskRegisterProcess.stakeholders.push(new Stakeholder({
            id: this.patriarcaId_ProcessStakeholders,
            name: this.patriarcaNome_ProcessStakeholders,
            type: StakeholderType.Patriarch
        }));
    }

    togglePatriarcaSection() {
        this.isShowingPatriarcaSection = !this.isShowingPatriarcaSection;
    }
    // #endregion

    //#region [Interessados :: Organização]
    organizacaoDisplayChange_ProcessStakeholders() {
        this.selectableOrganizacoes_ProcessStakeholders = this.organizacoesFilter.transform(this.organizacoes_ProcessStakeholders, this.organizacaoDisplay_ProcessStakeholders);
    }

    async organizacaoIdChange_ProcessStakeholders(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.organizacaoId_ProcessStakeholders = event.option.value.guid;
        }

        if (this.organizacaoId_ProcessStakeholders != '') {
            let organization = this.organizacoes_ProcessStakeholders.find(x => x.guid == this.organizacaoId_ProcessStakeholders);
            this.organizacaoNome_ProcessStakeholders = `${organization.sigla} - ${organization.nomeFantasia == '.' ? organization.sigla : organization.nomeFantasia}`;

            let response = await this.organogramaService.getUnidadesOrganizacao(this.organizacaoId_ProcessStakeholders);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.unidades_ProcessStakeholders = response.data;
            this.unidades_ProcessStakeholders.sort((a, b) => {
                let first = a.nomeCurto || a.nome || a.sigla || '';
                let second = b.nomeCurto || b.nome || b.sigla || '';
                return first.localeCompare(second);
            });
            this.selectableUnidades_ProcessStakeholders = this.unidades_ProcessStakeholders;

            response = await this.acessoCidadaoService.getConjuntoGrupos(this.organizacaoId_ProcessStakeholders, TipoFiltro.TodosGrupoTrabalho);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.grupos_ProcessStakeholders = response.data;
            this.grupos_ProcessStakeholders.sort((a, b) => a.nome.localeCompare(b.nome));
            this.grupos_ProcessStakeholders.sort((a, b) => {
                let first = a.nome || '';
                let second = b.nome || '';
                return first.localeCompare(second);
            });
            this.selectableGrupos_ProcessStakeholders = this.grupos_ProcessStakeholders;

            response = await this.acessoCidadaoService.getConjuntoGrupos(this.organizacaoId_ProcessStakeholders, TipoFiltro.ComissaoSiarhes);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.comissoes_ProcessStakeholders = response.data;
            this.comissoes_ProcessStakeholders.sort((a, b) => a.nome.localeCompare(b.nome));
            this.comissoes_ProcessStakeholders.sort((a, b) => {
                let first = a.nome || '';
                let second = b.nome || '';
                return first.localeCompare(second);
            });
            this.selectableComissoes_ProcessStakeholders = this.comissoes_ProcessStakeholders;
        }
    }

    displayOrganizacaoId_ProcessStakeholders(option): string {
        return !Utils.isNullOrEmpty(option)
            ? `${option.sigla} - ${option.nomeFantasia == '.' ? option.sigla : option.nomeFantasia}`
            : '';
    }

    clearOrganizacao_ProcessStakeholders() {
        this.organizacaoId_ProcessStakeholders = '';
        this.organizacaoDisplay_ProcessStakeholders = '';
        this.organizacaoNome_ProcessStakeholders = null;
        this.selectableOrganizacoes_ProcessStakeholders = this.organizacoes_ProcessStakeholders;
        this.unidades_ProcessStakeholders = [];
        this.clearUnidade_ProcessStakeholders();
    }

    selectOrganizacao_ProcessStakeholders() {
        let prefix = this.patriarcaNome_ProcessStakeholders.split(' - ')[0];

        this.inputConfigSchema.taskRegisterProcess.stakeholders.push(new Stakeholder({
            id: this.organizacaoId_ProcessStakeholders,
            name: `${prefix} - ${this.organizacaoNome_ProcessStakeholders}`,
            type: StakeholderType.Organization
        }));
    }

    toggleOrganizacaoSection() {
        this.isShowingOrganizacaoSection = !this.isShowingOrganizacaoSection;
    }
    // #endregion

    // #region [Interessados :: Unidade]
    unidadeDisplayChange_ProcessStakeholders() {
        this.selectableUnidades_ProcessStakeholders = this.unidadesFilter.transform(this.unidades_ProcessStakeholders, this.unidadeDisplay_ProcessStakeholders);
    }

    unidadeIdChange_ProcessStakeholders(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.unidadeId_ProcessStakeholders = event.option.value.guid;
        }

        if (this.unidadeId_ProcessStakeholders == '') {
            this.clearUnidade_ProcessStakeholders();
        } else {
            let unidade = this.unidades_ProcessStakeholders.find(x => x.guid == this.unidadeId_ProcessStakeholders);
            this.unidadeNome_ProcessStakeholders = (unidade.nomeCurto ? unidade.nomeCurto.toUpperCase() + ' - ' : '') + unidade.nome.toUpperCase();
        }
    }

    displayUnidadeId_ProcessStakeholders(option): string {
        return !Utils.isNullOrEmpty(option)
            ? `${option.nomeCurto ? option.nomeCurto.toUpperCase() + ' - ' : ''}${option.nome.toUpperCase()}`
            : '';
    }

    clearUnidade_ProcessStakeholders() {
        this.unidadeId_ProcessStakeholders = '';
        this.unidadeDisplay_ProcessStakeholders = '';
        this.unidadeNome_ProcessStakeholders = null;
        this.selectableUnidades_ProcessStakeholders = this.unidades_ProcessStakeholders;
    }

    selectUnidade_ProcessStakeholders() {
        let prefix = this.patriarcaNome_ProcessStakeholders.split(' - ')[0];
        prefix += ` - ${this.organizacaoNome_ProcessStakeholders.split(' - ')[0]}`;

        this.inputConfigSchema.taskRegisterProcess.stakeholders.push(new Stakeholder({
            id: this.unidadeId_ProcessStakeholders,
            name: `${prefix} - ${this.unidadeNome_ProcessStakeholders}`,
            type: StakeholderType.Unit
        }));
    }

    toggleUnidadeSection() {
        this.isShowingUnidadeSection = !this.isShowingUnidadeSection;
    }
    // #endregion

    // #region [Interessados :: Grupo]
    grupoDisplayChange_ProcessStakeholders() {
        this.selectableGrupos_ProcessStakeholders = this.gruposFilter.transform(this.grupos_ProcessStakeholders, this.grupoDisplay_ProcessStakeholders);
    }

    grupoIdChange_ProcessStakeholders(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.grupoId_ProcessStakeholders = event.option.value.guid;
        }

        if (this.grupoId_ProcessStakeholders == '') {
            this.clearGrupo_ProcessStakeholders();
        } else {
            this.grupoNome_ProcessStakeholders = this.grupos_ProcessStakeholders.find(x => x.guid == this.grupoId_ProcessStakeholders).nome.toUpperCase();
        }
    }

    displayGrupoId_ProcessStakeholders(option): string {
        return !Utils.isNullOrEmpty(option)
            ? option.nome.toUpperCase()
            : '';
    }

    clearGrupo_ProcessStakeholders() {
        this.grupoId_ProcessStakeholders = '';
        this.grupoDisplay_ProcessStakeholders = '';
        this.grupoNome_ProcessStakeholders = null;
        this.selectableGrupos_ProcessStakeholders = this.grupos_ProcessStakeholders;
    }

    selectGrupo_ProcessStakeholders() {
        let prefix = this.patriarcaNome_ProcessStakeholders.split(' - ')[0];
        prefix += ` - ${this.organizacaoNome_ProcessStakeholders.split(' - ')[0]}`;
        prefix += ` - ${this.unidadeNome_ProcessStakeholders.split(' - ')[0]}`;

        this.inputConfigSchema.taskRegisterProcess.stakeholders.push(new Stakeholder({
            id: this.grupoId_ProcessStakeholders,
            name: `${prefix} - ${this.grupoNome_ProcessStakeholders}`,
            type: StakeholderType.Group
        }));
    }

    toggleGrupoSection() {
        this.isShowingGrupoSection = !this.isShowingGrupoSection;
    }
    // #endregion

    // #region [Interessados :: Comissão]
    comissaoDisplayChange_ProcessStakeholders() {
        this.selectableComissoes_ProcessStakeholders = this.comissoesFilter.transform(this.comissoes_ProcessStakeholders, this.comissaoDisplay_ProcessStakeholders);
    }

    comissaoIdChange_ProcessStakeholders(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.comissaoId_ProcessStakeholders = event.option.value.guid;
        }

        if (this.comissaoId_ProcessStakeholders == '') {
            this.clearComissao_ProcessStakeholders();
        } else {
            this.comissaoNome_ProcessStakeholders = this.comissoes_ProcessStakeholders.find(x => x.guid == this.comissaoId_ProcessStakeholders).nome.toUpperCase();
        }
    }

    displayComissaoId_ProcessStakeholders(option): string {
        return !Utils.isNullOrEmpty(option)
            ? option.nome.toUpperCase()
            : '';
    }

    clearComissao_ProcessStakeholders() {
        this.comissaoId_ProcessStakeholders = '';
        this.comissaoDisplay_ProcessStakeholders = '';
        this.comissaoNome_ProcessStakeholders = null;
        this.selectableComissoes_ProcessStakeholders = this.comissoes_ProcessStakeholders;
    }

    selectComissao_ProcessStakeholders() {
        let prefix = this.patriarcaNome_ProcessStakeholders.split(' - ')[0];
        prefix += ` - ${this.organizacaoNome_ProcessStakeholders.split(' - ')[0]}`;
        prefix += ` - ${this.unidadeNome_ProcessStakeholders.split(' - ')[0]}`;

        this.inputConfigSchema.taskRegisterProcess.stakeholders.push(new Stakeholder({
            id: this.comissaoId_ProcessStakeholders,
            name: `${prefix} - ${this.comissaoNome_ProcessStakeholders}`,
            type: StakeholderType.Comission
        }));
    }

    toggleComissaoSection() {
        this.isShowingComissaoSection = !this.isShowingComissaoSection;
    }
    // #endregion

    // #region [Interessados :: Servidor]
    servidorPapelDisplayChange_ProcessStakeholders() {
        this.selectableServidorPapeis_ProcessStakeholders = this.papeisFilter.transform(this.servidorPapeis_ProcessStakeholders, this.servidorPapelDisplay_ProcessStakeholders);
    }

    servidorPapelIdChange_ProcessStakeholders(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.servidorPapelId_ProcessStakeholders = event.option.value.guid;
        }

        if (this.servidorPapelId_ProcessStakeholders == '') {
            this.clearServidorPapel_ProcessStakeholders();
        } else {
            let servidor = this.servidorPapeis_ProcessStakeholders.find(x => x.guid == this.servidorPapelId_ProcessStakeholders);
            this.servidorPapelNome_ProcessStakeholders = servidor.nome.toUpperCase();
        }
    }

    displayServidorPapelId_ProcessStakeholders(option): string {
        return !Utils.isNullOrEmpty(option)
            ? option.nome.toUpperCase()
            : '';
    }

    clearServidorPapel_ProcessStakeholders() {
        this.servidorPapelId_ProcessStakeholders = '';
        this.servidorPapelDisplay_ProcessStakeholders = '';
        this.servidorPapelNome_ProcessStakeholders = null;
        this.selectableServidorPapeis_ProcessStakeholders = this.servidorPapeis_ProcessStakeholders;
    }

    selectServidorPapel_ProcessStakeholders() {
        let papel = this.servidorPapeis_ProcessStakeholders.find(x => x.guid == this.servidorPapelId_ProcessStakeholders);
        let suffix = papel.lotacao.nomeCurto;
        suffix += ` - ${papel.lotacao.organizacao.sigla}`;
        suffix += ` - ${papel.lotacao.organizacao.organizacaoPai.sigla}`;

        this.inputConfigSchema.taskRegisterProcess.stakeholders.push(new Stakeholder({
            id: this.servidorPapelId_ProcessStakeholders,
            name: `${this.servidorNome_ProcessStakeholders} - ${this.servidorPapelNome_ProcessStakeholders} - ${suffix}`,
            type: StakeholderType.PublicAgent
        }));
    }

    async searchServidorCpf_ProcessStakeholders() {
        if (!Utils.isNullOrEmpty(this.servidorCpf_ProcessStakeholders)) {
            let response = await this.acessoCidadaoService.getAgentePublicoSub(this.servidorCpf_ProcessStakeholders);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            response = await this.acessoCidadaoService.getAgentePublico(response.data.sub);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.servidorNome_ProcessStakeholders = response.data.nome;

            let responsePapeis = await this.acessoCidadaoService.getAgentePublicoPapeis(response.data.sub);

            if (!responsePapeis.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.servidorPapeis_ProcessStakeholders = responsePapeis.data;
            this.servidorPapeis_ProcessStakeholders.sort((a, b) => {
                let first = a.nome || '';
                let second = b.nome || '';
                return first.localeCompare(second);
            });
            this.selectableServidorPapeis_ProcessStakeholders = this.servidorPapeis_ProcessStakeholders;
        }
    }

    clearServidorCpf_ProcessStakeholders() {
        this.servidorCpf_ProcessStakeholders = '';
    }

    toggleServidorSection() {
        this.isShowingServidorSection = !this.isShowingServidorSection;
    }
    // #endregion

    // #region [Interessados :: Cidadão]
    selectCidadaoFlowInstanceOwner_ProcessStakeholders() {
        this.inputConfigSchema.taskRegisterProcess.stakeholders.push(new Stakeholder({
            name: 'Instanciador do Fluxo',
            isCitizenFlowInstanceOwner: true,
            type: StakeholderType.Citizen
        }));
    }

    selectCidadaoFormField_ProcessStakeholders() {
        if (!Utils.isNullOrEmpty(this.cidadaoFormField_ProcessStakeholders)) {
            this.inputConfigSchema.taskRegisterProcess.stakeholders.push(new Stakeholder({
                name: `CPF obtido do campo "${this.cidadaoFormField_ProcessStakeholders}"`,
                citizenCpfFormField: this.cidadaoFormField_ProcessStakeholders,
                type: StakeholderType.Citizen
            }));
        }
    }

    clearCidadaoFormField_ProcessStakeholders() {
        this.cidadaoFormField_ProcessStakeholders = '';
    }

    toggleCidadaoSection() {
        this.isShowingCidadaoSection = !this.isShowingCidadaoSection;
    }
    // #endregion

    // #region [Interessados :: Pessoa Jurídica]
    addCompanyStakeholder() {
        let stakeholder = new Stakeholder({
            type: StakeholderType.Company
        });

        if (!Utils.isNullOrEmpty(this.empresaCompanyName_ProcessStakeholders)) {
            stakeholder.companyName = this.empresaCompanyName_ProcessStakeholders;
        } else {
            stakeholder.companyName = `Razão Social obtida do campo "${this.empresaCompanyNameFormField_ProcessStakeholders}"`;
            stakeholder.companyNameFormField = this.empresaCompanyNameFormField_ProcessStakeholders;
        }

        if (!Utils.isNullOrEmpty(this.empresaCnpj_ProcessStakeholders)) {
            stakeholder.cnpj = this.empresaCnpj_ProcessStakeholders;
        } else {
            stakeholder.cnpj = `CNPJ obtido do campo "${this.empresaCnpjFormField_ProcessStakeholders}"`;
            stakeholder.cnpjFormField = this.empresaCnpjFormField_ProcessStakeholders;
        }

        if (!Utils.isNullOrEmpty(this.empresaEmail_ProcessStakeholders)) {
            stakeholder.email = this.empresaEmail_ProcessStakeholders;
        } else {
            stakeholder.email = `E-mail obtido do campo "${this.empresaEmailFormField_ProcessStakeholders}"`;
            stakeholder.emailFormField = this.empresaEmailFormField_ProcessStakeholders;
        }

        this.inputConfigSchema.taskRegisterProcess.stakeholders.push(stakeholder);
    }

    shouldDisableAddCompanyStakeholder(): boolean {
        return (this.isEmpresaCompanyNameFreeEntry && Utils.isNullOrEmpty(this.empresaCompanyName_ProcessStakeholders))
            || (!this.isEmpresaCompanyNameFreeEntry && Utils.isNullOrEmpty(this.empresaCompanyNameFormField_ProcessStakeholders))
            || (this.isEmpresaCnpjFreeEntry && Utils.isNullOrEmpty(this.empresaCnpj_ProcessStakeholders))
            || (!this.isEmpresaCnpjFreeEntry && Utils.isNullOrEmpty(this.empresaCnpjFormField_ProcessStakeholders))
            || (this.isEmpresaEmailFreeEntry && Utils.isNullOrEmpty(this.empresaEmail_ProcessStakeholders))
            || (!this.isEmpresaEmailFreeEntry && Utils.isNullOrEmpty(this.empresaEmailFormField_ProcessStakeholders));
    }

    clearEmpresaCompanyName_ProcessStakeholders() {
        this.empresaCompanyName_ProcessStakeholders = null;
    }

    clearEmpresaCompanyNameFormField_ProcessStakeholders() {
        this.empresaCompanyNameFormField_ProcessStakeholders = '';
    }

    clearEmpresaCnpj_ProcessStakeholders() {
        this.empresaCnpj_ProcessStakeholders = null;
    }

    clearEmpresaCnpjFormField_ProcessStakeholders() {
        this.empresaCnpjFormField_ProcessStakeholders = '';
    }

    clearEmpresaEmail_ProcessStakeholders() {
        this.empresaEmail_ProcessStakeholders = null;
    }

    clearEmpresaEmailFormField_ProcessStakeholders() {
        this.empresaEmailFormField_ProcessStakeholders = '';
    }

    toggleEmpresaSection() {
        this.isShowingEmpresaSection = !this.isShowingEmpresaSection;
    }
    // #endregion

    updateSourceCode(forcedConfigSchema: ConfigSchema) {
        this.inputConfigSchema = forcedConfigSchema;

        Object.assign(this.configSchemaSectionDocumentClass, this.inputConfigSchema.taskRegisterProcess.processClass);

        this.initProcessInfo();
    }

    onUpdateProcessClass(event: ConfigSchemaSectionDocumentClass) {
        Object.assign(this.configSchemaSectionDocumentClass, event);
        Object.assign(this.inputConfigSchema.taskRegisterProcess.processClass, this.configSchemaSectionDocumentClass);
    }

    resolveProcessInfo(): boolean {
        if (this.processSummary?.length < 5) {
            this.toastr.error(Enums.Messages.MandatoryProcessSummary, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return false;
        }

        let div = document.createElement('div');
        div.innerHTML = this.processSummary?.replaceAll('<br />', '\n');
        this.inputConfigSchema.taskRegisterProcess.processSummary = div.textContent || div.innerText || '';

        return true;
    }

    // ======================
    // private methods
    // ======================

    private initProcessInfo() {
        this.processSummary = this.inputConfigSchema.taskRegisterProcess.processSummary?.replaceAll('\n', '<br />')

        this.cidadaoFormFieldOptions_ProcessStakeholders = this.formFlowObjectSchemaFields.map(x => ({ value: x.key, description: x.label }));
        this.empresaCompanyNameFormFieldOptions_ProcessStakeholders = this.formFlowObjectSchemaFields.map(x => ({ value: x.key, description: x.label }));
        this.empresaCnpjFormFieldOptions_ProcessStakeholders = this.formFlowObjectSchemaFields.map(x => ({ value: x.key, description: x.label }));
        this.empresaEmailFormFieldOptions_ProcessStakeholders = this.formFlowObjectSchemaFields.map(x => ({ value: x.key, description: x.label }));
    }

    private getTinyMceMenuFormEntries(flowObject: FlowObjectDefinition, editor): any[] {
        if (Utils.isNullOrEmpty(flowObject.formSchema)) return;

        let entries = [];
        let formSchema = JSON.parse(flowObject.formSchema) as FormSchema;

        Utils.getFormFieldsWithUsableValue(formSchema.components).forEach(item => {
            entries.push({
                type: 'menuitem',
                text: item.label,
                onAction: () => editor.insertContent(`{|${item.key}|}`)
            });
        });

        return entries;
    }
}
