import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import { Plano, Classe, FundamentoLegal } from '../models/edocs.model';
import { Patriarca } from '../models/edocs.model';

@Injectable({
    providedIn: 'root'
})
export class EDocsService extends BaseService {
    controllerRoute: string = 'EDocs';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async getPatriarcas(): Promise<IBaseModel<Patriarca[]>> {
        return this.httpClient.get<IBaseModel<Patriarca[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/Patriarcas`).toPromise();
    }

    public async getPlanosAtivos(idPatriarca: string): Promise<IBaseModel<Plano[]>> {
        return this.httpClient.get<IBaseModel<Plano[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/PlanosAtivos/${idPatriarca}`).toPromise();
    }

    public async getClassesAtivas(idPlano: string): Promise<IBaseModel<Classe[]>> {
        return this.httpClient.get<IBaseModel<Classe[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/ClassesAtivas/${idPlano}`).toPromise();
    }

    public async getFundamentosLegaisPatriarca(idPatriarca: string): Promise<IBaseModel<FundamentoLegal[]>> {
        return this.httpClient.get<IBaseModel<FundamentoLegal[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/FundamentosLegais/${idPatriarca}`).toPromise();
    }

    public async getFundamentosLegaisCidadao(): Promise<IBaseModel<FundamentoLegal[]>> {
        return this.httpClient.get<IBaseModel<FundamentoLegal[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/FundamentosLegais`).toPromise();
    }
}
