<header>
    <nav class="navbar navbar-light px-2">
        <div class="wrapper">
            <div class="keep-left">
                <div class="show justify-content-start align-self-center mr-2" *ngIf="!isLandingPage">
                    <ul class="navbar-nav flex-grow">
                        <main-menu></main-menu>
                    </ul>
                </div>

                <a class="mini-eflow-link" matTooltip="Acessar a página inicial do E-Flow Admin" *ngIf="!isLandingPage; else landingPageTemplate"
                   [routerLink]="[Enums.PagesPaths.Home]"
                >
                    <img class="mini-eflow-logo" alt="E-Flow" src="/assets/img/logo_eflow_admin.svg" />
                </a>
                <ng-template #landingPageTemplate>
                    <a class="mini-eflow-link" matTooltip="E-Flow Admin">
                        <img class="mini-eflow-logo" alt="E-Flow" src="/assets/img/logo_eflow_admin.svg" />
                    </a>
                </ng-template>
            </div>

            <div class="navbar-collapse collapse show">
                <!--<div class="token-timer mr-2" [class.blink]="tokenTimer == 0">
                    <i class="fas fa-redo-alt fa-spin mr-2" *ngIf="tokenTimer == 0"></i>
                    <span>{{tokenTimerFormatted}}</span>
                </div>-->

                <ul class="navbar-nav flex-grow" *ngIf="!isLandingPage">
                    <help-menu></help-menu>
                    <user-menu></user-menu>
                </ul>

                <button type="button" class="btn btn-warning login" *ngIf="showLogInButton"
                        (click)="logIn()"
                >Acessar</button>
            </div>
        </div>
    </nav>
</header>
