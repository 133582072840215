import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dashboard-item',
    templateUrl: './dashboard-item.component.html',
    styleUrls: ['./dashboard-item.component.scss']
})
export class DashboardItemComponent implements OnInit {
    @Input() inputCounter: string;
    @Input() inputCounterLabel: string;

    constructor() { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() { }

    // ======================
    // public methods
    // ======================

    // ======================
    // private methods
    // ======================
}
