import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormioCustomComponent } from '@formio/angular';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../../shared/enums';
import { Utils } from '../../../shared/utils';

@Component({
    selector: 'pdf-upload',
    templateUrl: './pdf-upload.component.html',
    styleUrls: ['./pdf-upload.component.scss']
})
export class PdfUploadComponent implements FormioCustomComponent<any> {
    private MAX_SIZE_MB: number = 15;

    fileName: string = null;

    @Input() disabled: boolean;
    @Input() value: any;
    @Output() valueChange = new EventEmitter<any>();

    constructor(private toastr: ToastrService) { }

    // ======================
    // lifecycle methods
    // ======================

    // ======================
    // public methods
    // ======================

    changeFile(event) {
        const file = event.target.files[0] as File;

        // caso a extensão do arquivo não seja PDF
        if (file.name.split('.').slice(-1)[0].toLowerCase() != 'pdf') {
            // reinicia valores do input e do componente Formio
            this.removeFile(event);
            this.toastr.error(Enums.Messages.PdfFilesOnly, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        // caso o tamanho do arquivo seja maior que o máximo estabelecido
        if (file.size > this.MAX_SIZE_MB * 1024 * 1024) {
            // reinicia valores do input e do componente Formio
            this.removeFile(event);
            this.toastr.error(Enums.Messages.PdfMaxSizeLimit.replace('{0}', this.MAX_SIZE_MB.toString()), Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onloadend = () => {
            // converte o arquivo para um array de bytes, uma vez que o "JSON.stringify > JSON.parse"
            // que ocorre no meio do caminho até o hook do evento de submit do Formio
            // transformaria o objeto do tipo File em um objeto vazio ("{}")
            let byteArray = [];
            let array = new Uint8Array(fileReader.result as ArrayBuffer);
            for (let byte of array) {
                byteArray.push(byte);
            }

            this.fileName = file.name;
            this.value = {
                isCustomFileComponent: true,
                fileName: [file.name],
                fileType: [file.type],
                fileContent: [byteArray]
            };
            this.valueChange.emit(this.value);
        };
    }

    removeFile(event) {
        event.target.value = '';
        this.fileName = null;
        this.value = {};
        this.valueChange.emit(this.value);
    }

    // ======================
    // private methods
    // ======================
}
