import { Component } from '@angular/core';
import { Enums } from './shared/enums';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    title = 'app';

    get isLandingPage(): boolean {
        return window?.location?.pathname == Enums.PagesPaths.LandingPage;
    }

    constructor() { }
}
