export class DashboardAdmin {
    totalFinishedFlowInstances?: number;
    averageFinishedFlowInstancesDay?: number;
    averageFlowInstanceFinishingTimeSeconds?: number;
    averageFlowInstanceFinishingTimeSecondsProcessed?: string;
    percentageFinishedFlowInstancesWeekday1?: number;
    percentageFinishedFlowInstancesWeekday2?: number;
    percentageFinishedFlowInstancesWeekday3?: number;
    percentageFinishedFlowInstancesWeekday4?: number;
    percentageFinishedFlowInstancesWeekday5?: number;
    percentageFinishedFlowInstancesWeekday6?: number;
    percentageFinishedFlowInstancesWeekday7?: number;
    percentageFinishedFlowInstancesHour0?: number;
    percentageFinishedFlowInstancesHour1?: number;
    percentageFinishedFlowInstancesHour2?: number;
    percentageFinishedFlowInstancesHour3?: number;
    percentageFinishedFlowInstancesHour4?: number;
    percentageFinishedFlowInstancesHour5?: number;
    percentageFinishedFlowInstancesHour6?: number;
    percentageFinishedFlowInstancesHour7?: number;
    percentageFinishedFlowInstancesHour8?: number;
    percentageFinishedFlowInstancesHour9?: number;
    percentageFinishedFlowInstancesHour10?: number;
    percentageFinishedFlowInstancesHour11?: number;
    percentageFinishedFlowInstancesHour12?: number;
    percentageFinishedFlowInstancesHour13?: number;
    percentageFinishedFlowInstancesHour14?: number;
    percentageFinishedFlowInstancesHour15?: number;
    percentageFinishedFlowInstancesHour16?: number;
    percentageFinishedFlowInstancesHour17?: number;
    percentageFinishedFlowInstancesHour18?: number;
    percentageFinishedFlowInstancesHour19?: number;
    percentageFinishedFlowInstancesHour20?: number;
    percentageFinishedFlowInstancesHour21?: number;
    percentageFinishedFlowInstancesHour22?: number;
    percentageFinishedFlowInstancesHour23?: number;

    public constructor(init ?: Partial<DashboardAdmin>) {
        Object.assign(this, init);
    }
}

export const DashboardAdminLabel = new Map<string, string>([
    ['percentageFinishedFlowInstancesWeekday1', 'Domingo'],
    ['percentageFinishedFlowInstancesWeekday2', 'Segunda'],
    ['percentageFinishedFlowInstancesWeekday3', 'Terça'],
    ['percentageFinishedFlowInstancesWeekday4', 'Quarta'],
    ['percentageFinishedFlowInstancesWeekday5', 'Quinta'],
    ['percentageFinishedFlowInstancesWeekday6', 'Sexta'],
    ['percentageFinishedFlowInstancesWeekday7', 'Sábado'],
    ['percentageFinishedFlowInstancesHour0', '0h'],
    ['percentageFinishedFlowInstancesHour1', '1h'],
    ['percentageFinishedFlowInstancesHour2', '2h'],
    ['percentageFinishedFlowInstancesHour3', '3h'],
    ['percentageFinishedFlowInstancesHour4', '4h'],
    ['percentageFinishedFlowInstancesHour5', '5h'],
    ['percentageFinishedFlowInstancesHour6', '6h'],
    ['percentageFinishedFlowInstancesHour7', '7h'],
    ['percentageFinishedFlowInstancesHour8', '8h'],
    ['percentageFinishedFlowInstancesHour9', '9h'],
    ['percentageFinishedFlowInstancesHour10', '10h'],
    ['percentageFinishedFlowInstancesHour11', '11h'],
    ['percentageFinishedFlowInstancesHour12', '12h'],
    ['percentageFinishedFlowInstancesHour13', '13h'],
    ['percentageFinishedFlowInstancesHour14', '14h'],
    ['percentageFinishedFlowInstancesHour15', '15h'],
    ['percentageFinishedFlowInstancesHour16', '16h'],
    ['percentageFinishedFlowInstancesHour17', '17h'],
    ['percentageFinishedFlowInstancesHour18', '18h'],
    ['percentageFinishedFlowInstancesHour19', '19h'],
    ['percentageFinishedFlowInstancesHour20', '20h'],
    ['percentageFinishedFlowInstancesHour21', '21h'],
    ['percentageFinishedFlowInstancesHour22', '22h'],
    ['percentageFinishedFlowInstancesHour23', '23h'],
]);

export const DashboardAdminDescription = new Map<string, string>([
    ['totalFinishedFlowInstances', 'Total de Fluxos executados no período'],
    ['averageFinishedFlowInstancesDay', 'Média de Fluxos executados por dia no período'],
    ['averageFlowInstanceFinishingTimeSeconds', 'Tempo médio de execução dos Fluxos no período'],
    ['percentageFinishedFlowInstancesWeekday1', 'Média de Fluxos executados aos domingos do período'],
    ['percentageFinishedFlowInstancesWeekday2', 'Média de Fluxos executados às segundas do período'],
    ['percentageFinishedFlowInstancesWeekday3', 'Média de Fluxos executados às terças do período'],
    ['percentageFinishedFlowInstancesWeekday4', 'Média de Fluxos executados às quartas do período'],
    ['percentageFinishedFlowInstancesWeekday5', 'Média de Fluxos executados às quintas do período'],
    ['percentageFinishedFlowInstancesWeekday6', 'Média de Fluxos executados às sextas do período'],
    ['percentageFinishedFlowInstancesWeekday7', 'Média de Fluxos executados aos sábados do período'],
]);

export class SearchToken {
    searchCriteriaType?: SearchCriteriaType;
    text?: string;
    key?: string;
    addedTimestamp?: Date = new Date();

    public constructor(init?: Partial<SearchToken>) {
        Object.assign(this, init);
    }
}

export class DashboardSortState {
    descending: boolean = false;
    field: DashboardSortField = DashboardSortField.Name;

    public constructor(init?: Partial<DashboardSortState>) {
        Object.assign(this, init);
    }
}

export class FlowInfoFilterGroup {
    flowInboxId: string;
    flowDefinitionId: string;
    version: string;

    public constructor(init?: Partial<FlowInfoFilterGroup>) {
        Object.assign(this, init);
    }
}

export class FlowInstanceCsv {
    flowDefinitionId?: string;
    name?: string;
    version?: number;
    createDate?: Date;
    finishDate?: Date;
    inputData?: string;
    inputDataParsed?: InputDataTaskForm;
    ownerInfo?: string;
    ownerRole?: string;
    forwardingProtocol?: string;
    lastExecutedTask?: string;
}

export class InputDataTaskForm {
    data?: object;
}

export enum DashboardSortField {
    CreateDate,
    UpdateDate,
    Name,
    OwnerInfo
}

export enum SearchCriteriaType {
    Custom,
    FinishDateFrom,
    FinishDateUntil,
    FlowInbox,
    FlowDefinition,
    Version
}

export enum CustomDateTimeCriteriaType {
    Today,
    Last24,
    Last48,
    Last72,
    LastWeek,
    LastFortnight,
    LastMonth,
    LastYear
}
