<ng-container *ngIf="model != null">
    <form class="flow-object-form" (ngSubmit)="onSubmit()" #flowObjectForm="ngForm">
        <!-- Form Builder -->
        <div class="form-builder-wrapper debounce-hidden" #wrapperRef>
            <form-builder [form]="formSchema" [options]="options" (change)="onChange($event)"></form-builder>
        </div>

        <!-- Botões -->
        <div class="form-footer form-group text-right mt-3 mb-1 mr-1">
            <hr />

            <!-- FormSchema -->
            <button type="button" class="btn btn-outline-secondary float-left ml-1"
                    (click)="showFormSchema()"
            >Editar código-fonte</button>

            <!-- Importar CSV -->
            <button type="button" class="btn btn-outline-secondary float-left ml-1"
                    (click)="showImportCsv()"
            >Importar CSV em Lista de Seleção</button>

            <!-- Cancelar -->
            <button type="button" class="btn btn-outline-danger mr-2"
                    (click)="closeForm()"
            >Cancelar</button>

            <!-- Salvar -->
            <button type="submit" class="btn btn-outline-primary"
                    [class.read-only]="inputIsReadOnlyMode"
                    [disabled]="!flowObjectForm.form.valid || inputIsReadOnlyMode"
            >Salvar Definição de Formulário</button>
        </div>
    </form>

    <!-- Modal do FormSchema -->
    <ng-template #formSchemaRef>
        <textarea id="formSchemaEditor" name="formSchemaEditor" class="form-control" #formSchemaEditorRef
                  style="height: calc(100vh - 78px); resize: none; opacity: 0; transition: opacity .3s ease-in"
                  [ngModel]="formSchema"
        ></textarea>
    </ng-template>

    <!-- Modal do Importar CSV -->
    <ng-template #importCsvRef>
        <div class="instructions-panel text-highlight relevant-info-font mb-3">
            <p>
                Escolha abaixo o campo do tipo "Lista de Seleção" do formulário atual que gostaria de alterar e,
                em seguida, o arquivo CSV contendo a lista de opções personalizada. Concluída a importação com sucesso,
                o campo "Lista de Seleção" indicado terá as opções listadas no CSV <b>adicionadas</b> às opções
                existentes, caso exista alguma.
            </p>
            <p>
                O arquivo CSV deve:
            </p>

            <ul>
                <li>
                    <p>
                        conter apenas duas colunas -- "rotulo" e "valor". Os valores podem se repetir ou não
                        entre as colunas, dependendo da necessidade. Exemplos:
                    </p>

                    <div class="example-container mb-3">
                        <table>
                            <tr><th>rotulo</th><th>valor</th></tr>
                            <tr><td>Vitória</td><td>VIX</td></tr>
                            <tr><td>Vila Velha</td><td>VV</td></tr>
                            <tr><td>Serra</td><td>SER</td></tr>
                        </table>
                        <table>
                            <tr><th>rotulo</th><th>valor</th></tr>
                            <tr><td>Vitória</td><td>Vitória</td></tr>
                            <tr><td>Vila Velha</td><td>Vila Velha</td></tr>
                            <tr><td>Serra</td><td>Serra</td></tr>
                        </table>
                    </div>
                </li>
                <li>
                    <p>
                        utilizar apenas ponto-e-vírgula (";") como separador. Arquivos CSV dos exemplos acima
                        quando abertos em um editor de texto como o "Bloco de Notas":
                    </p>

                    <div class="example-container mb-3">
                        <div class="px-2 py-1">
                            <div>rotulo;valor</div>
                            <div>Vitória;VIX</div>
                            <div>Vila Velha;VV</div>
                            <div>Serra;SER</div>
                        </div>
                        <div class="px-2 py-1">
                            <div>rotulo;valor</div>
                            <div>Vitória;Vitória</div>
                            <div>Vila Velha;Vila Velha</div>
                            <div>Serra;Serra</div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="form-group">
            <label for="dropdownId">Lista de Seleção a receber os dados</label>
            <select class="form-control relevant-info-font" id="dropdownId" name="dropdownId" required
                    [(ngModel)]="selectedDropdown"
            >
                <option *ngFor="let option of availableDropdownsOptions" [value]="option.value">{{option.description}}</option>
            </select>
        </div>

        <div class="form-group">
            <label>Arquivo CSV contendo os dados das opções</label>
            <file-upload [maxSizeMb]="10" [allowedExtensions]="['csv']" [(value)]="csvFile"></file-upload>
        </div>

        <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-outline-primary"
                    [disabled]="Utils.isNullOrEmpty(selectedDropdown) || csvFile == null || Utils.isEmptyObject(csvFile)"
                    (click)="loadOptions()"
            >
                <span>Carregar opções</span>
            </button>
        </div>
    </ng-template>
</ng-container>
