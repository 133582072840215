<div class="wrapper">
    <!-- área 1 -->
    <ng-content>
    <!--<div class="area a-1 py-5 px-2">
        <div class="area-content">-->
            <!-- imagem -->
            <!--<div class="keep-left">
                <div class="base-item img-11">
                    <img class="box-shadow" src="../../../assets/img/img-12.png" [class.show]="currentWindow == 0" />
                    <img class="box-shadow" src="../../../assets/img/img-13.png" [class.show]="currentWindow == 1" />-->
                    <!--<img class="box-shadow" src="../../../assets/img/img-11.png" [class.show]="currentWindow == 2" />-->
                <!--</div>
            </div>-->

            <!-- tópicos -->
            <!--<div class="keep-right">
                <div class="base-item txt-12">
                    <span>Suas tarefas do E-Docs nunca foram tão ágeis</span>
                </div>

                <div class="base-item txt-11 mt-5">
                    <i class="fas fa-share mr-2"></i>
                    <span>Automatize procedimentos recorrentes no E-Docs para seu órgão</span>
                </div>

                <div class="base-item txt-11 mt-4">
                    <i class="fas fa-share mr-2"></i>
                    <span>Configure apenas uma vez e receba seus documentos corretamente sempre</span>
                </div>
            </div>
        </div>
    </div>-->
    </ng-content>

    <!-- área 1b -->
    <div class="area a-1b py-5 px-2">
        <div class="area-content">
            <!-- imagem -->
            <div class="keep-left" *ngIf="showEdoquinho">
                <div class="base-item img-11">
                    <img class="" src="../../../assets/img/edoquinho_eflow.png" />
                </div>
            </div>

            <!-- tópicos -->
            <div class="keep-right">
                <div class="base-item txt-12">
                    <span>Suas tarefas do E-Docs nunca foram tão ágeis</span>
                </div>

                <div class="base-item txt-11 mt-5">
                    <i class="fas fa-share mr-2"></i>
                    <span>Automatize procedimentos recorrentes no E-Docs para seu órgão ou para o cidadão</span>
                </div>

                <div class="base-item txt-11 mt-4">
                    <i class="fas fa-share mr-2"></i>
                    <span>Configure apenas uma vez e receba seus documentos corretamente sempre</span>
                </div>
            </div>
        </div>
    </div>

    <!-- área 2a -->
    <div class="area a-2a py-5 px-2">
        <!-- row 1 -->
        <div class="area-content mb-5">
            <div class="keep-left">
                <div class="base-item txt-21">
                    <span class="topic py-2">
                        <span class="order">1</span>
                        Com o E-Flow, você cria formulários dinâmicos e disponibiliza para a população
                        ou servidores do seu órgão
                    </span>
                    <i class="far fa-hand-point-right ml-4"></i>
                </div>
            </div>

            <div class="keep-right">
                <div class="base-item img-21">
                    <img class="box-shadow" src="../../../assets/img/img-24.png" />
                </div>
            </div>
        </div>

        <!-- row 2 -->
        <div class="area-content my-5">
            <div class="keep-left">
                <div class="base-item txt-21">
                    <span class="topic py-2">
                        <span class="order skinny">2</span>
                        Cada formulário preenchido vira um documento PDF assinado pelo cidadão e é encaminhado
                        automaticamente para uma caixa do E-Docs escolhida por você
                    </span>
                    <i class="far fa-hand-point-right ml-4"></i>
                </div>
            </div>

            <div class="keep-right">
                <div class="base-item img-21">
                    <img class="box-shadow" src="../../../assets/img/img-22.png" />
                </div>
            </div>
        </div>

        <!-- row 3 -->
        <div class="area-content mt-5">
            <div class="keep-left">
                <div class="base-item txt-21">
                    <span class="topic py-2">
                        <span class="order skinny">3</span>
                        Após terminar o preenchimento, as informações são enviadas diretamente
                        ao setor responsável
                    </span>
                    <i class="far fa-hand-point-right ml-4"></i>
                </div>
            </div>

            <div class="keep-right">
                <div class="base-item img-21">
                    <img class="box-shadow" src="../../../assets/img/img-25.png" />
                </div>
            </div>
        </div>
    </div>

    <!-- área 2b -->
    <div class="area a-2b py-5 px-2">
        <!-- tópicos -->
        <div class="area-content">
            <div class="base-item txt-11">
                <i class="fas fa-share mr-2"></i>
                <span>Cansado de criar manuais complexos para receber documentos e formulários no E-Docs?</span>
            </div>

            <div class="base-item txt-11 mt-4">
                <i class="fas fa-share mr-2"></i>
                <span>Precisa explicar a mesma coisa várias vezes para receber as informações corretamente?</span>
            </div>

            <div class="base-item txt-11 mt-4">
                <i class="fas fa-share mr-2"></i>
                <span>Você precisa conhecer o E-Flow!</span>
            </div>
        </div>
    </div>

    <!-- área 2c -->
    <div class="area a-2c py-5 px-2">
        <!-- row 4 -->
        <div class="area-content mb-5">
            <div class="keep-left">
                <div class="base-item txt-21">
                    <span class="topic py-2">
                        Com o Construtor de Formulários, crie formulários simples e intuitivos, focados apenas
                        nas informações essenciais e sem necessidade de acessar o E-Docs
                    </span>
                    <i class="far fa-hand-point-right ml-4"></i>
                </div>
            </div>

            <div class="keep-right">
                <div class="base-item img-21">
                    <img class="box-shadow" src="../../../assets/img/img-21.png" />
                </div>
            </div>
        </div>

        <!-- row 5 -->
        <div class="area-content mt-5">
            <div class="keep-left">
                <div class="base-item txt-21">
                    <span class="topic py-2">
                        Crie campos, solicite anexos, configure o nível de acesso e a classe documental
                        e defina o modelo do documento PDF resultante
                    </span>
                    <i class="far fa-hand-point-right ml-4"></i>
                </div>
            </div>

            <div class="keep-right">
                <div class="base-item img-21">
                    <img class="box-shadow" src="../../../assets/img/img-23.png" />
                </div>
            </div>
        </div>
    </div>

    <!-- área 3 -->
    <div class="area a-3 py-5 px-2">
        <div class="area-content">
            <!-- header -->
            <div class="keep-up">
                <div class="base-item txt-31">
                    O E-Flow já conta com dezenas de fluxos de trabalho em diversos órgãos do Governo do Estado.
                    <br /><br />
                    Conheça alguns dos mais usados:
                </div>
            </div>

            <!-- body -->
            <div class="keep-down mt-5">
                <!-- card 1 -->
                <div class="base-item card-31 box-shadow p-3">
                    <div class="header">PGE</div>

                    <div class="sub-header mt-1">Pagamento de Advogados Dativos</div>

                    <div class="body mt-2">
                        Serviço para requisitar o pagamento de honorários pela atuação como Advogado Dativo nas esferas
                        do poder judiciário do Estado do Espírito Santo.
                    </div>

                    <div class="footer">
                        <a target="_blank" href="https://e-flow.es.gov.br/flow-definition/38c32372-13cd-8e0c-630e-b1eca22972a9">
                            <span>Confira</span>
                            <i class="fas fa-external-link-alt ml-1"></i>
                        </a>
                    </div>
                </div>

                <!-- card 2 -->
                <div class="base-item card-31 box-shadow p-3">
                    <div class="header">PROCON</div>

                    <div class="sub-header mt-1">Atendimento Eletrônico</div>

                    <div class="body mt-2">
                        Serviço para registrar reclamações sobre produtos adquiridos e serviços contratados como
                        contas de água, luz, telefone, internet, TV por assinatura, planos de saúde, empréstimos,
                        consórcios, financiamentos e outros.
                    </div>

                    <div class="footer">
                        <a target="_blank" href="https://e-flow.es.gov.br/flow-definition/81753be6-72c6-c67f-ce36-e51fee00a5c9">
                            <span>Confira</span>
                            <i class="fas fa-external-link-alt ml-1"></i>
                        </a>
                    </div>
                </div>

                <!-- card 3 -->
                <div class="base-item card-31 box-shadow p-3">
                    <div class="header">SEGER</div>

                    <div class="sub-header mt-1">CRC</div>

                    <div class="body mt-2">
                        Serviço para inscrição no Certificado de Registro Cadastral (CRC), concedido a pessoas físicas
                        ou jurídicas interessadas em participar de procedimentos licitatórios junto ao Governo do Estado do Espirito Santo.
                    </div>

                    <div class="footer">
                        <a target="_blank" href="https://e-flow.es.gov.br/flow-definition/261dd60f-70c7-1bd0-80ce-4f7a12cb3372">
                            <span>Confira</span>
                            <i class="fas fa-external-link-alt ml-1"></i>
                        </a>
                    </div>
                </div>

                <!-- card 4 -->
                <div class="base-item card-31 box-shadow p-3">
                    <div class="header">HPM</div>

                    <div class="sub-header mt-1">Solicitação de EDI</div>

                    <div class="body mt-2">
                        Serviço para solicitação de exames de diagnóstico por imagem, destinado exclusivamente
                        ao militar estadual ativo ou inativo do Estado do Espírito Santo ou seu dependente legal.
                    </div>

                    <div class="footer">
                        <a target="_blank" href="https://e-flow.es.gov.br/flow-definition/c50e61ac-2d23-25dd-1b3c-786faa1384e3">
                            <span>Confira</span>
                            <i class="fas fa-external-link-alt ml-1"></i>
                        </a>
                    </div>
                </div>

                <!-- card 5 -->
                <div class="base-item card-31 box-shadow p-3">
                    <div class="header">IEMA</div>

                    <div class="sub-header mt-1">Criação Amadora de Passeriforme</div>

                    <div class="body mt-2">
                        Requerimento de diversos serviços relacionados ao Sistema de Controle e Monitoramento
                        da Atividade de Criação Amadora de Pássaros (SISPASS).
                    </div>

                    <div class="footer">
                        <a target="_blank" href="https://e-flow.es.gov.br/flow-definition/98b22a89-ee01-2012-bdba-7ac0fdb930d6">
                            <span>Confira</span>
                            <i class="fas fa-external-link-alt ml-1"></i>
                        </a>
                    </div>
                </div>

                <!-- card 6 -->
                <div class="base-item card-31 box-shadow p-3">
                    <div class="header">IDAF</div>

                    <div class="sub-header mt-1">Dominialidade de Imóveis</div>

                    <div class="body mt-2">
                        Serviço para discriminar imóveis rurais ou urbanos regulares e identificar se dentro
                        de seu perímetro ou de seus eventuais acréscimos há terrenos públicos do Estado.
                    </div>

                    <div class="footer">
                        <a target="_blank" href="https://e-flow.es.gov.br/flow-definition/19589884-d3ef-dbd4-7367-69515e553af9">
                            <span>Confira</span>
                            <i class="fas fa-external-link-alt ml-1"></i>
                        </a>
                    </div>
                </div>

                <!-- card 7 -->
                <div class="base-item card-31 box-shadow p-3">
                    <div class="header">SEDU</div>

                    <div class="sub-header mt-1">PETE/REPASSE</div>

                    <div class="body mt-2">
                        Serviço para solicitação de apoio financeiro à SEDU para atendimento às demandas do transporte escolar
                        de municípios participantes do Programa de Transporte Escolar do Espírito Santo (PETE/ES).
                    </div>

                    <div class="footer">
                        <a target="_blank" href="https://e-flow.es.gov.br/flow-definition/76216428-e83c-0abc-08ef-3fc42376fbff">
                            <span>Confira</span>
                            <i class="fas fa-external-link-alt ml-1"></i>
                        </a>
                    </div>
                </div>

                <!-- card 8 -->
                <div class="base-item card-31 box-shadow p-3">
                    <div class="header">SEJUS</div>

                    <div class="sub-header mt-1">RECAE</div>

                    <div class="body mt-2">
                        Serviço para processar o pagamento de empresas prestadoras de serviço pela SEJUS por meio
                        do Relatório de Comprovação de Adimplência de Encargos - RECAE.
                    </div>

                    <div class="footer">
                        <a target="_blank" href="https://e-flow.es.gov.br/flow-definition/e5078df2-b396-9fcd-9b97-c168e364130d">
                            <span>Confira</span>
                            <i class="fas fa-external-link-alt ml-1"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- área 4 -->
    <div class="area a-4 py-5 px-2">
        <div class="area-content">
            <div class="footer-left">
                <a href="//www.es.gov.br/" target="_blank" matTooltip="Acessar o portal do Governo do Estado do Espírito Santo">
                    <img class="gov-logo" src="//cdn.es.gov.br/images/logo/governo/brasao/left-white/Brasao_Governo_240.png" alt="Governo do Estado Espírito Santo">
                </a>
            </div>

            <div class="footer-center">
                <img class="eflow-logo" alt="E-Flow" src="../../../assets/img/logo_eflow.svg" />
            </div>

            <div class="footer-right">
                <a href="//prodest.es.gov.br/" target="_blank" matTooltip="Acessar o site do PRODEST">
                    <img class="prodest-logo" src="//cdn.es.gov.br/images/logo/prodest/logomarca/left-white/Logomarca_Prodest_240.png" alt="Prodest">
                </a>
            </div>
        </div>
    </div>
</div>
