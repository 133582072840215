export const FORMBUILDER_SCHEMA_DEFAULT = {
    "components": [
        {
            "label": "Enviar",
            "action": "submit",
            "theme": "primary",
            "size": "md",
            "block": false,
            "description": "",
            "tooltip": "",
            "disableOnInvalid": false,
            "autofocus": false,
            "disabled": false,
            "key": "enviar",
            "conditional": {
                "show": null,
                "when": null,
                "eq": ""
            },
            "type": "button",
            "input": true,
            "placeholder": "",
            "prefix": "",
            "customClass": "",
            "suffix": "",
            "multiple": false,
            "defaultValue": null,
            "protected": false,
            "unique": false,
            "persistent": false,
            "hidden": false,
            "clearOnHide": true,
            "refreshOn": "",
            "redrawOn": "",
            "tableView": false,
            "modalEdit": false,
            "dataGridLabel": true,
            "labelPosition": "top",
            "errorLabel": "",
            "hideLabel": false,
            "tabindex": "",
            "dbIndex": false,
            "customDefaultValue": "",
            "calculateValue": "",
            "calculateServer": false,
            "widget": {
                "type": "input"
            },
            "attributes": {},
            "validateOn": "change",
            "validate": {
                "required": false,
                "custom": "",
                "customPrivate": false,
                "strictDateValidation": false,
                "multiple": false,
                "unique": false
            },
            "overlay": {
                "style": "",
                "left": "",
                "top": "",
                "width": "",
                "height": ""
            },
            "allowCalculateOverride": false,
            "encrypted": false,
            "showCharCount": false,
            "showWordCount": false,
            "properties": {},
            "allowMultipleMasks": false,
            "leftIcon": "",
            "rightIcon": ""
        }
    ]
};
