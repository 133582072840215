export const FLOW_OBJECT_DETAILS_MIDWAY_OUTBOUND_API_TINYMCE_OPTIONS = {
    selector: '#tinyMce',
    language: 'pt_BR',
    height: '300px',
    content_css: '/assets/css/flow-object-details-outbound-api-tinymce.css',
    body_class: 'tinymce-body',
    theme: 'silver',
    //forced_root_block: false,
    statusbar: false,
    menubar: false,
    contextmenu: false,
    branding: false,
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 36pt 48pt 72pt',

    // evita que o tinyMce altere as URLs das imagens fornecidas
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,

    plugins: [
        'preview',
        'fullscreen',
        'code'
    ],

    toolbar1: 'fontsizeselect | searchreplace | code fullscreen',
    //toolbar1: 'styleselect fontsizeselect | outdent indent | searchreplace | charmap subscript superscript | removeformat',
    //toolbar2: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table | undo redo | visualchars visualblocks | code fullscreen',
    //toolbar3: 'pdfPreview | vPessoas vLocais vDatas vForm',

    table_toolbar: '',

    mobile: {
        theme: 'mobile',
        plugins: ['autosave', 'lists', 'autolink'],
        toolbar: ['undo', 'bold', 'italic', 'styleselect'],
        height: '50px'
    }
};
