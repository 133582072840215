import { ToastrService } from 'ngx-toastr';
import { ConfigSchema } from '../models/config-schema.model';
import { FlowObjectType, FlowObjectTypeDescription } from '../models/flow-object.model';
import { FlowDefinition } from '../models/flow.model';
import { FlowObjectDefinitionService } from '../services/flow-object-definition.service';
import { Enums } from './enums';
import { TaskRules } from './task-rules';
import { Utils } from './utils';

export const FlowRules = {
    async isFlowDefinitionValid(
        model: FlowDefinition,
        isReadOnlyMode: boolean,
        toastr: ToastrService,
        flowObjectDefinitionService: FlowObjectDefinitionService
    ): Promise<boolean> {
        // #region [definições]
        const hasFlowStarter = model.flowObjectDefinitions.some(x => x.isFlowStarter);
        const first = model.flowObjectDefinitions.find(x => x.outerIndex == 0);
        const last = model.flowObjectDefinitions.find(x => x.outerIndex == model.flowObjectDefinitions.length - 1);

        const formioTasks = model.flowObjectDefinitions.filter(x => TaskRules.getFormioTaskTypes().includes(x.typeId));
        const startInboundApiTasks = model.flowObjectDefinitions.filter(x => TaskRules.getStartInboundApiTaskTypes().includes(x.typeId));
        const forwardingTasks = model.flowObjectDefinitions.filter(x => TaskRules.getForwardingTaskTypes().includes(x.typeId));
        const registerProcessTasks = model.flowObjectDefinitions.filter(x => TaskRules.getRegisterProcessTaskTypes().includes(x.typeId));
        const dispatchProcessTasks = model.flowObjectDefinitions.filter(x => TaskRules.getDispatchProcessTaskTypes().includes(x.typeId));
        const documentGeneratingTasks = model.flowObjectDefinitions.filter(x => TaskRules.getDocumentGeneratingTaskTypes().includes(x.typeId));
        const outboundApiTasks = model.flowObjectDefinitions.filter(x => TaskRules.getOutboundApiTaskTypes().includes(x.typeId));
        const midwayOutboundApiTasks = model.flowObjectDefinitions.filter(x => TaskRules.getMidwayOutboundApiTaskTypes().includes(x.typeId));
        const gatewayPathTasks = model.flowObjectDefinitions.filter(x => TaskRules.getGatewayPathsTaskTypes().includes(x.typeId));

        const hasGatewayPath = gatewayPathTasks.length > 0;
        const firstGatewayPathSequence = model.flowObjectDefinitions.filter(x => x.isFirstGatewayPath);
        const secondGatewayPathSequence = model.flowObjectDefinitions.filter(x => x.isSecondGatewayPath);
        let firstGatewayPathFirst = null;
        let lastGatewayPathFirst = null;
        let firstGatewayPathSecond = null;
        let lastGatewayPathSecond = null;
        if (hasGatewayPath) {
            firstGatewayPathFirst = firstGatewayPathSequence.sort((a, b) => a.outerIndex - b.outerIndex)[0];
            lastGatewayPathFirst = firstGatewayPathSequence.sort((a, b) => b.outerIndex - a.outerIndex)[0];
            firstGatewayPathSecond = secondGatewayPathSequence.sort((a, b) => a.outerIndex - b.outerIndex)[0];
            lastGatewayPathSecond = secondGatewayPathSequence.sort((a, b) => b.outerIndex - a.outerIndex)[0];
        }
        // #endregion

        // #region [todo FlowDefinition deve possuir Task iniciadora]
        if (!hasFlowStarter) {
            toastr.error(Enums.Messages.FlowStarterTaskNotFound, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
            return false;
        }
        // #endregion

        // #region [a Task na primeira posição do FlowDefinition deve pertencer à categoria de Tasks iniciadoras]
        if (!TaskRules.getStarterTaskTypes().includes(first.typeId)) {
            toastr.error(Enums.Messages.FirstTaskShouldBeStart, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
            return false;
        }
        // #endregion

        // #region [o FlowDefinition não pode ser alterado caso esteja em modo somente leitura]
        if (isReadOnlyMode) {
            toastr.error(Enums.Messages.FlowDefinitionIsReadOnly, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
            return false;
        }
        // #endregion

        // #region [todo FlowDefinition deve possuir no máximo uma Task de divisão de Fluxo]
        if (gatewayPathTasks.length > 1) {
            toastr.error(Enums.Messages.SingleGatewayPathsTaskOnly, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
            return false;
        }
        // #endregion

        // #region [caso o FlowDefinition possua Tasks de preenchimento de formulário]
        for (let task of formioTasks) {
            const response = await flowObjectDefinitionService.getFormData(task.id);

            if (!response.isSuccess) {
                toastr.error(Enums.Messages.NoFormSchemaError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                return false;
            }

            task.formSchema = response.data;

            // toda Task de formulário deve possuir um botão para submit em seu FormSchema
            if (Utils.isNullOrEmpty(task.formSchema) || !task.formSchema.replace(/\s/g, '').includes('"type":"button"')) {
                toastr.error(Enums.Messages.NoFormBuilderButtonError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                return false;
            }

            // não é possível configurar um anexo com captura para "Cidadão" caso o público-alvo do Fluxo não seja "Cidadão"
            if (model.targetId != Enums.FlowTarget.Citizen && task.formSchema.replace(/\s/g, '').includes('"label":"Cidadão:')) {
                toastr.error(Enums.Messages.CannotCaptureAsCitizenError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                return false;
            }

            // não é possível configurar um anexo com captura para "Servidor" caso o público-alvo do Fluxo seja "Cidadão"
            if (model.targetId == Enums.FlowTarget.Citizen && task.formSchema.replace(/\s/g, '').includes('"label":"Servidor:')) {
                toastr.error(Enums.Messages.CannotCaptureAsPublicAgentError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                return false;
            }
        }
        // #endregion

        // #region [caso o FlowDefinition possua Tasks de encaminhamento de conteúdo]
        for (let task of forwardingTasks) {
            if (!Utils.isNullOrEmpty(task.configSchema)) {
                let parsedConfigSchema = JSON.parse(task.configSchema) as ConfigSchema;

                // toda Task de encaminhamento de conteúdo deve fornecer um Destinatário em seu ConfigSchema
                if (
                    parsedConfigSchema.taskForward.toImmediateSupervisor == null
                    || (
                        parsedConfigSchema.taskForward.toImmediateSupervisor == false
                        && parsedConfigSchema.taskForward.recipient.id == null
                        && parsedConfigSchema.taskForward.conditionalRecipients.length == 0
                    )
                ) {
                    toastr.error(Enums.Messages.NoRecipientError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }

                // se o público-alvo do FlowDefinition for "Cidadão", não pode haver destinatário para "Gestor Imediato"
                if (
                    parsedConfigSchema.taskForward.toImmediateSupervisor != null
                    && parsedConfigSchema.taskForward.toImmediateSupervisor == true
                    && model.targetId == Enums.FlowTarget.Citizen
                ) {
                    toastr.error(Enums.Messages.TargetCitizenImmediateSupervisorError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }

                // se o FlowDefinition se iniciar por API, não pode haver destinatário condicional
                if (
                    startInboundApiTasks.length > 0
                    && parsedConfigSchema.taskForward.conditionalRecipients.length > 0
                ) {
                    toastr.error(Enums.Messages.ConditionalRecipientsOnStartInboundApiError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }
            } else {
                toastr.error(Enums.Messages.NoConfigSchemaError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                return false;
            }
        }
        // #endregion

        // #region [caso o FlowDefinition possua Tasks de autuação de Processos]
        for (let task of registerProcessTasks) {
            if (!Utils.isNullOrEmpty(task.configSchema)) {
                let parsedConfigSchema = JSON.parse(task.configSchema) as ConfigSchema;

                // toda Task de autuação de Processo deve fornecer um Local de Autuação em seu ConfigSchema
                if (Utils.isNullOrEmpty(parsedConfigSchema.taskRegisterProcess.processLocationId)) {
                    toastr.error(Enums.Messages.NoProcessLocation, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }

                // toda Task de autuação de Processo deve fornecer uma Classificação Processual em seu ConfigSchema
                if (Utils.isNullOrEmpty(parsedConfigSchema.taskRegisterProcess.processClass.id)) {
                    toastr.error(Enums.Messages.NoProcessClass, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }

                // toda Task de autuação de Processo deve fornecer um Resumo do Processo em seu ConfigSchema
                if (Utils.isNullOrEmpty(parsedConfigSchema.taskRegisterProcess.processSummary)) {
                    toastr.error(Enums.Messages.NoProcessSummary, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }

                // toda Task de autuação de Processo deve fornecer ao menos um Interessado do Processo em seu ConfigSchema
                if (parsedConfigSchema.taskRegisterProcess.stakeholders.length == 0) {
                    toastr.error(Enums.Messages.NoProcessStakeholders, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }
            } else {
                toastr.error(Enums.Messages.NoConfigSchemaError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                return false;
            }
        }
        // #endregion

        // #region [caso o FlowDefinition possua Tasks de despacho de Processos]
        for (let task of dispatchProcessTasks) {
            if (!Utils.isNullOrEmpty(task.configSchema)) {
                let parsedConfigSchema = JSON.parse(task.configSchema) as ConfigSchema;

                // toda Task de despacho de Processo deve fornecer um Destinatário do Despacho em seu ConfigSchema
                if (
                    Utils.isNullOrEmpty(parsedConfigSchema.taskDispatchProcess.dispatchRecipient.id)
                    && parsedConfigSchema.taskDispatchProcess.conditionalRecipients.length == 0
                ) {
                    toastr.error(Enums.Messages.NoDispatchRecipient, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }

                // se o FlowDefinition se iniciar por API, não pode haver destinatário condicional
                if (
                    startInboundApiTasks.length > 0
                    && parsedConfigSchema.taskDispatchProcess.conditionalRecipients.length > 0
                ) {
                    toastr.error(Enums.Messages.ConditionalRecipientsOnStartInboundApiError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }

                // toda Task de despacho de Processo deve fornecer uma Mensagem de Despacho em seu ConfigSchema
                if (Utils.isNullOrEmpty(parsedConfigSchema.taskDispatchProcess.dispatchSummary)) {
                    toastr.error(Enums.Messages.NoDispatchMessage, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }

                // toda Task de despacho de Processo deve fornecer o Nível de Acesso do Termo de Despacho em seu ConfigSchema
                if (Utils.isNullOrEmpty(parsedConfigSchema.taskDispatchProcess.accessLevel.id)) {
                    toastr.error(Enums.Messages.NoDispatchAccessLevel, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }
            } else {
                toastr.error(Enums.Messages.NoConfigSchemaError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                return false;
            }
        }
        // #endregion

        // #region [caso o FlowDefinition possua Tasks que gerem documentos no E-Docs]
        for (let task of documentGeneratingTasks) {
            if (!Utils.isNullOrEmpty(task.configSchema)) {
                let parsedConfigSchema = JSON.parse(task.configSchema) as ConfigSchema;

                // toda Task que seja executada por um servidor deve fornecer uma Classificação Documental em seu ConfigSchema
                if (
                    model.targetId != Enums.FlowTarget.Citizen && (
                        (
                            task.typeId == FlowObjectType.StartForm
                            && Utils.isNullOrEmpty(parsedConfigSchema.taskForm.documentClass.id)
                        ) || (
                            task.typeId == FlowObjectType.GatewayApprove
                            && Utils.isNullOrEmpty(parsedConfigSchema.taskApprove?.documentClass.id)
                        ) || (
                            task.typeId == FlowObjectType.TaskAcknowledge
                            && Utils.isNullOrEmpty(parsedConfigSchema.taskAcknowledge?.documentClass.id)
                        )
                    )
                ) {
                    toastr.error(Enums.Messages.NoDocumentClassIdError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }
            } else {
                toastr.error(Enums.Messages.NoConfigSchemaError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                return false;
            }
        }
        // #endregion

        // #region [caso o FlowDefinition possua Tasks intermediárias de API de saída]
        for (let task of midwayOutboundApiTasks) {
            if (!Utils.isNullOrEmpty(task.configSchema)) {
                let parsedConfigSchema = JSON.parse(task.configSchema) as ConfigSchema;

                // toda Task de API de saída deve fornecer uma URL para o endpoint de destino em seu ConfigSchema
                if (Utils.isNullOrEmpty(parsedConfigSchema.taskOutboundApi.url)) {
                    toastr.error(Enums.Messages.NoEndpointUrlError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }
            } else {
                toastr.error(Enums.Messages.NoConfigSchemaError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                return false;
            }
        }
        // #endregion

        // #region [caso o FlowDefinition possua Tasks de API de saída]
        for (let task of outboundApiTasks) {
            if (!Utils.isNullOrEmpty(task.configSchema)) {
                let parsedConfigSchema = JSON.parse(task.configSchema) as ConfigSchema;

                // toda Task de API de saída deve fornecer uma URL para o endpoint de destino em seu ConfigSchema
                if (Utils.isNullOrEmpty(parsedConfigSchema.taskOutboundApi.url)) {
                    toastr.error(Enums.Messages.NoEndpointUrlError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }

                // toda Task de API de saída deve fornecer um resolvedor em seu ConfigSchema
                if (Utils.isNullOrEmpty(parsedConfigSchema.taskOutboundApi.resolver?.id)) {
                    toastr.error(Enums.Messages.NoResolverIdError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }
            } else {
                toastr.error(Enums.Messages.NoConfigSchemaError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                return false;
            }
        }
        // #endregion

        // #region [caso o FlowDefinition possua Tasks de divisão de Fluxo]
        if (hasGatewayPath) {
            for (let task of gatewayPathTasks) {
                if (!Utils.isNullOrEmpty(task.configSchema)) {
                    let parsedConfigSchema = JSON.parse(task.configSchema) as ConfigSchema;

                    // toda Task de divisão de Fluxo deve fornecer nomes para suas sequências em seu ConfigSchema
                    if (
                        Utils.isNullOrEmpty(parsedConfigSchema.taskGatewayPaths.firstGatewayPathName)
                        || Utils.isNullOrEmpty(parsedConfigSchema.taskGatewayPaths.secondGatewayPathName)
                    ) {
                        toastr.error(Enums.Messages.NoGatewayPathsSequenceNameError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                        return false;
                    }

                    // toda Task de divisão de Fluxo deve fornecer ao menos uma condição para divisão entre suas sequências em seu ConfigSchema
                    if (parsedConfigSchema.taskGatewayPaths.conditions.length == 0) {
                        toastr.error(Enums.Messages.NoGatewayPathsConditionsError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                        return false;
                    }

                    // toda condição para divisão de uma Task de divisão de Fluxo deve fornecer um código de campo do formulário em seu ConfigSchema
                    if (parsedConfigSchema.taskGatewayPaths.conditions.some(x => Utils.isNullOrEmpty(x.formField))) {
                        toastr.error(Enums.Messages.NoGatewayPathsFormFieldError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                        return false;
                    }
                } else {
                    toastr.error(Enums.Messages.NoConfigSchemaError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                    return false;
                }
            }

            // ambas as Sequências devem conter Tasks
            if (
                firstGatewayPathSequence.length == 0
                || secondGatewayPathSequence.length == 0
            ) {
                toastr.error(Enums.Messages.EmptyGatewayPathError, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                return false;
            }

            if (
                // a Task na última posição dos Gateway Paths deve pertencer à categoria de Tasks finalizadoras
                !TaskRules.getFinisherTaskTypes().includes(lastGatewayPathFirst.typeId)
                || !TaskRules.getFinisherTaskTypes().includes(lastGatewayPathSecond.typeId)
                // a Task na última posição do FlowDefinition não pode ser do tipo Gateway (GroupingColumn com mais de uma Task) se as
                // duas últimas Tasks não pertencerem à categoria de Tasks finalizadoras
                || (
                    lastGatewayPathFirst.innerIndex > 0
                    && !TaskRules.getFinisherTaskTypes().includes(
                        model.flowObjectDefinitions.find(x => x.groupingColumnId == lastGatewayPathFirst.groupingColumnId && x.innerIndex == 0).typeId
                    )
                ) || (
                    lastGatewayPathSecond.innerIndex > 0
                    && !TaskRules.getFinisherTaskTypes().includes(
                        model.flowObjectDefinitions.find(x => x.groupingColumnId == lastGatewayPathSecond.groupingColumnId && x.innerIndex == 0).typeId
                    )
                )
            ) {
                toastr.error(Enums.Messages.LastTaskShoudBeFinish, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                return false;
            }
        }
        // #endregion

        // #region [caso o FlowDefinition não possua Tasks de divisão de Fluxo]
        if (
            !hasGatewayPath
            && (
                // a Task na última posição do FlowDefinition deve pertencer à categoria de Tasks finalizadoras
                !TaskRules.getFinisherTaskTypes().includes(last.typeId)
                // a Task na última posição do FlowDefinition não pode ser do tipo Gateway (GroupingColumn com mais de uma Task) se as
                // duas últimas Tasks não pertencerem à categoria de Tasks finalizadoras
                || (
                    last.innerIndex > 0
                    && !TaskRules.getFinisherTaskTypes().includes(
                        model.flowObjectDefinitions.find(x => x.groupingColumnId == last.groupingColumnId && x.innerIndex == 0).typeId
                    )
                )
            )
        ) {
            toastr.error(Enums.Messages.LastTaskShoudBeFinish, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
            return false;
        }
        // #endregion

        // #region [cada tipo de Task possui um conjunto próprio de tipos de Task permitidos na posição de Next]
        // caso o FlowDefinition não possua Tasks de divisão de Fluxo
        if (!hasGatewayPath) {
            for (const flowObject of model.flowObjectDefinitions) {
                for (const next of flowObject.next) {
                    const validNext = TaskRules.getValidNextTaskTypes(flowObject.typeId);

                    if (!validNext.includes(next.typeId)) {
                        let message = Enums.Messages.InvalidNextType.replace('{0}', FlowObjectTypeDescription.get(flowObject.typeId));
                        let validNextNames = '';
                        validNext.forEach(x => validNextNames += `"${FlowObjectTypeDescription.get(x)}"; `);

                        if (validNextNames != '') {
                            validNextNames = validNextNames.split('; ').slice(0, -1).join('; ');
                        } else {
                            validNextNames = '(nenhum)';
                        }

                        message = message.replace('{1}', validNextNames);

                        toastr.error(message, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                        return false;
                    }
                }
            }
        }
        // caso o FlowDefinition possua Tasks de divisão de Fluxo
        else {
            let lastOfMainPathSequence = null;

            // análise das Tasks da Sequência Principal
            for (const flowObject of model.flowObjectDefinitions) {
                for (const next of flowObject.next) {
                    if (next.typeId == FlowObjectType.GatewayPaths) {
                        lastOfMainPathSequence = flowObject;
                        break;
                    }

                    const validNext = TaskRules.getValidNextTaskTypes(flowObject.typeId);

                    if (!validNext.includes(next.typeId)) {
                        let message = Enums.Messages.InvalidNextType.replace('{0}', FlowObjectTypeDescription.get(flowObject.typeId));
                        let validNextNames = '';
                        validNext.forEach(x => validNextNames += `"${FlowObjectTypeDescription.get(x)}"; `);

                        if (validNextNames != '') {
                            validNextNames = validNextNames.split('; ').slice(0, -1).join('; ');
                        } else {
                            validNextNames = '(nenhum)';
                        }

                        message = message.replace('{1}', validNextNames);

                        toastr.error(message, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                        return false;
                    }
                }

                if (lastOfMainPathSequence != null) break;
            }

            // análise das Tasks no ponto de divisão do Fluxo
            const validNextGatewayPath = TaskRules.getValidNextTaskTypes(lastOfMainPathSequence.typeId);
            if (
                !validNextGatewayPath.includes(firstGatewayPathFirst.typeId)
                || !validNextGatewayPath.includes(firstGatewayPathSecond.typeId)
            ) {
                let message = Enums.Messages.InvalidNextType.replace('{0}', FlowObjectTypeDescription.get(lastOfMainPathSequence.typeId));
                let validNextGatewayPathNames = '';
                validNextGatewayPath.forEach(x => validNextGatewayPathNames += `"${FlowObjectTypeDescription.get(x)}"; `);

                if (validNextGatewayPathNames != '') {
                    validNextGatewayPathNames = validNextGatewayPathNames.split('; ').slice(0, -1).join('; ');
                } else {
                    validNextGatewayPathNames = '(nenhum)';
                }

                message = message.replace('{1}', validNextGatewayPathNames);

                toastr.error(message, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                return false;
            }

            // análise das Tasks da Sequência A
            for (const flowObject of firstGatewayPathSequence) {
                for (const next of flowObject.next) {
                    const validNext = TaskRules.getValidNextTaskTypes(flowObject.typeId);

                    if (!validNext.includes(next.typeId)) {
                        let message = Enums.Messages.InvalidNextType.replace('{0}', FlowObjectTypeDescription.get(flowObject.typeId));
                        let validNextNames = '';
                        validNext.forEach(x => validNextNames += `"${FlowObjectTypeDescription.get(x)}"; `);

                        if (validNextNames != '') {
                            validNextNames = validNextNames.split('; ').slice(0, -1).join('; ');
                        } else {
                            validNextNames = '(nenhum)';
                        }

                        message = message.replace('{1}', validNextNames);

                        toastr.error(message, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                        return false;
                    }
                }
            }

            // análise das Tasks da Sequência B
            for (const flowObject of secondGatewayPathSequence) {
                for (const next of flowObject.next) {
                    const validNext = TaskRules.getValidNextTaskTypes(flowObject.typeId);

                    if (!validNext.includes(next.typeId)) {
                        let message = Enums.Messages.InvalidNextType.replace('{0}', FlowObjectTypeDescription.get(flowObject.typeId));
                        let validNextNames = '';
                        validNext.forEach(x => validNextNames += `"${FlowObjectTypeDescription.get(x)}"; `);

                        if (validNextNames != '') {
                            validNextNames = validNextNames.split('; ').slice(0, -1).join('; ');
                        } else {
                            validNextNames = '(nenhum)';
                        }

                        message = message.replace('{1}', validNextNames);

                        toastr.error(message, Enums.Messages.DataValidationError, Utils.getToastrErrorOptions());
                        return false;
                    }
                }
            }
        }
        // #endregion

        return true;
    }
};
