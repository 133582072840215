import { FORMBUILDER_OPTIONS_I18N } from "./formbuilder-options-i18n";

export const FORMBUILDER_OPTIONS_DEFAULT = {
    noDefaultSubmitButton: true,
    language: 'pt',
    i18n: FORMBUILDER_OPTIONS_I18N,
    builder: {
        basic: {
            components: {
                password: false,
                selectboxes: false,
                button: false,
                pdfUpload: true,
                pdfUploadMultiple: true,
                eDocsProtocol: true
            }
        },
        advanced: {
            components: {
                content: true,
                tags: false,
                survey: false,
                signature: false,
                address: false,
                datetime: false,
                datePicker: true,
                dateTimePicker: true,
                tableCustom: true,
                datagridCustom: true
            }
        },
        layout: false,
        data: false,
        premium: false
    },
    editForm: {
        textfield: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    //{
                    //    key: 'inputMask',
                    //    ignore: true
                    //},
                    {
                        key: 'inputMaskPlaceholderChar',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: false
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                components: [
                    {
                        key: 'multiple',
                        ignore: true
                    },
                    {
                        key: 'defaultValue',
                        ignore: false
                    },
                    {
                        key: 'persistent',
                        ignore: true
                    },
                    {
                        key: 'inputFormat',
                        ignore: true
                    },
                    {
                        key: 'protected',
                        ignore: true
                    },
                    {
                        key: 'dbIndex',
                        ignore: true
                    },
                    {
                        key: 'encrypted',
                        ignore: true
                    },
                    {
                        key: 'redrawOn',
                        ignore: true
                    },
                    {
                        key: 'clearOnHide',
                        ignore: true
                    },
                    {
                        key: 'customDefaultValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateValuePanel',
                        ignore: false,
                        components: [
                            {
                                key: 'calculateValue-json',
                                ignore: true
                            }
                        ]
                    },
                    {
                        key: 'calculateServer',
                        ignore: true
                    },
                    {
                        key: 'allowCalculateOverride',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        cpf: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'placeholder',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'inputMaskPlaceholderChar',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'showWordCount',
                        ignore: true
                    },
                    {
                        key: 'showCharCount',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minLength',
                        ignore: true
                    },
                    {
                        key: 'validate.maxLength',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'validate.customMessage',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true,
                components: [
                    {
                        key: 'multiple',
                        ignore: true
                    },
                    {
                        key: 'defaultValue',
                        ignore: true
                    },
                    {
                        key: 'persistent',
                        ignore: true
                    },
                    {
                        key: 'inputFormat',
                        ignore: true
                    },
                    {
                        key: 'protected',
                        ignore: true
                    },
                    {
                        key: 'dbIndex',
                        ignore: true
                    },
                    {
                        key: 'encrypted',
                        ignore: true
                    },
                    {
                        key: 'redrawOn',
                        ignore: true
                    },
                    {
                        key: 'clearOnHide',
                        ignore: true
                    },
                    {
                        key: 'customDefaultValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateServer',
                        ignore: true
                    },
                    {
                        key: 'allowCalculateOverride',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        cnpj: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'placeholder',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'inputMaskPlaceholderChar',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'showWordCount',
                        ignore: true
                    },
                    {
                        key: 'showCharCount',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minLength',
                        ignore: true
                    },
                    {
                        key: 'validate.maxLength',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'validate.customMessage',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true,
                components: [
                    {
                        key: 'multiple',
                        ignore: true
                    },
                    {
                        key: 'defaultValue',
                        ignore: true
                    },
                    {
                        key: 'persistent',
                        ignore: true
                    },
                    {
                        key: 'inputFormat',
                        ignore: true
                    },
                    {
                        key: 'protected',
                        ignore: true
                    },
                    {
                        key: 'dbIndex',
                        ignore: true
                    },
                    {
                        key: 'encrypted',
                        ignore: true
                    },
                    {
                        key: 'redrawOn',
                        ignore: true
                    },
                    {
                        key: 'clearOnHide',
                        ignore: true
                    },
                    {
                        key: 'customDefaultValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateServer',
                        ignore: true
                    },
                    {
                        key: 'allowCalculateOverride',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        eDocsProtocol: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'placeholder',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'inputMaskPlaceholderChar',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'showWordCount',
                        ignore: true
                    },
                    {
                        key: 'showCharCount',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minLength',
                        ignore: true
                    },
                    {
                        key: 'validate.maxLength',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'validate.customMessage',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true,
                components: [
                    {
                        key: 'multiple',
                        ignore: true
                    },
                    {
                        key: 'defaultValue',
                        ignore: true
                    },
                    {
                        key: 'persistent',
                        ignore: true
                    },
                    {
                        key: 'inputFormat',
                        ignore: true
                    },
                    {
                        key: 'protected',
                        ignore: true
                    },
                    {
                        key: 'dbIndex',
                        ignore: true
                    },
                    {
                        key: 'encrypted',
                        ignore: true
                    },
                    {
                        key: 'redrawOn',
                        ignore: true
                    },
                    {
                        key: 'clearOnHide',
                        ignore: true
                    },
                    {
                        key: 'customDefaultValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateServer',
                        ignore: true
                    },
                    {
                        key: 'allowCalculateOverride',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        textarea: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    },
                    {
                        key: 'editor',
                        ignore: true
                    },
                    {
                        key: 'autoExpand',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                components: [
                    {
                        key: 'multiple',
                        ignore: true
                    },
                    {
                        key: 'defaultValue',
                        ignore: false
                    },
                    {
                        key: 'persistent',
                        ignore: true
                    },
                    {
                        key: 'inputFormat',
                        ignore: true
                    },
                    {
                        key: 'protected',
                        ignore: true
                    },
                    {
                        key: 'dbIndex',
                        ignore: true
                    },
                    {
                        key: 'encrypted',
                        ignore: true
                    },
                    {
                        key: 'redrawOn',
                        ignore: true
                    },
                    {
                        key: 'clearOnHide',
                        ignore: true
                    },
                    {
                        key: 'customDefaultValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateServer',
                        ignore: true
                    },
                    {
                        key: 'allowCalculateOverride',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        number: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                components: [
                    {
                        key: 'multiple',
                        ignore: true
                    },
                    {
                        key: 'persistent',
                        ignore: true
                    },
                    {
                        key: 'inputFormat',
                        ignore: true
                    },
                    {
                        key: 'protected',
                        ignore: true
                    },
                    {
                        key: 'dbIndex',
                        ignore: true
                    },
                    {
                        key: 'encrypted',
                        ignore: true
                    },
                    {
                        key: 'redrawOn',
                        ignore: true
                    },
                    {
                        key: 'clearOnHide',
                        ignore: true
                    },
                    {
                        key: 'customDefaultValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateValuePanel',
                        ignore: false,
                        components: [
                            {
                                key: 'calculateValue-json',
                                ignore: true
                            }
                        ]
                    },
                    {
                        key: 'calculateServer',
                        ignore: true
                    },
                    {
                        key: 'allowCalculateOverride',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        checkbox: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    },
                    {
                        key: 'shortcut',
                        ignore: true
                    },
                    {
                        key: 'inputType',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                components: [
                    {
                        key: 'multiple',
                        ignore: true
                    },
                    {
                        key: 'persistent',
                        ignore: true
                    },
                    {
                        key: 'inputFormat',
                        ignore: true
                    },
                    {
                        key: 'protected',
                        ignore: true
                    },
                    {
                        key: 'dbIndex',
                        ignore: true
                    },
                    {
                        key: 'encrypted',
                        ignore: true
                    },
                    {
                        key: 'redrawOn',
                        ignore: true
                    },
                    {
                        key: 'clearOnHide',
                        ignore: true
                    },
                    {
                        key: 'customDefaultValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateServer',
                        ignore: true
                    },
                    {
                        key: 'allowCalculateOverride',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        select: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'widget',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: false
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    },
                    {
                        key: 'shortcut',
                        ignore: true
                    },
                    {
                        key: 'inputType',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                components: [
                    {
                        key: 'multiple',
                        ignore: false
                    },
                    {
                        key: 'persistent',
                        ignore: true
                    },
                    {
                        key: 'inputFormat',
                        ignore: true
                    },
                    {
                        key: 'protected',
                        ignore: true
                    },
                    {
                        key: 'dbIndex',
                        ignore: true
                    },
                    {
                        key: 'encrypted',
                        ignore: true
                    },
                    {
                        key: 'redrawOn',
                        ignore: true
                    },
                    {
                        key: 'clearOnHide',
                        ignore: true
                    },
                    {
                        key: 'customDefaultValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateServer',
                        ignore: true
                    },
                    {
                        key: 'dataSrc',
                        ignore: true
                    },
                    {
                        key: 'dataType',
                        ignore: true
                    },
                    {
                        key: 'idPath',
                        ignore: true
                    },
                    {
                        key: 'template',
                        ignore: true
                    },
                    {
                        key: 'refreshOn',
                        ignore: true
                    },
                    {
                        key: 'refreshOnBlur',
                        ignore: true
                    },
                    {
                        key: 'clearOnRefresh',
                        ignore: true
                    },
                    {
                        key: 'searchEnabled',
                        ignore: true
                    },
                    {
                        key: 'selectThreshold',
                        ignore: true
                    },
                    {
                        key: 'readOnlyValue',
                        ignore: true
                    },
                    {
                        key: 'customOptions',
                        ignore: true
                    },
                    {
                        key: 'allowCalculateOverride',
                        ignore: true
                    },
                    {
                        key: 'valueProperty',
                        ignore: true
                    },
                    {
                        key: 'data.custom',
                        ignore: false
                    },
                    {
                        key: 'data.values',
                        components: [
                            {
                                key: 'label',
                                weight: 1,
                                validate: {
                                    required: true
                                }
                            },
                            {
                                key: 'value',
                                weight: 999999,
                                validate: {
                                    required: true
                                },
                                calculateValue(context) {
                                    //if (context.row.value != null && context.row.value != '') {
                                    //    if (context.row.label.startsWith(context.row.value)) {
                                    //        return context.row.label;
                                    //    }

                                    //    return context.row.value;
                                    //}

                                    //return context.row.label;

                                    return context.row.value;
                                }
                            }
                        ]
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        selectRepository: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'labelPosition',
                        ignore: true
                    },
                    {
                        key: 'placeholder',
                        ignore: true
                    },
                    {
                        key: 'description',
                        ignore: true
                    },
                    {
                        key: 'tooltip',
                        ignore: true
                    },
                    {
                        key: 'hideLabel',
                        ignore: true
                    },
                    {
                        key: 'uniqueOptions',
                        ignore: true
                    },
                    {
                        key: 'autofocus',
                        ignore: true
                    },
                    {
                        key: 'disabled',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'widget',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    },
                    {
                        key: 'shortcut',
                        ignore: true
                    },
                    {
                        key: 'inputType',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                components: [
                    {
                        key: 'data.custom',
                        ignore: false
                    },
                    {
                        key: 'valueProperty',
                        ignore: true
                    },
                    {
                        key: 'dataSrc',
                        ignore: true
                    },
                    {
                        key: 'useExactSearch',
                        ignore: true
                    },
                    {
                        key: 'defaultValue',
                        ignore: false
                    },
                    {
                        key: 'multiple',
                        ignore: true
                    },
                    {
                        key: 'persistent',
                        ignore: true
                    },
                    {
                        key: 'inputFormat',
                        ignore: true
                    },
                    {
                        key: 'protected',
                        ignore: true
                    },
                    {
                        key: 'dbIndex',
                        ignore: true
                    },
                    {
                        key: 'encrypted',
                        ignore: true
                    },
                    {
                        key: 'redrawOn',
                        ignore: true
                    },
                    {
                        key: 'clearOnHide',
                        ignore: true
                    },
                    {
                        key: 'customDefaultValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateServer',
                        ignore: true
                    },
                    {
                        key: 'dataType',
                        ignore: true
                    },
                    {
                        key: 'idPath',
                        ignore: true
                    },
                    {
                        key: 'template',
                        ignore: true
                    },
                    {
                        key: 'refreshOn',
                        ignore: true
                    },
                    {
                        key: 'refreshOnBlur',
                        ignore: true
                    },
                    {
                        key: 'clearOnRefresh',
                        ignore: true
                    },
                    {
                        key: 'searchEnabled',
                        ignore: true
                    },
                    {
                        key: 'selectThreshold',
                        ignore: true
                    },
                    {
                        key: 'readOnlyValue',
                        ignore: true
                    },
                    {
                        key: 'customOptions',
                        ignore: true
                    },
                    {
                        key: 'allowCalculateOverride',
                        ignore: true
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                ignore: true
            },
            {
                key: 'conditional',
                ignore: true
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        selectFiltered: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'widget',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: false
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    },
                    {
                        key: 'shortcut',
                        ignore: true
                    },
                    {
                        key: 'inputType',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                components: [
                    {
                        key: 'multiple',
                        ignore: false
                    },
                    {
                        key: 'persistent',
                        ignore: true
                    },
                    {
                        key: 'inputFormat',
                        ignore: true
                    },
                    {
                        key: 'protected',
                        ignore: true
                    },
                    {
                        key: 'dbIndex',
                        ignore: true
                    },
                    {
                        key: 'encrypted',
                        ignore: true
                    },
                    {
                        key: 'redrawOn',
                        ignore: true
                    },
                    {
                        key: 'clearOnHide',
                        ignore: true
                    },
                    {
                        key: 'customDefaultValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateServer',
                        ignore: true
                    },
                    {
                        key: 'dataSrc',
                        ignore: true
                    },
                    {
                        key: 'dataType',
                        ignore: true
                    },
                    {
                        key: 'idPath',
                        ignore: true
                    },
                    {
                        key: 'template',
                        ignore: true
                    },
                    {
                        key: 'refreshOn',
                        ignore: true
                    },
                    {
                        key: 'refreshOnBlur',
                        ignore: true
                    },
                    {
                        key: 'clearOnRefresh',
                        ignore: true
                    },
                    {
                        key: 'searchEnabled',
                        ignore: true
                    },
                    {
                        key: 'selectThreshold',
                        ignore: true
                    },
                    {
                        key: 'readOnlyValue',
                        ignore: true
                    },
                    {
                        key: 'customOptions',
                        ignore: true
                    },
                    {
                        key: 'allowCalculateOverride',
                        ignore: true
                    },
                    {
                        key: 'valueProperty',
                        ignore: true
                    },
                    {
                        key: 'data.custom',
                        ignore: false
                    },
                    {
                        key: 'data.values',
                        components: [
                            {
                                key: 'label',
                                weight: 1,
                                validate: {
                                    required: true
                                }
                            },
                            {
                                key: 'value',
                                weight: 999999,
                                validate: {
                                    required: true
                                },
                                calculateValue(context) {
                                    if (context.row.value != null && context.row.value != '') {
                                        if (context.row.label.startsWith(context.row.value)) {
                                            return context.row.label;
                                        }

                                        return context.row.value;
                                    }

                                    return context.row.label;
                                }
                            }
                        ]
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        radio: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    },
                    {
                        key: 'shortcut',
                        ignore: true
                    },
                    {
                        key: 'inputType',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                components: [
                    {
                        key: 'defaultValue',
                        ignore: false
                    },
                    {
                        key: 'multiple',
                        ignore: true
                    },
                    {
                        key: 'persistent',
                        ignore: true
                    },
                    {
                        key: 'inputFormat',
                        ignore: true
                    },
                    {
                        key: 'protected',
                        ignore: true
                    },
                    {
                        key: 'dbIndex',
                        ignore: true
                    },
                    {
                        key: 'encrypted',
                        ignore: true
                    },
                    {
                        key: 'redrawOn',
                        ignore: true
                    },
                    {
                        key: 'clearOnHide',
                        ignore: true
                    },
                    {
                        key: 'customDefaultValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateServer',
                        ignore: true
                    },
                    {
                        key: 'dataType',
                        ignore: true
                    },
                    {
                        key: 'calculateValue',
                        ignore: true
                    },
                    {
                        key: 'allowCalculateOverride',
                        ignore: true
                    },
                    {
                        key: 'values',
                        components: [
                            {
                                key: 'label',
                                weight: 1,
                                validate: {
                                    required: true
                                }
                            },
                            {
                                key: 'value',
                                weight: 999999,
                                validate: {
                                    required: true
                                },
                                calculateValue(context) {
                                    //if (context.row.value != null && context.row.value != '') {
                                    //    if (context.row.label.startsWith(context.row.value)) {
                                    //        return context.row.label;
                                    //    }

                                    //    return context.row.value;
                                    //}

                                    //return context.row.label;

                                    return context.row.value;
                                }
                            },
                            {
                                key: 'shortcut',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.onlyAvailableItems',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        button: [
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'display',
                ignore: true,
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    //{
                    //    key: 'action',
                    //    ignore: true
                    //},
                    {
                        key: 'saveOnEnter',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    },
                    {
                        key: 'leftIcon',
                        ignore: true
                    },
                    {
                        key: 'rightIcon',
                        ignore: true
                    },
                    {
                        key: 'shortcut',
                        ignore: true
                    },
                    {
                        key: 'showValidations',
                        ignore: true
                    },
                    {
                        key: 'inputType',
                        ignore: true
                    }
                ]
            },
            {
                key: 'api',
                ignore: true,
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'validation',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        email: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                components: [
                    {
                        key: 'multiple',
                        ignore: true
                    },
                    {
                        key: 'defaultValue',
                        ignore: false
                    },
                    {
                        key: 'persistent',
                        ignore: true
                    },
                    {
                        key: 'inputFormat',
                        ignore: true
                    },
                    {
                        key: 'protected',
                        ignore: true
                    },
                    {
                        key: 'dbIndex',
                        ignore: true
                    },
                    {
                        key: 'encrypted',
                        ignore: true
                    },
                    {
                        key: 'redrawOn',
                        ignore: true
                    },
                    {
                        key: 'clearOnHide',
                        ignore: true
                    },
                    {
                        key: 'customDefaultValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateServer',
                        ignore: true
                    },
                    {
                        key: 'allowCalculateOverride',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'kickbox',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        url: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'kickbox',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        phoneNumber: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'placeholder',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'inputMasks',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'inputMaskPlaceholderChar',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'kickbox',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'validate.customMessage',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        landlineNumber: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'placeholder',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'inputMasks',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'inputMaskPlaceholderChar',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'kickbox',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'validate.customMessage',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        cellNumber: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'placeholder',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'inputMasks',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'inputMaskPlaceholderChar',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'kickbox',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'validate.customMessage',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        day: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'hideInputLabels',
                        ignore: true
                    },
                    {
                        key: 'inputsLabelPosition',
                        ignore: true
                    },
                    {
                        key: 'useLocaleSettings',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'day',
                components: [
                    {
                        key: 'dayFirst',
                        ignore: true
                    },
                    {
                        key: 'fields.day.placeholder',
                        ignore: true
                    }
                ]
            },
            {
                key: 'month',
                components: [
                    {
                        key: 'fields.month.placeholder',
                        ignore: true
                    }
                ]
            },
            {
                key: 'year',
                components: [
                    {
                        key: 'fields.year.placeholder',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'kickbox',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        time: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'inputType',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'kickbox',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        currency: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'data',
                label: 'Dados',
                components: [
                    {
                        key: 'multiple',
                        ignore: true
                    },
                    {
                        key: 'persistent',
                        ignore: true
                    },
                    {
                        key: 'inputFormat',
                        ignore: true
                    },
                    {
                        key: 'protected',
                        ignore: true
                    },
                    {
                        key: 'dbIndex',
                        ignore: true
                    },
                    {
                        key: 'encrypted',
                        ignore: true
                    },
                    {
                        key: 'redrawOn',
                        ignore: true
                    },
                    {
                        key: 'clearOnHide',
                        ignore: true
                    },
                    {
                        key: 'customDefaultValuePanel',
                        ignore: true
                    },
                    {
                        key: 'calculateValuePanel',
                        ignore: false,
                        components: [
                            {
                                key: 'calculateValue-json',
                                ignore: true
                            }
                        ]
                    },
                    {
                        key: 'calculateServer',
                        ignore: true
                    },
                    {
                        key: 'case',
                        ignore: true
                    },
                    {
                        key: 'allowCalculateOverride',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'kickbox',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js'
                        //,ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        content: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'refreshOnChange',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    //{
                    //    key: 'inputMask',
                    //    ignore: true
                    //},
                    {
                        key: 'inputMaskPlaceholderChar',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        tableCustom: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'cloneRows',
                        ignore: true
                    },
                    {
                        key: 'hover',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'inputMaskPlaceholderChar',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                ignore: true
            },
            {
                key: 'data',
                ignore: true
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        datagridCustom: [
            {
                key: 'display',
                components: [
                    {
                        key: 'autofocus',
                        ignore: true
                    },
                    {
                        key: 'disabled',
                        ignore: true
                    },
                    {
                        key: 'conditionalAddButton',
                        ignore: true
                    },
                    {
                        key: 'initEmpty',
                        ignore: true
                    },
                    {
                        key: 'enableRowGroups',
                        ignore: true
                    },
                    {
                        key: 'rowGroups',
                        ignore: true
                    },
                    {
                        key: 'reorder',
                        ignore: true
                    },
                    {
                        key: 'cloneRows',
                        ignore: true
                    },
                    {
                        key: 'hover',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'inputMaskPlaceholderChar',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                ignore: true
            },
            {
                key: 'data',
                ignore: true
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        pdfUpload: [
            {
                key: 'display',
                components: [
                    {
                        weight: 9910,
                        input: true,
                        type: 'select',
                        key: 'documentLegalValueCombination',
                        label: 'Valor Legal do Documento para Conferência',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Valor Legal do Documento anexado que será capturado no E-Docs',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: true,
                        }
                    },
                    {
                        weight: 9911,
                        input: true,
                        type: 'select',
                        key: 'activePlans',
                        label: 'Planos Ativos',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Selecione um dos Planos Ativos para filtrar as possíveis Classes para o documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: false,
                        }
                    },
                    {
                        weight: 9912,
                        input: true,
                        type: 'select',
                        key: 'documentClass',
                        label: 'Classificação Documental',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Classificação Documental do documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.8,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: false,
                        }
                    },
                    {
                        weight: 9920,
                        input: true,
                        type: 'select',
                        key: 'accessLevel',
                        label: 'Nível de Acesso',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Nível de acesso no E-Docs do documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: true,
                        }
                    },
                    {
                        weight: 9921,
                        input: true,
                        type: 'select',
                        key: 'legalReasoning',
                        label: 'Fundamentação Legal',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Fundamentação legal para a restrição no E-Docs do documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: true,
                        }
                    },
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'autofocus',
                        ignore: true
                    },
                    {
                        key: 'showCharCount',
                        ignore: true
                    },
                    {
                        key: 'showWordCount',
                        ignore: true
                    },
                    //{
                    //    key: 'hideLabel',
                    //    ignore: true
                    //},
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'placeholder',
                        ignore: true
                    },
                    {
                        key: 'labelPosition',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.customMessage',
                        ignore: true
                    },
                    {
                        key: 'validate.minLength',
                        ignore: true
                    },
                    {
                        key: 'validate.maxLength',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'kickbox',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        pdfUploadMultiple: [
            {
                key: 'display',
                components: [
                    {
                        weight: 9910,
                        input: true,
                        type: 'select',
                        key: 'documentLegalValueCombination',
                        label: 'Valor Legal do Documento para Conferência',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Valor Legal do Documento anexado que será capturado no E-Docs',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: true,
                        }
                    },
                    {
                        weight: 9911,
                        input: true,
                        type: 'select',
                        key: 'activePlans',
                        label: 'Planos Ativos',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Selecione um dos Planos Ativos para filtrar as possíveis Classes para o documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: false,
                        }
                    },
                    {
                        weight: 9912,
                        input: true,
                        type: 'select',
                        key: 'documentClass',
                        label: 'Classificação Documental',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Classificação Documental do documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.8,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: false,
                        }
                    },
                    {
                        weight: 9920,
                        input: true,
                        type: 'select',
                        key: 'accessLevel',
                        label: 'Nível de Acesso',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Nível de acesso no E-Docs do documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: true,
                        }
                    },
                    {
                        weight: 9921,
                        input: true,
                        type: 'select',
                        key: 'legalReasoning',
                        label: 'Fundamentação Legal',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Fundamentação legal para a restrição no E-Docs do documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: false,
                        }
                    },
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'autofocus',
                        ignore: true
                    },
                    {
                        key: 'showCharCount',
                        ignore: true
                    },
                    {
                        key: 'showWordCount',
                        ignore: true
                    },
                    //{
                    //    key: 'hideLabel',
                    //    ignore: true
                    //},
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'placeholder',
                        ignore: true
                    },
                    {
                        key: 'labelPosition',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        weight: 9901,
                        input: true,
                        type: 'number',
                        key: 'maxFiles',
                        label: 'Quantidade máxima de arquivos',
                        tooltip: 'A quantidade máxima de arquivos que será permitido inserir neste campo',
                        defaultValue: 5,
                        validate: {
                            required: true,
                            custom: 'valid = input > 0 && input <= 20;',
                            customMessage: 'O valor deste campo deve estar entre 1 e 20'
                        }
                    },
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.customMessage',
                        ignore: true
                    },
                    {
                        key: 'validate.minLength',
                        ignore: true
                    },
                    {
                        key: 'validate.maxLength',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'kickbox',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        datePicker: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'showCharCount',
                        ignore: true
                    },
                    {
                        key: 'showWordCount',
                        ignore: true
                    },
                    {
                        key: 'placeholder',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'inputMaskPlaceholderChar',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'validate.minLength',
                        ignore: true
                    },
                    {
                        key: 'validate.maxLength',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        //,ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        dateTimePicker: [
            {
                key: 'display',
                components: [
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'showCharCount',
                        ignore: true
                    },
                    {
                        key: 'showWordCount',
                        ignore: true
                    },
                    {
                        key: 'placeholder',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'inputMaskPlaceholderChar',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'validate.minLength',
                        ignore: true
                    },
                    {
                        key: 'validate.maxLength',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js'
                        //,ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ]
    }
};
