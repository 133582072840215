import { AbstractControl } from '@angular/forms';
import { isObservable } from 'rxjs';
import { FlowDefinition, FlowDefinitionContext } from '../models/flow.model';
import { FlowObjectDefinition } from '../models/flow-object.model';

export const Utils = {
    getToastrErrorOptions(options?: any): any {
        let ret = {
            closeButton: true,
            //disableTimeOut: true,
            timeOut: 60000,
            extendedTimeOut: 60000,
        };

        if (options != null) {
            Object.assign(ret, options);
        }

        return ret;
    },

    scrollTo(id: string) {
        let interval = setInterval(() => {
            let elem = document.getElementById(id);
            if (elem.scrollHeight == 0) return;

            elem.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });

            clearInterval(interval);
        }, 300);
    },

    scrollToTop(selector?: string) {
        setTimeout(() => document.querySelector(selector || '.container > .content-wrapper').scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        }), 400);
    },

    scrollToBottom(selector?: string) {
        setTimeout(() => document.querySelector(selector || '.container > .content-wrapper').scroll({
            top: window.innerHeight,
            left: 0,
            behavior: 'smooth'
        }), 400);
    },

    maskCnpj(cnpj: string): string {
        return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    },

    round(value: number, precision: number): number {
        let multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    },

    toCamelCase(text: string): string {
        return text.replace(/\b[a-z]/g, x => x.toUpperCase());
    },

    isObject(x: any): boolean {
        return x != null && typeof x === 'object';
    },

    isString(x: any): boolean {
        return x != null && typeof x === 'string';
    },

    isFunction(value: any): boolean {
        return typeof value === 'function';
    },

    isNullOrEmpty(value: any): boolean {
        return value == null || value.toString().trim() === '';
    },

    isEmptyObject(value: any): boolean {
        for (var x in value) return false;
        return true;
    },

    tryParseJson(str: string): (object|boolean) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    },

    areEqualObjects(obj1: any, obj2: any): boolean {
        if (JSON.stringify(obj1) == JSON.stringify(obj2)) {
            return true;
        }

        return false;
    },

    cloneArray(array): any {
        if (Array.isArray(array)) {
            return array.slice(0).map(v => Utils.clone(v));
        }

        return array;
    },

    clone(value: any): any {
        if (
            !Utils.isObject(value)
            || isObservable(value)
            || value.changingThisBreaksApplicationSecurity
            || ['RegExp', 'FileList', 'File', 'Blob'].includes(value.constructor.name)
        ) {
            return value;
        }

        if (value._isAMomentObject && Utils.isFunction(value.clone)) {
            return value.clone();
        }

        if (value instanceof AbstractControl) {
            return null;
        }

        if (value instanceof Date) {
            return new Date(value.getTime());
        }

        if (Array.isArray(value)) {
            //return value.slice(0).map(v => this.clone(v));
            return null;
        }

        const proto = Object.getPrototypeOf(value);
        let c = Object.create(proto);
        c = Object.setPrototypeOf(c, proto);

        return Object.keys(value).reduce((newVal, prop) => {
            const propDesc = Object.getOwnPropertyDescriptor(value, prop);
            if (propDesc.get) {
                Object.defineProperty(newVal, prop, propDesc);
            } else {
                newVal[prop] = Utils.clone(value[prop]);
            }

            return newVal;
        }, c);
    },

    flowAreaReady(callback, isGatewayPath: boolean = false) {
        if (
            (
                isGatewayPath
                && document.querySelector('.gateway-path > .msg.empty') == null
                && document.querySelector('.gateway-path .selected-flow-object') == null
            ) || (
                document.querySelector('.main-path > .msg.empty') == null
                && document.querySelector('.main-path .selected-flow-object') == null
            )
        ) {
            setTimeout(() => this.flowAreaReady(callback), 100);
        } else {
            setTimeout(callback, 1);
        }
    },

    getFormFieldsWithUsableValue(components: any[]): any[] {
        return components.filter(x =>
            !['button', 'content', 'pdfUpload', 'pdfUploadMultiple', 'datagrid', 'datagridCustom'].includes(x.type)
            && !(
                x.type == 'select'
                && x.multiple == true
            )
        );
    },

    isVisibleOnScreen(domElement) {
        return new Promise(resolve => {
            const o = new IntersectionObserver(([entry]) => {
                resolve(entry.intersectionRatio === 1);
                o.disconnect();
            });
            o.observe(domElement);
        });
    },

    jsonSyntaxHighlight(json: string, keepHtmlTags?: boolean): string {
        if (!keepHtmlTags) {
            json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        }

        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, match => {
            let cssClass = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cssClass = 'key';
                } else {
                    cssClass = 'string';
                }
            } else if (/true|false/.test(match)) {
                cssClass = 'boolean';
            } else if (/null/.test(match)) {
                cssClass = 'null';
            }
            return `<span class="${cssClass}">${match}</span>`;
        }).replace('\n}</pre>', '\n  \n}</pre>');
    },

    decycleFlowDefinition(data: FlowDefinition): FlowDefinition {
        let dataClone = Utils.clone(data) as FlowDefinition;
        dataClone.flowObjectDefinitions = [];

        data.flowObjectDefinitions.forEach(flowObject => {
            let flowObjectClone = Utils.clone(flowObject) as FlowObjectDefinition;
            flowObjectClone.next = [];

            flowObject.next.forEach(next => {
                let nextClone = Utils.clone(next) as FlowObjectDefinition;
                flowObjectClone.next.push(nextClone);
            });

            dataClone.flowObjectDefinitions.push(flowObjectClone);
        });

        return dataClone;
    },

    decycleFlowDefinitionContext(data: FlowDefinitionContext): FlowDefinitionContext {
        let dataClone = Utils.clone(data) as FlowDefinitionContext;
        dataClone.flowDefinitions = [];
        return dataClone;
    }
};
