import { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormioCustomComponent } from '@formio/angular';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../../shared/enums';
import { Utils } from '../../../shared/utils';

@Component({
    selector: 'file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements FormioCustomComponent<any>, OnInit {
    fileName: string = null;
    mimeType: string = '';
    drawInput: boolean = true;

    @Input() allowedExtensions: string[] = [];
    @Input() maxSizeMb: number = 15;
    @Input() disabled: boolean;
    @Input() value: any;
    @Output() valueChange = new EventEmitter<any>();

    constructor(private toastr: ToastrService) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        for (let extension of this.allowedExtensions) {
            this.mimeType += `.${extension.toLowerCase()},`;
        }

        if (this.mimeType == '') {
            this.mimeType = 'application/octet-stream';
        }
    }

    // ======================
    // public methods
    // ======================

    changeFile(event) {
        const file = event.target.files[0] as File;

        // caso a extensão do arquivo não seja permitida
        if (!this.allowedExtensions.includes(file.name.split('.').slice(-1)[0].toLowerCase())) {
            // reinicia valores do input e do componente Formio
            this.removeFile(event);
            this.toastr.error(Enums.Messages.InvalidFileExtension.replace('{0}', this.allowedExtensions.join('; ').toUpperCase()), Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        // caso o tamanho do arquivo seja maior que o máximo estabelecido
        if (file.size > this.maxSizeMb * 1024 * 1024) {
            // reinicia valores do input e do componente Formio
            this.removeFile(event);
            this.toastr.error(Enums.Messages.FileMaxSizeLimit.replace('{0}', this.maxSizeMb.toString()), Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onloadend = () => {
            // converte o arquivo para um array de bytes, uma vez que o "JSON.stringify > JSON.parse"
            // que ocorre no meio do caminho até o hook do evento de submit do Formio
            // transformaria o objeto do tipo File em um objeto vazio ("{}")
            let byteArray = [];
            let array = new Uint8Array(fileReader.result as ArrayBuffer);
            for (let byte of array) {
                byteArray.push(byte);
            }

            this.fileName = file.name;
            this.value = {
                isCustomFileComponent: true,
                fileName: [file.name],
                fileType: [file.type],
                fileContent: [byteArray]
            };
            this.valueChange.emit(this.value);
        };
    }

    removeFile(event) {
        event.target.value = '';
        this.fileName = null;
        this.value = {};
        this.valueChange.emit(this.value);

        // workaround usando "component recycling" para comportamento impreciso da tag "input[file]" ao sofrer reset de valor
        this.drawInput = false;
        setTimeout(() => this.drawInput = true, 1);
    }

    // ======================
    // private methods
    // ======================
}
