export const FLOW_OBJECT_DETAILS_MODEL_EDITOR_TINYMCE_OPTIONS = {
    selector: '#tinyMce',
    language: 'pt_BR',
    height: 'calc(100vh - 332px)',
    //content_css: '/styles.css',
    content_css: '/assets/css/flow-object-details-model-editor-tinymce.css',
    body_class: 'tinymce-body',
    theme: 'silver',
    //forced_root_block: false,
    statusbar: true,
    menubar: false,
    contextmenu: false,
    branding: false,
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 36pt 48pt 72pt',

    // evita que o tinyMce altere as URLs das imagens fornecidas
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,

    plugins: [
        'advlist autolink lists link charmap preview',
        'searchreplace visualblocks fullscreen',
        'insertdatetime table paste code wordcount visualchars visualblocks'
    ],

    //toolbar1: 'styleselect fontselect fontsizeselect | outdent indent | searchreplace | charmap subscript superscript | removeformat',
    toolbar1: 'styleselect fontsizeselect | outdent indent | searchreplace | charmap subscript superscript | removeformat',
    toolbar2: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table | undo redo | visualchars visualblocks | code fullscreen | customPageBreak',
    //toolbar3: 'pdfPreview | vPessoas vLocais vDatas pdfInfo',
    toolbar3: 'pdfPreview | vPessoas vLocais vDatas vForm',

    table_toolbar: '',

    mobile: {
        theme: 'mobile',
        plugins: ['autosave', 'lists', 'autolink'],
        toolbar: ['undo', 'bold', 'italic', 'styleselect'],
        height: '50px'
    },

    addDynamicMenu: editor => { },

    setup: editor => {
        FLOW_OBJECT_DETAILS_MODEL_EDITOR_TINYMCE_OPTIONS.addDynamicMenu(editor);

        editor.ui.registry.addMenuButton('vPessoas', {
            text: 'Pessoas',
            fetch: callback => callback([
                {
                    type: 'nestedmenuitem',
                    text: 'Usuário',
                    getSubmenuItems: () => [
                        {
                            type: 'menuitem',
                            text: 'Nome Completo',
                            onAction: () => editor.insertContent('{|UsuarioNome|}')
                        },
                        {
                            type: 'menuitem',
                            text: 'CPF',
                            onAction: () => editor.insertContent('{|UsuarioCpf|}')
                        },
                        {
                            type: 'menuitem',
                            text: 'E-mail',
                            onAction: () => editor.insertContent('{|UsuarioEmail|}')
                        }
                    ]
                },
                {
                    type: 'nestedmenuitem',
                    text: 'Servidor',
                    getSubmenuItems: () => [
                        {
                            type: 'menuitem',
                            text: 'Papel/Cargo/Função',
                            onAction: () => editor.insertContent('{|UsuarioPapel|}')
                        }
                    ]
                },
                {
                    type: 'nestedmenuitem',
                    text: 'Gestor Imediato',
                    getSubmenuItems: () => [
                        {
                            type: 'menuitem',
                            text: 'Nome Completo',
                            onAction: () => editor.insertContent('{|GestorImediatoNome|}')
                        },
                        {
                            type: 'menuitem',
                            text: 'Papel/Cargo/Função',
                            onAction: () => editor.insertContent('{|GestorImediatoPapel|}')
                        }
                    ]
                }
            ])
        });

        editor.ui.registry.addMenuButton('vLocais', {
            text: 'Locais',
            fetch: callback => callback([
                {
                    type: 'nestedmenuitem',
                    text: 'Usuário',
                    getSubmenuItems: () => [
                        {
                            type: 'nestedmenuitem',
                            text: 'Órgão',
                            getSubmenuItems: () => [
                                {
                                    type: 'menuitem',
                                    text: 'Nome',
                                    onAction: () => editor.insertContent('{|UsuarioOrgaoNome|}')
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Sigla',
                                    onAction: () => editor.insertContent('{|UsuarioOrgaoSigla|}')
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Brasão',
                                    onAction: () => editor.insertContent('{|UsuarioOrgaoBrasao|}')
                                }
                            ]
                        },
                        {
                            type: 'nestedmenuitem',
                            text: 'Unidade',
                            getSubmenuItems: () => [
                                {
                                    type: 'menuitem',
                                    text: 'Nome',
                                    onAction: () => editor.insertContent('{|UsuarioUnidadeNome|}')
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Sigla',
                                    onAction: () => editor.insertContent('{|UsuarioUnidadeSigla|}')
                                }
                            ]
                        },
                        {
                            type: 'nestedmenuitem',
                            text: 'Patriarca',
                            getSubmenuItems: () => [
                                {
                                    type: 'menuitem',
                                    text: 'Nome',
                                    onAction: () => editor.insertContent('{|UsuarioPatriarcaNome|}')
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Sigla',
                                    onAction: () => editor.insertContent('{|UsuarioPatriarcaSigla|}')
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Brasão',
                                    onAction: () => editor.insertContent('{|UsuarioPatriarcaBrasao|}')
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'nestedmenuitem',
                    text: 'Fluxo',
                    getSubmenuItems: () => [
                        {
                            type: 'nestedmenuitem',
                            text: 'Órgão',
                            getSubmenuItems: () => [
                                {
                                    type: 'menuitem',
                                    text: 'Nome',
                                    onAction: () => editor.insertContent('{|FluxoOrgaoNome|}')
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Sigla',
                                    onAction: () => editor.insertContent('{|FluxoOrgaoSigla|}')
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Brasão',
                                    onAction: () => editor.insertContent('{|FluxoOrgaoBrasao|}')
                                }
                            ]
                        },
                        {
                            type: 'nestedmenuitem',
                            text: 'Unidade',
                            getSubmenuItems: () => [
                                {
                                    type: 'menuitem',
                                    text: 'Nome',
                                    onAction: () => editor.insertContent('{|FluxoUnidadeNome|}')
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Sigla',
                                    onAction: () => editor.insertContent('{|FluxoUnidadeSigla|}')
                                }
                            ]
                        },
                        {
                            type: 'nestedmenuitem',
                            text: 'Patriarca',
                            getSubmenuItems: () => [
                                {
                                    type: 'menuitem',
                                    text: 'Nome',
                                    onAction: () => editor.insertContent('{|FluxoPatriarcaNome|}')
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Sigla',
                                    onAction: () => editor.insertContent('{|FluxoPatriarcaSigla|}')
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Brasão',
                                    onAction: () => editor.insertContent('{|FluxoPatriarcaBrasao|}')
                                }
                            ]
                        }
                    ]
                }
            ])
        });

        editor.ui.registry.addMenuButton('vDatas', {
            text: 'Data Corrente',
            fetch: callback => callback([
                {
                    type: 'menuitem',
                    text: 'Ano',
                    onAction: () => editor.insertContent('{|DataAno|}')
                },
                {
                    type: 'menuitem',
                    text: 'Mês',
                    onAction: () => editor.insertContent('{|DataMes|}')
                },
                {
                    type: 'menuitem',
                    text: 'Mês por extenso',
                    onAction: () => editor.insertContent('{|DataMesPorExtenso|}')
                },
                {
                    type: 'menuitem',
                    text: 'Dia',
                    onAction: () => editor.insertContent('{|DataDia|}')
                },
                {
                    type: 'menuitem',
                    text: 'Hora',
                    onAction: () => editor.insertContent('{|DataHora|}')
                },
                {
                    type: 'menuitem',
                    text: 'Minuto',
                    onAction: () => editor.insertContent('{|DataMinuto|}')
                },
                {
                    type: 'menuitem',
                    text: 'Segundo',
                    onAction: () => editor.insertContent('{|DataSegundo|}')
                }
            ])
        });

        editor.ui.registry.addButton('pdfPreview', {
            text: 'Pré-visualizar PDF',
            tooltip: 'Visualize o modelo em PDF, convertendo todas as variáveis em seus valores reais e correntes',
            icon: 'preview',
            onAction: () => FLOW_OBJECT_DETAILS_MODEL_EDITOR_TINYMCE_OPTIONS.previewPdf()
        });
           

        editor.ui.registry.addButton('customPageBreak', {
            text: '',
            tooltip: 'Inserir quebra de página no documento',
            icon: 'page-break',
            onAction: () => editor.insertContent('<div style="width: 100%; text-align: center; font-family: consolas; font-size: 12px; border: 1px dashed;">{~~|QuebraDePagina|~~}</div>')
        });

        //editor.ui.registry.addButton('pdfInfo', {
        //    icon: 'help',
        //    text: '',
        //    tooltip: 'Informações acerca das variáveis e brasões',
        //    onAction: function () {
        //        abreModalInfo();
        //    }
        //});
    },

    previewPdf: () => { },

    init_instance_callback: editor => { }
};
