<ng-container *ngIf="isReady()">
    <!-- Patriarcas -->
    <div class="form-group ml-3px" featureHint="flowObjectDetails|DocumentClassPatriarch">
        <label for="patriarcaId">Selecione um Patriarca para filtrar os Planos abaixo</label>
        <input type="text" class="form-control relevant-info-font one-button" id="patriarcaDisplay" name="patriarcaDisplay"
               placeholder="(nenhum selecionado)"
               [(ngModel)]="patriarcaDisplay"
               [matTooltip]="patriarcaNome"
               [matAutocomplete]="patriarcaAutocomplete"
               (input)="patriarcaDisplayChange()"
        >
        <mat-autocomplete #patriarcaAutocomplete="matAutocomplete"
                          [displayWith]="displayPatriarcaId"
                          (optionSelected)="patriarcaIdChange($event)"
        >
            <mat-option *ngFor="let option of selectablePatriarcas" [value]="option">{{option.sigla}} - {{option.nome.toUpperCase()}}</mat-option>
        </mat-autocomplete>
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearPatriarca()"></i>
    </div>

    <!-- Planos Ativos -->
    <div class="form-group ml-4" featureHint="flowObjectDetails|DocumentClassActivePlans">
        <label for="planoId">Selecione um Plano Ativo para filtrar as Classes abaixo</label>
        <input type="text" class="form-control relevant-info-font one-button" id="planoDisplay" name="planoDisplay"
               placeholder="(nenhum selecionado)"
               [(ngModel)]="planoDisplay"
               [matTooltip]="planoNome"
               [disabled]="planos.length == 0"
               [matAutocomplete]="planoAutocomplete"
               (input)="planoDisplayChange()"
        >
        <mat-autocomplete #planoAutocomplete="matAutocomplete"
                          [displayWith]="displayPlanoId"
                          (optionSelected)="planoIdChange($event)"
        >
            <mat-option *ngFor="let option of selectablePlanos" [value]="option">{{option.codigo}} - {{option.nome.toUpperCase()}}</mat-option>
        </mat-autocomplete>
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearPlano()"></i>
    </div>

    <!-- Classes Ativas -->
    <div class="form-group ml-5" featureHint="flowObjectDetails|DocumentClassActiveClasses">
        <label for="classeDisplay">Selecione uma das Classes Ativas disponíveis</label>
        <input type="text" class="form-control relevant-info-font one-button" id="classeDisplay" name="classeDisplay"
               placeholder="(nenhuma selecionada)"
               [(ngModel)]="classeDisplay"
               [matTooltip]="inputModel.name"
               [disabled]="classes.length == 0"
               [matAutocomplete]="classeAutocomplete"
               (input)="classeDisplayChange()"
        >
        <mat-autocomplete #classeAutocomplete="matAutocomplete"
                          [displayWith]="displayClasseId"
                          (optionSelected)="classeIdChange($event)"
        >
            <ng-container *ngFor="let option of selectableClasses">
                <mat-optgroup *ngIf="!isActualClasse(option)" [value]="option">{{option.codigo}} - {{option.nome.toUpperCase()}}</mat-optgroup>
                <mat-option *ngIf="isActualClasse(option)" [value]="option">{{option.codigo}} - {{option.nome.toUpperCase()}}</mat-option>
            </ng-container>
        </mat-autocomplete>
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearClasse()"></i>
    </div>

    <!-- Classificação Selecionada -->
    <div class="form-group ml-6" featureHint="flowObjectDetails|DocumentClassSelected" featureHintShowOn="top">
        <label for="documentClassName">Classificação Selecionada</label>
        <i class="fas fa-question-circle ml-1" *ngIf="inputShowTooltips"
           matTooltip="Classificação do documento gerado a partir do encerramento da Tarefa.
                       Selecione um dos Planos disponíveis para filtrar as Classes correspondentes"
        ></i>
        <input type="text" class="form-control relevant-info-font one-button" id="documentClassName" name="documentClassName" readonly
               placeholder="(nenhuma)"
               [(ngModel)]="inputModel.name"
               [matTooltip]="inputModel.name"
               (change)="documentClassNameChange()"
        >
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearClassificacao()"></i>
    </div>
</ng-container>
