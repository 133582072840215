<ng-container *ngIf="inputConfigSchema != null">
    <div class="form-group mt-4">
        <h5>Configurações do E-Docs</h5>
        <hr class="mt-0 mb-4" />
    </div>

    <div class="form-group mt-4">
        <h6>
            <span featureHint="flowObjectDetails|RegisterProcessLocation">Local de Autuação</span>
        </h6>
    </div>

    <!-- Patriarca -->
    <div class="form-group process-location ml-3px" featureHint="flowObjectDetails|RegisterProcessLocationPatriarch">
        <label for="patriarcaDisplay_ProcessLocation">Patriarca</label>
        <input type="text" class="form-control relevant-info-font two-buttons" id="patriarcaDisplay_ProcessLocation" name="patriarcaDisplay_ProcessLocation"
               placeholder="Selecione uma opção para filtrar os órgãos abaixo"
               [(ngModel)]="patriarcaDisplay_ProcessLocation"
               [matTooltip]="patriarcaNome_ProcessLocation"
               [matAutocomplete]="patriarcaAutocompleteProcessLocation"
               (input)="patriarcaDisplayChange_ProcessLocation()"
        >
        <mat-autocomplete #patriarcaAutocompleteProcessLocation="matAutocomplete"
                          [displayWith]="displayPatriarcaId_ProcessLocation"
                          (optionSelected)="patriarcaIdChange_ProcessLocation($event)"
        >
            <mat-option *ngFor="let option of selectablePatriarcas_ProcessLocation" [value]="option">
                {{option.sigla}} - {{option.nome}}
            </mat-option>
        </mat-autocomplete>
        <i class="fas fa-check-circle action add ml-2" matTooltip="Escolher" (click)="selectPatriarca_ProcessLocation()"></i>
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearPatriarca_ProcessLocation()"></i>
    </div>

    <!-- Órgão -->
    <div class="form-group process-location ml-3px" featureHint="flowObjectDetails|RegisterProcessLocationOrganization">
        <label for="organizacaoDisplay_ProcessLocation">Órgão</label>
        <input type="text" class="form-control relevant-info-font two-buttons" id="organizacaoDisplay_ProcessLocation" name="organizacaoDisplay_ProcessLocation"
               placeholder="Selecione um Patriarca acima para filtrar as opções disponíveis"
               [(ngModel)]="organizacaoDisplay_ProcessLocation"
               [matTooltip]="organizacaoNome_ProcessLocation"
               [matAutocomplete]="organizacaoAutocompleteProcessLocation"
               [disabled]="organizacoes_ProcessLocation.length == 0"
               (input)="organizacaoDisplayChange_ProcessLocation()"
        >
        <mat-autocomplete #organizacaoAutocompleteProcessLocation="matAutocomplete"
                          [displayWith]="displayOrganizacaoId_ProcessLocation"
                          (optionSelected)="organizacaoIdChange_ProcessLocation($event)"
        >
            <mat-option *ngFor="let option of selectableOrganizacoes_ProcessLocation" [value]="option">
                {{option.sigla}} - {{option.nomeFantasia == '.' ? option.sigla : option.nomeFantasia}}
            </mat-option>
        </mat-autocomplete>
        <i class="fas fa-check-circle action add ml-2" matTooltip="Escolher" (click)="selectOrganizacao_ProcessLocation()"></i>
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearOrganizacao_ProcessLocation()"></i>
    </div>

    <!-- Setor -->
    <div class="form-group process-location ml-3px" featureHint="flowObjectDetails|RegisterProcessLocationUnit">
        <label for="unidadeDisplay_ProcessLocation">Setor</label>
        <input type="text" class="form-control relevant-info-font two-buttons" id="unidadeDisplay_ProcessLocation" name="unidadeDisplay_ProcessLocation"
               placeholder="Selecione um Órgão acima para filtrar as opções disponíveis"
               [(ngModel)]="unidadeDisplay_ProcessLocation"
               [matTooltip]="unidadeNome_ProcessLocation"
               [matAutocomplete]="unidadeAutocompleteProcessLocation"
               [disabled]="unidades_ProcessLocation.length == 0"
               (input)="unidadeDisplayChange_ProcessLocation()"
        >
        <mat-autocomplete #unidadeAutocompleteProcessLocation="matAutocomplete"
                          [displayWith]="displayUnidadeId_ProcessLocation"
                          (optionSelected)="unidadeIdChange_ProcessLocation($event)"
        >
            <mat-option *ngFor="let option of selectableUnidades_ProcessLocation" [value]="option">
                {{option.nomeCurto ? option.nomeCurto.toUpperCase() + ' - ' : ''}}{{option.nome.toUpperCase()}}
            </mat-option>
        </mat-autocomplete>
        <i class="fas fa-check-circle action add ml-2" matTooltip="Escolher" (click)="selectUnidade_ProcessLocation()"></i>
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearUnidade_ProcessLocation()"></i>
    </div>

    <!-- Local de Autuação -->
    <div class="form-group ml-4" featureHint="flowObjectDetails|RegisterProcessLocationSelected">
        <label for="processLocationId">Local de Autuação</label>
        <i class="fas fa-question-circle ml-1"
           matTooltip="Local de autuação do Processo. Selecione uma das opções disponíveis acima"
        ></i>
        <input type="text" class="form-control relevant-info-font one-button" id="processLocationId" name="processLocationId" readonly
               placeholder="(nenhum)"
               [(ngModel)]="inputConfigSchema.taskRegisterProcess.processLocationName"
               [matTooltip]="inputConfigSchema.taskRegisterProcess.processLocationName"
        >
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clear_ProcessLocation()"></i>
    </div>

    <div class="form-group mt-4">
        <h6>
            <span featureHint="flowObjectDetails|DocumentClass">Classe do Processo</span>
        </h6>
    </div>

    <!-- Classe do Processo -->
    <document-class [inputModel]="configSchemaSectionDocumentClass"
                    [inputFlowDefinitionOrganizationId]="inputFlowDefinition.organizationId"
                    [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                    (outputModelChange)="onUpdateProcessClass($event)"
    ></document-class>

    <div class="form-group mt-4">
        <h6>
            <span featureHint="flowObjectDetails|RegisterProcessStakeholders">Interessados do Processo</span>
        </h6>
    </div>

    <!-- Patriarca -->
    <div class="form-group process-stakeholders ml-3px">
        <label class="toggle-section" (click)="togglePatriarcaSection()">
            <i class="fas fa-chevron-circle-right mr-1" [class.on]="isShowingPatriarcaSection"></i>
            <span>Patriarca</span>
            <!--<i class="fas fa-chevron-circle-left ml-1" [class.on]="isShowingPatriarcaSection"></i>-->
        </label>

        <div *ngIf="isShowingPatriarcaSection" featureHint="flowObjectDetails|RegisterProcessStakeholdersPatriarch">
            <input type="text" class="form-control relevant-info-font two-buttons" id="patriarcaDisplay_ProcessStakeholders"
                   name="patriarcaDisplay_ProcessStakeholders"
                   placeholder="Selecione uma opção"
                   [(ngModel)]="patriarcaDisplay_ProcessStakeholders"
                   [matTooltip]="patriarcaNome_ProcessStakeholders"
                   [matAutocomplete]="patriarcaAutocompleteProcessStakeholders"
                   (input)="patriarcaDisplayChange_ProcessStakeholders()"
            >
            <mat-autocomplete #patriarcaAutocompleteProcessStakeholders="matAutocomplete"
                              [displayWith]="displayPatriarcaId_ProcessStakeholders"
                              (optionSelected)="patriarcaIdChange_ProcessStakeholders($event)"
            >
                <mat-option *ngFor="let option of selectablePatriarcas_ProcessStakeholders" [value]="option">
                    {{option.sigla}} - {{option.nome}}
                </mat-option>
            </mat-autocomplete>
            <i class="fas fa-plus-circle action add ml-2" matTooltip="Adicionar" (click)="selectPatriarca_ProcessStakeholders()"></i>
            <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearPatriarca_ProcessStakeholders()"></i>
        </div>
    </div>

    <!-- Órgão -->
    <div class="form-group process-stakeholders ml-3px">
        <label class="toggle-section" (click)="toggleOrganizacaoSection()">
            <i class="fas fa-chevron-circle-right mr-1" [class.on]="isShowingOrganizacaoSection"></i>
            <span>Órgão</span>
            <!--<i class="fas fa-chevron-circle-left ml-1" [class.on]="isShowingOrganizacaoSection"></i>-->
        </label>

        <div *ngIf="isShowingOrganizacaoSection" featureHint="flowObjectDetails|RegisterProcessStakeholdersOrganization">
            <input type="text" class="form-control relevant-info-font two-buttons" id="organizacaoDisplay_ProcessStakeholders"
                   name="organizacaoDisplay_ProcessStakeholders"
                   placeholder="Selecione um Patriarca para filtrar os Órgãos disponíveis"
                   [(ngModel)]="organizacaoDisplay_ProcessStakeholders"
                   [matTooltip]="organizacaoNome_ProcessStakeholders"
                   [matAutocomplete]="organizacaoAutocompleteProcessStakeholders"
                   [disabled]="organizacoes_ProcessStakeholders.length == 0"
                   (input)="organizacaoDisplayChange_ProcessStakeholders()"
            >
            <mat-autocomplete #organizacaoAutocompleteProcessStakeholders="matAutocomplete"
                              [displayWith]="displayOrganizacaoId_ProcessStakeholders"
                              (optionSelected)="organizacaoIdChange_ProcessStakeholders($event)"
            >
                <mat-option *ngFor="let option of selectableOrganizacoes_ProcessStakeholders" [value]="option">
                    {{option.sigla}} - {{option.nomeFantasia == '.' ? option.sigla : option.nomeFantasia}}
                </mat-option>
            </mat-autocomplete>
            <i class="fas fa-plus-circle action add ml-2" matTooltip="Escolher" (click)="selectOrganizacao_ProcessStakeholders()"></i>
            <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearOrganizacao_ProcessStakeholders()"></i>
        </div>
    </div>

    <!-- Setor -->
    <div class="form-group process-stakeholders ml-3px">
        <label class="toggle-section" (click)="toggleUnidadeSection()">
            <i class="fas fa-chevron-circle-right mr-1" [class.on]="isShowingUnidadeSection"></i>
            <span>Setor</span>
            <!--<i class="fas fa-chevron-circle-left ml-1" [class.on]="isShowingUnidadeSection"></i>-->
        </label>

        <div *ngIf="isShowingUnidadeSection" featureHint="flowObjectDetails|RegisterProcessStakeholdersUnit">
            <input type="text" class="form-control relevant-info-font two-buttons" id="unidadeDisplay_ProcessStakeholders"
                   name="unidadeDisplay_ProcessStakeholders"
                   placeholder="Selecione um Órgão para filtrar os Setores disponíveis"
                   [(ngModel)]="unidadeDisplay_ProcessStakeholders"
                   [matTooltip]="unidadeNome_ProcessStakeholders"
                   [matAutocomplete]="unidadeAutocompleteProcessStakeholders"
                   [disabled]="unidades_ProcessStakeholders.length == 0"
                   (input)="unidadeDisplayChange_ProcessStakeholders()"
            >
            <mat-autocomplete #unidadeAutocompleteProcessStakeholders="matAutocomplete"
                              [displayWith]="displayUnidadeId_ProcessStakeholders"
                              (optionSelected)="unidadeIdChange_ProcessStakeholders($event)"
            >
                <mat-option *ngFor="let option of selectableUnidades_ProcessStakeholders" [value]="option">
                    {{option.nomeCurto ? option.nomeCurto.toUpperCase() + ' - ' : ''}}{{option.nome.toUpperCase()}}
                </mat-option>
            </mat-autocomplete>
            <i class="fas fa-plus-circle action add ml-2" matTooltip="Escolher" (click)="selectUnidade_ProcessStakeholders()"></i>
            <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearUnidade_ProcessStakeholders()"></i>
        </div>
    </div>

    <!-- Grupo de Trabalho -->
    <div class="form-group process-stakeholders ml-3px">
        <label class="toggle-section" (click)="toggleGrupoSection()">
            <i class="fas fa-chevron-circle-right mr-1" [class.on]="isShowingGrupoSection"></i>
            <span>Grupo de Trabalho</span>
            <!--<i class="fas fa-chevron-circle-left ml-1" [class.on]="isShowingGrupoSection"></i>-->
        </label>

        <div *ngIf="isShowingGrupoSection" featureHint="flowObjectDetails|RegisterProcessStakeholdersGroup">
            <input type="text" class="form-control relevant-info-font two-buttons" id="grupoDisplay_ProcessStakeholders"
                   name="grupoDisplay_ProcessStakeholders"
                   placeholder="Selecione um Órgão para filtrar os Grupos disponíveis"
                   [(ngModel)]="grupoDisplay_ProcessStakeholders"
                   [matTooltip]="grupoNome_ProcessStakeholders"
                   [matAutocomplete]="grupoAutocompleteProcessStakeholders"
                   [disabled]="grupos_ProcessStakeholders.length == 0"
                   (input)="grupoDisplayChange_ProcessStakeholders()"
            >
            <mat-autocomplete #grupoAutocompleteProcessStakeholders="matAutocomplete"
                              [displayWith]="displayGrupoId_ProcessStakeholders"
                              (optionSelected)="grupoIdChange_ProcessStakeholders($event)"
            >
                <mat-option *ngFor="let option of selectableGrupos_ProcessStakeholders" [value]="option">
                    {{option.nome.toUpperCase()}}
                </mat-option>
            </mat-autocomplete>
            <i class="fas fa-plus-circle action add ml-2" matTooltip="Escolher" (click)="selectGrupo_ProcessStakeholders()"></i>
            <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearGrupo_ProcessStakeholders()"></i>
        </div>
    </div>

    <!-- Comissão ou Contrato -->
    <div class="form-group process-stakeholders ml-3px">
        <label class="toggle-section" (click)="toggleComissaoSection()">
            <i class="fas fa-chevron-circle-right mr-1" [class.on]="isShowingComissaoSection"></i>
            <span>Comissão ou Contrato</span>
            <!--<i class="fas fa-chevron-circle-left ml-1" [class.on]="isShowingComissaoSection"></i>-->
        </label>

        <div *ngIf="isShowingComissaoSection" featureHint="flowObjectDetails|RegisterProcessStakeholdersComission">
            <input type="text" class="form-control relevant-info-font two-buttons" id="comissaoDisplay_ProcessStakeholders"
                   name="comissaoDisplay_ProcessStakeholders"
                   placeholder="Selecione um Órgão para filtrar as Comissões ou Contratos disponíveis"
                   [(ngModel)]="comissaoDisplay_ProcessStakeholders"
                   [matTooltip]="comissaoNome_ProcessStakeholders"
                   [matAutocomplete]="comissaoAutocompleteProcessStakeholders"
                   [disabled]="comissoes_ProcessStakeholders.length == 0"
                   (input)="comissaoDisplayChange_ProcessStakeholders()"
            >
            <mat-autocomplete #comissaoAutocompleteProcessStakeholders="matAutocomplete"
                              [displayWith]="displayComissaoId_ProcessStakeholders"
                              (optionSelected)="comissaoIdChange_ProcessStakeholders($event)"
            >
                <mat-option *ngFor="let option of selectableComissoes_ProcessStakeholders" [value]="option">
                    {{option.nome.toUpperCase()}}
                </mat-option>
            </mat-autocomplete>
            <i class="fas fa-plus-circle action add ml-2" matTooltip="Escolher" (click)="selectComissao_ProcessStakeholders()"></i>
            <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearComissao_ProcessStakeholders()"></i>
        </div>
    </div>

    <!-- Servidor -->
    <div class="form-group process-stakeholders ml-3px">
        <label class="toggle-section" (click)="toggleServidorSection()">
            <i class="fas fa-chevron-circle-right mr-1" [class.on]="isShowingServidorSection"></i>
            <span>Servidor</span>
            <!--<i class="fas fa-chevron-circle-left ml-1" [class.on]="isShowingServidorSection"></i>-->
        </label>
    </div>

    <ng-container *ngIf="isShowingServidorSection">
        <!-- CPF do Servidor -->
        <div class="form-group process-stakeholders ml-4" featureHint="flowObjectDetails|RegisterProcessStakeholdersPublicAgentCpf">
            <label for="servidorCpf_ProcessStakeholders">CPF do Servidor</label>
            <input type="text" class="form-control two-buttons" id="servidorCpf_ProcessStakeholders" name="servidorCpf_ProcessStakeholders"
                   placeholder="Entre com o CPF do servidor para filtrar seus papéis"
                   maxlength="11"
                   pattern="[0-9]{11}"
                   (keyup.enter)="searchServidorCpf_ProcessStakeholders()"
                   [(ngModel)]="servidorCpf_ProcessStakeholders"
            >
            <i class="fas fa-search action search ml-2" matTooltip="Procurar" (click)="searchServidorCpf_ProcessStakeholders()"></i>
            <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearServidorCpf_ProcessStakeholders()"></i>
        </div>

        <!-- Papéis do Servidor -->
        <div class="form-group process-stakeholders ml-5" featureHint="flowObjectDetails|RegisterProcessStakeholdersPublicAgentRole">
            <label for="servidorPapelDisplay_ProcessStakeholders">Papéis do Servidor</label>
            <input type="text" class="form-control relevant-info-font two-buttons" id="servidorPapelDisplay_ProcessStakeholders"
                   name="servidorPapelDisplay_ProcessStakeholders"
                   placeholder="Selecione um papel do servidor"
                   [(ngModel)]="servidorPapelDisplay_ProcessStakeholders"
                   [matTooltip]="servidorPapelNome_ProcessStakeholders"
                   [matAutocomplete]="servidorPapelAutocompleteProcessStakeholders"
                   [disabled]="servidorPapeis_ProcessStakeholders.length == 0"
                   (input)="servidorPapelDisplayChange_ProcessStakeholders()"
            >
            <mat-autocomplete #servidorPapelAutocompleteProcessStakeholders="matAutocomplete"
                              [displayWith]="displayServidorPapelId_ProcessStakeholders"
                              (optionSelected)="servidorPapelIdChange_ProcessStakeholders($event)"
            >
                <mat-option *ngFor="let option of selectableServidorPapeis_ProcessStakeholders" [value]="option">
                    <span class="to-upper">
                        {{option.nome}} - {{option.lotacao.nomeCurto}} - {{option.lotacao.organizacao.sigla}} - {{option.lotacao.organizacao.organizacaoPai.sigla}}
                    </span>
                </mat-option>
            </mat-autocomplete>
            <i class="fas fa-plus-circle action add ml-2" matTooltip="Adicionar" (click)="selectServidorPapel_ProcessStakeholders()"></i>
            <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearServidorPapel_ProcessStakeholders()"></i>
        </div>
    </ng-container>

    <!-- Cidadão -->
    <div class="form-group process-stakeholders ml-3px">
        <label class="toggle-section" (click)="toggleCidadaoSection()">
            <i class="fas fa-chevron-circle-right mr-1" [class.on]="isShowingCidadaoSection"></i>
            <span>Cidadão</span>
            <!--<i class="fas fa-chevron-circle-left ml-1" [class.on]="isShowingCidadaoSection"></i>-->
        </label>
    </div>

    <ng-container *ngIf="isShowingCidadaoSection">
        <!-- Cidadão instanciador -->
        <mat-radio-group class="citizen-stakeholder ml-4" id="citizenStakeholder" name="citizenStakeholder" color="primary"
                         featureHint="flowObjectDetails|RegisterProcessStakeholdersCitizen" featureHintShowOn="right"
                         [(ngModel)]="isCitizenFlowInstanceOwner"
        >
            <mat-radio-button [value]="true">
                Adicionar automaticamente o cidadão instanciador do Fluxo
                <i class="fas fa-plus-circle action add ml-2 float-right" matTooltip="Adicionar" *ngIf="isCitizenFlowInstanceOwner === true"
                   (click)="selectCidadaoFlowInstanceOwner_ProcessStakeholders()"
                ></i>
            </mat-radio-button>
            <mat-radio-button [value]="false">
                Adicionar cidadão a partir de campo <b>CPF</b> do Formulário
            </mat-radio-button>
        </mat-radio-group>

        <!-- Campo CPF do Formulário -->
        <div class="form-group form-field ml-5" *ngIf="isCitizenFlowInstanceOwner === false" featureHint="flowObjectDetails|RegisterProcessStakeholdersCitizenCpf">
            <label for="cidadaoFormField_ProcessStakeholders">Campo CPF do Formulário</label>
            <i class="fas fa-question-circle ml-1"
               matTooltip="Campo do Formulário contendo o CPF que será utilizado para identificar
                           o cidadão a ser adicionado como Interessado no Processo"
            ></i>
            <select class="form-control two-buttons" id="cidadaoFormField_ProcessStakeholders" name="cidadaoFormField_ProcessStakeholders"
                    [(ngModel)]="cidadaoFormField_ProcessStakeholders"
            >
                <option value="">Selecione um dos campos do formulário</option>
                <option *ngFor="let option of cidadaoFormFieldOptions_ProcessStakeholders"
                        [value]="option.value"
                >{{option.description}}</option>
            </select>
            <i class="fas fa-plus-circle action add ml-2" matTooltip="Adicionar"
               [class.disabled]="Utils.isNullOrEmpty(cidadaoFormField_ProcessStakeholders)"
               (click)="selectCidadaoFormField_ProcessStakeholders()"
            ></i>
            <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearCidadaoFormField_ProcessStakeholders()"></i>
        </div>
    </ng-container>

    <!-- Pessoa Jurídica -->
    <div class="form-group process-stakeholders ml-3px">
        <label class="toggle-section" (click)="toggleEmpresaSection()">
            <i class="fas fa-chevron-circle-right mr-1" [class.on]="isShowingEmpresaSection"></i>
            <span>Pessoa Jurídica</span>
            <!--<i class="fas fa-chevron-circle-left ml-1" [class.on]="isShowingEmpresaSection"></i>-->
        </label>
    </div>

    <ng-container *ngIf="isShowingEmpresaSection">
        <!-- Razão Social -->
        <ng-container>
            <div class="form-group fw-600 mt-2 mb-2 ml-4">
                <span>Razão Social</span>
            </div>

            <!-- Modo da Razão Social -->
            <mat-radio-group class="company-stakeholder ml-5" id="companyNameStakeholder" name="companyNameStakeholder" color="primary"
                             featureHint="flowObjectDetails|RegisterProcessStakeholdersCompanyName"
                             [(ngModel)]="isEmpresaCompanyNameFreeEntry"
            >
                <mat-radio-button [value]="true">
                    Entrar livremente com o valor para o campo <b>Razão Social</b>
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    Escolher campo do Formulário que fornecerá o valor de <b>Razão Social</b>
                </mat-radio-button>
            </mat-radio-group>

            <!-- Entrada Livre -->
            <div class="form-group process-stakeholders ml-6 mb-2" *ngIf="isEmpresaCompanyNameFreeEntry === true"
                 featureHint="flowObjectDetails|RegisterProcessStakeholdersCompanyNameFree"
            >
                <label for="empresaCompanyName_ProcessStakeholders">Entrada Livre</label>
                <input type="text" class="form-control one-button" id="empresaCompanyName_ProcessStakeholders"
                       name="empresaCompanyName_ProcessStakeholders"
                       maxlength="200"
                       (change)="clearEmpresaCompanyNameFormField_ProcessStakeholders()"
                       [(ngModel)]="empresaCompanyName_ProcessStakeholders"
                >
                <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearEmpresaCompanyName_ProcessStakeholders()"></i>
            </div>

            <!-- Campo do Formulário -->
            <div class="form-group process-stakeholders ml-6 mb-2" *ngIf="isEmpresaCompanyNameFreeEntry === false"
                 featureHint="flowObjectDetails|RegisterProcessStakeholdersCompanyNameFormField"
            >
                <label class="d-inline-block" for="empresaCompanyNameFormField_ProcessStakeholders">Campo do Formulário</label>
                <i class="fas fa-question-circle ml-1"
                   matTooltip="Campo do Formulário contendo a Razão Social que será utilizada para identificar
                               a pessoa jurídica a ser adicionada como Interessado no Processo"
                ></i>
                <select class="form-control one-button" id="empresaCompanyNameFormField_ProcessStakeholders"
                        name="empresaCompanyNameFormField_ProcessStakeholders"
                        (change)="clearEmpresaCompanyName_ProcessStakeholders()"
                        [(ngModel)]="empresaCompanyNameFormField_ProcessStakeholders"
                >
                    <option value="">Selecione um dos campos do formulário</option>
                    <option *ngFor="let option of empresaCompanyNameFormFieldOptions_ProcessStakeholders"
                            [value]="option.value"
                    >{{option.description}}</option>
                </select>
                <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearEmpresaCompanyNameFormField_ProcessStakeholders()"></i>
            </div>
        </ng-container>

        <!-- CNPJ -->
        <ng-container>
            <div class="form-group fw-600 mt-2 mb-2 ml-4">
                <span>CNPJ</span>
            </div>

            <!-- Modo do CNPJ -->
            <mat-radio-group class="company-stakeholder ml-5" id="cnpjStakeholder" name="cnpjStakeholder" color="primary"
                             featureHint="flowObjectDetails|RegisterProcessStakeholdersCompanyCnpj"
                             [(ngModel)]="isEmpresaCnpjFreeEntry"
            >
                <mat-radio-button [value]="true">
                    Entrar livremente com o valor para o campo <b>CNPJ</b>
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    Escolher campo do Formulário que fornecerá o valor de <b>CNPJ</b>
                </mat-radio-button>
            </mat-radio-group>

            <!-- Entrada Livre -->
            <div class="form-group process-stakeholders ml-6 mb-2" *ngIf="isEmpresaCnpjFreeEntry === true"
                 featureHint="flowObjectDetails|RegisterProcessStakeholdersCompanyCnpjFree"
            >
                <label for="empresaCnpj_ProcessStakeholders">Entrada Livre</label>
                <input type="text" class="form-control one-button" id="empresaCnpj_ProcessStakeholders"
                       name="empresaCnpj_ProcessStakeholders"
                       maxlength="14"
                       pattern="[0-9]{14}"
                       (change)="clearEmpresaCnpjFormField_ProcessStakeholders()"
                       [(ngModel)]="empresaCnpj_ProcessStakeholders"
                >
                <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearEmpresaCnpj_ProcessStakeholders()"></i>
            </div>

            <!-- Campo do Formulário -->
            <div class="form-group process-stakeholders ml-6 mb-2" *ngIf="isEmpresaCnpjFreeEntry === false"
                 featureHint="flowObjectDetails|RegisterProcessStakeholdersCompanyCnpjFormField"
            >
                <label class="d-inline-block" for="empresaCnpjFormField_ProcessStakeholders">Campo do Formulário</label>
                <i class="fas fa-question-circle ml-1"
                   matTooltip="Campo do Formulário contendo o CNPJ que será utilizado para identificar
                               a pessoa jurídica a ser adicionada como Interessado no Processo"
                ></i>
                <select class="form-control one-button" id="empresaCnpjFormField_ProcessStakeholders"
                        name="empresaCnpjFormField_ProcessStakeholders"
                        (change)="clearEmpresaCnpj_ProcessStakeholders()"
                        [(ngModel)]="empresaCnpjFormField_ProcessStakeholders"
                >
                    <option value="">Selecione um dos campos do formulário</option>
                    <option *ngFor="let option of empresaCnpjFormFieldOptions_ProcessStakeholders"
                            [value]="option.value"
                    >{{option.description}}</option>
                </select>
                <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearEmpresaCnpjFormField_ProcessStakeholders()"></i>
            </div>
        </ng-container>

        <!-- E-mail -->
        <ng-container>
            <div class="form-group fw-600 mt-2 mb-2 ml-4">
                <span>E-mail</span>
            </div>

            <!-- Modo do E-mail -->
            <mat-radio-group class="company-stakeholder ml-5" id="emailStakeholder" name="emailStakeholder" color="primary"
                             featureHint="flowObjectDetails|RegisterProcessStakeholdersCompanyEmail"
                             [(ngModel)]="isEmpresaEmailFreeEntry"
            >
                <mat-radio-button [value]="true">
                    Entrar livremente com o valor para o campo <b>E-mail</b>
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    Escolher campo do Formulário que fornecerá o valor de <b>E-mail</b>
                </mat-radio-button>
            </mat-radio-group>

            <!-- Entrada Livre -->
            <div class="form-group process-stakeholders ml-6 mb-2" *ngIf="isEmpresaEmailFreeEntry === true"
                 featureHint="flowObjectDetails|RegisterProcessStakeholdersCompanyEmailFree"
            >
                <label for="empresaEmail_ProcessStakeholders">Entrada Livre</label>
                <input type="email" class="form-control one-button" id="empresaEmail_ProcessStakeholders"
                       name="empresaEmail_ProcessStakeholders"
                       (change)="clearEmpresaEmailFormField_ProcessStakeholders()"
                       [(ngModel)]="empresaEmail_ProcessStakeholders"
                >
                <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearEmpresaEmail_ProcessStakeholders()"></i>
            </div>

            <!-- Campo do Formulário -->
            <div class="form-group process-stakeholders ml-6 mb-2" *ngIf="isEmpresaEmailFreeEntry === false"
                 featureHint="flowObjectDetails|RegisterProcessStakeholdersCompanyEmailFormField"
            >
                <label class="d-inline-block" for="empresaEmailFormField_ProcessStakeholders">Campo do Formulário</label>
                <i class="fas fa-question-circle ml-1"
                   matTooltip="Campo do Formulário contendo o e-mail que será utilizado para identificar
                               a pessoa jurídica a ser adicionada como Interessado no Processo"
                ></i>
                <select class="form-control one-button" id="empresaEmailFormField_ProcessStakeholders"
                        name="empresaEmailFormField_ProcessStakeholders"
                        (change)="clearEmpresaEmail_ProcessStakeholders()"
                        [(ngModel)]="empresaEmailFormField_ProcessStakeholders"
                >
                    <option value="">Selecione um dos campos do formulário</option>
                    <option *ngFor="let option of empresaEmailFormFieldOptions_ProcessStakeholders"
                            [value]="option.value"
                    >{{option.description}}</option>
                </select>
                <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearEmpresaEmailFormField_ProcessStakeholders()"></i>
            </div>
        </ng-container>

        <!-- Adicionar Pessoa Jurídica -->
        <div class="form-group add-company-stakeholder mt-4" featureHint="flowObjectDetails|RegisterProcessStakeholdersCompanyAdd">
            <button type="button" class="btn btn-outline-primary"
                    [disabled]="shouldDisableAddCompanyStakeholder()"
                    (click)="addCompanyStakeholder()"
            >
                <i class="fas fa-arrow-circle-down mr-2"></i>
                <span>Adicionar Pessoa Jurídica</span>
                <i class="fas fa-arrow-circle-down ml-2"></i>
            </button>
        </div>
    </ng-container>

    <!-- Interessados -->
    <ng-container>
        <div class="stakeholders-container empty mt-4 mb-3 p-2"
             featureHint="flowObjectDetails|RegisterProcessStakeholdersPanel" featureHintShowOn="right"
             *ngIf="inputConfigSchema.taskRegisterProcess.stakeholders.length == 0; else stakeholdersTemplate"
        >
            <span class="relevant-info-font">Nenhum interessado adicionado</span>
        </div>
        <ng-template #stakeholdersTemplate>
            <div class="stakeholders-container mt-4 mb-3 p-2"
                 featureHint="flowObjectDetails|RegisterProcessStakeholdersPanel" featureHintShowOn="right" featureHintVAlign="top"
            >
                <div class="stakeholder p-2" *ngFor="let stakeholder of inputConfigSchema.taskRegisterProcess.stakeholders; let i = index">
                    <div class="stakeholder-content m-0"
                         *ngIf="[
                             StakeholderType.Patriarch,
                             StakeholderType.Organization,
                             StakeholderType.Unit,
                             StakeholderType.Group,
                             StakeholderType.Comission,
                             StakeholderType.Citizen
                         ].includes(stakeholder.type)"
                    >
                        <b [class.to-upper]="stakeholder.type != StakeholderType.Citizen">{{stakeholder.name}}</b>
                        <br />
                        <span>{{StakeholderTypeDescription.get(+stakeholder.type)}}</span>
                    </div>

                    <div class="stakeholder-content m-0" *ngIf="stakeholder.type == StakeholderType.PublicAgent">
                        <b class="to-upper">{{stakeholder.name.split(' - ')[0]}}</b>
                        <br />
                        <span class="to-upper">{{stakeholder.name.split(' - ').splice(1).join(' - ')}}</span>
                        <br />
                        <span>{{StakeholderTypeDescription.get(+stakeholder.type)}}</span>
                    </div>

                    <div class="stakeholder-content m-0" *ngIf="stakeholder.type == StakeholderType.Company">
                        <b [class.to-upper]="Utils.isNullOrEmpty(stakeholder.companyNameFormField)">{{stakeholder.companyName}}</b>
                        <br />
                        <span>{{Utils.maskCnpj(stakeholder.cnpj)}} <b>|</b> {{stakeholder.email}}</span>
                        <br />
                        <span>{{StakeholderTypeDescription.get(+stakeholder.type)}}</span>
                    </div>

                    <i class="fas fa-trash-alt ml-2" matTooltip="Remover interessado" (click)="removeStakeholder(stakeholder)"></i>

                    <span class="checkbox-group" *ngIf="[StakeholderType.Citizen, StakeholderType.Company].includes(stakeholder.type)">
                        <i class="fas fa-question-circle"
                           matTooltip="Se obrigatório, a autuação do Processo não será permitida caso o sistema não consiga determinar todos os dados deste interessado"
                        ></i>
                        <mat-checkbox id="mandatoryStakeholder_{{i}}" name="mandatoryStakeholder_{{i}}" color="primary"
                                      [(ngModel)]="stakeholder.isMandatory"
                        >Obrigatório</mat-checkbox>
                    </span>
                </div>

                <button type="button" class="btn btn-outline-danger"
                        [disabled]="inputIsReadOnlyMode"
                        (click)="removeAllStakeholders()"
                >
                    <i class="fas fa-times-circle mr-1"></i>
                    <span>Remover todos os interessados</span>
                </button>
            </div>
        </ng-template>
    </ng-container>

    <div class="form-group mt-4">
        <h6>Resumo do Processo</h6>
    </div>

    <div class="form-group ml-3px" featureHint="flowObjectDetails|RegisterProcessSummary" featureHintShowOn="right" featureHintVAlign="top">
        <editor name="processSummary" [init]="tinyMceOptionsProcess" [(ngModel)]="processSummary"></editor>
        <textarea id="tinyMceRegisterProcess" class="d-none"></textarea>
    </div>
</ng-container>
