import { Component, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'flow-object-details-model-editor',
    templateUrl: './flow-object-details-model-editor.component.html',
    styleUrls: ['./flow-object-details-model-editor.component.scss']
})
export class FlowObjectDetailsModelEditorComponent implements OnInit {
    // #region [ViewChild]
    @ViewChild('wrapperRef') wrapperRef: ElementRef;
    // #endregion

    // #region [Input/Output]
    @Input() ngModel: string;
    @Output() ngModelChange = new EventEmitter<string>();
    @Input() inputShouldLoadTinyMce: boolean;
    @Input() inputIsReadOnlyMode: boolean;
    @Input() inputTinyMceOptions: string;
    @Output() outputOnInitNgModelEvent = new EventEmitter<any>();
    @Output() outputToggleEditAreaEvent = new EventEmitter<any>();
    @Output() outputUpdateHtmlEditorAreaEvent = new EventEmitter<any>();
    // #endregion

    constructor() { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() { }

    // ======================
    // public methods
    // ======================

    tinyMceFinishedLoading() {
        this.outputOnInitNgModelEvent.emit();
    }

    toggleEditArea() {
        this.outputToggleEditAreaEvent.emit();
    }

    onSubmit() {
        if (this.inputIsReadOnlyMode) return false;

        this.outputUpdateHtmlEditorAreaEvent.emit();
    }

    editorNgModelChange(event) {
        this.ngModelChange.emit(event);
    }

    // ======================
    // private methods
    // ======================
}
