import { Pipe, PipeTransform } from "@angular/core";
import { Servico } from "../../../models/catalogo-es.model";

@Pipe({
    name: 'catalogServicesFilter'
})
export class CatalogServicesFilter implements PipeTransform {
    transform(data: Servico[], inputText?: string) {
        return inputText != null && inputText.trim() != ''
            ? data.filter(x => x.nome.toLowerCase().includes(inputText.toLowerCase()))
            : data;
    }
}
