<ng-container *ngIf="inputConfigSchema != null">
    <div class="form-group mt-4">
        <h5>Configurações do E-Docs</h5>
        <hr class="mt-0 mb-4" />
    </div>

    <div class="form-group mt-4 mb-2">
        <h6 class="d-inline-block">Destinatário</h6>
        <i class="fas fa-question-circle ml-1"
           matTooltip="Selecione um dos locais disponíveis (filtrados por Patriarca e Órgão) ou um servidor (filtrado por CPF) que irá receber o Despacho do Processo via E-Docs"
        ></i>
    </div>

    <!-- Seletor de destinatário -->
    <div featureHint="flowObjectDetails|DispatchProcessRecipientMode" featureHintVAlign="top" featureHintShowOn="right">
        <mat-radio-group class="recipient-mode mb-2" id="recipientMode" name="recipientMode" color="primary"
                         [(ngModel)]="recipientMode"
                         [required]="true"
                         (change)="recipientModeChange()"
        >
            <mat-radio-button [value]="RecipientMode.PublicAgent">Servidor</mat-radio-button>
            <mat-radio-button [value]="RecipientMode.Fixed">Local do E-Docs fixo</mat-radio-button>
            <mat-radio-button [value]="RecipientMode.Conditional">
                Local do E-Docs condicional
                <i class="fas fa-question-circle ml-1"
                   matTooltip="Despacha o Processo para um destinatário diferente de acordo com cada situação.
                               O destinatário escolhido será aquele associado à primeira condição (ordenada cronologicamente
                               por data/hora de adição) que for satisfeita"
                ></i>
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <!-- Servidor -->
    <ng-container *ngIf="recipientMode == RecipientMode.PublicAgent">
        <!-- CPF do Servidor -->
        <div class="form-group dispatch-recipient ml-3px" featureHint="flowObjectDetails|RegisterProcessStakeholdersPublicAgentCpf">
            <label for="servidorCpf">CPF do Servidor</label>
            <input type="text" class="form-control two-buttons" id="servidorCpf" name="servidorCpf"
                   placeholder="Entre com o CPF do servidor para filtrar seus papéis"
                   maxlength="11"
                   pattern="[0-9]{11}"
                   (keyup.enter)="searchServidorCpf()"
                   [(ngModel)]="servidorCpf"
            >
            <i class="fas fa-search action search ml-2" matTooltip="Procurar" (click)="searchServidorCpf()"></i>
            <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearServidorCpf()"></i>
        </div>

        <!-- Papéis do Servidor -->
        <div class="form-group dispatch-recipient ml-4" featureHint="flowObjectDetails|RegisterProcessStakeholdersPublicAgentRole">
            <label for="servidorPapelDisplay">Papéis do Servidor</label>
            <input type="text" class="form-control relevant-info-font two-buttons" id="servidorPapelDisplay"
                   name="servidorPapelDisplay"
                   placeholder="Selecione um papel do servidor"
                   [(ngModel)]="servidorPapelDisplay"
                   [matTooltip]="servidorPapelNome"
                   [matAutocomplete]="servidorPapelAutocompleteProcessStakeholders"
                   [disabled]="servidorPapeis.length == 0"
                   (input)="servidorPapelDisplayChange()"
            >
            <mat-autocomplete #servidorPapelAutocompleteProcessStakeholders="matAutocomplete"
                              [displayWith]="displayServidorPapelId"
                              (optionSelected)="servidorPapelIdChange($event)"
            >
                <mat-option *ngFor="let option of selectableServidorPapeis" [value]="option">
                    <span class="to-upper">
                        {{option.nome}} - {{option.lotacao.nomeCurto}} - {{option.lotacao.organizacao.sigla}} - {{option.lotacao.organizacao.organizacaoPai.sigla}}
                    </span>
                </mat-option>
            </mat-autocomplete>
            <i class="fas fa-check-circle action add ml-2" matTooltip="Escolher" (click)="selectServidorPapel()"></i>
            <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearServidorPapel()"></i>
        </div>
    </ng-container>

    <!-- Local do E-Docs fixo -->
    <ng-container *ngIf="recipientMode == RecipientMode.Fixed">
        <!-- Local do E-Docs -->
        <edocs-location #edocsLocationRef
                        [inputModel]="configSchemaSectionEDocsLocation"
                        [inputFlowDefinitionOrganizationId]="inputFlowDefinition.organizationId"
                        [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                        (outputModelChange)="onUpdateEDocsLocation($event)"
        ></edocs-location>
    </ng-container>

    <!-- Local do E-Docs condicional -->
    <ng-container *ngIf="recipientMode == RecipientMode.Conditional">
        <div class="condition-row ml-3px">
            <!-- Campo do Formulário -->
            <div class="form-group col-5 px-0">
                <label for="formFieldConditional">Campo do Formulário</label>
                <i class="fas fa-question-circle ml-1"
                   matTooltip="Campo do formulário que será utilizado para a comparação"
                ></i>
                <select class="form-control" id="formFieldConditional" name="formFieldConditional"
                        [(ngModel)]="formFieldConditional"
                >
                    <option *ngFor="let option of formFieldConditionalOptions"
                            [value]="option.value"
                    >{{option.description}}</option>
                </select>
            </div>

            <!-- Operação -->
            <div class="form-group col-3 px-2">
                <label for="valueConditional">Operação</label>
                <i class="fas fa-question-circle ml-1"
                   matTooltip="Operação a ser aplicada na verificação da condição"
                ></i>
                <select class="form-control" id="operationConditional" name="operationConditional"
                        [(ngModel)]="isOperationEqualConditional"
                >
                    <option [value]="true">IGUAL</option>
                    <option [value]="false">DIFERENTE</option>
                </select>
            </div>

            <!-- Valor do Campo -->
            <div class="form-group col-4 px-0">
                <label for="valueConditional">Valor do Campo</label>
                <i class="fas fa-question-circle ml-1"
                   matTooltip="Valor do campo do formulário escolhido ao lado que fará com que o Encaminhamento se dê para o destinatário escolhido abaixo"
                ></i>
                <input type="text" class="form-control" id="valueConditional" name="valueConditional"
                       [(ngModel)]="valueConditional"
                />
            </div>
        </div>

        <!-- Local do E-Docs -->
        <edocs-location #edocsLocationConditionalRef
                        [inputModel]="configSchemaSectionEDocsLocationConditional"
                        [inputFlowDefinitionOrganizationId]="inputFlowDefinition.organizationId"
                        [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                        [inputIsConditionalForwarding]="true"
                        (outputModelChange)="onUpdateEDocsLocationConditional($event)"
        ></edocs-location>

        <!-- Painel de condições -->
        <ng-container>
            <div class="conditions-container empty mt-4 mb-3 p-2"
                 *ngIf="inputConfigSchema.taskDispatchProcess.conditionalRecipients.length == 0; else recipientsTemplate"
            >
                <span class="relevant-info-font">Nenhuma condição adicionada</span>
            </div>
            <ng-template #recipientsTemplate>
                <div class="conditions-container mt-4 mb-3 p-2">
                    <div class="condition p-2" *ngFor="let condition of inputConfigSchema.taskDispatchProcess.conditionalRecipients; let i = index">
                        <pre class="m-0" [innerHtml]="sanitizer.bypassSecurityTrustHtml(prettyPrint(condition))"></pre>
                        <i class="fas fa-arrow-up ml-2" matTooltip="Mover condição para cima" (click)="moveCondition(condition, true)" *ngIf="i > 0"></i>
                        <i class="fas fa-arrow-down ml-2" matTooltip="Mover condição para baixo" (click)="moveCondition(condition)" *ngIf="i < inputConfigSchema.taskDispatchProcess.conditionalRecipients.length - 1"></i>
                        <i class="fas fa-trash-alt ml-2" matTooltip="Remover condição" (click)="removeCondition(condition)"></i>
                    </div>

                    <button type="button" class="btn btn-outline-danger"
                            [disabled]="inputIsReadOnlyMode"
                            (click)="removeAllConditions()"
                    >
                        <i class="fas fa-times-circle mr-1"></i>
                        <span>Remover todas as condições</span>
                    </button>
                </div>
            </ng-template>
        </ng-container>
    </ng-container>

    <!-- Destinatário selecionado -->
    <div class="form-group ml-6 mt-4" *ngIf="recipientMode != RecipientMode.Conditional" featureHint="flowObjectDetails|ForwardSelected">
        <label for="dispatchRecipientId">Destinatário selecionado</label>
        <input type="text" class="form-control relevant-info-font one-button" id="dispatchRecipientId" name="dispatchRecipientId" readonly
               placeholder="(nenhum)"
               [(ngModel)]="configSchemaSectionEDocsLocation.name"
               [matTooltip]="configSchemaSectionEDocsLocation.name"
        >
        <i class="fas fa-times-circle action remove ml-2" matTooltip="Limpar" (click)="clearDestinatario()"></i>
    </div>

    <div class="form-group mt-4">
        <h6>Mensagem do Despacho</h6>
    </div>

    <div class="form-group ml-3px" *ngIf="isDispatchSummaryBootstrapFinished" featureHint="flowObjectDetails|DispatchProcessSummary" featureHintVAlign="top">
        <editor name="dispatchSummary" [init]="tinyMceOptionsProcess" [(ngModel)]="dispatchSummary"></editor>
        <textarea id="tinyMceDispatchProcess" class="d-none"></textarea>
    </div>

    <div class="form-group mt-4">
        <h6>Nível de Acesso do Despacho</h6>
    </div>

    <!-- Nível de Acesso -->
    <access-level #accessLevelRef
                  [inputModel]="configSchemaSectionAccessLevel"
                  [inputFlowDefinitionOrganizationId]="inputFlowDefinition.organizationId"
                  [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                  (outputModelChange)="onUpdateAccessLevel($event)"
    ></access-level>
</ng-container>
