<div class="ribs" matTooltip="Ambiente de {{environment}}"
     [class.invisible]="shouldHideEnvironment"
>
    <div class="rib left {{env}}">
        <span class="environment-name">{{environment}}</span>
    </div>
    <div class="rib right {{env}}">
        <span class="environment-name">{{environment}}</span>
    </div>
</div>
