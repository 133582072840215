import { FORMBUILDER_OPTIONS_I18N } from "./formbuilder-options-i18n";

export const FORMBUILDER_OPTIONS_INBOUND_API = {
    noDefaultSubmitButton: true,
    language: 'pt',
    i18n: FORMBUILDER_OPTIONS_I18N,
    builder: {
        basic: {
            components: {
                pdfUpload: true,
                pdfUploadMultiple: true,
                textfield: false,
                textarea: false,
                number: false,
                checkbox: false,
                select: false,
                radio: false,
                password: false,
                selectboxes: false,
                button: false,
                eDocsProtocol: false
            }
        },
        advanced: false,
        layout: false,
        data: false,
        premium: false
    },
    editForm: {
        pdfUpload: [
            {
                key: 'display',
                components: [
                    {
                        weight: 9910,
                        input: true,
                        type: 'select',
                        key: 'documentLegalValueCombination',
                        label: 'Valor Legal do Documento para Conferência',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Valor Legal do Documento anexado que será capturado no E-Docs',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: true,
                        }
                    },
                    {
                        weight: 9911,
                        input: true,
                        type: 'select',
                        key: 'activePlans',
                        label: 'Planos Ativos',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Selecione um dos Planos Ativos para filtrar as possíveis Classes para o documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: false,
                        }
                    },
                    {
                        weight: 9912,
                        input: true,
                        type: 'select',
                        key: 'documentClass',
                        label: 'Classificação Documental',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Classificação Documental do documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.8,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: false,
                        }
                    },
                    {
                        weight: 9920,
                        input: true,
                        type: 'select',
                        key: 'accessLevel',
                        label: 'Nível de Acesso',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Nível de acesso no E-Docs do documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: true,
                        }
                    },
                    {
                        weight: 9921,
                        input: true,
                        type: 'select',
                        key: 'legalReasoning',
                        label: 'Fundamentação Legal',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Fundamentação legal para a restrição no E-Docs do documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: true,
                        }
                    },
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'autofocus',
                        ignore: true
                    },
                    {
                        key: 'showCharCount',
                        ignore: true
                    },
                    {
                        key: 'showWordCount',
                        ignore: true
                    },
                    {
                        key: 'hideLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'placeholder',
                        ignore: true
                    },
                    {
                        key: 'labelPosition',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.customMessage',
                        ignore: true
                    },
                    {
                        key: 'validate.minLength',
                        ignore: true
                    },
                    {
                        key: 'validate.maxLength',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'kickbox',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ],
        pdfUploadMultiple: [
            {
                key: 'display',
                components: [
                    {
                        weight: 9910,
                        input: true,
                        type: 'select',
                        key: 'documentLegalValueCombination',
                        label: 'Valor Legal do Documento para Conferência',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Valor Legal do Documento anexado que será capturado no E-Docs',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: true,
                        }
                    },
                    {
                        weight: 9911,
                        input: true,
                        type: 'select',
                        key: 'activePlans',
                        label: 'Planos Ativos',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Selecione um dos Planos Ativos para filtrar as possíveis Classes para o documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: false,
                        }
                    },
                    {
                        weight: 9912,
                        input: true,
                        type: 'select',
                        key: 'documentClass',
                        label: 'Classificação Documental',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Classificação Documental do documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.8,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: false,
                        }
                    },
                    {
                        weight: 9920,
                        input: true,
                        type: 'select',
                        key: 'accessLevel',
                        label: 'Nível de Acesso',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Nível de acesso no E-Docs do documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: true,
                        }
                    },
                    {
                        weight: 9921,
                        input: true,
                        type: 'select',
                        key: 'legalReasoning',
                        label: 'Fundamentação Legal',
                        placeholder: 'Selecione uma opção',
                        tooltip: 'Fundamentação legal para a restrição no E-Docs do documento anexado',
                        defaultValue: null,
                        searchThreshold: 0.7,
                        customOptions: {
                            searchResultLimit: 999
                        },
                        dataSrc: 'custom',
                        validate: {
                            required: false,
                        }
                    },
                    {
                        key: 'dataGridLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'autofocus',
                        ignore: true
                    },
                    {
                        key: 'showCharCount',
                        ignore: true
                    },
                    {
                        key: 'showWordCount',
                        ignore: true
                    },
                    {
                        key: 'hideLabel',
                        ignore: true
                    },
                    {
                        key: 'customClass',
                        ignore: true
                    },
                    {
                        key: 'inputMask',
                        ignore: true
                    },
                    {
                        key: 'placeholder',
                        ignore: true
                    },
                    {
                        key: 'labelPosition',
                        ignore: true
                    },
                    {
                        key: 'prefix',
                        ignore: true
                    },
                    {
                        key: 'suffix',
                        ignore: true
                    },
                    {
                        key: 'widget.type',
                        ignore: true
                    },
                    {
                        key: 'allowMultipleMasks',
                        ignore: true
                    },
                    {
                        key: 'tabindex',
                        ignore: true
                    },
                    {
                        key: 'autocomplete',
                        ignore: true
                    },
                    {
                        key: 'hidden',
                        ignore: true
                    },
                    {
                        key: 'mask',
                        ignore: true
                    },
                    {
                        key: 'tableView',
                        ignore: true
                    },
                    {
                        key: 'modalEdit',
                        ignore: true
                    },
                    {
                        key: 'spellcheck',
                        ignore: true
                    }
                ]
            },
            {
                key: 'validation',
                components: [
                    {
                        weight: 9901,
                        input: true,
                        type: 'number',
                        key: 'maxFiles',
                        label: 'Quantidade máxima de arquivos',
                        tooltip: 'A quantidade máxima de arquivos que será permitido inserir neste campo',
                        defaultValue: 5,
                        validate: {
                            required: true,
                            custom: 'valid = input > 0 && input <= 20;',
                            customMessage: 'O valor deste campo deve estar entre 1 e 20'
                        }
                    },
                    {
                        key: 'validateOn',
                        ignore: true
                    },
                    {
                        key: 'unique',
                        ignore: true
                    },
                    {
                        key: 'validate.customMessage',
                        ignore: true
                    },
                    {
                        key: 'validate.minLength',
                        ignore: true
                    },
                    {
                        key: 'validate.maxLength',
                        ignore: true
                    },
                    {
                        key: 'validate.minWords',
                        ignore: true
                    },
                    {
                        key: 'validate.maxWords',
                        ignore: true
                    },
                    {
                        key: 'errorLabel',
                        ignore: true
                    },
                    {
                        key: 'kickbox',
                        ignore: true
                    },
                    {
                        key: 'validate.pattern',
                        ignore: true
                    },
                    {
                        key: 'custom-validation-js',
                        ignore: true
                    },
                    {
                        key: 'json-validation-json',
                        ignore: true
                    }
                ]
            },
            {
                key: 'conditional',
                components: [
                    {
                        key: 'customConditionalPanel',
                        ignore: false,
                        components: [
                            {
                                key: 'customConditional-json',
                                ignore: true
                            }
                        ]
                    }
                ]
            },
            {
                key: 'api',
                components: [
                    {
                        key: 'key',
                        disabled: false
                    },
                    {
                        key: 'tags',
                        ignore: true
                    },
                    {
                        key: 'properties',
                        ignore: true
                    }
                ]
            },
            {
                key: 'layout',
                ignore: true
            },
            {
                key: 'data',
                label: 'Dados',
                ignore: true
            },
            {
                key: 'logic',
                ignore: true
            }
        ]
    }
};
