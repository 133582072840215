import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output
} from "@angular/core";
import {
    FlowObjectType,
    FlowObjectDefinition,
    FormSchema
} from "../../../../models/flow-object.model";
import {
    ApiResolver,
    AuthenticationModel,
    AuthenticationSchemeType,
    AuthenticationSchemeTypeDescription,
    ConfigSchema,
    HttpMethod,
    HttpMethodDescription,
    LocationType
} from "../../../../models/config-schema.model";
import { FlowDefinition } from "../../../../models/flow.model";
import { ToastrService } from "ngx-toastr";
import { Enums } from "../../../../shared/enums";
import { FLOW_OBJECT_DETAILS_MIDWAY_OUTBOUND_API_TINYMCE_OPTIONS } from "./flow-object-details-midway-outbound-api-tinymce-options";
import { Utils } from "../../../../shared/utils";
import { IBaseOption } from "../../../../models/base.model";
import { FlowObjectDefinitionService } from "../../../../services/flow-object-definition.service";
import { ConjuntoGrupo, TipoFiltro } from "../../../../models/acesso-cidadao.model";
import { Patriarca } from "../../../../models/edocs.model";
import { Organizacao, Unidade } from "../../../../models/organograma.model";
import { PatriarcasFilter, OrganizacoesFilter, UnidadesFilter, GruposFilter, ComissoesFilter } from "../flow-object-details.pipe";
import { EDocsService } from "../../../../services/edocs.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { AcessoCidadaoService } from "../../../../services/acesso-cidadao.service";
import { OrganogramaService } from "../../../../services/organograma.service";

@Component({
    selector: "flow-object-details-midway-outbound-api",
    templateUrl: "./flow-object-details-midway-outbound-api.component.html",
    styleUrls: ["./flow-object-details-midway-outbound-api.component.scss"]
})
export class FlowObjectDetailsMidwayOutboundApiComponent implements OnInit {
    // #region [Type properties]
    AuthenticationSchemeType: typeof AuthenticationSchemeType = AuthenticationSchemeType;
    HttpMethod: typeof HttpMethod = HttpMethod;
    // #endregion

    // #region [properties]
    FormTypeStartTag = '{$|form|$}_' as const;
    FormTypeSeparatorTag = '{$|_|$}' as const;
    FormTypeEndTag = '_{$|form|$}' as const;
    PairValueTypeValues = {
        String: '{$|string|$}',
        Number: '{$|number|$}',
        Boolean: '{$|bool|$}',
        Null: '{$|null|$}',
        EDocs: '{$|edocs|$}',
        Form: `${this.FormTypeStartTag}{0}${this.FormTypeSeparatorTag}{1}${this.FormTypeEndTag}`,
        CPF: '{$|cpf|$}',
    } as const;

    model: FlowObjectDefinition;
    configSchema = new ConfigSchema();
    url = '';
    pairKey = '';
    pairValue = '';
    pairValueType: string;
    fullJson = '';
    jsonToEdit = '';
    publicSystemName: string = '';
    keyValuePairsArray: any[] = [];
    keyValuePairsObject: any = {};
    formFlowObjectEntries: any[] = [];
    formFlowObject: FlowObjectDefinition;
    tinyMceOptions: any = FLOW_OBJECT_DETAILS_MIDWAY_OUTBOUND_API_TINYMCE_OPTIONS;
    showJsonView = false;
    previousShowJsonView = false;
    authentication = new AuthenticationModel();
    backgroundExecution = false;
    ignoreErrors: boolean = false;
    errorMessage?: string = null;
    showParametersUrl = false;
    parameterKey: string;
    parameterKeyType: string;
    keyValueParametersArray: any[] = [];
    cpfParameterTip = 'e.g. "{{cpf}}"';
    formParameterTip = 'e.g. "{{matricula}}"';
    useUrlParameters = false;
    selectedHttpMethod: HttpMethod = HttpMethod.Get;
    httpMethodOptions: IBaseOption[] = [];
    authenticationSchemeOptions: IBaseOption[] = [];
    openTabs: number[] = [1];
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowObjectDefinition;
    @Output() inputModelChange = new EventEmitter<FlowObjectDefinition>();
    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Output() outputSubmitEvent = new EventEmitter<FlowObjectDefinition>();
    @Output() outputCloseEvent = new EventEmitter<any>();
    // #endregion

    constructor(
        private toastr: ToastrService,
        private organogramaService: OrganogramaService,
        private edocsService: EDocsService,
        private acessoCidadaoService: AcessoCidadaoService,
        private flowObjectDefinitionService: FlowObjectDefinitionService,
        private patriarcasFilter: PatriarcasFilter,
        private organizacoesFilter: OrganizacoesFilter,
        private unidadesFilter: UnidadesFilter,
        private gruposFilter: GruposFilter,
        private comissoesFilter: ComissoesFilter
    ) {
        for (let item in AuthenticationSchemeType) {
            if (!isNaN(parseInt(item))) {
                this.authenticationSchemeOptions.push({
                    value: +item,
                    description: AuthenticationSchemeTypeDescription.get(+item)
                });
            }
        }

        for (let item in HttpMethod) {
            if (!isNaN(parseInt(item))) {
                this.httpMethodOptions.push({
                    value: +item,
                    description: HttpMethodDescription.get(+item)
                });
            }
        }
    }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        setTimeout(async () => {
            this.model = this.inputModel;
            if (this.model?.configSchema != null) {
                this.configSchema = JSON.parse(this.model.configSchema) as ConfigSchema;
                this.selectedHttpMethod = this.configSchema.taskOutboundApi.httpMethod ?? HttpMethod.Get;
                this.url = this.configSchema.taskOutboundApi.url;
                this.publicSystemName = this.configSchema.taskOutboundApi.publicSystemName;
                this.ignoreErrors = this.configSchema.taskOutboundApi.ignoreErrors;

                for (const [key, value] of Object.entries(this.configSchema.taskOutboundApi.urlParameters)) {
                    const keyValuePair = JSON.parse(`{"${key}": "${value}"}`);
                    this.keyValueParametersArray.push(keyValuePair);
                }

                if (this.keyValueParametersArray.length > 0) {
                    this.showParametersUrl = this.useUrlParameters = true;
                }

                this.backgroundExecution = this.configSchema.taskOutboundApi.backgroundExecution;
                this.errorMessage = this.configSchema.taskOutboundApi.errorMessage;
                this.authentication = this.configSchema.taskOutboundApi.authentication;
                this.fullJson = JSON.stringify(this.configSchema.taskOutboundApi.data);
                this.setJsonToEdit();

                for (const key in this.configSchema.taskOutboundApi.data) {
                    let value = this.configSchema.taskOutboundApi.data[key];
                    if (!this.isNumber(value) && !this.isBool(value) && !this.isNull(value)) {
                        value = `"${value}"`;
                    }

                    const keyValuePair = JSON.parse(`{"${key}": ${value}}`);
                    this.keyValuePairsObject = Object.assign(this.keyValuePairsObject, keyValuePair);
                    this.keyValuePairsArray.push(keyValuePair);
                }
            }
        }, 1);

        this.pairValueType = this.PairValueTypeValues.String;
        this.parameterKeyType = this.PairValueTypeValues.CPF;
        this.authentication.authenticationScheme = AuthenticationSchemeType.None;

        this.formFlowObject = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.typeId == FlowObjectType.StartForm);
        if (this.formFlowObject != null) {
            if (Utils.isNullOrEmpty(this.formFlowObject.formSchema)) {
                const response = await this.flowObjectDefinitionService.getFormData(this.formFlowObject.id);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                this.formFlowObject.formSchema = response.data;
            }

            this.formFlowObjectEntries = this.getFormFlowObjectEntries(this.formFlowObject);
        }
    }

    // ======================
    // public methods
    // ======================

    addKeyValuePair() {
        if (this.isPairValueTypeEmpty()) return;
        if (this.isPairKeyEmpty()) return;
        if (this.isPairKeyAlreadyAdded()) return;
        if (!this.isPairValueValid()) return;

        let keyValuePair = this.parseKeyValuePair();
        this.keyValuePairsArray.push(keyValuePair);
        this.keyValuePairsObject = Object.assign(this.keyValuePairsObject, keyValuePair);
        this.fullJson = JSON.stringify(this.keyValuePairsObject);
        this.setJsonToEdit();
        this.clearKeyValueFields();
    }

    removeKeyValuePair(pair) {
        this.keyValuePairsArray = this.keyValuePairsArray.filter(x => x != pair);

        this.keyValuePairsObject = {};
        this.keyValuePairsArray.forEach(item => {
            this.keyValuePairsObject = Object.assign(this.keyValuePairsObject, item);
        });

        this.fullJson = JSON.stringify(this.keyValuePairsObject);
        this.setJsonToEdit();
    }

    isJsonValid(event?: any) {
        try {
            this.fullJson = this.getJsonToEditText();
            if (Utils.isNullOrEmpty(this.fullJson)) return true;

            this.keyValuePairsObject = JSON.parse(this.fullJson);
            this.keyValuePairsArray = [];
            for (const key in this.keyValuePairsObject) {
                let value = this.keyValuePairsObject[key];
                if (!this.isNumber(value) && !this.isBool(value) && !this.isNull(value)) {
                    value = `"${value}"`;
                }

                let keyValuePair = JSON.parse(`{"${key}": ${value}}`);
                this.keyValuePairsArray.push(keyValuePair);
            }

            if (this.showJsonView) {
                this.setJsonToEdit();
                this.jsonToEdit = Utils.jsonSyntaxHighlight(this.jsonToEdit, true);
            }

            this.previousShowJsonView = this.showJsonView;
            return true;
        } catch (error) {
            this.toastr.error(Enums.Messages.JsonDeserializeError.toString(), Enums.Messages.Error.toString(), this.getToastrErrorOptions());
            console.error(error);
            this.showJsonView = this.previousShowJsonView;
            if (event?.source != null) {
                event.source.checked = this.previousShowJsonView;
            }
            return false;
        }
    }

    toggleShowJsonView() {
        this.showJsonView = !this.showJsonView;
        this.isJsonValid();
    }

    getFormFieldKey(): string {
        return this.pairValueType
            .split(this.FormTypeStartTag).join('')
            .split(this.FormTypeEndTag).join('')
            .split(this.FormTypeSeparatorTag)
            .slice(-1)[0];
    }

    prettyPrint(pair): string {
        const jsonString = JSON.stringify(pair, null, 2);
        return Utils.jsonSyntaxHighlight(jsonString);
    }

    clearKeyValueFields() {
        this.pairKey = null;
        this.pairValue = null;
    }

    authenticationSchemeChangeHandler() {
        if (this.authentication.authenticationScheme != AuthenticationSchemeType.Jwt) {
            this.authentication.clientId = null;
            this.authentication.clientSecret = null;
            this.authentication.scopes = null;
            this.authentication.tokenEndpoint = null;
        }

        if (
            this.authentication.authenticationScheme == AuthenticationSchemeType.None
            || this.authentication.authenticationScheme == AuthenticationSchemeType.Jwt
        ) {
            this.authentication.basicOrApiKey = null;
        }
    }

    shouldDisablePairValueInput(): boolean {
        return this.pairValueType == this.PairValueTypeValues.EDocs
            || this.pairValueType == this.PairValueTypeValues.Null
            || this.isFormPairValueType();
    }

    toggleShowParametersUrl() {
        this.showParametersUrl = !this.showParametersUrl;

        if (!this.showParametersUrl) {
            this.keyValueParametersArray.length = 0;
        }
    }

    addParameter() {
        if (this.isInvalidKey(this.parameterKeyType, this.parameterKey)) return;

        const keyValue = this.parseKeyValuePair(this.parameterKey, this.parameterKeyType);
        this.keyValueParametersArray.push(keyValue);
        this.clearKeyValueFields();
    }

    removeParameter(parameter) {
        this.keyValueParametersArray = this.keyValueParametersArray.filter(x => x !== parameter);
    }

    toggleTab(tab: number) {
        if (this.isTabOpen(tab)) {
            this.openTabs = this.openTabs.filter(x => x != tab);
        } else {
            this.openTabs.push(tab);
        }
    }

    isTabOpen(tab: number): boolean {
        return this.openTabs.includes(tab);
    }

    closeForm() {
        this.outputCloseEvent.emit();
    }

    onSubmit() {
        if (this.inputIsReadOnlyMode || !this.isJsonValid()) return false;

        this.configSchema.taskOutboundApi.httpMethod = this.selectedHttpMethod;
        this.configSchema.taskOutboundApi.url = this.url;
        this.configSchema.taskOutboundApi.publicSystemName = this.publicSystemName;
        this.configSchema.taskOutboundApi.ignoreErrors = this.ignoreErrors;
        //this.configSchema.taskOutboundApi.backgroundExecution = this.backgroundExecution;
        this.configSchema.taskOutboundApi.errorMessage = this.errorMessage;

        this.configSchema.taskOutboundApi.urlParameters = {};
        if (this.keyValueParametersArray.length > 0) {
            let objectsArray = {};
            this.keyValueParametersArray.forEach(item => {
                objectsArray = Object.assign(objectsArray, item);
            });

            this.configSchema.taskOutboundApi.urlParameters = objectsArray;
        }

        if (this.configSchema.taskOutboundApi.authentication != null) {
            Object.assign(this.configSchema.taskOutboundApi.authentication, this.authentication);
        } else {
            this.configSchema.taskOutboundApi.authentication = this.authentication;
        }

        if (!Utils.isNullOrEmpty(this.fullJson)) {
            this.configSchema.taskOutboundApi.data = JSON.parse(this.fullJson);
        }

        this.model.configSchema = JSON.stringify(this.configSchema);
        this.outputSubmitEvent.emit(this.model);
    }

    // ======================
    // private methods
    // ======================

    private getFormFlowObjectEntries(flowObject: FlowObjectDefinition): any[] {
        if (Utils.isNullOrEmpty(flowObject.formSchema)) return;

        let entries = [];
        let formSchema = JSON.parse(flowObject.formSchema) as FormSchema;

        formSchema.components.forEach(item => {
            if (!['button', 'pdfUpload'].includes(item.type)) {
                entries.push({
                    label: item.label,
                    key: `${item.key}`
                });
            }
        });

        return entries;
    }

    private isPairKeyAlreadyAdded(): boolean {
        if (this.keyValuePairsObject.hasOwnProperty(this.pairKey)) {
            this.toastr.error(Enums.Messages.KeyAlreadyAdded, Enums.Messages.Error, this.getToastrErrorOptions());
            return true;
        }

        return false;
    }

    private isPairKeyEmpty(): boolean {
        if (this.pairKey == '' || this.pairKey == null) {
            this.toastr.error(Enums.Messages.EmptyKey, Enums.Messages.Error, this.getToastrErrorOptions());
            return true;
        }

        return false;
    }

    private isPairValueTypeEmpty(): boolean {
        if (this.pairValueType == '' || this.pairValueType == null) {
            this.toastr.error(Enums.Messages.EmptyPairValueType, Enums.Messages.Error, this.getToastrErrorOptions());
            return true;
        }

        return false;
    }

    private isPairValueValid(): boolean {
        if (
            this.pairValueType != '' && this.pairValueType != null
            && (
                (
                    this.pairValueType == this.PairValueTypeValues.String
                    && (this.pairValue != '' && this.pairValue != null)
                ) || (
                    this.pairValueType == this.PairValueTypeValues.Number
                    && this.isNumber(this.pairValue)
                ) || (
                    this.pairValueType == this.PairValueTypeValues.Boolean
                    && this.isBool(this.pairValue)
                ) || (
                    this.pairValueType == this.PairValueTypeValues.Null
                    && this.isNull(this.pairValue)
                ) || (
                    this.pairValueType == this.PairValueTypeValues.EDocs
                    && this.pairValue !== this.PairValueTypeValues.EDocs
                ) || this.isFormPairValueType()
            )
        ) {
            return true;
        }

        this.toastr.error(Enums.Messages.InvalidPairValue, Enums.Messages.Error, this.getToastrErrorOptions());
        return false;
    }

    private isFormPairValueType(): boolean {
        return this.pairValueType.startsWith(this.FormTypeStartTag)
            && this.pairValueType.includes(this.FormTypeSeparatorTag)
            && this.pairValueType.endsWith(this.FormTypeEndTag);
    }

    private isNumber(value): boolean {
        value = '' + value;
        return !isNaN(value) && !isNaN(parseFloat(value));
    }

    private isBool(value): boolean {
        return ['true', 'false'].includes(value?.toString().trim().toLowerCase());
    }

    private isNull(value): boolean {
        return value === null;
    }

    private parseKeyValuePair(key?, value?): any {
        key = key || this.pairKey;
        value = value || this.pairValue;

        try {
            switch (this.pairValueType) {
                case this.PairValueTypeValues.String:
                    return JSON.parse(`{"${key}": "${value}"}`);

                case this.PairValueTypeValues.Number:
                    return JSON.parse(`{"${key}": ${value}}`);

                case this.PairValueTypeValues.Boolean:
                    return JSON.parse(`{"${key}": ${value}}`);

                case this.PairValueTypeValues.Null:
                    return JSON.parse(`{"${key}": null}`);

                default:
                    return JSON.parse(`{"${key}" : "${value || this.pairValueType}"}`);
            }
        } catch (error) {
            this.toastr.error(Enums.Messages.JsonSerializeError, Enums.Messages.Error, this.getToastrErrorOptions());
            console.error(error);
        }
    }

    private setJsonToEdit() {
        this.keyValuePairsObject = JSON.parse(this.fullJson);
        this.jsonToEdit = JSON.stringify(this.keyValuePairsObject, null, 2);
        let pre = document.createElement('pre');
        pre.innerHTML = this.jsonToEdit;
        let div = document.createElement('div');
        div.appendChild(pre);
        this.jsonToEdit = div.innerHTML;
    }

    private getJsonToEditText(): string {
        let div = document.createElement('div');
        div.innerHTML = this.jsonToEdit;
        return div.innerText.replace('\n', '');
    }

    private getToastrErrorOptions() {
        return {
            closeButton: true,
            timeOut: 10000,
            extendedTimeOut: 5000,
        };
    }

    private isFormValueType(value: any): boolean {
        return value.startsWith(this.FormTypeStartTag)
            && value.includes(this.FormTypeSeparatorTag)
            && value.endsWith(this.FormTypeEndTag);
    }

    private isInvalidTemplate(value: any): boolean {
        if (!value.startsWith('{{') || !value.endsWith('}}')) {
            this.toastr.error(Enums.Messages.InvalidKeyTemplate.toString(), Enums.Messages.Error.toString(), this.getToastrErrorOptions());
            return true;
        }
        return false;
    }

    private isInvalidKey(keyType: any, key: any): boolean {
        if (Utils.isNullOrEmpty(keyType) || Utils.isNullOrEmpty(key)) {
            this.toastr.error(Enums.Messages.NullOrEmpty.toString(), Enums.Messages.Error.toString(), this.getToastrErrorOptions());
            return true;
        }

        if (this.isInvalidTemplate(key)) return true;

        if (keyType == this.PairValueTypeValues.String
            || (
                keyType == this.PairValueTypeValues.Number
                && this.isNumber(key)
            ) || (
                keyType == this.PairValueTypeValues.Boolean
                && this.isBool(key)
            ) || (
                keyType == this.PairValueTypeValues.Null
                && this.isNull(key)
            ) || (
                keyType == this.PairValueTypeValues.EDocs
                && key !== this.PairValueTypeValues.EDocs
            ) || this.isFormValueType(keyType) || (
                keyType == this.PairValueTypeValues.CPF
                && key !== this.PairValueTypeValues.CPF
            )
        ) {
            return false;
        }

        this.toastr.error(Enums.Messages.InvalidPairValue.toString(), Enums.Messages.Error.toString(), this.getToastrErrorOptions());
        return true;
    }
}
