import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ViewChild
} from '@angular/core';
import {
    FlowObjectType,
    FormSchema
} from '../../../../models/flow-object.model';
import {
    ConditionalRecipient,
    ConfigSchema,
    ConfigSchemaSectionAccessLevel,
    ConfigSchemaSectionEDocsLocation
} from '../../../../models/config-schema.model';
import { FlowDefinition } from '../../../../models/flow.model';
import { IBaseOption } from '../../../../models/base.model';
import { Utils } from '../../../../shared/utils';
import { OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AccessLevelComponent } from '../edocs-fields/access-level/access-level.component';
import { EDocsLocationComponent } from '../edocs-fields/edocs-location/edocs-location.component';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../../../shared/enums';
import { FlowObjectDefinitionService } from '../../../../services/flow-object-definition.service';

@Component({
    selector: 'flow-object-details-forward',
    templateUrl: './flow-object-details-forward.component.html',
    styleUrls: ['./flow-object-details-forward.component.scss']
})
export class FlowObjectDetailsForwardComponent implements OnInit, OnChanges {
    // #region [ViewChild]
    @ViewChild('accessLevelRef') accessLevelRef: AccessLevelComponent;
    @ViewChild('edocsLocationRef') edocsLocationRef: EDocsLocationComponent;
    @ViewChild('edocsLocationConditionalRef') edocsLocationConditionalRef: EDocsLocationComponent;
    // #endregion

    // #region [Type properties]
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    RecipientMode: typeof Enums.RecipientMode = Enums.RecipientMode;
    Utils: typeof Utils = Utils;
    // #endregion

    // #region [properties]
    formFlowObjectSchemaFields: any[] = [];
    recipientMode: Enums.RecipientMode = Enums.RecipientMode.Fixed;
    configSchemaSectionAccessLevel: ConfigSchemaSectionAccessLevel = new ConfigSchemaSectionAccessLevel();
    configSchemaSectionEDocsLocation: ConfigSchemaSectionEDocsLocation = new ConfigSchemaSectionEDocsLocation();
    configSchemaSectionEDocsLocationConditional: ConfigSchemaSectionEDocsLocation = new ConfigSchemaSectionEDocsLocation();
    // #endregion

    // #region [Encaminhar Condicional]
    formFieldConditionalOptions: IBaseOption[] = [];
    formFieldConditional: string = null;
    valueConditional: string = null;
    isOperationEqualConditional: boolean = true;
    // #endregion

    // #region [Input/Output]
    @Input() inputConfigSchema: ConfigSchema;
    @Output() inputConfigSchemaChange = new EventEmitter<ConfigSchema>();
    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Output() outputRecipientModeChange = new EventEmitter<Enums.RecipientMode>();
    // #endregion

    constructor(
        public sanitizer: DomSanitizer,
        private toastr: ToastrService,
        private flowObjectDefinitionService: FlowObjectDefinitionService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        let formFlowObject = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.typeId == FlowObjectType.StartForm);
        if (formFlowObject != null) {
            if (Utils.isNullOrEmpty(formFlowObject.formSchema)) {
                const response = await this.flowObjectDefinitionService.getFormData(formFlowObject.id);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                formFlowObject.formSchema = response.data;
            }

            let formSchema = JSON.parse(formFlowObject.formSchema) as FormSchema;
            this.formFlowObjectSchemaFields = Utils.getFormFieldsWithUsableValue(formSchema.components);
        }

        for (let prop in this.configSchemaSectionAccessLevel) {
            this.configSchemaSectionAccessLevel[prop] = this.inputConfigSchema.taskForward.accessLevel[prop] || this.configSchemaSectionAccessLevel[prop];
        }
        for (let prop in this.configSchemaSectionEDocsLocation) {
            this.configSchemaSectionEDocsLocation[prop] = this.inputConfigSchema.taskForward.recipient[prop] || this.configSchemaSectionEDocsLocation[prop];
        }

        this.initForwardInfo();
    }

    ngOnChanges() {
        this.inputConfigSchemaChange.emit(this.inputConfigSchema);
    }

    // ======================
    // public methods
    // ======================

    // #region [Encaminhar]
    clearDestinatario() {
        this.configSchemaSectionEDocsLocation = new ConfigSchemaSectionEDocsLocation();

        Object.assign(this.inputConfigSchema.taskForward.recipient, this.configSchemaSectionEDocsLocation);
    }
    // #endregion

    //#region [Encaminhar Condicional]
    removeCondition(condition: ConditionalRecipient) {
        if (this.inputIsReadOnlyMode) return;

        this.inputConfigSchema.taskForward.conditionalRecipients = this.inputConfigSchema.taskForward.conditionalRecipients.filter(x => x.timestamp != condition.timestamp);
    }

    moveCondition(condition: ConditionalRecipient, isDirectionUp: boolean = false) {
        if (this.inputIsReadOnlyMode) return;

        let offset = isDirectionUp ? -1 : 1;

        let fromKey = condition.timestamp;
        let fromCondition = this.inputConfigSchema.taskForward.conditionalRecipients.find(x => x.timestamp == fromKey);
        let toCondition = this.inputConfigSchema.taskForward.conditionalRecipients[this.inputConfigSchema.taskForward.conditionalRecipients.indexOf(fromCondition) + offset];
        let toKey = toCondition.timestamp;

        fromCondition.timestamp = toKey;
        toCondition.timestamp = fromKey;

        this.inputConfigSchema.taskForward.conditionalRecipients = this.inputConfigSchema.taskForward.conditionalRecipients.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
    }

    removeAllConditions() {
        if (this.inputIsReadOnlyMode) return;

        this.inputConfigSchema.taskForward.conditionalRecipients = [];
    }

    prettyPrint(condition: ConditionalRecipient): string {
        var optionDescription = this.formFieldConditionalOptions.find(x => x.value == condition.formFieldId)?.description;
        optionDescription = optionDescription || '(N/D)';

        return '<span>Se o valor de {0} for {1} {2}, encaminhar para {3}</span>'
            .replace('{0}', `<b style="color:red">${optionDescription}</b>`)
            .replace('{1}', `<b style="color:purple">${condition.isOperationEqual ? 'igual a' : 'diferente de'}</b>`)
            .replace('{2}', `<b style="color:blue">"${condition.value}"</b>`)
            .replace('{3}', `<b style="color:green">${condition.recipient?.name}</b>`);
    }
    // #endregion

    recipientModeChange() {
        if (this.recipientMode == Enums.RecipientMode.ImmediateSupervisor) {
            this.inputConfigSchema.taskForward.toImmediateSupervisor = true;
        } else {
            this.inputConfigSchema.taskForward.toImmediateSupervisor = false;
        }

        if (this.recipientMode != Enums.RecipientMode.Fixed) {
            this.configSchemaSectionEDocsLocation = new ConfigSchemaSectionEDocsLocation();

            Object.assign(this.inputConfigSchema.taskForward.recipient, this.configSchemaSectionEDocsLocation);

            this.edocsLocationRef?.clearPatriarca();
        }

        if (this.recipientMode != Enums.RecipientMode.Conditional) {
            this.formFieldConditional = null;
            this.valueConditional = null;

            this.inputConfigSchema.taskForward.conditionalRecipients = [];

            this.edocsLocationConditionalRef?.clearPatriarca();
        }

        this.outputRecipientModeChange.emit(this.recipientMode);
    }

    updateSourceCode(forcedConfigSchema: ConfigSchema) {
        this.inputConfigSchema = forcedConfigSchema;

        Object.assign(this.configSchemaSectionEDocsLocation, this.inputConfigSchema.taskForward.recipient);
        Object.assign(this.configSchemaSectionAccessLevel, this.inputConfigSchema.taskForward.accessLevel);

        this.initForwardInfo();
        this.accessLevelRef?.initLegalReasoningsInfo();
    }

    onUpdateEDocsLocation(event: ConfigSchemaSectionEDocsLocation) {
        Object.assign(this.configSchemaSectionEDocsLocation, event);
        Object.assign(this.inputConfigSchema.taskForward.recipient, this.configSchemaSectionEDocsLocation);
    }

    onUpdateEDocsLocationConditional(event: ConfigSchemaSectionEDocsLocation) {
        if (Utils.isNullOrEmpty(this.formFieldConditional) || Utils.isNullOrEmpty(this.valueConditional)) {
            this.toastr.warning(Enums.Messages.MandatoryConditionalFields, Enums.Messages.Warning, Utils.getToastrErrorOptions());
            return;
        }

        Object.assign(this.configSchemaSectionEDocsLocationConditional, event);

        this.inputConfigSchema.taskForward.conditionalRecipients.push(new ConditionalRecipient({
            formFieldId: this.formFieldConditional,
            value: this.valueConditional,
            isOperationEqual: this.isOperationEqualConditional.toString().toLowerCase() == 'true',
            recipient: Utils.clone(this.configSchemaSectionEDocsLocationConditional)
        }));

        if (this.inputConfigSchema.taskForward.conditionalRecipients.length == 1) {
            Utils.scrollToBottom('.scroll-wrapper');
        }
    }

    onUpdateAccessLevel(event: ConfigSchemaSectionAccessLevel) {
        Object.assign(this.configSchemaSectionAccessLevel, event);
        Object.assign(this.inputConfigSchema.taskForward.accessLevel, this.configSchemaSectionAccessLevel);
    }

    // ======================
    // private methods
    // ======================

    private initForwardInfo() {
        this.inputConfigSchema.taskForward.conditionalRecipients = this.inputConfigSchema.taskForward.conditionalRecipients || [];

        this.formFieldConditionalOptions = this.formFlowObjectSchemaFields.map(x => ({ value: x.key, description: x.label }));

        this.recipientMode = this.inputConfigSchema?.taskForward.toImmediateSupervisor
            ? Enums.RecipientMode.ImmediateSupervisor
            : this.inputConfigSchema?.taskForward.conditionalRecipients.length > 0
                ? Enums.RecipientMode.Conditional
                : Enums.RecipientMode.Fixed;

        this.recipientModeChange();
    }
}
