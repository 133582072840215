import { Pipe, PipeTransform } from "@angular/core";
import { AgentePublicoPapel, ConjuntoGrupo } from "../../../models/acesso-cidadao.model";
import { Classe, FundamentoLegal, Plano, Patriarca } from "../../../models/edocs.model";
import { Organizacao, Unidade } from "../../../models/organograma.model";
import { Utils } from "../../../shared/utils";

@Pipe({
    name: 'planosFilter'
})
export class PlanosFilter implements PipeTransform {
    transform(data: Plano[], inputText?: string) {
        return !Utils.isNullOrEmpty(inputText)
            ? data.filter(x =>
                x.nome?.toLowerCase().includes(inputText.toLowerCase())
                || x.codigo?.toLowerCase().includes(inputText.toLowerCase())
            )
            : data;
    }
}

@Pipe({
    name: 'classesFilter'
})
export class ClassesFilter implements PipeTransform {
    transform(data: Classe[], inputText?: string) {
        return !Utils.isNullOrEmpty(inputText)
            ? data.filter(x =>
                x.nome?.toLowerCase().includes(inputText.toLowerCase())
                || x.codigo?.toLowerCase().includes(inputText.toLowerCase())
            )
            : data;
    }
}

@Pipe({
    name: 'fundamentosFilter'
})
export class FundamentosFilter implements PipeTransform {
    transform(data: FundamentoLegal[], inputText?: string) {
        return !Utils.isNullOrEmpty(inputText)
            ? data.filter(x =>
                x.nome?.toLowerCase().includes(inputText.toLowerCase())
                || x.nivelAcesso?.toLowerCase().includes(inputText.toLowerCase())
            )
            : data;
    }
}

@Pipe({
    name: 'organizacoesFilter'
})
export class OrganizacoesFilter implements PipeTransform {
    transform(data: Organizacao[], inputText?: string) {
        return !Utils.isNullOrEmpty(inputText)
            ? data.filter(x =>
                x.sigla?.toLowerCase().includes(inputText.toLowerCase())
                || x.nomeFantasia?.toLowerCase().includes(inputText.toLowerCase())
            )
            : data;
    }
}

@Pipe({
    name: 'patriarcasFilter'
})
export class PatriarcasFilter implements PipeTransform {
    transform(data: Patriarca[], inputText?: string) {
        return !Utils.isNullOrEmpty(inputText)
            ? data.filter(x =>
                x.sigla?.toLowerCase().includes(inputText.toLowerCase())
                || x.nome?.toLowerCase().includes(inputText.toLowerCase())
            )
            : data;
    }
}

@Pipe({
    name: 'unidadesFilter'
})
export class UnidadesFilter implements PipeTransform {
    transform(data: Unidade[], inputText?: string) {
        return !Utils.isNullOrEmpty(inputText)
            ? data.filter(x =>
                x.nome?.toLowerCase().includes(inputText.toLowerCase())
                || x.nomeCurto?.toLowerCase().includes(inputText.toLowerCase())
            )
            : data;
    }
}

@Pipe({
    name: 'gruposFilter'
})
export class GruposFilter implements PipeTransform {
    transform(data: ConjuntoGrupo[], inputText?: string) {
        return !Utils.isNullOrEmpty(inputText)
            ? data.filter(x => x.nome?.toLowerCase().includes(inputText.toLowerCase()))
            : data;
    }
}

@Pipe({
    name: 'comissoesFilter'
})
export class ComissoesFilter implements PipeTransform {
    transform(data: ConjuntoGrupo[], inputText?: string) {
        return !Utils.isNullOrEmpty(inputText)
            ? data.filter(x => x.nome?.toLowerCase().includes(inputText.toLowerCase()))
            : data;
    }
}

@Pipe({
    name: 'papeisFilter'
})
export class PapeisFilter implements PipeTransform {
    transform(data: AgentePublicoPapel[], inputText?: string) {
        return !Utils.isNullOrEmpty(inputText)
            ? data.filter(x => x.nome?.toLowerCase().includes(inputText.toLowerCase()))
            : data;
    }
}
