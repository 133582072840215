import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FlowDefinition } from '../models/flow.model';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import { Utils } from '../shared/utils';
import { FlowDefinitionServiceGetParams } from './interfaces/flow-definition.interface';
import { AuditLog } from '../models/auditlog.model';

@Injectable({
    providedIn: 'root'
})
export class FlowDefinitionService extends BaseService {
    controllerRoute: string = 'FlowDefinition';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async get(getParams?: FlowDefinitionServiceGetParams): Promise<IBaseModel<FlowDefinition[]>> {
        let httpParams = new HttpParams();

        httpParams = httpParams.append('flowInboxId', getParams.flowInboxId.toString());

        if (getParams?.name != null) httpParams = httpParams.append('name', getParams.name);
        if (getParams?.version != null) httpParams = httpParams.append('version', getParams.version.toString());
        if (getParams?.isActive != null) httpParams = httpParams.append('isActive', getParams.isActive.toString());
        if (getParams?.isPublished != null) httpParams = httpParams.append('isPublished', getParams.isPublished.toString());

        return this.httpClient.get<IBaseModel<FlowDefinition[]>>(`${this.apiBaseUrl}/${this.controllerRoute}`, { params: httpParams }).toPromise();
    }

    public async getById(id: string, version?: number, shouldIncludeFormSchema?: boolean): Promise<IBaseModel<FlowDefinition>> {
        return this.httpClient.get<IBaseModel<FlowDefinition>>(
            `${this.apiBaseUrl}/${this.controllerRoute}/${id}/${(shouldIncludeFormSchema ? 1 : 0)}/${version || ''}`
        ).toPromise();
    }

    public async getHistory(data: any, rowCount?: number): Promise<IBaseModel<AuditLog[]>> {
        return this.httpClient.post<IBaseModel<AuditLog[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/GetHistory/` + (rowCount || ''), data).toPromise();
    }

    public async insert(data: FlowDefinition): Promise<IBaseModel<void>> {
        return this.httpClient.post<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}`, Utils.decycleFlowDefinition(data)).toPromise();
    }

    public async update(data: FlowDefinition): Promise<IBaseModel<void>> {
        return this.httpClient.put<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}`, Utils.decycleFlowDefinition(data)).toPromise();
    }

    public async updateRoot(data: FlowDefinition): Promise<IBaseModel<void>> {
        return this.httpClient.put<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}/UpdateRoot`, Utils.decycleFlowDefinition(data)).toPromise();
    }

    public async delete(id: string): Promise<IBaseModel<void>> {
        return this.httpClient.delete<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}`).toPromise();
    }

    public async activate(id: string): Promise<IBaseModel<void>> {
        return this.httpClient.put<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}/Activate`, null).toPromise();
    }

    public async deactivate(id: string): Promise<IBaseModel<void>> {
        return this.httpClient.put<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}/Deactivate`, null).toPromise();
    }

    public async publish(id: string): Promise<IBaseModel<void>> {
        return this.httpClient.put<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}/Publish`, null).toPromise();
    }

    public async newVersion(id: string): Promise<IBaseModel<void>> {
        return this.httpClient.put<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}/NewVersion`, null).toPromise();
    }

    public async clone(id: string): Promise<IBaseModel<FlowDefinition>> {
        return this.httpClient.put<IBaseModel<FlowDefinition>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}/Clone`, null).toPromise();
    }

    public async addToContext(id: string, contextDispatcher: FlowDefinition): Promise<IBaseModel<void>> {
        return this.httpClient.put<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}/AddToContext`, contextDispatcher).toPromise();
    }

    public async removeFromContext(id: string, contextDispatcher: FlowDefinition): Promise<IBaseModel<void>> {
        return this.httpClient.put<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}/RemoveFromContext`, contextDispatcher).toPromise();
    }

    public async pin(id: string): Promise<IBaseModel<FlowDefinition>> {
        return this.httpClient.put<IBaseModel<FlowDefinition>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}/Pin`, null).toPromise();
    }

    public async unpin(id: string): Promise<IBaseModel<FlowDefinition>> {
        return this.httpClient.put<IBaseModel<FlowDefinition>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}/Unpin`, null).toPromise();
    }
}
